
/* eslint-disable react-hooks/exhaustive-deps */import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LOADER_IMAGE from "../../../Assets/Images/LIU-Loader.gif";
import { useLocation, useNavigate, useParams } from "react-router";
import { CHECKOUT, ORDER_SUCCESS } from "../../../Constants/Route";
import { stripePlaceOrderAction } from "../../../Redux/Pages/Checkout/CheckoutAction";
import { ORDER_PLACED_SUCCESS, STRIPE_ORDER_FAILED, STRIPE_ORDER_SUCESS } from "../../../Redux/Pages/Checkout/CheckoutTypes";



const StripePaymentProgress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params : any = useParams();

  const stripePaymentReducer = useSelector(
    (state: any) => state.stripePaymentReducer
  );

  const checkoutResponse = useSelector(
    (state: any) => state.checkoutResponse
  );

 
  
  useEffect(() => {
    if (
      stripePaymentReducer &&
      stripePaymentReducer.type === STRIPE_ORDER_SUCESS &&  checkoutResponse && checkoutResponse.type === ORDER_PLACED_SUCCESS && checkoutResponse?.data
    ) {
      navigate(ORDER_SUCCESS + "?fromCheckout=True");
    }else if(stripePaymentReducer.type === STRIPE_ORDER_FAILED) {
        navigate(CHECKOUT);
    }
  }, [stripePaymentReducer , checkoutResponse]);

  return (
    <p className="order-processing">
      <img src={LOADER_IMAGE} alt="Loader" />
      Please wait while we process your request...
    </p>
  );
};

export default StripePaymentProgress;

