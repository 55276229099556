/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import cateimg1 from "../../Assets/Images/bespoke.svg";
import cateimg2 from "../../Assets/Images/fabric.svg";
import { useEffect, useState } from "react";
import ProductListSkl from "./ProductListSkl";
import { useDispatch, useSelector } from "react-redux";
import { getProductListData } from "../../Redux/Pages/Products/ProductActions";
import { GET_PRODUCT_LIST_SUCCESS } from "../../Redux/Pages/Products/ProductTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { IProductResponse } from "../../Interfaces/Products.interface";
import { GET_CMS_PAGE_FAILED } from "../../Redux/Pages/CMSPage/CMSPageTypes";
import ProductListView from "./ProductListView";
import ProductListInnerSkl from "./ProductListInnerSkl";
import { useLocation } from "react-router";
import FAQs from "../FAQs/FAQs";
import FAQ from "../FAQ/FAQ";
import seoCodeFaqs from "../../Utility/seo_schema_code.json";
import { getFaqFromSeoCode } from "../../Utility/Utilities";
import Flowbox from "../Flowbox/Flowbox";
import { FLOWBOX_CONTAINER } from "../../Constants/Labels";
import { FLOWBOX_CATEGORY_PAGE_FLOW_KEY } from "../../Constants/Config";

const ProductList = (props: {
  category?: string;
  subCategory?: string;
  slug?: string;
  entity_uid?: string;
}) => {
  const INITIAL_PATH = {
    path: "",
    label: "Home",
  };
  const [breadcrumbs, setBreadcrumbs] = useState<any>([INITIAL_PATH]);
  const location = useLocation();
  const [flowBoxTag , setFlowBoxTag] = useState("");
  const [reqData, setReqData] = useState({
    page: 1,
    size: 21,
    search: props.slug,
    entity_uid: props.entity_uid,
  });

  const INITIAL_STATE = {
    items: [],
    hasMore: false,
    total_count: 0,
    page_info: {
      current_page: 0,
      page_size: 0,
      total_pages: 0,
    },
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [faqData, setFaqData] = useState<any>([]);

  const [productListData, setProductList] =
    useState<IProductResponse>(INITIAL_STATE);

  const productListResponse = useSelector((state: any) => state.productList);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_PRODUCT_LIST_SUCCESS,
        payload: {
          type: GET_PRODUCT_LIST_SUCCESS,
          data: null,
        },
      });
      setProductList(INITIAL_STATE);
      setBreadcrumbs(INITIAL_PATH);
    };
  }, []);

  useEffect(() => {
    setProductList(INITIAL_STATE);
    setBreadcrumbs(INITIAL_PATH);
  }, [props]);

  useEffect(() => {
    if (productListResponse) {
      if (productListResponse.type === GET_PRODUCT_LIST_SUCCESS) {
        if (
          productListResponse.data &&
          productListResponse.data.products &&
          productListResponse.data.categoryList &&
          productListResponse.data.categoryList[0] &&
          productListResponse?.data?.categoryList[0].display_mode !== "PAGE"
        ) {
          const currState = Object.assign({}, productListData);
          currState.page_info = productListResponse.data.products?.page_info;
          currState.hasMore =
            reqData.page <
            productListResponse.data.products?.page_info?.total_pages;
          currState.total_count =
            productListResponse.data?.products?.total_count;
          currState.items.push(...productListResponse.data.products?.items);
          setProductList(currState);
        }
        setLoading(false);
      } else if (productListResponse.type === GET_CMS_PAGE_FAILED) {
        setProductList(INITIAL_STATE);
        setLoading(false);
      }
    }
  }, [productListResponse]);

  useEffect(() => {
    if (location.pathname) {
      if (seoCodeFaqs.hasOwnProperty(location?.pathname?.slice(1))) {
        const faqs = getFaqFromSeoCode(
          seoCodeFaqs,
          location?.pathname?.slice(1)
        );
        setFaqData(faqs);
      }
      setProductList(INITIAL_STATE);
    }
  }, [location.pathname]);

  useEffect(() => {
    let breadcrumbs: any[] = [INITIAL_PATH];
    if (props.category) {
      breadcrumbs.push({
        path: props.category + ".html",
        label: props.category.split(".html")[0],
      });
      if (props.subCategory) {
        breadcrumbs.push({
          path: props.subCategory + ".html",
          label: props.subCategory.split(".html")[0],
        });
      }
    }
    if (props.slug) {
      breadcrumbs.push({
        path: props.slug,
        label: props.slug.split(".html")[0],
      });
    }
    setBreadcrumbs(breadcrumbs);
    setLoading(true);
    const currReqData = Object.assign({}, reqData);
    currReqData.entity_uid = props.entity_uid;
    setReqData(currReqData);
    // dispatch(getProductListData(currReqData));
  }, [props.entity_uid]);

  const fetchProductList = (page = 1) => {
    if (page <= productListData.page_info.total_pages) {
      const currReqData = Object.assign({}, reqData);
      currReqData.page = page;
      setReqData(currReqData);
      dispatch(getProductListData(currReqData));
    } else {
      setProductList({
        ...productListData,
        hasMore: false,
      });
    }
  };

  useEffect(() => {
    let tag = props?.subCategory;
    if (!tag) tag = props?.category;
    if (!tag) tag = props?.slug;
    tag = tag?.split(".")?.[0] ?? "";
    setFlowBoxTag(tag);
  }, [props?.category , props?.subCategory])

  const [isOff, setIsOff] = useState(true);

  let firstP =
    productListResponse?.data?.categoryList[0]?.category_content?.substring(
      0,
      productListResponse?.data?.categoryList[0]?.category_content?.search(
        "</p>"
      ) + 4
    );
  let strLength =
    productListResponse?.data?.categoryList[0]?.category_content?.length;
  let secondContent =
    productListResponse?.data?.categoryList[0]?.category_content?.slice(
      productListResponse?.data?.categoryList[0]?.category_content?.search(
        "</p>"
      ) + 4,
      strLength
    );

  return (
    <>
      {loading ? (
        <ProductListSkl />
      ) : (
        <>
          <div className="categories">
            <div className="custom-container">
              {breadcrumbs && breadcrumbs.length > 1 ? (
                <Breadcrumbs value={breadcrumbs}></Breadcrumbs>
              ) : null}
              {productListResponse?.data?.categoryList[0] &&
              productListResponse?.data?.categoryList[0]?.image ? (
                <div className="cat-banner">
                  <div className="cat-txt">
                    <h1 className="caps-on">
                      {productListResponse?.data?.categoryList[0]?.name}
                    </h1>
                    <p
                      className="bm"
                      dangerouslySetInnerHTML={{
                        __html:
                          productListResponse?.data?.categoryList[0]
                            ?.description,
                      }}
                    ></p>
                    <div className="cat-service">
                      <div className="cat-service-img">
                        <img
                          src={cateimg1}
                          alt={"Bespoke Service"}
                          title={"Bespoke Service"}
                        />
                      </div>
                      <div className="cat-service-txt">
                        <p className="bm">Bespoke Service</p>
                      </div>
                    </div>
                    <div className="cat-service">
                      <div className="cat-service-img">
                        <img
                          src={cateimg2}
                          alt={"Free Fabric Samples"}
                          title={"Free Fabric Samples"}
                        />
                      </div>
                      <div className="cat-service-txt">
                        <p className="bm">Free Fabric Samples</p>
                      </div>
                    </div>
                  </div>
                  <div className="cat-banner-img">
                    {productListResponse?.data?.categoryList[0]?.image && (
                      <img
                        className="show-992"
                        src={productListResponse?.data?.categoryList[0]?.image}
                        alt={productListResponse?.data?.categoryList[0]?.name}
                        title={productListResponse?.data?.categoryList[0]?.name}
                      />
                    )}
                    {productListResponse?.data?.categoryList[0]
                      ?.mobile_thumbnail ? (
                      <img
                        className="hide-991"
                        src={
                          process.env.REACT_APP_BASE_MEDIA_URL?.split(
                            "media"
                          )[0] +
                          productListResponse?.data?.categoryList[0]
                            ?.mobile_thumbnail
                        }
                        alt={productListResponse?.data?.categoryList[0]?.name}
                        title={productListResponse?.data?.categoryList[0]?.name}
                      />
                    ) : null}
                  </div>
                </div>
              ) : (
                ""
              )}
              {productListResponse?.data?.categoryList &&
              productListResponse?.data?.categoryList.length > 0 &&
              (productListResponse?.data?.categoryList[0].display_mode ===
                "PAGE" ||
                productListResponse?.data?.categoryList[0].display_mode ===
                  "PRODUCTS_AND_PAGE") &&
              productListResponse.data?.categoryList[0]?.cms_block_custom ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      productListResponse?.data?.categoryList[0]
                        ?.cms_block_custom?.content,
                  }}
                ></div>
              ) : null}
              <div
                className={
                  !loading
                    ? productListResponse?.data?.categoryList &&
                      productListResponse?.data?.categoryList.length > 0 &&
                      productListResponse?.data?.categoryList[0]
                        .display_mode === "PAGE"
                      ? "product-list d-none"
                      : "product-list"
                    : "product-list loading"
                }
              >
                {loading ? (
                  <ProductListSkl />
                ) : productListData.items.length > 0 ? (
                  <InfiniteScroll
                    dataLength={productListData.items.length}
                    next={() => fetchProductList(reqData.page + 1)}
                    hasMore={productListData.hasMore}
                    loader={<ProductListInnerSkl />}
                    className="product-list"
                  >
                    {productListData.items
                      .filter((data) => data)
                      .map((data: any, index: any) => {
                        return (
                          <ProductListView
                            index={index}
                            key={index}
                            {...data}
                          />
                        );
                      })}
                  </InfiniteScroll>
                ) : (
                  <p className="tm nodata-found">
                    We can't find products matching the selection.
                  </p>
                )}
              </div>
              {
                flowBoxTag && (<Flowbox containerName={FLOWBOX_CONTAINER.CATEGORY_PAGE} flowKey={FLOWBOX_CATEGORY_PAGE_FLOW_KEY} tags={[flowBoxTag]} />)
              }
              <div className="additional-desc">
                {productListResponse?.data?.categoryList[0]
                  ?.category_content ? (
                  <>
                    <h2 className="caps-on">
                      {productListResponse?.data?.categoryList[0]?.name}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: firstP }}></p>
                    {strLength >
                    productListResponse?.data?.categoryList[0]?.category_content?.search(
                      "</p>"
                    ) +
                      4 ? (
                      <>
                        <button
                          className={isOff ? "rdrl-btn on" : "rdrl-btn off"}
                          onClick={() => setIsOff(!isOff)}
                        >
                          {isOff ? "Read More" : "Read Less"}
                        </button>
                        {isOff ? null : (
                          <div
                            className="more-desc"
                            dangerouslySetInnerHTML={{ __html: secondContent }}
                          ></div>
                        )}
                        <button
                          className={isOff ? "rdrl-btn off" : "rdrl-btn on"}
                          onClick={() => setIsOff(!isOff)}
                        >
                          {isOff ? "Read More" : "Read Less"}
                        </button>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
              {faqData.length ? <FAQ title={`Faqs`} faqs={faqData} /> : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductList;
