import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";

const MobileProductCMSContent = (props: {
  setFreesampleModalShow: any;
  dimensions: string;
  description: string;
  whyus: string;
  delivery: string;
}) => {
  const [freesampleModalShow, setfreesampleModalShow] = useState(false);
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("fspopup")?.addEventListener("click", (e) => {
        setfreesampleModalShow(true);
      });
    }, 100);
  }, []);

  return (
    <>
      <div className="custom-container">
        <div className="product-accordion">
          <Accordion>
            {props.description && props.description !== "" ? (
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h6>DESCRIPTION</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {props.dimensions && props.dimensions !== "" ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h6>DIMENSIONS</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="dimensions-box"
                    dangerouslySetInnerHTML={{ __html: props.dimensions }}
                  ></div>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {props.delivery && props.delivery !== "" ? (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h6>DELIVERY</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: props.delivery }} />
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {props.whyus && props.whyus !== "" ? (
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h6>WHY US</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: props.whyus }} />
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
          </Accordion>
        </div>
      </div>
      <FreeSamplesPopup
        storeConfigInfo={storeConResponse}
        show={freesampleModalShow}
        onHide={() => setfreesampleModalShow(false)}
      />
    </>
  );
};

export default MobileProductCMSContent;
