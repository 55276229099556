const CheckboxGroups = (props: any) => {
  return (
    <div className={props.variant === "withdot" ? "ck" : "ck-tick"}>
      <input
        type="checkbox"
        value={props.value}
        checked={props.value}
        name={props.name}
        id={props.id}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
      />
      <label className="bm" htmlFor={props.id}>
        {props.children}
      </label>
    </div>
  );
};

export default CheckboxGroups;
