import  { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate} from 'react-router';
import { verifySplashKeyAction } from '../../../../Redux/Pages/Checkout/CheckoutAction';
import { VERIFY_SPLASH_KEY_FAILED, VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID, VERIFY_SPLASH_KEY_REQUEST, VERIFY_SPLASH_KEY_SUCCESS } from '../../../../Redux/Pages/Checkout/CheckoutTypes';
import LOADER_IMAGE from "../../../../Assets/Images/LIU-Loader.gif";
import { CHECKOUT, HOME, ORDER_SUCCESS } from '../../../../Constants/Route';
import { setSessionItem, showToast } from '../../../../Utility/Utilities';
import { CART_ID } from '../../../../Constants/LocalStorage';
import { getCartCountAction } from '../../../../Redux/Pages/CartPage/CartPageActions';
import { ERROR } from '../../../../Constants/Labels';


 const  DivideBuyProgress = () => {
    const location : any = useLocation()
    const dispatch = useDispatch();
    const  verifySplashKeyResponse = useSelector((state : any) => state.divideBuyReducer);
    const navigate = useNavigate();
    
    useEffect(() => {
        if(location.search){
            const params = new URLSearchParams(location.search);
            const splashKey = params.get("splashKey");
            if(splashKey && splashKey != ""){
                dispatch(verifySplashKeyAction(splashKey))  
            } 
        }
    },[location]);


    useEffect(() => {
        if(verifySplashKeyResponse.type === VERIFY_SPLASH_KEY_REQUEST){
                 
        }else if (verifySplashKeyResponse.type === VERIFY_SPLASH_KEY_SUCCESS){
            // redirect on order sucess page 
            navigate(ORDER_SUCCESS + "?fromCheckout=True");
        }else if (verifySplashKeyResponse.type === VERIFY_SPLASH_KEY_FAILED){
            // save quote id in local and redirect customer to checkout page 
            navigate(HOME);
        }
        else if (verifySplashKeyResponse.type === VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID){
            // save quote id in local and redirect customer to checkout page 
            if(verifySplashKeyResponse?.data){
                setSessionItem(CART_ID , verifySplashKeyResponse?.data)
                dispatch(getCartCountAction());
                navigate(CHECKOUT);
            }            
        }
    },[verifySplashKeyResponse])


    return (
        <p className="order-processing">
          <img src={LOADER_IMAGE} alt="Loader" />
          Please wait while we process your request...
        </p>
      );
}

export default DivideBuyProgress
