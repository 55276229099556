/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CREATE_RAPYD_CHECKOUT_ID_REQUEST,
  CREATE_RAPYD_CHECKOUT_ID_FAILED,
  CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
  RAPYD_ORDER_SUCCESS,
  RAPYD_ORDER_FAILED,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import { useDispatch } from "react-redux";
import {
  getRapydCheckoutIdAction,
  // rapydPlaceOrderAction,
} from "../../Redux/Pages/Checkout/CheckoutAction";
import {
  AWAITING_PAYMENT,
  PAYMENT_SUCCESSFUL,
  CANCELED_BY_MERCHENT_OR_CUSTOMER_BANK as CANCELLED_BY_MERCHANT_OR_CUSTOMER_BANK,
  EXPIRED,
  ON_CHECKOUT_PAYMENT_SUCCESS,
  ON_CHECKOUT_PAYMENT_FAILURE,
  ON_CHECKOUT_FAILURE,
} from "../../Constants/PaymentStatus";
import { getSessionItem, isAuth, scrollTop, showToast } from "../../Utility/Utilities";
import { ERROR, PAYMENT_ACTIONS, PAYMENT_METHOD } from "../../Constants/Labels";
import { CHECKOUT, PAYMENT_PROGRESS } from "../../Constants/Route";
import { useNavigate } from "react-router";
import LOADER_IMAGE from "../../Assets/Images/LIU-Loader.gif";
import { createLogs } from "../../Services/axios.service";

declare global {
  interface Window {
    RapydCheckoutToolkit: any;
  }
}

function RapydPayment(props: {
  setMobileState: any;
  mobileState: any;
  showContinueBtn: any;
  setSubmitLogin: any;
  checkoutResponse: any;
  cartResponse: any;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rapydPaymentResponse = useSelector(
    (state: any) => state.RapydPaymentReducer
  );

  const checkoutResponse = useSelector(
    (state: any) => state.checkoutResponse?.data
  );


  const handlePaymentSuccessful = async (response: any) => {
    let logStatus = null;
    if (response.status === PAYMENT_SUCCESSFUL) {
      // showToast(SUCCESS, "Payment Successful.");
      // dispatch(rapydPlaceOrderAction());
      navigate(PAYMENT_PROGRESS);
    } else if (response.status === AWAITING_PAYMENT) {
      showToast(ERROR, "Awaiting payment");
      // window.location.href = CHECKOUT;
      scrollTop();
      // dispatch(rapydPlaceOrderAction());
      window.location.href = response.redirect_url;
    } else if (response.status === CANCELLED_BY_MERCHANT_OR_CUSTOMER_BANK) {
      logStatus = ERROR;
      showToast(ERROR, "Payment is canceled by merchant or customer bank.");
      window.location.href = CHECKOUT;
    } else if (response.status === EXPIRED) {
      logStatus = ERROR;
      showToast(ERROR, "Payment is expired. Please try after some time.");
      window.location.href = CHECKOUT;
    } else {
      logStatus = ERROR;
      showToast(ERROR, "Something went wrong please try again.");
      // error while processing payment
      window.location.href = CHECKOUT;
    }
    createLogs({
      action: PAYMENT_ACTIONS.RAPYD_ON_CHECKOUT_PAYMENT_SUCCESS,
      payment_method: PAYMENT_METHOD.RAPYD,
      api_response: JSON.stringify(response),
      api_request: null,
      amount: checkoutResponse?.prices?.grand_total?.value,
      status: logStatus
    });
  };

  useEffect(() => {
    function oncheckoutPaymentSuccess(event:any){
      if (getSessionItem('guest_email') || isAuth()) {
        const response = event.detail;
        handlePaymentSuccessful(response);
      } else {
        // scrollTop();
        props.setSubmitLogin(true)
      }
    }
    function onCheckoutFailure(event: any){
      dispatch(getRapydCheckoutIdAction());
      createLogs({
        action: PAYMENT_ACTIONS.RAPYD_CHECKOUT_FAILURE,
        payment_method: PAYMENT_METHOD.RAPYD,
        api_response: JSON.stringify(event),
        api_request: null,
        amount: checkoutResponse?.prices?.grand_total?.value,
        status: ERROR
      });
      console.log("FAILURE ", event);
      showToast(ERROR, event.detail.error);
    }

    function onChckoutPaymentFailure(event: any){
      dispatch(getRapydCheckoutIdAction());
      createLogs({
        action: PAYMENT_ACTIONS.RAPYD_CHECKOUT_PAYMENT_FAILURE,
        payment_method: PAYMENT_METHOD.RAPYD,
        api_response: JSON.stringify(event),
        api_request: null,
        amount: checkoutResponse?.prices?.grand_total?.value,
        status: ERROR
      });

      console.log("CHECKOUT PAYMENT FAILURE ", event);
      showToast(ERROR, event.detail.error);
    }
    window.addEventListener(ON_CHECKOUT_PAYMENT_SUCCESS, oncheckoutPaymentSuccess);
    window.addEventListener(ON_CHECKOUT_FAILURE, onCheckoutFailure);
    window.addEventListener(ON_CHECKOUT_PAYMENT_FAILURE, onChckoutPaymentFailure);
   
    if (!rapydPaymentResponse?.data) {
      dispatch(getRapydCheckoutIdAction());
    }
    if (props.setMobileState) {
      props.setMobileState({
        ...props.mobileState,
        notShow: true,
      });
    }
    return () => {
      window.removeEventListener(ON_CHECKOUT_PAYMENT_SUCCESS, oncheckoutPaymentSuccess);
      window.removeEventListener(ON_CHECKOUT_FAILURE, onCheckoutFailure);
      window.removeEventListener(ON_CHECKOUT_PAYMENT_FAILURE, onChckoutPaymentFailure);
    };
  }, []);

  useEffect(() => {
    if (
      rapydPaymentResponse &&
      rapydPaymentResponse.type === CREATE_RAPYD_CHECKOUT_ID_REQUEST
    ) {
      console.log("inside request", rapydPaymentResponse);
    } else if (
      rapydPaymentResponse &&
      rapydPaymentResponse.type === CREATE_RAPYD_CHECKOUT_ID_FAILED
    ) {
      console.log("inside failed", rapydPaymentResponse);
    } else if (
      rapydPaymentResponse &&
      rapydPaymentResponse.type === CREATE_RAPYD_CHECKOUT_ID_SUCCESS &&
      rapydPaymentResponse.data
    ) {
      const checkoutId = rapydPaymentResponse?.data;
      if (checkoutId) {
        let checkout = new window.RapydCheckoutToolkit({
          pay_button_text: "Pay Now",
          pay_button_color: "#4BB4D2",
          id: checkoutId, // your checkout page id goes here
          style: {},
        });
        dispatch({
          type: CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
          payload: {
            type: CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
            data: null
          }
        })
        let rapydId : any = document?.getElementById("rapyd-checkout");
        rapydId.innerHTML = "";
        checkout.displayCheckout();
      }
    } else if (
      rapydPaymentResponse &&
      rapydPaymentResponse.type === RAPYD_ORDER_SUCCESS
    ) {
    } else if (
      rapydPaymentResponse &&
      rapydPaymentResponse.type === RAPYD_ORDER_FAILED
    ) {
      window.location.href = CHECKOUT;
    }
  }, [rapydPaymentResponse]);

  return (
    <>
      <div className="row justify-content-center">
        <div
          className="col"
          style={{ maxWidth: "500px" }}
          id="rapyd-checkout"
        >
          {rapydPaymentResponse?.type === CREATE_RAPYD_CHECKOUT_ID_REQUEST &&
            <p className="order-processing">
              <img src={LOADER_IMAGE} alt="Loader" />
              Please wait while we process your request...
            </p>
          }
        </div>
      </div>
    </>
  );
}

export default RapydPayment;
