/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { scrollTop } from './Utilities';

function ScrollToTop() {
    useEffect(() => {
        // window.scrollTo(0, 0);
        scrollTop()
    }, []);

    return (null);
}

export default ScrollToTop;