import {
  CHECKOUT_ADDRESS_FAILED,
  CHECKOUT_ADDRESS_REQUEST,
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_DELIVERY_METHOD_FAILED,
  CHECKOUT_DELIVERY_METHOD_REQUEST,
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_FAILED,
  CHECKOUT_GET_DELIVERY_METHOD_REQUEST,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  CHECK_EMAIL_AVAILABLE_SUCCESS,
  CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
  CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS,
  GET_BRAINTREETOKEN_FAILED,
  GET_BRAINTREETOKEN_REQUEST,
  GET_BRAINTREETOKEN_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
  CREATE_RAPYD_CHECKOUT_ID_REQUEST,
  CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
  CREATE_RAPYD_CHECKOUT_ID_FAILED,
  RAPYD_ORDER_SUCCESS,
  RAPYD_ORDER_FAILED,
  STRIPE_ORDER_SUCESS,
  STRIPE_ORDER_FAILED,
  STRIPE_ORDER_REQUEST,
  STRIPE_ORDER_CLEAR,
  STRIPE_ORDER_AUTHENTICATION_REQUIRED,
  CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST,
  DIVIDEBUY_SPLASH_KEY_SUCCESS,
  DIVIDEBUY_ORDER_SUCCESS,
  DIVIDEBUY_SPLASH_KEY_REQUEST,
  DIVIDEBUY_SPLASH_KEY_FAILED,
  VERIFY_SPLASH_KEY_FAILED,
  VERIFY_SPLASH_KEY_SUCCESS,
  VERIFY_SPLASH_KEY_REQUEST,
  PAYMENT_INSTALLMENT_FAILED,
  PAYMENT_INSTALLMENT_REQUEST,
  PAYMENT_INSTALLMENT_SUCCESS,
  VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
  GET_PAYMENT_MIN_AMOUNT_REQUEST,
  GET_PAYMENT_MIN_AMOUNT_FAILED,
  GET_PAYMENT_MIN_AMOUNT_SUCCESS,
} from "./CheckoutTypes";

export function checkoutResponse(state = {}, action: any) {
  switch (action.type) {
    case CHECKOUT_ADDRESS_REQUEST:
      return { ...action.payload };
    case CHECKOUT_ADDRESS_SUCCESS:
      return { ...action.payload };
    case CHECKOUT_ADDRESS_FAILED:
      return { ...action.payload };
    case CHECKOUT_GET_DELIVERY_METHOD_REQUEST:
      return { ...action.payload };
    case CHECKOUT_GET_DELIVERY_METHOD_SUCCESS:
      return { ...action.payload };
    case CHECKOUT_GET_DELIVERY_METHOD_FAILED:
      return { ...action.payload };
    case CHECKOUT_DELIVERY_METHOD_REQUEST:
      return { ...action.payload };
    case CHECKOUT_DELIVERY_METHOD_SUCCESS:
      return { ...action.payload };
    case CHECKOUT_DELIVERY_METHOD_FAILED:
      return { ...action.payload };
    case GET_BRAINTREETOKEN_REQUEST:
      return { ...action.payload };
    case GET_BRAINTREETOKEN_SUCCESS:
      return { ...action.payload };
    case GET_BRAINTREETOKEN_FAILED:
      return { ...action.payload };
    case ORDER_PLACED_SUCCESS:
      return { ...action.payload };
    case ORDER_PLACED_FAILED:
      return { ...action.payload };
    case CHECK_EMAIL_AVAILABLE_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

export const paypalExpressReducer = (state = {}, action: any) => {
  switch (action.type) {
    case CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST:
      return { ...action.payload };
    case CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS:
      return { ...action.payload };
    case CREATE_PAYPAL_EXPRESS_TOKEN_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
};

export const RapydPaymentReducer = (state = {}, action: any) => {
  switch (action.type) {
    case CREATE_RAPYD_CHECKOUT_ID_REQUEST:
      return { ...action.payload };
    case CREATE_RAPYD_CHECKOUT_ID_SUCCESS:
      return { ...action.payload };
    case CREATE_RAPYD_CHECKOUT_ID_FAILED:
      return { ...action.payload };
    case RAPYD_ORDER_SUCCESS:
      return { ...action.payload };
    case RAPYD_ORDER_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
};

export const stripePaymentReducer = (state = {}, action: any) => {
  switch (action.type) {
    case STRIPE_ORDER_REQUEST:
      return { ...action.payload };
    case STRIPE_ORDER_SUCESS:
      return { ...action.payload };
    case STRIPE_ORDER_FAILED:
      return { ...action.payload };
    case STRIPE_ORDER_AUTHENTICATION_REQUIRED:
      return { ...action.payload };
    case STRIPE_ORDER_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
};

export const divideBuyReducer = (state = {}, action: any) => {
  switch (action.type) {
    case DIVIDEBUY_SPLASH_KEY_REQUEST:
      return { ...action.payload };
    case DIVIDEBUY_SPLASH_KEY_SUCCESS:
      return { ...action.payload };
    case DIVIDEBUY_SPLASH_KEY_FAILED:
      return { ...action.payload };
    case DIVIDEBUY_ORDER_SUCCESS:
      return { ...action.payload };
    case VERIFY_SPLASH_KEY_REQUEST:
      return { ...action.payload };
      case VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID:
        return { ...action.payload };
    case VERIFY_SPLASH_KEY_SUCCESS:
      return { ...action.payload };
    case VERIFY_SPLASH_KEY_FAILED:
      return { ...action.payload };

    default:
      return state;
  }
};

export const paymentInstallmentReducer = (state = {} , action : any) => {
  switch (action.type) {
    case PAYMENT_INSTALLMENT_REQUEST:
      return { ...action.payload };
    case PAYMENT_INSTALLMENT_SUCCESS:
      return { ...action.payload };
    case PAYMENT_INSTALLMENT_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}


export const getPaymentMinAmountReducer = (state = {}, action: any) => {
  switch (action.type) {
      case GET_PAYMENT_MIN_AMOUNT_REQUEST:
          return { ...action.payload }
      case GET_PAYMENT_MIN_AMOUNT_SUCCESS:
          return { ...action.payload }
      case GET_PAYMENT_MIN_AMOUNT_FAILED:
          return { ...action.payload }
      default:
          return state
  }
}