/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import {
  getUserDetail,
  updateNewsLetterData,
} from "../../../Redux/Auth/AuthAction";
import { UPDATE_NEWS_LETTER_FAILED, UPDATE_NEWS_LETTER_SUCCESS } from "../../../Redux/Auth/AuthType";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../../Common/CustomButton/CustomButton";
import Sidebar from "../Sidebar/Sidebar";

const Newsletter = () => {
  const dispatch = useDispatch();

  const [isSubcribed, setSubcribed] = useState(false);
  const [firstname, setFirstName] = useState("")

  const userData = useSelector((state: any) => state?.userResponse);

  const newsLetterData = useSelector((state: any) => state?.updateNewsLetter);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = () => {
    setLoading(true);
    dispatch(updateNewsLetterData(isSubcribed, false, firstname));
  };

  useEffect(() => {
    if (userData && userData.data) {
      setSubcribed(userData.data.is_subscribed);
      setFirstName(userData.data.firstname);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getUserDetail(false));
  }, []);

  useEffect(() => {
    if (newsLetterData) {
      if (newsLetterData.type === UPDATE_NEWS_LETTER_SUCCESS) {
        setLoading(false);
        // setTimeout(() => setLoading(false), 3000);
      } else if (newsLetterData.type === UPDATE_NEWS_LETTER_FAILED) {
        setLoading(false);
      }
    }
  }, [newsLetterData]);

  console.log("userData", userData)
  return (
    <div className="account-container">
      <div className="ll hide-991">
        <Link to={MY_DETAIL} className="acc-backbtn ll">
          My Account
        </Link>
      </div>
      <div className="account-wrap">
        <Sidebar />
        <div className="acc-rightwrap">
          <div className="newsletter-wrap">
            <h1 className="caps-on">Newsletter Subscription</h1>
            <div className="news-sub">
              <p className="box-title tm">Subscription option</p>
              <CheckboxGroups
                defaultChecked={isSubcribed}
                value={isSubcribed}
                onChange={(e: any) => setSubcribed(e.target.checked)}
                id="news-box"
              >
                General Subscription
              </CheckboxGroups>
            </div>
            <div className="news-action">
              <CustomButton isLoading={loading} onClick={onSubmit} bg={"fill"}>
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
