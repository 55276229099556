/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IRelatedProducts } from "../../Interfaces/ProductDetail.interface";
import { GET_UPSELL_PRODUCT_DETAIL_SUCCESS } from "../../Redux/Pages/Products/ProductTypes";

const RelatedProducts = () => {
  const location = useLocation();
  const [relatedProductSlider, setRelatedProductSlider] = useState({
    autoplay: true,
    loop: true,
    dots: false,
    nav: false,
    responsiveClass: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1.2,
        margin: 16,
      },
      768: {
        items: 3,
        margin: 24,
      },
    },
  });

  const [data, setData] = useState([]);

  const upsellProductResponse = useSelector(
    (state: any) => state.productDetailResponse
  );

  useEffect(() => {
    if (upsellProductResponse.type === GET_UPSELL_PRODUCT_DETAIL_SUCCESS) {
      setData(upsellProductResponse.data);
      const currentProductSlider = Object.assign({}, relatedProductSlider);
      currentProductSlider.responsive = {
        0: {
          items: 1.2,
          margin: 16,
        },
        768: {
          items: 3,
          // items: upsellProductResponse.data?.length,
          margin: 24,
        },
      };
      setRelatedProductSlider(currentProductSlider);
    }
  }, [upsellProductResponse]);

  return data.length > 0 ? (
    <div className="related-product">
      <div className="custom-container">
        <h6 className="caps-on sec-title">YOU MAY ALSO LIKE</h6>
      </div>
      <div className="product-slider">
        <div className="custom-container">
          {data.length > 2 ? (
            <OwlCarousel className="owl-theme" {...relatedProductSlider}>
              {data?.map((data: IRelatedProducts) => {
                return (
                  <div key={data.name} className="list-item">
                    <div className="image-top">
                      <Link
                        to={"/" + data.url_key + data.url_suffix}
                        title={data.name}
                        state={{path:location.pathname}}
                      >
                        <img
                          src={data.small_image?.url}
                          alt={data.small_image?.label}
                        />
                      </Link>
                    </div>
                    <div className="product-details">
                      <Link
                        to={"/" + data.url_key + data.url_suffix}
                        className="pdt-name bm"
                        title={data.name}
                        state={{path:location.pathname}}
                      >
                        {data.name}
                      </Link>
                      <div className="price-wrapper">
                        {data.price_range.minimum_price.final_price.value !==
                        data.price_range.minimum_price.regular_price.value ? (
                          <>
                            <p className="product-price">
                              £
                              {Number(
                                data.price_range.minimum_price.regular_price
                                  .value
                              ).toFixed(2)}
                            </p>
                            <p className="special-price">
                              £
                              {data.price_range.minimum_price.final_price.value.toFixed(
                                2
                              )}
                            </p>
                          </>
                        ) : (
                          <p className="normal-price">
                            £
                            {Number(
                              data.price_range.minimum_price.final_price.value
                            ).toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            <div className="without-slider">
              {data?.map((data: IRelatedProducts) => {
                return (
                  <div key={data.name} className="list-item">
                    <div className="image-top">
                      <Link
                        to={"/" + data.url_key + data.url_suffix}
                        title={data.name}
                        state={{path:location.pathname}}
                      >
                        <img
                          src={data.small_image?.url}
                          alt={data.small_image?.label}
                        />
                      </Link>
                    </div>
                    <div className="product-details">
                      <Link
                        to={"/" + data.url_key + data.url_suffix}
                        className="pdt-name bm"
                        title={data.name}
                        state={{path:location.pathname}}
                      >
                        {data.name}
                      </Link>
                      <div className="price-wrapper">
                        {data.price_range.minimum_price.final_price.value !==
                        data.price_range.minimum_price.regular_price.value ? (
                          <>
                            <p className="product-price">
                              £
                              {Number(
                                data.price_range.minimum_price.regular_price
                                  .value
                              ).toFixed(2)}
                            </p>

                            <p className="special-price">
                              £
                              {data.price_range.minimum_price.final_price.value.toFixed(
                                2
                              )}
                            </p>
                          </>
                        ) : (
                          <p className="normal-price">
                            £
                            {Number(
                              data.price_range.minimum_price.final_price.value
                            ).toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default RelatedProducts;
