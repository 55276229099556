/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Link } from "react-router-dom";
import BlogPagePlaceholderImage from "../../Assets/Images/BlogPagePlaceholder.jpg";

const BlogList = (props: { data: any[] }) => {
  return props.data ? (
    <ul className="list-items">
      {props.data
        ?.filter((data: any) => data)
        .map((item: any, index: any) => (
          <>
            <li className="item" key={index + item.url_key}>
              <div className="img-section">
                <Link
                  to={"/blog/" + item.url_key + ".html"}
                  title={item.title}
                  className="tm blog-name"
                >
                  <img
                    src={item?.list_thumbnail || BlogPagePlaceholderImage}
                    alt={item?.title}
                  />
                </Link>
              </div>
              <div className="info-section">
               
                <Link
                  to={"/blog/" + item.url_key + ".html"}
                  title={item?.title}
                  className="tm blog-name"
                >
                  {item.title}
                </Link>
                <div className="extra-info">
                  <div className="bm author">
                    By{" "}
                    <Link to={""} title={item?.author_name}>
                      {item?.author_name}
                    </Link>
                  </div>
                  <div className="bm pub-date">
                    <p className="bm">
                      {moment(item?.published_at).format("D MMM YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </>
        ))}
    </ul>
  ) : (
    <p className="tm nodata-found">
      We can't find blogs matching the selection.
    </p>
  );
};
export default BlogList;
