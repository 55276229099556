/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useEffect, useRef, useState } from "react";
import RelatedProductPopup from "./RelatedProductPopup";
import info from "../../Assets/Images/info.svg";
import SelectGroups from "../Common/SelectGroups/SelectGroups";
import CheckboxGroups from "../Common/CheckboxGroups/CheckboxGroups";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import { useLocation } from "react-router";
import {
  GroupedColors,
  IConfigurationOption,
  IConfigurationOptionValues,
  IProductDetail,
  IRelatedProducts,
  IVariant,
  SelectedProductOption,
} from "../../Interfaces/ProductDetail.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getCMSContent,
  getProductDetailByName,
  getProductDetailFabricInformation,
} from "../../Redux/Pages/Products/ProductActions";
import {
  ADD_TO_WISHLIST_REQUEST,
  GET_CONFIGURABLE_PRODUCTS_SUCCESS,
  GET_PRODUCT_CMS_BLOCK_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
  GET_PRODUCT_VARIANTS_SUCCESS,
  GET_UPSELL_PRODUCT_DETAIL_SUCCESS,
} from "../../Redux/Pages/Products/ProductTypes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FabricInfoPopup from "./FabricInfoPopup";
import PanelCMSBlock from "./PanelCMSBlock";
import ProductImageSlider from "./ProductImageSlider";
import ShareDropdown from "./ShareDropdown";
import ProductColorbox from "./ProductColorbox";
import SidePanelButtons from "./SidePanelButtons";
import MobileProductCMSContent from "./MobileProductCMSContent";
import WebProductCMSContent from "./WebProductCMSContent";
import {
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_TO_WISHLIST_SUCCESS,
  GET_WISHLIST_SUCCESS,
  REMOVE_FROM_WISHLIST_SUCCESS,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import {
  addProductToCartItem,
  addToWishlist,
  getWishlist,
  removeFromWishlistItems,
} from "../../Redux/Pages/CartPage/CartPageActions";
import {
  getSessionItem,
  isAuth,
  isMobile,
  removeSessionItem,
  scrollTop,
  setSessionItem,
} from "../../Utility/Utilities";
import ProductDetailSkl from "./ProductDetailSkl";
import { IImage } from "../../Interfaces/Common.interface";
import { ProductConfigurationSkeleton } from "./ProductConfigurationSkeleton";
import { useNavigate } from "react-router";
import { LOGIN } from "../../Constants/Route";
import RelatedProducts from "./RelatedProducts";
import { PRODUCT_DETAILS_PREVIOUS_URL } from "../../Constants/LocalStorage";
import { getMinInstallmentAmountAction, getProductInstallmentHtmlAction } from "../../Redux/General/GeneralAction";
import DivideBuyDetails from "./DivideBuyDetails/DivideBuyDetails";
import Flowbox from "../Flowbox/Flowbox";
import { FLOWBOX_PRODUCT_DETAILS_PAGE_FLOW_KEY, FLOWBOX_REVIEW_WIDGET_FLOW_KEY, FLOWBOX_START_WIDGET_FLOW_KEY } from "../../Constants/Config";
import { FLOWBOX_CONTAINER } from "../../Constants/Labels";
import ProductStarWidget from "./ProductStarWidget/ProductStarWidget";
const ProductDetail = (props: { sku: string }) => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const response: any = useSelector(
    (state: any) => state.productDetailResponse
  );
  // const filterProductResponse: any = useSelector(
  //   (state: any) => state.filterProductReducer
  // );

  const [data, setData] = useState<IProductDetail>();

  const wishlistResponse = useSelector((state: any) => state.wishlistResponse);
  const productVariantsResponse = useSelector(
    (state: any) => state.getProductVariantsReducer
  );

  const cartResponse = useSelector((state: any) => state.addProductToCart);

  const [loading, setLoading] = useState(true);
  const [isLoadingConfigurable, setLoadingConfigurable] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<IVariant[]>([]);
  const [productVariants, setProductVariants] = useState<IVariant[]>([]);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [filteredProduct, setFilteredProduct] = useState<IProductDetail>();
  const [groupedColors, setGroupedColors] = useState<GroupedColors[]>([]);
  const [groupedColorsForMobile, setGroupColorsForMobile] = useState<
    GroupedColors[]
  >([]);
  const [sizes, setSizes] = useState<any>([]);
  const [defaultOptionSelected, setDefaultOptionSelected] = useState("");
  // const [selectedValue, setSelectedValue] = useState<string>("");
  const [windowSize, setWindowSize] = useState(1366);
  const [guardiansChecked, setGuardiansChecked] = useState<any[]>([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [colors, setColors] = useState<any>([
    { title: "Filter Colour", value: "0" },
  ]);
  const [fabrics, setFabrics] = useState([
    { title: "Filter Fabric", value: "0" },
  ]);
  const [wishlistListId, setWishlistId] = useState<string>("");
  const [cmsBlock, setCMSBlock] = useState("");
  const [showError, setError] = useState<any>({});
  const [fabricsInformation, setFabricsInformation] = useState("");
  const [relatedProduct, setRelatedProduct] = useState<IRelatedProducts>();
  const [freeSampleModalShow, setFreeSampleModalShow] = useState(false);
  const [fabricInfoPopupShow, setFabricInfoPopupShow] = useState(false);
  const [isCheckedColoredBox, setCheckedColoredBox] = useState({
    uid: "",
    value: false,
    label: "",
  });
  const INITIAL_PATH = [
    {
      path: "",
      label: "Home",
    },
  ];
  const [breadSc, setBreadcrumbs] = useState(INITIAL_PATH);
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);
  const relatedProductResponse = useSelector(
    (state: any) => state.getRelatedProductHTMLContentReducer
  );
  const INITIAL_SELECTED_VALUE = { colorKey: "0", fabricKey: "0" };
  const [colorSelectValue, setColorSelectValue] = useState<any>(
    INITIAL_SELECTED_VALUE
  );
  const [searchOptions, setSearchOptions] = useState<any>();
  const productReviewRef : any= useRef(null)
  useEffect(() => {
    const handlePopstate = (e: any) => {
      const previousUrl = getSessionItem(PRODUCT_DETAILS_PREVIOUS_URL);
      navigate(previousUrl ?? -1);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
      removeSessionItem(PRODUCT_DETAILS_PREVIOUS_URL);
    };
  }, [])


  useEffect(() => {
    setLoading(true);
    setLoadingConfigurable(true);
    dispatch(getProductDetailByName(props.sku));
    dispatch(getCMSContent(`"product_cms_default"`));
    dispatch(getProductDetailFabricInformation());
    // dispatch(getGuardsmanBedProtection());
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      dispatch({
        type: GET_PRODUCT_DETAIL_SUCCESS,
        payload: {
          type: GET_PRODUCT_DETAIL_SUCCESS,
          data: null,
        },
      });
      setBreadcrumbs(INITIAL_PATH);
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window.innerWidth]);




  useEffect(() => {
    if (location && location.pathname) {
      if (location && location.search) {
        const searchParam = new URLSearchParams(location.search);
        const entries: any = searchParam.entries();
        const result: any = {};
        for (const [key, value] of entries) {
          // each 'entry' is a [key, value] tupple
          result[key] = value;
        }
        setSearchOptions(result);
      }
      if (location?.state?.path) {
        setSessionItem(PRODUCT_DETAILS_PREVIOUS_URL, location?.state?.path);
      }
      if (!isMobile()) {
        scrollTop();
      }

      setBreadcrumbs(INITIAL_PATH);
    }
  }, [location]);

  useEffect(() => {
    if (productVariantsResponse) {
      if (productVariantsResponse.type === GET_PRODUCT_VARIANTS_SUCCESS) {
        if (
          productVariantsResponse.data &&
          productVariantsResponse.data.length > 0
        ) {
          setProductVariants(productVariantsResponse.data[0].variants);
        } else {
          setProductVariants([]);
        }
      }
    }
  }, [productVariantsResponse]);

  useEffect(() => {
    if (response && response.data) {
      if (response.type === GET_PRODUCT_DETAIL_SUCCESS) {
       
        setBreadcrumbs(INITIAL_PATH);
        const currentBreadScrum: any[] = Object.assign([], breadSc);
        if (response.data?.name) {
          currentBreadScrum.push({
            label: response.data?.name,
            path: "",
          });
        }
        setBreadcrumbs(currentBreadScrum);
        response.data.media_gallery?.sort(
          (a: { position: number }, b: { position: number }) =>
            a.position - b.position
        );
        const media_gallery: IImage[] = [response.data.image].concat(
          response.data.media_gallery
        );
        const imageNames: string[] = Array.from(
          new Set(media_gallery?.map((s: IImage) => s.url))
        );
        let mGalleries: IImage[] = [];
        imageNames.forEach((c: string, i: number) => {
          const image: any = media_gallery?.find((m) => m.url === c);
          image.position = i + 1;
          mGalleries.push(image);
        });
        mGalleries?.sort((a: IImage, b: IImage) => a.position - b.position);
        response.data.media_gallery = mGalleries;

        setFilteredProduct({ ...filteredProduct, ...response.data });
        if (response.data && response.data.configurable_options) {
        } else if (response.data && !response.data.configurable_options) {
          setSelectedProducts(response.data);
        }
        setData({ ...filteredProduct, ...response.data });
        setLoading(false);
        if (isAuth()) {
          dispatch(getWishlist(""));
        }
        if (response?.data?.__typename === "SimpleProduct") {
          setLoadingConfigurable(false);
        }

        if (response.data?.dividebuy_enable && response.data.price_range?.minimum_price
          .final_price?.value 
        ) {
          const price = Number(
            response.data.price_range?.minimum_price
              ?.final_price?.value
          ).toFixed(2);
          dispatch(getProductInstallmentHtmlAction(price));
          dispatch(getMinInstallmentAmountAction(price));
        }

      } else if (response.type === GET_CONFIGURABLE_PRODUCTS_SUCCESS) {
        if (response && response.data && response.data.configurable_options) {
          const index = response.data.configurable_options.findIndex(
            (c: any) => c.attribute_code === "color"
          );
          if (index > -1) {
            const colorConfigurations: any[] =
              response.data.configurable_options[index].values?.filter(
                (c: any) => c.product_status === "1"
              );
            response.data.configurable_options[index].values =
              colorConfigurations;
          }
        }
        response.data?.media_gallery?.sort(
          (a: { position: number }, b: { position: number }) =>
            a.position - b.position
        );
        const colorAttributeOptions = getAttributeValues(
          response.data?.configurable_options,
          "color"
        );

        const fOptions: any[] = [];
        if (colorAttributeOptions) {
          const colours = Array.from(
            new Set(
              colorAttributeOptions
                .filter((c) => c?.swatch_data?.color_group)
                .map((c) => {
                  return {
                    title: c.swatch_data.color_group,
                    value: "" + c.swatch_data.group,
                  };
                })
            )
          );
          const arr = Array.from(new Set(colours.map((c) => c.title))).map(
            (olKey) => {
              const value = colours.find((colmns) => colmns.title === olKey);
              return { title: value?.title, value: olKey };
            }
          );
          arr.push({ title: "All", value: "all" });
          setColors([...colors, ...arr]);
          const addFabr = Array.from(
            new Set(colorAttributeOptions?.map((c) => c.swatch_data?.group))
          )
            .filter((c) => c)
            .map((c) => {
              return {
                title: c,
                value: c,
              };
            });
          addFabr.push({ title: "All", value: "all" });
          setFabrics([...fabrics, ...addFabr]);
          const colorSections = getGroupedColors(colorAttributeOptions); // MUST KEEP
          setGroupedColors(colorSections);
          const colorList = Array.prototype.concat(
            ...colorSections.map((c) => c.value)
          );
          if (searchOptions && searchOptions.color) {
            const selectedColor = colorList.find((c) =>
              c.label
                .replace(/\s/g, "")
                .toLowerCase()
                .includes(searchOptions.color?.replace(/\s/g, "").toLowerCase())
            );
            const selectedColorGroup = colorAttributeOptions?.find(
              (ca) => ca.label === selectedColor?.label
            );

            if (selectedColor) {
              const group = selectedColor?.label?.split("-")?.[1]?.trim();
              if (group && selectedColorGroup) {
                setColorSelectValue({ colorKey: "all", fabricKey: "all" });
              }
              setCheckedColoredBox({
                uid: selectedColor.uid,
                value: true,
                label: selectedColor.label,
              });
              fOptions.push({ uid: selectedColor.uid, type: "color" });
            }
          } else {
            setColorSelectValue(INITIAL_SELECTED_VALUE);
          }
        }
        if (response.data.configurable_options) {
          const sizeData = response.data.configurable_options.find(
            (d: { attribute_code: string }) => d.attribute_code === "size"
          );
          if (sizeData) {
            const size = [
              { value: null, title: "Select size" },
              ...sizeData.values.map((v: { uid: string; label: any }) => {
                return {
                  value: v.uid,
                  title: v.label,
                };
              }),
            ];
            setSizes(size);
            const selectedSize = searchOptions?.size
              ? size.find((s) => s.title === searchOptions?.size?.trim())
                  ?.value ?? size[1].value
              : size[1].value;
            setSelectedSize(selectedSize);
            fOptions.push({ uid: selectedSize, type: "size" });
          } else {
            setSizes([]);
          }
          const fProducts = { ...filteredProduct, ...response.data };
          setFilteredProduct(fProducts);

          if (fProducts) {
            const options = getAttributeValues(
              fProducts.configurable_options,
              "option"
            );
            if (options) {
              const selectedOption = searchOptions?.option
                ? options.find((s) => s.label === searchOptions?.option?.trim())
                    ?.uid ?? options[options?.length - 1].uid
                : options[options?.length - 1].uid;
              setDefaultOptionSelected(selectedOption);
              fOptions.push({
                uid: selectedOption,
                type: "option",
              });
            }
          }
          setData({ ...filteredProduct, ...response.data });
        }
        setFilterOptions(fOptions);
        setLoadingConfigurable(false);
      } else if (response.type === GET_PRODUCT_CMS_BLOCK_SUCCESS) {
        setCMSBlock(response.data);
      } else if (response.type === GET_PRODUCT_FABRIC_INFORMATION_SUCCESS) {
        setFabricsInformation(response.data);
      } else if (response.type === GET_UPSELL_PRODUCT_DETAIL_SUCCESS) {
        // NOTHING TO DO HERE. DON'T REMOVE IT BECAUSE OF ELSE CONDITION // JAINAM MEHTA
      } else {
        setData(undefined);
        setLoading(false);
        setLoadingConfigurable(false);
      }
    }
  }, [response]);

  useEffect(() => {
    if (
      relatedProductResponse?.data?.related_products &&
      relatedProductResponse?.data?.related_products?.length > 0
    ) {
      const currentData = Object.assign({}, data);
      const currentFilterProduct = Object.assign({}, filteredProduct);
      relatedProductResponse?.data?.related_products.forEach(
        (c: IRelatedProducts) => {
          const index = currentData.related_products?.findIndex(
            (cc: IRelatedProducts) => cc.name === c.name
          );
          if (index > -1) {
            currentData.related_products[index].image = c.image;
            currentData.related_products[index].description = c.description;
          }
          const fProductIndex =
            currentFilterProduct.related_products?.findIndex(
              (cc) => cc.name === c.name
            );
          if (fProductIndex > -1) {
            currentFilterProduct.related_products[fProductIndex].image =
              c.image;
            currentFilterProduct.related_products[fProductIndex].description =
              c.description;
          }
        }
      );
      setData(currentData);
      setFilteredProduct(currentFilterProduct);
    }
  }, [relatedProductResponse]);

  
  useEffect(() => {
    if (
      filterOptions.length === filteredProduct?.configurable_options?.length &&
      data &&
      selectedProducts &&
      selectedProducts.length > 0
    ) {
      let media_gallery =
        selectedProducts[0]?.product.media_gallery.length > 0
          ? [
              ...selectedProducts[0]?.product.media_gallery,
              ...data.media_gallery,
            ]
          : filteredProduct?.media_gallery;
      media_gallery = media_gallery.filter((m) => !m.disabled);
      media_gallery.forEach((m, i) => (m.position = i + 1));
      setFilteredProduct({
        ...selectedProducts[0]?.product,
        dividebuy_enable: selectedProducts[0]?.product?.dividebuy_enable,
        related_products: filteredProduct.related_products,
        description: filteredProduct.description,
        dimensions: filteredProduct.dimensions,
        whyus: filteredProduct.whyus,
        delivery: filteredProduct.delivery,
        media_gallery,
        configurable_options: filteredProduct?.configurable_options,
        short_description: filteredProduct?.short_description,
        stock_status: selectedProducts[0].product?.stock_status,
        name: filteredProduct.name,
        price_range: selectedProducts[0].product.price_range,
      });
     
      if (selectedProducts[0].product.price_range?.minimum_price
        .final_price?.value && filteredProduct?.dividebuy_enable
      ) {
        const price = Number(
          selectedProducts[0].product.price_range?.minimum_price
            ?.final_price?.value
        ).toFixed(2);
        dispatch(getProductInstallmentHtmlAction(price));
        dispatch(getMinInstallmentAmountAction(price));
      }
    }
    
  }, [selectedProducts]);

  useEffect(() => {
    if (data) {
      setLatestProduct(0, data.variants);
      setLoading(false);
    }
  }, [filterOptions]);

  useEffect(() => {
    if (wishlistResponse && wishlistResponse.data) {
      setLoading(false);
      if (wishlistResponse.type === GET_WISHLIST_SUCCESS && data) {
        const wishlist = wishlistResponse.data.items_v2?.items.find(
          (item: any) => item.product?.sku === data.sku
        );
        setWishlistId(wishlist?.id);
      } else if (
        wishlistResponse &&
        wishlistResponse.type === ADD_TO_WISHLIST_SUCCESS &&
        data
      ) {
        const wishlist = wishlistResponse.data.items_v2.items.find(
          (item: { product: { sku: string } }) => item.product.sku === data.sku
        );
        setWishlistId(wishlist?.id);
      } else if (
        wishlistResponse &&
        response.type === REMOVE_FROM_WISHLIST_SUCCESS
      ) {
        setWishlistId("");
      }
    }
  }, [wishlistResponse]);

  useEffect(() => {
    if (
      cartResponse &&
      (cartResponse.type === ADD_PRODUCT_TO_CART_SUCCESS ||
        cartResponse.type === ADD_PRODUCT_TO_CART_FAILED)
    ) {
      setCartLoading(false);
    }
  }, [cartResponse]);

  useEffect(() => {
    if (filteredProduct?.configurable_options) {
      let filteredColors: IConfigurationOptionValues[] = [];
      const configurationColors = getAttributeValues(
        filteredProduct?.configurable_options,
        "color"
      );
      if (
        (colorSelectValue.fabricKey === "0" || colorSelectValue.fabricKey === "all") &&
        (colorSelectValue.colorKey !== "0" && colorSelectValue.colorKey !== "all") &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) =>
            option.swatch_data.color_group === colorSelectValue.colorKey
        );
      } else if (
        (colorSelectValue.fabricKey !== "0" && colorSelectValue.fabricKey !== "all") &&
        (colorSelectValue.colorKey !== "0" && colorSelectValue.colorKey !== "all") &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) =>
            option.swatch_data.color_group === colorSelectValue.colorKey &&
            option.swatch_data.group === colorSelectValue.fabricKey
        );
      } else if (
        (colorSelectValue.colorKey === "0" || colorSelectValue.colorKey === "all") &&
        (colorSelectValue.fabricKey !== "0" && colorSelectValue.fabricKey !== "all") &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) => option.swatch_data.group === colorSelectValue.fabricKey
        );
      } else if (
        configurationColors &&
        (colorSelectValue.fabricKey === "0" || colorSelectValue.fabricKey === "all") &&
        (colorSelectValue.colorKey === "0" || colorSelectValue.colorKey === "all")
      ) {
        filteredColors = configurationColors;
      }
      setGroupColorsForMobile([
        {
          key: "",
          value: filteredColors?.map(
            (c) =>
              new SelectedProductOption(
                c.uid,
                c.swatch_data?.thumbnail,
                c.label,
                c.swatch_data?.value
              )
          ),
        },
      ]);
    }
  }, [colorSelectValue]);

  const getGroupedColors = (
    colors: IConfigurationOptionValues[]
  ): GroupedColors[] => {
    const groupNames: string[] = Array.from(
      new Set(colors.map((c) => c.swatch_data?.group))
    ).filter((c) => c);
    return groupNames.map((c: string) => {
      return new GroupedColors(
        c,
        colors
          .filter((cc) => cc.swatch_data?.group === c)
          .map(
            (cc) =>
              new SelectedProductOption(
                cc.uid,
                cc.swatch_data?.thumbnail,
                cc.label,
                cc.swatch_data?.value
              )
          )
      );
    });
  };

  const onUserSelectFromColorOptions = (selectedKey: string, e: any) => {
    setColorSelectValue({ ...colorSelectValue, [selectedKey]: e.target.value });
  };

  const onUserSelect = async ({ isChecked, label, uid, type }: any) => {
    navigate({
      pathname: location.pathname,
      search: new URLSearchParams({
        ...searchOptions,
        ...{ [type]: label ?? "" },
      }).toString(),
    });
    if (type === "color") {
      setCheckedColoredBox({
        uid,
        value: isChecked,
        label,
      });
    } else if (type === "size") {
      // setSelectedValue(uid === "Select size" ? null : uid);
    } else if (type === "option") {
      setDefaultOptionSelected(uid);
    }
    let currentFilterOptions: any[] = Object.assign([], filterOptions);
    if (uid !== "Select size") {
      const filterOptionIndex = filterOptions.findIndex((f) => f.type === type);
      if (filterOptionIndex > -1) {
        currentFilterOptions[filterOptionIndex].uid = uid;
      } else {
        currentFilterOptions.push({ uid, type });
      }
    } else {
      currentFilterOptions = currentFilterOptions.filter(
        (c) => c.type !== "size"
      );
    }
    setFilterOptions(currentFilterOptions);
  };
  const getAttributeValues = (
    configurable_options: IConfigurationOption[],
    attribute_code: string
  ): IConfigurationOptionValues[] | undefined => {
    return configurable_options?.find(
      (o) => o.attribute_code === attribute_code
    )?.values;
  };

  const setLatestProduct = async (i: number, variants: IVariant[]) => {
    let result: any[] = [];
    if (i === filterOptions.length) {
      const vPIndex = productVariants.findIndex(
        (vP) => vP.product.uid === variants[0].product.uid
      );
      if (vPIndex > -1) {
        variants[0].product.availability_message =
          productVariants[vPIndex].product.availability_message;
        variants[0].product.price_range =
          productVariants[vPIndex].product.price_range;
      }
      setSelectedProducts(variants);
      return;
    }
    if (variants && variants.length > 0) {
      const option = filterOptions[i];
      for (let index = 0; index < variants.length; index++) {
        result =
          variants[index].attributes.filter(
            (at) => at.code === option.type && at.uid === option.uid
          ).length > 0
            ? [...result, variants[index]]
            : [...result];
      }
      setLatestProduct(i + 1, result);
    }
  };

  const onAddToWishlist = (e: any) => {
    if (!isAuth()) {
      navigate(LOGIN);
      return;
    }
    if (e.target.checked) {
      dispatch(
        addToWishlist({
          sku: data?.sku,
          quantity: 1,
        })
      );
    } else {
      dispatch(removeFromWishlistItems({ wishlistItemId: wishlistListId }));
    }
  };

  const addProductToCart = () => {
    if (
      filterOptions.filter((c) => c.uid !== "Select size").length !==
      (filteredProduct?.configurable_options?.length || 0)
    ) {
      const attributeCodes = filteredProduct?.configurable_options.map(
        (c) => c.attribute_code
      );
      if (filterOptions && filterOptions.length === 0) {
        let obj: any = {};
        attributeCodes?.forEach((a) => {
          obj[a] = true;
        });
        setError(obj);
      } else if (filterOptions.length > 0) {
        const obj: any = {};
        const types = filterOptions
          .filter(
            (c) =>
              (c.type === "size" && c.uid !== "Select size") ||
              c.type !== "size"
          )
          .map((c) => c.type);
        const notIncludedAttributes = attributeCodes?.filter(
          (d) => !types?.includes(d)
        );
        if (notIncludedAttributes && notIncludedAttributes.length > 0) {
          notIncludedAttributes.forEach((c) => (obj[c] = true));
          setError(obj);
        }
      }
      return;
    } else {
      setError({});
    }
    setCartLoading(true);
    const parent_sku = data?.sku;
    const child_sku =
      selectedProducts && selectedProducts.length > 0
        ? selectedProducts[0]?.product.sku
        : null;
    const sku: string[] = guardiansChecked.map((c) => c.sku);
    if (parent_sku && child_sku) {
      // configuration options
      dispatch(
        addProductToCartItem({
          parent_sku,
          child_sku,
          sku,
          qty: 1,
        })
      );
    } else if (!parent_sku || !child_sku) {
      // showToast(WARNING, "No data found");
      setLoading(false);
      setLoadingConfigurable(false);
      setCartLoading(false);
    } else {
      setCartLoading(false);
      setLoading(false);
      setLoadingConfigurable(false);
    }
    if (data?.__typename === "SimpleProduct") {
      // only simple products
      dispatch(
        addProductToCartItem({
          simple_sku: data.sku,
          sku,
          qty: 1,
        })
      );
    } else {
      setLoading(false);
      setLoadingConfigurable(false);
    }
  };


  const scrollToReview = () => {
    productReviewRef.current.scrollIntoView()    
  }
  
  return !loading ? (
    <div className="product-detail-wrap">
      {windowSize < 768 ? (
        <>
          {data?.productbannermobiletop && (
            <div
              className="custom-container custom-topbar"
              dangerouslySetInnerHTML={{ __html: data.productbannermobiletop }}
            ></div>
          )}
        </>
      ) : (
        ""
      )}
      <div className="custom-container top">
        <Breadcrumbs value={breadSc} />
        <div className="product-detail-main">
          <div className="left-media">
            {cmsBlock ? (
              <PanelCMSBlock cms_block={cmsBlock} resolution="desktop" />
            ) : null}
            <div className="sample-productdetails">
              {filteredProduct && filteredProduct.media_gallery ? (
                <ProductImageSlider
                  media_gallery={filteredProduct?.media_gallery}
                />
              ) : null}
            </div>
          </div>
          <div className="product-right-txt">
            <h1 className="caps-on">{filteredProduct?.name}</h1>
            <div className="product-social">
              {filteredProduct && filteredProduct.short_description ? (
                <p
                  className="bs"
                  dangerouslySetInnerHTML={{
                    __html: filteredProduct.short_description.html,
                  }}
                />
              ) : null}
              <div className="extra-action">
                <div className="heart-wishlist">
                  <input
                    type="checkbox"
                    checked={
                      wishlistListId !== undefined &&
                      wishlistListId !== null &&
                      wishlistListId !== ""
                    }
                    disabled={
                      wishlistResponse &&
                      wishlistResponse.type === ADD_TO_WISHLIST_REQUEST
                    }
                    onChange={onAddToWishlist}
                  />
                </div>
                <ShareDropdown />
              </div>
            </div>
            <div className="product-txt-box">
              {!isLoadingConfigurable ? (
                <>
                  {data && data.stock_status === "IN_STOCK" ? (
                    <>
                      {filteredProduct?.configurable_options?.map((d) => {
                        return d.attribute_code === "color" ? (
                          <>
                            {windowSize < 768 ? (
                              <>
                                <div key={d.id} className="mob-filter">
                                  <SelectGroups
                                    values={colors}
                                    selectedValue={colorSelectValue?.colorKey}
                                    onSelect={(e: any) =>
                                      onUserSelectFromColorOptions(
                                        "colorKey",
                                        e
                                      )
                                    }
                                    label=""
                                  ></SelectGroups>
                                  <SelectGroups
                                    onSelect={(e: any) =>
                                      onUserSelectFromColorOptions(
                                        "fabricKey",
                                        e
                                      )
                                    }
                                    selectedValue={colorSelectValue?.fabricKey}
                                    values={fabrics}
                                    label=""
                                  ></SelectGroups>
                                </div>
                                <div
                                  key={d.id + " 1"}
                                  className="product-swatches hide-767"
                                >
                                  <div className="product-swatches">
                                    <div className="sample-main">
                                      <div className="sample-checkbox-mob">
                                        {groupedColorsForMobile &&
                                        groupedColorsForMobile[0] &&
                                        groupedColorsForMobile[0]?.value
                                          ?.length !== 0 ? (
                                          <>
                                            {groupedColorsForMobile &&
                                            groupedColorsForMobile[0].value
                                              ? groupedColorsForMobile[0]?.value?.map(
                                                  (
                                                    gCV: SelectedProductOption
                                                  ) => (
                                                    <ProductColorbox
                                                      key={gCV.label}
                                                      {...gCV}
                                                      isChecked={
                                                        gCV.uid ===
                                                        isCheckedColoredBox.uid
                                                          ? isCheckedColoredBox.value
                                                          : false
                                                      }
                                                      onClicked={onUserSelect}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </>
                                        ) : (
                                          <p className="no-combi-available tm">
                                            The selected product combination is
                                            currently unavailable.
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="product-swatches">
                                {groupedColors?.map((gC) => {
                                  return (
                                    <div key={gC.key} className="sample-wrap">
                                      <p className="ll box-title">{gC.key}</p>
                                      <div className="sample-main">
                                        <div className="sample-checkbox">
                                          {gC.value?.map(
                                            (gCV: SelectedProductOption) => (
                                              <ProductColorbox
                                                key={gCV.label}
                                                {...gCV}
                                                isChecked={
                                                  gCV.uid ===
                                                  isCheckedColoredBox.uid
                                                    ? isCheckedColoredBox.value
                                                    : false
                                                }
                                                onClicked={onUserSelect}
                                              />
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <div className="select-box">
                              {filteredProduct &&
                              getAttributeValues(
                                filteredProduct.configurable_options,
                                "color"
                              ) ? (
                                <div className="apply-color">
                                  <p className="bm color-text">
                                    {isCheckedColoredBox &&
                                    isCheckedColoredBox.label !== ""
                                      ? isCheckedColoredBox.label
                                      : "Select by colour or fabric above"}
                                  </p>
                                  <button
                                    onClick={() => setFabricInfoPopupShow(true)}
                                  >
                                    <img src={info} alt={""} />
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            {showError && showError["color"] && (
                              <p className="text-danger">
                                *This field is required
                              </p>
                            )}
                          </>
                        ) : d.attribute_code === "size" &&
                          sizes &&
                          sizes.length > 0 ? (
                          <div className="select-size">
                            <SelectGroups
                              values={sizes}
                              label=""
                              selectedValue={selectedSize}
                              onSelect={(e: any) => {
                                const eventData = e.target.value;
                                const label = sizes?.find(
                                  (s: any) => s.value === eventData
                                )?.title;
                                onUserSelect({
                                  isChecked: false,
                                  label: label,
                                  uid: eventData,
                                  type: "size",
                                });
                              }}
                            />
                            {showError && showError["size"] && (
                              <p className="text-danger">
                                *This field is required
                              </p>
                            )}
                          </div>
                        ) : d.attribute_code === "option" &&
                          filteredProduct &&
                          filteredProduct.configurable_options ? (
                          <div className="select-box">
                            <div className="sidepanel-tabs">
                              {getAttributeValues(
                                filteredProduct?.configurable_options,
                                "option"
                              )?.map((c) => {
                                return (
                                  <SidePanelButtons
                                    key={c.label}
                                    defaultSelected={defaultOptionSelected}
                                    label={c.label}
                                    uid={c.uid}
                                    onSelectPanel={(
                                      uid: string,
                                      label: string
                                    ) => {
                                      onUserSelect({
                                        isChecked: false,
                                        label: label,
                                        uid: uid,
                                        type: "option",
                                      });
                                    }}
                                  />
                                );
                              })}
                            </div>
                            {showError && showError["option"] && (
                              <p className="text-danger">
                                *This field is required
                              </p>
                            )}
                          </div>
                        ) : null;
                      })}
                      {filteredProduct &&
                      filteredProduct.availability_message ? (
                        <div className="availibility">
                          <p className="ll">Availability</p>
                          <p className="bm">
                            {filteredProduct.availability_message}
                          </p>
                        </div>
                      ) : data?.variants?.length === 0 ? (
                        <div className="availibility">
                          <p className="ll">Availability</p>
                          <p className="text-danger">{"OUT OF STOCK"}</p>
                        </div>
                      ) : null}
                      <div className="product-price">
                        {filteredProduct && filteredProduct.price_range ? (
                          <div className="price">
                            {filteredProduct.price_range.minimum_price
                              .final_price.value !==
                            filteredProduct.price_range.minimum_price
                              .regular_price.value ? (
                              <p className="tm specialprice">
                                £
                                {Number(
                                  filteredProduct.price_range.minimum_price
                                    .regular_price.value
                                ).toFixed(2)}
                              </p>
                            ) : null}
                            <p className="tm">
                              £
                              {Number(
                                filteredProduct.price_range.minimum_price
                                  .final_price.value
                              ).toFixed(2)}
                            </p>
                          </div>
                        ) : null}

                        {
                          props?.sku && <div className="flowbox_avg_star" onClick={scrollToReview}> <ProductStarWidget 
                          flowKey={FLOWBOX_START_WIDGET_FLOW_KEY}
                          containerName={FLOWBOX_CONTAINER.MINIMAL_START_WIDGET}
                          productId={props?.sku}
                          /></div> 
                        }
                      
                      </div>{" "}
                      {filteredProduct?.related_products?.map((rP) => {
                        return (
                          <div key={rP.name} className="protection-box">
                            <div className="protect-txt">
                              <CheckboxGroups
                                onChange={(e: any) => {
                                  if (!e.target.checked) {
                                    setGuardiansChecked(
                                      guardiansChecked.filter(
                                        (c: any) =>
                                          c.sku !== rP.sku && c.checked
                                      )
                                    );
                                  } else {
                                    setGuardiansChecked([
                                      ...guardiansChecked,
                                      { checked: true, sku: rP.sku },
                                    ]);
                                  }
                                }}
                                id={rP.name}
                              >
                                {rP.name}
                              </CheckboxGroups>
                              <img
                                src={info}
                                alt={""}
                                onClick={() => setRelatedProduct(rP)}
                              />
                            </div>
                            <p className="bm">
                              £
                              {Number(
                                rP.price_range?.minimum_price?.final_price
                                  ?.value
                              ).toFixed(2) || 0.0}
                            </p>
                          </div>
                        );
                      })}{" "}    

                      <DivideBuyDetails 
                      filteredProduct={filteredProduct}
                      />              
                      <div className="action-btn">
                        <CustomButton
                          bg={"fill"}
                          isLoading={cartLoading}
                          disabled={
                            !data ||
                            (data && data?.variants?.length === 0) ||
                            filteredProduct?.stock_status !== "IN_STOCK"
                          }
                          onClick={addProductToCart}
                        >
                          {/* PLEASE DON'T REMOVE IT. IT IS VALIDATION in disabled attribute
                           filterOptions.length !==
                          (filteredProduct?.configurable_options?.length || 0)
                          ||  */}
                          Add to Basket
                        </CustomButton>
                        {data.productbanner && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.productbanner,
                            }}
                          ></div>
                        )}
                        <a
                          className="fill-link"
                          href="tel:01162695960"
                          title="Finance available call now"
                        >
                          <span>Finance available call now</span>
                          {/* <span>0116 269 5960</span> */}
                        </a>
                        {filteredProduct?.enable_free_fabric_sample === 1 ? (
                          <CustomButton
                            bg={"outline-btn"}
                            onClick={() => setFreeSampleModalShow(true)}
                          >
                            Order a Free Fabric Sample
                          </CustomButton>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="action-btn">
                      <CustomButton bg={"fill"} disabled={true}>
                        Out of stock
                      </CustomButton>
                    </div>
                  )}{" "}
                </>
              ) : (
                <ProductConfigurationSkeleton />
              )}
            </div>
          </div>
        </div>
      </div>
      {windowSize < 768 ? (
        <MobileProductCMSContent
          description={filteredProduct?.description?.html || ""}
          dimensions={filteredProduct?.dimensions?.html || ""}
          delivery={filteredProduct?.delivery?.html || ""}
          whyus={filteredProduct?.whyus?.html || ""}
          setFreesampleModalShow={setFreeSampleModalShow}
        />
      ) : (
        <WebProductCMSContent
          description={filteredProduct?.description?.html}
          dimensions={filteredProduct?.dimensions?.html}
          delivery={filteredProduct?.delivery?.html}
          whyus={filteredProduct?.whyus?.html}
          setFreesampleModalShow={setFreeSampleModalShow}
        />
      )}
      {props?.sku && (
        <div className="custom-container">
          <Flowbox
            containerName={FLOWBOX_CONTAINER.PRODUCT_DETAILS}
            flowKey={FLOWBOX_PRODUCT_DETAILS_PAGE_FLOW_KEY}
            productId={props?.sku}
            ref={productReviewRef}
          />
        </div>
      )}

      <RelatedProducts />
      {props?.sku && (
        <div className="custom-container flowbox_review_widget">
          <ProductStarWidget
            containerName={FLOWBOX_CONTAINER.REVIEW_WIDGET}
            flowKey={FLOWBOX_REVIEW_WIDGET_FLOW_KEY}
            productId={props?.sku}
            ref={productReviewRef}
          />
          </div>
      )}
      {relatedProduct && (
        <RelatedProductPopup
          show={true}
          relatedProduct={relatedProduct}
          onHide={() => setRelatedProduct(undefined)}
        />
      )}
      <FabricInfoPopup
        show={fabricInfoPopupShow}
        content={fabricsInformation}
        onHide={() => setFabricInfoPopupShow(false)}
      />
      <FreeSamplesPopup
        storeConfigInfo={storeConResponse}
        show={freeSampleModalShow}
        onHide={() => setFreeSampleModalShow(false)}
      />
    </div>
  ) : (
    <ProductDetailSkl />
  );
};

export default ProductDetail;
