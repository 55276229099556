import React, { useEffect } from "react";
import { loadFlowbox } from "../../Utility/Utilities";

const locale = "en-GB";

function Flowbox({ flowKey, tags, containerName , productId }: any) {
  const container = `#${containerName}`;
  useEffect(() => {
    loadFlowbox().then(() => {
      window.flowbox("init", {
        container,
        key: flowKey,
        locale,
        ...(tags && { tags }),
        ...(productId && { productId }),
      });
    });
  }, [container, flowKey]);

  return <div id={containerName}>  </div>;
}

export default Flowbox;
