const SidePanelButtons = (props: {
  uid: string;
  label: string;
  onSelectPanel: any;
  defaultSelected: any;
}) => {
  return (
    <div className="on-radio">
      <input
        onClick={() => props.onSelectPanel(props.uid, props.label)}
        checked={props.uid === props.defaultSelected}
        type={"radio"}
        name="side-panel"
        id={props.label}
        value={props.label}
      />
      <label className="bm" htmlFor={props.label}>
        {props.label}
      </label>
    </div>
  );
};

export default SidePanelButtons;
