import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import Sidebar from "../Sidebar/Sidebar";

const MyAddressesSkl = () => {
  return (
    <div className="account-container skl">
      <div className="ll hide-991">
        <Link to={MY_DETAIL} className="acc-backbtn ll">
          My Account
        </Link>
      </div>
      <div className="account-wrap">
        <Sidebar></Sidebar>
        <div className="acc-rightwrap">
          <div className="myaddress-wrap skl">
            <h6 className="caps-on">
              <Skeleton />
            </h6>
            <div className="addr-top">
              <span className="tm">
                <Skeleton />
              </span>
              <span title="Add New Address" className="bm adr">
                <Skeleton />
              </span>
            </div>
            <ul className="addr-list">
              <li className="add-item skl">
                <div className="addr-details">
                  <p className="ll skl-name">
                    <Skeleton />
                  </p>
                  <address className="bm skl-addr">
                    <Skeleton />
                  </address>
                  <p className="ll skl-mob">
                    <Skeleton />
                  </p>
                </div>
                <div className="addr-action">
                  <p className="ll skl-edit">
                    <Skeleton />
                  </p>
                  <p className="ll skl-remove">
                    <Skeleton />
                  </p>
                </div>
              </li>
              <li className="add-item skl">
                <div className="addr-details">
                  <p className="ll skl-name">
                    <Skeleton />
                  </p>
                  <address className="bm skl-addr">
                    <Skeleton />
                  </address>
                  <p className="ll skl-mob">
                    <Skeleton />
                  </p>
                </div>
                <div className="addr-action">
                  <p className="ll skl-edit">
                    <Skeleton />
                  </p>
                  <p className="ll skl-remove">
                    <Skeleton />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAddressesSkl;
