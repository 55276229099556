
import Skeleton from "react-loading-skeleton";

const Checkoutskl = () => {
  return (
    <>
      <div className="checkout skl">
        <div className="custom-container">
          <div className="res-checkout-header skl">
            <div className="checkout-header">
              <div className="shipping-cart">
                <div className="arrow-img">
                <Skeleton />
                </div>
                <p className="ll shpng-txt"><Skeleton /></p>
              </div>
              <div className="step-txt">
                <p className="ll step"><Skeleton /></p>
              </div>
            </div>
            <div className="email-box">
              <div className="email-input">
                  <p className="bm label"><Skeleton /></p>
                  <div className="input-box">
                  <Skeleton />
                  </div>
              </div>
              <div className="address-btn">
                <div className="addbtn">
                      <Skeleton />
                </div>
              </div>
            </div>
          </div>
          <div className="checkout-main skl">
            <div className="checkout-left">
              <div className="ck-head">
                <h6><Skeleton /></h6>
              </div>
              <div className="ckout-inner">
                <div className="ckout-top-box">
                  <div className="skl-head">
                    <div className="numberskl"><Skeleton /></div>
                    <div className="ts titleskl"><Skeleton /></div>
                  </div>
                  <div className="bm address-skl"><Skeleton /></div>
                </div>
                <div className="ckout-content">
                  <div className="ckout-mid-box">
                    <div className="ckout-address">
                      <div className="rd-skl">
                        <Skeleton />
                      </div>
                      <div className="bm ckout-skl">
                        <Skeleton />
                      </div>

                    </div>
                    <div className="ckout-edit">
                      <p className="bm edit">
                        <Skeleton />
                      </p>
                      <p className="bm remove">
                        <Skeleton />
                      </p>
                    </div>
                  </div>
                  <div className="ckout-mid-box">
                    <div className="ckout-address">
                      <div className="rd-skl">
                        <Skeleton />
                      </div>
                      <div className="bm ckout-skl">
                        <Skeleton />
                        <div className="deliver-btn">
                          <Skeleton />
                        </div>
                      </div>
                    </div>
                    <div className="ckout-edit">
                      <p className="bm edit">
                        <Skeleton />
                      </p>
                      <p className="bm remove">
                        <Skeleton />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ckout-inner">
                <div className="ckout-top-box">
                  <div className="skl-head">
                    <div className="numberskl"><Skeleton /></div>
                    <div className="ts titleskl"><Skeleton /></div>
                  </div>
                </div>
                <div className="summery-box skl">
                  <div className="ckout-summery">
                    <div className="summery-img">
                      <Skeleton />
                    </div>
                    <div className="summery-txt skl">
                      <p className="ll prod-name"><Skeleton /></p>
                      <p className="bs prod-det"> <Skeleton /></p>
                      <p className="qty skl"><Skeleton /></p>
                      <p className="ll prod-price"><Skeleton /></p>
                      <p className="bm order-remove"><Skeleton /></p>
                    </div>
                  </div>
                  <div className="ckout-summery">
                    <div className="summery-img">
                      <Skeleton />
                    </div>
                    <div className="summery-txt skl">
                      <p className="ll prod-name"><Skeleton /></p>
                      <p className="bs prod-det"><Skeleton /></p>
                      <p className="qty skl"><Skeleton /></p>
                      <p className="ll prod-price"><Skeleton /></p>
                      <p className="bm order-remove"><Skeleton /></p>
                    </div>
                  </div>
                  <div className="confirm-order">
                    <p className="bm order-email"><Skeleton /></p>
                    <div className="continew-btn">
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ckout-inner">
                <div className="ckout-top-box">
                  <div className="skl-head">
                    <div className="numberskl"><Skeleton /></div>
                    <div className="ts titleskl"><Skeleton /></div>
                  </div>
                </div>
                <div className="delivery-box skl">
                  <div className="delivery-method">
                    <div className="rd-skl">
                      <Skeleton />
                    </div>
                    <div className="bm price">
                      <Skeleton />
                    </div>
                    <div className="bm delivery-txt">
                      <Skeleton />
                    </div>

                  </div>
                  <div className="continew-btn">
                    <Skeleton />
                  </div>
                </div>
              </div>
              <div className="ckout-inner">
                <div className="ckout-top-box">
                  <div className="skl-head">
                    <div className="numberskl"><Skeleton /></div>
                    <div className="ts titleskl"><Skeleton /></div>
                  </div>
                </div>
                <div className="delivery-box skl">
                  <div className="delivery-method">
                    <div className="rd-skl">
                      <Skeleton />
                    </div>
                    <div className="bm credit">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="delivery-method">
                    <div className="rd-skl">
                      <Skeleton />
                    </div>
                    <div className="bm paypal">
                      <Skeleton />
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="checkout-price skl">
              <p className="tm detail"><Skeleton /></p>
              <div className="total-table skl">
                <div className="sub-col total">
                  <p className="stitle bm"><Skeleton /></p>
                  <p className="svalue bm"><Skeleton /></p>
                </div>
                <div className="sub-col discount">
                  <p className="stitle bm"><Skeleton /></p>
                  <p className="svalue bm"><Skeleton /></p>
                </div>
                <div className="sub-col delivery">
                  <p className="stitle bm"><Skeleton /></p>
                  <p className="svalue bm"><Skeleton /></p>
                </div>
              </div>
              <div className="discount-box">
                <div className="coupon"><Skeleton /></div>
              </div>
              <div className="order-total">
                <div className="sub-col maintotal">
                  <p className="stitle tm"><Skeleton /></p>
                  <p className="svalue tm"><Skeleton /></p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div className="checkout-sticky-bar skl hide-991">
        <div className="act-bar">
          <div className="od-total">
            <p className="ls od-total-txt"><Skeleton /></p>
            <p className="tm total-price"><Skeleton /></p>
          </div>
          <div className="od-action">
            <div className="action-btn">
            <Skeleton />
            </div>
          
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Checkoutskl;