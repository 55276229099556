/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CART_ID } from "../../Constants/LocalStorage";
import {
  LOGIN,
  MY_ADDRESS,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
} from "../../Constants/Route";
import { removeSessionItem, scrollTop } from "../../Utility/Utilities";
import BadgeHeader from "../Headers/BadgeHeader";
import MobileHeader from "../Headers/MobileHeader";
import WebHeader from "../Headers/WebHeader";

const Headers = () => {
  const [windowSize, setWindowSize] = useState(1366);
  const nav = useNavigate();
  const location = useLocation();


  useEffect(() => {
    scrollTop();
  }, [location.pathname]);

  const unAuthResponse = useSelector((state: any) => state.unAuthResponse);

  useEffect(() => {
    if (
      (unAuthResponse && Object.keys(unAuthResponse).length > 0) ||
      unAuthResponse.toString().includes("/")
    ) {
      removeSessionItem(CART_ID);
      nav(LOGIN, { state: { from: unAuthResponse } });
    }
  }, [unAuthResponse]);

  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => { });
      window.removeEventListener("resize", () => { });
    };
  }, []);

  const urls = [MY_WISHLIST, MY_ADDRESS, NEWSLETTER, MY_ORDERS_PAGE];

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  return (
    <>
      {urls.includes(currentLocation) && windowSize < 992 ? (
        ""
      ) : (
        <div className="header-main">
          <BadgeHeader />
          <WebHeader />
          <MobileHeader />
        </div>
      )}
    </>
  );
};

export default Headers;
