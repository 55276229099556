import { getCMSBlock } from "../../../Services/graphQL.service";
import { GET_CMS_BLOCK_FAILED, GET_CMS_BLOCK_REQUEST, GET_CMS_BLOCK_SUCCESS, GET_CONTACTUS_BLOCK_FAILED, GET_CONTACTUS_BLOCK_REQUEST, GET_CONTACTUS_BLOCK_SUCCESS } from "./CMSBlockTypes";

export function getCMSBlockAction(payload: string) {
    return (async (dispatch: any) => {
        try {
            dispatch({
                type: GET_CMS_BLOCK_REQUEST,
                payload: { type: GET_CMS_BLOCK_REQUEST },
            });
            const { data: response } = await getCMSBlock(payload);
            const graphQLResponse = response.data?.cmsBlocks;
            if (graphQLResponse) {
                dispatch({
                    type: GET_CMS_BLOCK_SUCCESS,
                    payload: {
                        type: GET_CMS_BLOCK_SUCCESS,
                        data: graphQLResponse
                    },
                })
            } else {
                dispatch({
                    type: GET_CMS_BLOCK_FAILED,
                    payload: {
                        type: GET_CMS_BLOCK_FAILED,
                        data: response.errors[0].message
                    }
                })
            }
        } catch (error) {
            console.error("SOMETHING WENT WRONG WHILE FETCHING CMS BLOCK DATA ", error);
            dispatch({
                type: GET_CMS_BLOCK_FAILED,
                payload: { type: GET_CMS_BLOCK_FAILED, message: error },
            });
        }
    });
}

export function getContactUsCMSBlockAction() {
    return (async (dispatch: any) => {
        try {
            dispatch({
                type: GET_CONTACTUS_BLOCK_REQUEST,
                payload: { type: GET_CONTACTUS_BLOCK_REQUEST },
            });
            const { data: response } = await getCMSBlock(`"contact-us-details"`);
            const graphQLResponse = response.data?.cmsBlocks;
            if (graphQLResponse) {
                dispatch({
                    type: GET_CONTACTUS_BLOCK_SUCCESS,
                    payload: {
                        type: GET_CONTACTUS_BLOCK_SUCCESS,
                        data: graphQLResponse
                    },
                })
            } else {
                dispatch({
                    type: GET_CONTACTUS_BLOCK_FAILED,
                    payload: {
                        type: GET_CONTACTUS_BLOCK_FAILED,
                        data: response.errors[0].message
                    }
                })
            }
        } catch (error) {
            console.error("SOMETHING WENT WRONG WHILE FETCHING CMS BLOCK DATA ", error);
            dispatch({
                type: GET_CONTACTUS_BLOCK_FAILED,
                payload: { type: GET_CONTACTUS_BLOCK_FAILED, message: error },
            });
        }
    });
}