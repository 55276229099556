export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST"
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS"
export const GET_PRODUCT_LIST_FAILED = "GET_PRODUCT_LIST_FAILED"

export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAILED = "GET_PRODUCT_DETAIL_FAILED"

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST"
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS"
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED"

export const GET_PRODUCT_CMS_BLOCK_SUCCESS = "GET_PRODUCT_CMS_BLOCK_SUCCESS"
export const GET_PRODUCT_FABRIC_INFORMATION_SUCCESS = "GET_PRODUCT_FABRIC_INFORMATION_SUCCESS"

export const GET_UPSELL_PRODUCT_DETAIL_SUCCESS = "GET_UPSELL_PRODUCT_DETAIL_SUCCESS"
export const GET_CONFIGURABLE_PRODUCTS_SUCCESS = "GET_CONFIGURABLE_PRODUCTS_SUCCESS"
export const GET_CONFIGURABLE_PRODUCTS_REQUEST = "GET_CONFIGURABLE_PRODUCTS_REQUEST"
export const GET_CONFIGURABLE_PRODUCTS_FAILURE = "GET_CONFIGURABLE_PRODUCTS_FAILURE"

export const FILTER_PRODUCT_RESPONSE_REQUEST = "FILTER_PRODUCT_RESPONSE_REQUEST"
export const FILTER_PRODUCT_RESPONSE_SUCCESS = "FILTER_PRODUCT_RESPONSE_SUCCESS"
export const FILTER_PRODUCT_RESPONSE_FAILED = "FILTER_PRODUCT_RESPONSE_FAILED"

export const GET_RELATED_PRODUCTS_SUCCESS = "GET_RELATED_PRODUCTS_SUCCESS"
export const GET_RELATED_PRODUCTS_FAILED = "GET_RELATED_PRODUCTS_FAILED"

export const GET_PRODUCT_VARIANTS_SUCCESS = "GET_PRODUCT_VARIANTS_SUCCESS"
export const GET_PRODUCT_VARIANTS_FAILED = "GET_PRODUCT_VARIANTS_FAILED"