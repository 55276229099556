import { combineReducers } from "redux";
import {
  homepageData,
  headerBannerReducer,
} from "./Pages/homepage/HomepageReducer";
import {
  urlResolver,
  contactUsSubmit,
  storeConfigInfo,
  guestNewsLetterResponse,
  searchCategoryResponse,
  myOrdersResponse,
  getCustomFormResponse,
  submitSampleFormResponse,
  getMetaTitleResponse,
  getMinInstallmentAmountReducer,
  getProductInstallmentHtmlReducer,
  getSoftSearchKeyReducer,
} from "./General/GeneralReducer";
import {
  registerResponse,
  loginResponse,
  userResponse,
  changeAccount,
  updateNewsLetter,
  forgotPassword,
  resetPassword,
  addressResponse,
  unAuthResponse,
  addressSearchResponse,
  pincodeLookupResponse,
} from "./Auth/AuthReducer";
import { cmsResponse } from "./Pages/CMSPage/CMSPageReducer";
import {
  productList,
  productDetailResponse,
  filterProductReducer,
  getRelatedProductHTMLContentReducer,
  getProductVariantsReducer,
} from "./Pages/Products/ProductReducer";
import {
  createCartResponse,
  getCartResponse,
  deleteCartItemResponse,
  wishlistResponse,
  addProductToCart,
  getMiniCartResponse,
  getCartCountResponse,
} from "./Pages/CartPage/CartPageReduer";
import { cmsBlockResponse } from "./Pages/CMSBlock/CMSBlockReducer";
import { ordersSuccessResponse } from "./Pages/Orders/OrdersReducer";
import { categoryListResponse } from "./Pages/CategoryList/CategoryListReducer";
import {
  checkoutResponse,
  paypalExpressReducer,
  RapydPaymentReducer,
  stripePaymentReducer,
  divideBuyReducer,
  paymentInstallmentReducer,
  getPaymentMinAmountReducer
} from "./Pages/Checkout/CheckoutReducer";
import { blogListResponse } from "./Pages/Blog/BlogPageReducer";
import { getProductInstallmentHtmlAction } from "./General/GeneralAction";

export default combineReducers({
  homepageData,
  urlResolver,
  registerResponse,
  loginResponse,
  userResponse,
  resetPassword,
  changeAccount,
  updateNewsLetter,
  forgotPassword,
  cmsResponse,
  productList,
  contactUsSubmit,
  getCartResponse,
  createCartResponse,
  cmsBlockResponse,
  deleteCartItemResponse,
  storeConfigInfo,
  wishlistResponse,
  ordersSuccessResponse,
  categoryListResponse,
  addressResponse,
  guestNewsLetterResponse,
  searchCategoryResponse,
  productDetailResponse,
  myOrdersResponse,
  checkoutResponse,
  addProductToCart,
  blogListResponse,
  unAuthResponse,
  getCustomFormResponse,
  submitSampleFormResponse,
  pincodeLookupResponse,
  addressSearchResponse,
  getMetaTitleResponse,
  getMiniCartResponse,
  paypalExpressReducer,
  getCartCountResponse,
  headerBannerReducer,
  RapydPaymentReducer,
  filterProductReducer,
  getRelatedProductHTMLContentReducer,
  getProductVariantsReducer,
  stripePaymentReducer,
  getSoftSearchKeyReducer,
  getMinInstallmentAmountReducer,
  getProductInstallmentHtmlReducer,
  divideBuyReducer,
  paymentInstallmentReducer,
  getPaymentMinAmountReducer
});
