import { HOME } from "./../../../Constants/Route";
import {
  filterProduct,
  getCateList,
  getRelatedProductHTMLContent,
  getVariantsPrice,
} from "./../../../Services/graphQL.service";
import { ERROR } from "../../../Constants/Labels";
import {
  getCMSBlock,
  getProductDetail,
  getProductList,
  getUpsellProductDetail,
} from "../../../Services/graphQL.service";
import { showToast } from "../../../Utility/Utilities";
import {
  GET_CONFIGURABLE_PRODUCTS_REQUEST,
  GET_CONFIGURABLE_PRODUCTS_SUCCESS,
  GET_CONFIGURABLE_PRODUCTS_FAILURE,
  GET_PRODUCT_CMS_BLOCK_SUCCESS,
  GET_PRODUCT_DETAIL_FAILED,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_UPSELL_PRODUCT_DETAIL_SUCCESS,
  FILTER_PRODUCT_RESPONSE_FAILED,
  FILTER_PRODUCT_RESPONSE_REQUEST,
  FILTER_PRODUCT_RESPONSE_SUCCESS,
  GET_RELATED_PRODUCTS_FAILED,
  GET_RELATED_PRODUCTS_SUCCESS,
  GET_PRODUCT_VARIANTS_FAILED,
  GET_PRODUCT_VARIANTS_SUCCESS,
} from "./ProductTypes";
import { getActiveProductSwatches } from "../../../Services/axios.service";
import axios from "axios";

export function getProductListData(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PRODUCT_LIST_REQUEST,
        payload: { type: GET_PRODUCT_LIST_REQUEST },
      });
      const { data: categoryListData } = await getCateList(payload);
      if (categoryListData && categoryListData.errors) {
        showToast(ERROR, categoryListData.errors[0].message);
      } else if (
        categoryListData &&
        categoryListData.data.categoryList &&
        categoryListData.data.categoryList.length > 0
      ) {
        const displayMode = categoryListData.data.categoryList[0].display_mode;
        if (displayMode === "PRODUCTS_AND_PAGE" || displayMode === "PRODUCTS") {
          await fetchCategoryList(payload, dispatch, categoryListData.data);
        } else if (displayMode === "PAGE") {
          dispatch({
            type: GET_PRODUCT_LIST_SUCCESS,
            payload: {
              type: GET_PRODUCT_LIST_SUCCESS,
              data: categoryListData.data,
            },
          });
        }
      } else {
        await fetchCategoryList(payload, dispatch);
      }
    } catch (error: any) {
      console.error(
        "SOMETHING WENT WRONG WHILE FETCHING HOMEPAGE DATA ",
        error
      );
      dispatch({
        type: GET_PRODUCT_LIST_FAILED,
        payload: { type: GET_PRODUCT_LIST_FAILED, message: error.message },
      });
    }
  };
}

export const getProductDetailByName = (name: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getProductDetail(name);
      if (
        response.data.products.items[0]?.__typename === "ConfigurableProduct"
      ) {
        dispatch(getProductVariantsAction(name))
        dispatch(
          getConfigurableOptionsByName(
            name,
            response.data.products.items[0].uid
          )
        );
      }
      dispatch(getUpsellProductDetailByName(name));
      dispatch(getRelatedProductHTMLContentAction(name));
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: GET_PRODUCT_DETAIL_FAILED,
          payload: {
            type: GET_PRODUCT_DETAIL_FAILED,
          },
        });
      } else {
        if (response.data.products?.items.length > 0) {
          dispatch({
            type: GET_PRODUCT_DETAIL_SUCCESS,
            payload: {
              type: GET_PRODUCT_DETAIL_SUCCESS,
              data:
                response.data.products?.items.length > 0
                  ? response.data.products?.items[0]
                  : null,
            },
          });
        } else {
          window.location.href = HOME;
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: GET_PRODUCT_DETAIL_FAILED,
        payload: {
          type: GET_PRODUCT_DETAIL_FAILED,
        },
      });
    }
  };
};

export const getUpsellProductDetailByName = (name: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getUpsellProductDetail(name);
      if (response.errors) {
        showToast(ERROR, response.errors[0].name);
      } else {
        dispatch({
          type: GET_UPSELL_PRODUCT_DETAIL_SUCCESS,
          payload: {
            type: GET_UPSELL_PRODUCT_DETAIL_SUCCESS,
            data: response?.data?.products?.items[0]?.upsell_products,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const getConfigurableOptionsByName = (name: string, uid: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_CONFIGURABLE_PRODUCTS_REQUEST,
      payload: { type: GET_CONFIGURABLE_PRODUCTS_REQUEST },
    });

    try {
      const url = `/product-details/${name}`;
      const responses = await Promise.all([
        axios.post(url),
        getActiveProductSwatches(uid),
      ]);
      const { data: response } = responses[0];
      const activeSwatches = await responses[1].json();
      response?.data?.products?.items[0].configurable_options?.forEach(
        (c: any) => {
          if (c.attribute_code === "color") {
            c.values = c.values.filter((cc: any) =>
              activeSwatches.includes(cc.value_index.toString())
            );
          }
        }
      );
      if (response && response.data) {
        dispatch({
          type: GET_CONFIGURABLE_PRODUCTS_SUCCESS,
          payload: {
            type: GET_CONFIGURABLE_PRODUCTS_SUCCESS,
            data: response?.data?.products?.items[0],
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
      if (error === "timeout exceeded" || error === "Network error") {
        dispatch(getConfigurableOptionsByName(name, uid));
      } else {
        dispatch({
          type: GET_CONFIGURABLE_PRODUCTS_FAILURE,
          payload: {
            type: GET_CONFIGURABLE_PRODUCTS_FAILURE,
            data: error,
          },
        });
      }

      if (error === "timeout exceeded" || error === "Network error") {
        getConfigurableOptionsByName(name, uid);
      }
    }
  };
};

export const filterProductAction = (uIds: string, sku: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: FILTER_PRODUCT_RESPONSE_REQUEST,
      payload: {
        type: FILTER_PRODUCT_RESPONSE_REQUEST,
      },
    });
    try {
      const { data: response } = await filterProduct(uIds, sku);
      dispatch({
        type: FILTER_PRODUCT_RESPONSE_SUCCESS,
        payload: {
          type: FILTER_PRODUCT_RESPONSE_SUCCESS,
          data: response.data?.products?.items[0]
            ?.configurable_product_options_selection,
        },
      });
    } catch (error) {
      dispatch({
        type: FILTER_PRODUCT_RESPONSE_FAILED,
        payload: {
          type: FILTER_PRODUCT_RESPONSE_FAILED,
        },
      });
    }
  };
};

export const getCMSContent = (name: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getCMSBlock(name);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: GET_PRODUCT_CMS_BLOCK_SUCCESS,
          payload: {
            type: GET_PRODUCT_CMS_BLOCK_SUCCESS,
            data: response.data?.cmsBlocks?.items[0]?.content,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const getProductDetailFabricInformation = () => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getCMSBlock(
        `"product-detail-fabric-info-popup"`
      );
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
          payload: {
            type: GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
            data: response.data?.cmsBlocks?.items[0]?.content,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

async function fetchCategoryList(
  payload: any,
  dispatch: any,
  categoryList?: any
) {
  const { data: response } = await getProductList(payload);
  const graphQLResponse = response.data;
  if (graphQLResponse) {
    let data = { ...graphQLResponse };
    data = categoryList ? { ...data, ...categoryList } : data;
    dispatch({
      type: GET_PRODUCT_LIST_SUCCESS,
      payload: {
        type: GET_PRODUCT_LIST_SUCCESS,
        data,
      },
    });
  } else {
    dispatch({
      type: GET_PRODUCT_LIST_FAILED,
      payload: {
        type: GET_PRODUCT_LIST_FAILED,
        message: response.errors[0].message,
      },
    });
  }
}

export const getRelatedProductHTMLContentAction = (sku: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getRelatedProductHTMLContent(sku);
      if (response && response.errors) {
        dispatch({
          type: GET_RELATED_PRODUCTS_FAILED,
          payload: {
            type: GET_RELATED_PRODUCTS_FAILED,
            message: response.errors[0].message,
          },
        });
      } else {
        dispatch({
          type: GET_RELATED_PRODUCTS_SUCCESS,
          payload: {
            type: GET_RELATED_PRODUCTS_SUCCESS,
            data: response?.data?.products?.items[0],
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_RELATED_PRODUCTS_FAILED,
        payload: {
          type: GET_RELATED_PRODUCTS_FAILED,
          message: error.message,
        },
      });
    }
  };
};

export const getProductVariantsAction = (sku: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getVariantsPrice(sku)
      if (response && response.data && response.data.products && response.data.products.items) {
        dispatch({
          type: GET_PRODUCT_VARIANTS_SUCCESS,
          payload: {
            type: GET_PRODUCT_VARIANTS_SUCCESS,
            data: response.data.products.items
          }
        })
      }
    } catch (error: any) {
      dispatch({
        type: GET_PRODUCT_VARIANTS_FAILED,
        payload: {
          type: GET_PRODUCT_VARIANTS_FAILED,
          message: error.message
        }
      })
    }
  }
}