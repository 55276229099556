import { Accordion } from "react-bootstrap";
import { FaqInteface } from "../../Interfaces/Common.interface";

const FAQ = ({ faqs , title }: any) => {
  return (
    <>
      <div className="faq-wrap faq-container">
        <div className="custom-container">
          <h3 className="caps-on faq-header"> {title} </h3>
          <div className="faq-list">
            <Accordion>
              {faqs?.map((item: FaqInteface, index: number) => {
                return (
                  <Accordion.Item eventKey={`${index}`} key={index + "question"}>
                    <Accordion.Header>
                      <span className="bm">{item?.question}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="bs">{item?.answer}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
