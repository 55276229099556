/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CMSPages } from "./Components/CMSPages/CMSPages";
import CMSPageSkl from "./Components/CMSPages/CMSPageSkl";
import ProductDetail from "./Components/ProductDetails/ProductDetail";
import ProductList from "./Components/ProductList/ProductList";
import { HOME } from "./Constants/Route";
import { TYPES } from "./Interfaces/Response.interface";
import { findCategoryByUrl } from "./Redux/General/GeneralAction";
import {
  GET_META_TITLE_SUCCESS,
  URL_RESOLVER_FAILED,
  URL_RESOLVER_REQUEST,
  URL_RESOLVER_SUCCESS,
} from "./Redux/General/GeneralType";
import { getProductListData } from "./Redux/Pages/Products/ProductActions";
import PageNotFound from "./Components/ErrorPages/PageNotFound";
// import { Buffer } from "buffer";

const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const urlResolverData = useSelector((state: any) => state.urlResolver);

  const metaTitleResponse = useSelector(
    (state: any) => state.getMetaTitleResponse
  );

  const [component, setComponent] = useState<any>();

  useEffect(() => {
    // API call
    // loadComponent(location.pathname); // CLOSED: TEMPORARY
    return () => {
      dispatch({
        type: URL_RESOLVER_SUCCESS,
        payload: {
          type: URL_RESOLVER_SUCCESS,
          data: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (metaTitleResponse.type === GET_META_TITLE_SUCCESS) {
      document.title = metaTitleResponse.data.meta_title;
    }
  }, [metaTitleResponse]);

  useEffect(() => {
    if (location.pathname) {
      if (location.search && location.pathname.includes("search")) {
        const urlDecoded = decodeURIComponent(location.search.slice(1));
        const urlValue = urlDecoded.includes(":")
          ? getUrl(
              urlDecoded.split(":")[3].split("//www.livingitup.co.uk/")[1]
            )
          : getUrl(urlDecoded);
        if (urlValue === "home") {
          console.log(
            "URL VALUE ",
            urlValue,
            " urlDecoded ",
            urlDecoded,
            " location.search ",
            location.search,
            urlDecoded.split(":")
          );
          setComponent(<PageNotFound/>);
        } else {
          if (
            urlResolverData &&
            Object.keys(urlResolverData).length > 0 &&
            urlResolverData?.type !== URL_RESOLVER_SUCCESS
          ) {
            dispatch(findCategoryByUrl(urlValue));
          }
        }
      } else {
        if (urlResolverData && urlResolverData?.data?.type !== TYPES.PRODUCT) {
          dispatch(findCategoryByUrl(location.pathname.slice(1)));
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search && location.pathname.includes("search")) {
      const urlDecoded = decodeURIComponent(location.search.slice(1));
      const urlValue = urlDecoded.includes(":")
        ? getUrl(urlDecoded.split(":")[3].split("//www.livingitup.co.uk/")[1])
        : getUrl(urlDecoded);
      if (urlValue === "home") {
        console.log("STATING ", urlValue, " urlDecoded", urlDecoded);
        setComponent(<PageNotFound/>);
      } else {
        if (
          urlResolverData &&
          Object.keys(urlResolverData).length > 0 &&
          urlResolverData?.type !== URL_RESOLVER_SUCCESS
        ) {
          dispatch(findCategoryByUrl(urlValue));
        }
      }
    }
  }, [location.search, location.pathname]);

  useEffect(() => {
    if (urlResolverData) {
      if (urlResolverData.type === URL_RESOLVER_REQUEST) {
        setComponent(<CMSPageSkl />);
      } else if (
        urlResolverData.type === URL_RESOLVER_SUCCESS &&
        urlResolverData.data
      ) {
        const splitR = urlResolverData.data.relative_url.split("/");
        let route = splitR.length > 2 ? splitR[1] : splitR[0];
        route = route.split(".html")[0];
        if (urlResolverData.data.type === TYPES.CATEGORY) {
          const isSubCategory = location?.pathname?.split("/").length > 2;
          const routeSplit = location?.pathname?.split("/");
          if (isSubCategory) {
            const category = routeSplit[1];
            const subCategory = routeSplit[2];
            let entity_uid: string = getEntityuid(urlResolverData);
            if (entity_uid) {
              dispatch(getProductListData({ entity_uid, page: 1, size: 21 }));
            }

            setComponent(
              <ProductList
                entity_uid={entity_uid}
                category={category}
                subCategory={subCategory}
              />
            );
          } else {
            let entity_uid: string = getEntityuid(urlResolverData);
            if (entity_uid) {
              dispatch(getProductListData({ entity_uid, page: 1, size: 21 }));
              setComponent(
                <ProductList entity_uid={entity_uid} slug={route} />
              );
            }
          }
        } else if (urlResolverData.data.type === TYPES.CMS_PAGE) {
          setComponent(<CMSPages page={urlResolverData.data.relative_url} />);
        } else if (urlResolverData.data.type === TYPES.PRODUCT) {
          setComponent(<ProductDetail sku={urlResolverData.data.sku} />);
          dispatch({
            type: URL_RESOLVER_SUCCESS,
            payload: {
              type: URL_RESOLVER_SUCCESS,
              data: null,
            },
          });
        }
      } else if (urlResolverData.type === URL_RESOLVER_FAILED) {
        console.log("URLES RESOLVE ING ", urlResolverData);
        setComponent(<PageNotFound/>);
      }
    }
  }, [urlResolverData]);

  function getEntityuid(urlResolverData: any): string {
    // let str = urlResolverData?.data?.entity_uid;
    // let entity_uid = "";
    // if (str) {
    // entity_uid = Buffer.from(str, "base64").toString();
    // }
    // return entity_uid;
    return urlResolverData?.data?.entity_uid;
  }

  const getUrl = (url: string): string => {
    return url
      ? !url.includes("&")
        ? url
        : url.split("&")[0] && url.split("&")[0].includes(".html")
        ? url.split("&")[0].split(".html")[0] + ".html"
        : url.split("&")[0]
        ? url.split("&")[0]
        : "home"
      : "home";
  };
  return <>{component}</>;
};

export default Main;
