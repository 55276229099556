/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CHECKOUT,
  MY_ADDRESS,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
  ORDER_SUCCESS,
} from "../../Constants/Route";
import { FooterBottom } from "./FooterBottom";
import { FooterLinks } from "./FooterLinks";
import { FooterTop } from "./FooterTop";
import DividebyFooter from "./DividebyFooter";

const Footer = () => {
  const urls2 = [CHECKOUT, ORDER_SUCCESS];

  const [windowSize, setWindowSize] = useState(1366);

  const urls = [MY_WISHLIST, MY_ADDRESS, NEWSLETTER, MY_ORDERS_PAGE];

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();
  const [currentLocation1, setCurrentLocation1] = useState(String);
  const navigate1: any = useLocation();

  useEffect(() => {
    if (navigate) {
      setCurrentLocation(navigate.pathname);
    }
  }, [navigate]);

  useEffect(() => {
    // setTimeout(() => {
    //   document.getElementById("fspopup")?.addEventListener("click", (e) => {
    //     setFreeSampleModalShow(true);
    //   });
    // }, 100);
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    setCurrentLocation1(navigate1.pathname);
  }, [navigate1]);

  return (
    <>
      {urls.includes(currentLocation) && windowSize < 992 ? (
        ""
      ) : (
        <>
          <div
            className={
              urls2.includes(currentLocation1)
                ? "footer-main in-checkout"
                : "footer-main"
            }
          >
            <FooterTop />
            <FooterLinks />
            <FooterBottom />
            <DividebyFooter/>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
