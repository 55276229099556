import { Link } from "react-router-dom";
import menu from "../../Assets/Images/menu.svg";
import search from "../../Assets/Images/search.svg";
import cart from "../../Assets/Images/cart.svg";
import { useEffect, useState , useRef} from "react";
import MobileMainNavigation from "../NavigationMenu/MobileMainNavigation";
import MiniCart from "../Home/MiniCart/MiniCart";
import { useSelector } from "react-redux";
import {
  CHECKOUT,
  HOME,
  ORDER_SUCCESS,
  SEARCH_CATEGORY,
} from "../../Constants/Route";
import {
  GET_META_TITLE_SUCCESS,
  SEARCH_FUNCTIONALITY_SUCCESS,
} from "../../Redux/General/GeneralType";
import { useLocation, useNavigate } from "react-router";
import { gtmlPageView } from "../../Utility/gtmhelper";
import { GET_CART_COUNT_SUCCESS } from "../../Redux/Pages/CartPage/CartPageTypes";

const MobileHeader = () => {
  const [isActive, setActive] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const cartResponse = useSelector((state: any) => state.getCartCountResponse);
  const htmlElRef: any = useRef<any>(null);
  const handleToggle = () => {
    setActive(!isActive);
  };

  const [isCartActive, toggleBack2] = useState(false);

  const handleCartToggle = () => {
    toggleBack2(!isCartActive);
  };

  const [toggleSide, setToggleSide] = useState(false);

  const toggleMenu = () => {
    setToggleSide(!toggleSide);
  };

  const [currentLocation1, setCurrentLocation1] = useState(String);
  const navigate1: any = useLocation();

  useEffect(() => {
    setCurrentLocation1(navigate1.pathname);
  }, [navigate1]);

  useEffect(() => {
    if (cartResponse.type === GET_CART_COUNT_SUCCESS) {
      setCartCount(cartResponse.data);
    }
  }, [cartResponse]);

  const urls = [CHECKOUT, ORDER_SUCCESS];

  const navigate = useNavigate();

  const [searchCategory, setSearchCategory] = useState("");

  const searchResponse = useSelector(
    (state: any) => state.searchCategoryResponse
  );

  // const cartData = useSelector((state: any) => state.getCartResponse);

  const metaTitleResponse = useSelector(
    (state: any) => state.getMetaTitleResponse
  );

  useEffect(() => {
    if (
      searchResponse &&
      searchResponse.type === SEARCH_FUNCTIONALITY_SUCCESS
    ) {
      setSearchCategory("");
      setActive(false);
    }
  }, [searchResponse]);

  useEffect(() => {
    htmlElRef.current && htmlElRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    if (metaTitleResponse.type === GET_META_TITLE_SUCCESS) {
      document.title = metaTitleResponse.data.meta_title;
      gtmlPageView(
        metaTitleResponse.data.meta_title
          ? metaTitleResponse.data.meta_title
          : "Livingitup"
      );
    }
  }, [metaTitleResponse]);

  const searchCat = (e: any) => {
    if (e.key === "Enter") {
      navigate({
        pathname: SEARCH_CATEGORY,
        search: searchCategory,
      });
    }
  };

  return (
    <>
      <MobileMainNavigation toggleSide={toggleSide} toggleMenu={toggleMenu} />
      <div
        className={
          urls.includes(currentLocation1)
            ? currentLocation1 === ORDER_SUCCESS
              ? "mobile-header-wrap hide-767 in-checkout with-osuccess"
              : "mobile-header-wrap hide-767 in-checkout"
            : "mobile-header-wrap hide-767"
        }
      >
        <div className="custom-container">
          <div className="header-mob">
            <div className="mobile-left">
              <Link className="sprites-hamburger" to={"#"} onClick={toggleMenu}>
                <img src={menu} alt="Menu Icon"></img>
              </Link>
            </div>
            <div className="mobile-center">
              {navigate1.pathname === HOME ? (
                <h1 className="mob-logo">
                  <img
                    src={`${process.env.REACT_APP_BASE_MEDIA_URL}logo/${process.env.REACT_APP_HEADER_LOGO_SRC}`}
                    width="34"
                    height="34"
                    alt="Livingitup"
                  />
                </h1>
              ) : (
                <Link to={HOME} className="mob-logo">
                  <img
                    src={`${process.env.REACT_APP_BASE_MEDIA_URL}logo/${process.env.REACT_APP_HEADER_LOGO_SRC}`}
                    width="34"
                    height="34"
                    alt="Livingitup"
                  />
                </Link>
              )}
            </div>
            <div className="mobile-right">
              <Link to={"#"} className="search" onClick={() => handleToggle()}>
                <img src={search} alt="Search Bar" />
              </Link>
              <div className="minicart">
                <Link
                  to={"#"}
                  className="cart"
                  onClick={() => handleCartToggle()}
                >
                  <img src={cart} alt="Cart" />
                  <span className="lm">{cartCount}</span>
                </Link>
                <div
                  className={
                    isCartActive
                      ? "minicart-wrapper open"
                      : "minicart-wrapper close"
                  }
                >
                  <MiniCart toggleBack2={toggleBack2} />
                </div>
              </div>
              <div className={
                    isCartActive
                      ? "hide-767 dropdown-back show"
                      : "hide-767 dropdown-back"
                  } onClick={() => handleCartToggle()}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={isActive ? "search-box open" : "search-box close"}>
        <div className="custom-container">
          <div className="search-grp">
            <input
              type="search"
              value={searchCategory}
              ref={htmlElRef}
              onChange={(e: any) => setSearchCategory(e.target.value)}
              onKeyDown={searchCat}
              autoFocus={true}
            ></input>
            <button
              className="close-search"
              onClick={() => handleToggle()}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
