/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteCartItem,
  updateCartItem,
} from "../../Redux/Pages/CartPage/CartPageActions";
import {
  DELETE_CART_ERROR,
  DELETE_CART_SUCCESS,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_SUCCESS,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import {
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import CustomButton from "../Common/CustomButton/CustomButton";
import IncDecBox from "../Common/IncDecBox/IncDecBox";
import RemovePopup from "../Home/MiniCart/RemovePopup/RemovePopup";
import tickImg from "../../Assets/Images/tick.svg";
import { isGuestEmail } from "../../Utility/Utilities";

const CheckoutOrderSummary = ({ setShowPaymentMethod }: any) => {
  const dispatch = useDispatch();

  const cartResponse = useSelector((state: any) => state.getCartResponse);
  const deleteCartResponse = useSelector(
    (state: any) => state.deleteCartItemResponse
  );

  const addressResponse = useSelector((state: any) => state.addressResponse);

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);

  const [cartData, setCartData] = useState<any>({});

  const [removeCartId, setRemoveCartId] = useState(null);

  const [isChangedOrder, setChangedOrder] = useState(false);

  const [showDetail, setShowDetail] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (cartResponse && cartResponse.type === GET_CART_PAGE_SUCCESS) {
      setCartData(cartResponse.data);
      setDisabled(false);
    } else if (cartResponse && cartResponse.type === GET_CART_PAGE_ERROR) {
      setDisabled(false);
    }
  }, [cartResponse]);

  useEffect(() => {
    if (deleteCartResponse && deleteCartResponse.type === DELETE_CART_SUCCESS) {
      setDeleteLoading(false);
      setRemoveCartId(null);
    } else if (
      deleteCartResponse &&
      deleteCartResponse.type === DELETE_CART_ERROR
    ) {
      setDeleteLoading(false);
    }
  }, [deleteCartResponse]);

  useEffect(() => {
    if (isChangedOrder) {
      setShowPaymentMethod(true);
    }
  }, [isChangedOrder]);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      setCartData(checkoutResponse?.data?.cart);
      setShowDetail(
        checkoutResponse?.data?.cart?.shipping_addresses?.length > 0
      );
      setChangedOrder(
        checkoutResponse?.data?.cart?.shipping_addresses[0]
          ?.selected_shipping_method !== null &&
          checkoutResponse?.data?.cart?.shipping_addresses[0]
            ?.selected_shipping_method !== undefined
      );
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_ADDRESS_SUCCESS
    ) {
      setCartData(checkoutResponse?.data);
      setShowDetail(checkoutResponse?.data?.shipping_addresses?.length > 0);
    }
  }, [checkoutResponse]);

  const updateCart = (quantity: number, uid: string) => {
    if (quantity >= 0) {
      setDisabled(true);
      dispatch(
        updateCartItem({
          uid,
          quantity,
        })
      );
    }
  };

  const onDeleteCart = () => {
    setDeleteLoading(true);
    dispatch(
      deleteCartItem({
        cartItemId: removeCartId,
      })
    );
  };

  return (
    <div className={`ckout-inner ${isChangedOrder ? "yeswith-img" : "no"}`}>
      <div className="ckout-top-box">
        <div className="ckout-head">
          <span className={`tick ${isChangedOrder ? "yes" : "no"}`}>
            {cartData &&
            cartData.items &&
            cartData.items.length > 0 &&
            isChangedOrder ? (
              <img src={tickImg} alt="Completed Step" />
            ) : (
              <p className="bs">2</p>
            )}
          </span>
          <p className="ts caps-on">Order Summary</p>
        </div>
        {cartData &&
        cartData.items &&
        cartData.items.length > 0 &&
        isChangedOrder ? (
          <Link
            to={""}
            title="Change Order"
            className="bm with-border"
            onClick={() => setChangedOrder(false)}
          >
            Change Order
          </Link>
        ) : null}
      </div>
      <div className="summery-box">
        {showDetail ? (
          <>
            {cartData && cartData.items && cartData.items.length > 0 ? (
              <>
                {" "}
                {cartData.items
                  ?.filter((cart: any) => cart)
                  .map((item: any, index: number) => (
                    <div className="ckout-summery" key={index}>
                      <div className="summery-img">
                        <img
                          src={item.product.image.url}
                          alt={item.product.image.label}
                          title={item.product.image.label}
                        />
                      </div>
                      <div className="summery-txt">
                        <Link
                          className="ll"
                          to={
                            "/" + item.product.url_key + item.product.url_suffix
                          }
                          title={item.product.image.label}
                        >
                          {item.product.name}
                        </Link>
                        {!isChangedOrder ? (
                          <ul>
                            {item.configurable_options
                              ?.filter((data: any) => data)
                              .map((option: { value_label: string }) => (
                                <li
                                  key={option.value_label}
                                  className="bs type attr"
                                >
                                  <span>{option.value_label}</span>
                                </li>
                              ))}
                            {item.product?.availability_message ? (
                              <li className="bs dispatch attr">
                                <span>{item.product.availability_message}</span>
                              </li>
                            ) : null}
                          </ul>
                        ) : null}
                        <div className="quantity">
                          <div className="qty-txt">
                            <p className="bs caps-on">qty</p>
                          </div>
                          {!isChangedOrder ? (
                            <div className="inc-dec-box">
                              <IncDecBox
                                disabled={disabled}
                                quantityNo={item.quantity}
                                onIncrement={(e: number) =>
                                  updateCart(e, item.uid)
                                }
                                onDecrement={(e: number) =>
                                  updateCart(e, item.uid)
                                }
                              />
                            </div>
                          ) : (
                            <div className="qty-txt">
                              <p className="bs caps-on">{item.quantity}</p>
                            </div>
                          )}
                        </div>
                        {!isChangedOrder ? (
                          <div className="price-wrapper">
                            <p className="normal-price">
                              {item.prices?.row_total?.value >= 0
                                ? "£" +
                                  Number(item.prices?.row_total?.value).toFixed(
                                    2
                                  )
                                : null}
                            </p>
                          </div>
                        ) : null}
                        {!isChangedOrder ? (
                          <Link
                            to={""}
                            className="bm remove"
                            onClick={() => setRemoveCartId(item.uid)}
                          >
                            Remove
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  ))}
                {!isChangedOrder ? (
                  <div className="confirm-order">
                    <p className="bm">
                      Order confirmation email will be sent to{" "}
                      {isGuestEmail() ? (
                        <Link to={`mailto:${isGuestEmail()}`}>
                          {isGuestEmail()}
                        </Link>
                      ) : (
                        <Link to={`mailto:${addressResponse?.data?.email}`}>
                          {addressResponse?.data?.email}
                        </Link>
                      )}
                    </p>
                    <CustomButton
                      bg={"fill"}
                      onClick={() => setChangedOrder(true)}
                    >
                      Continue
                    </CustomButton>
                  </div>
                ) : null}
              </>
            ) : (
              <p className="alignboth-center">No basket Data found</p>
            )}
          </>
        ) : null}
        <RemovePopup
          text="Are you sure you would like to remove this item from the shopping basket?"
          loading={deleteLoading}
          onCancel={() => setRemoveCartId(null)}
          onSubmit={onDeleteCart}
          show={removeCartId}
        />
      </div>
    </div>
  );
};
export default CheckoutOrderSummary;
