/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link, useLocation } from "react-router-dom";
import { pushDataLayer } from "../../Utility/gtmhelper";
import {
  LABEL_CONFIGURABLEPRODUCT,
  LBAEL_FROM_PRICE,
} from "../../Constants/Labels";
import { scrollTop } from "../../Utility/Utilities";
const ProductListView = ({
  name,
  sku,
  url_key,
  url_suffix,
  small_image,
  price_range,
  __typename,
  productbadgetext,
  showproductbadge,
}: any) => {
  const location = useLocation();

  const getGTMObj = ({ name, price, sku }: any) => {
    return {
      click: {
        products: [
          {
            name,
            sku,
            price,
          },
        ],
      },
    };
  };

  return (
    <div key={name} className="product-list-item">
      <div className="image-top">
        <Link
          to={"/" + url_key + url_suffix}
          state={{ path: location.pathname }}
          onClick={() => {
            // window.scrollTo(0, 0);
            scrollTop();
            pushDataLayer(
              "productClick",
              getGTMObj({
                name,
                price: price_range.minimum_price.final_price.value,
                sku: sku,
              })
            );
          }}
        >
          {showproductbadge && productbadgetext ? (
            <div className="product-list-badge-txt">
              <p>{productbadgetext}</p>
            </div>
          ) : null}
           
          <img
            src={small_image?.url}
            alt={small_image?.label}
            title={small_image?.label}
          />
        </Link>
      </div>
      <div className="product-list-item-txt">
        <Link
          to={"/" + url_key + url_suffix}
          state={{ path: location.pathname }}
          onClick={() =>
            pushDataLayer(
              "productClick",
              getGTMObj({
                name,
                price: price_range.minimum_price.final_price.value,
                sku: sku,
              })
            )
          }
        >
          <span className="bm">{name}</span>
        </Link>
      </div>
      {price_range && (
        <div className="price-wrapper">
          {price_range.minimum_price.final_price.value ===
          price_range.minimum_price.regular_price.value ? (
            <p className="normal-price">
              {__typename === LABEL_CONFIGURABLEPRODUCT
                ? `${LBAEL_FROM_PRICE} `
                : ""}
              £{Number(price_range.minimum_price.final_price.value).toFixed(2)}
            </p>
          ) : (
            <>
              <p className="product-price">
                £
                {Number(price_range.minimum_price.regular_price.value).toFixed(
                  2
                )}
              </p>
              <p className="special-price">
                £
                {Number(price_range.minimum_price.final_price.value).toFixed(2)}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductListView;
