import { useStripe, useElements, PaymentElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import React, { SyntheticEvent, useEffect, useState } from 'react'
import { ERROR, PAYMENT_ACTIONS, PAYMENT_METHOD, STRIPE_CARD_ERROR, SUCCESS } from '../../../Constants/Labels';
import { createLogs } from '../../../Services/axios.service';
import { getSessionItem, isAuth, showToast } from '../../../Utility/Utilities';
import CustomButton from '../../Common/CustomButton/CustomButton';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { stripePlaceOrder } from '../../../Services/graphQL.service';
import { CART_ID, GUEST_EMAIL } from '../../../Constants/LocalStorage';
import { onPlaceOrder, stripeAuthenticationPlaceOrderAction, stripePlaceOrderAction } from '../../../Redux/Pages/Checkout/CheckoutAction';
import { ORDER_PLACED_SUCCESS, STRIPE_ORDER_AUTHENTICATION_REQUIRED, STRIPE_ORDER_FAILED, STRIPE_ORDER_REQUEST, STRIPE_ORDER_SUCESS } from '../../../Redux/Pages/Checkout/CheckoutTypes';
import { ORDER_SUCCESS, STRIPE_PROGRESS } from '../../../Constants/Route';
import { useNavigate } from 'react-router';


export default function StripeCheckoutForm({
    showContinueButton,
    onSubmitStripe,
    submitStripeForm,
    setSubmitStripeForm,
    mobileState,
    setMobileState,
    addressData,
    setSubmitLogin
}: any) {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cardNumberError, setCardNumberError] = useState("");
    const [cardCvvError, setCardCvvError] = useState("");
    const [cardExpiryError, setCardExpiryError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    

    const stripe = useStripe();
    const elements = useElements();

    const stripePaymentReducer = useSelector((state: any) => state.stripePaymentReducer);
    const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
    const userEmail = useSelector((state: any) => state?.userResponse?.data?.email);

    const resetMobileState = () => {
        if (typeof setMobileState === "function") {
            setMobileState({
                ...mobileState,
                isClicked: false,
                loading: false
            });
        }
    }

    const stripeHandleAuthentication = async (clientSecret: string) => {
        try {
            const result = await stripe?.handleNextAction({
                clientSecret: clientSecret
            });
            if (result?.error) {
                showToast(ERROR, result?.error?.message);
                createLogs({
                    action: PAYMENT_ACTIONS.STRIPE_AUTHENTICATION_ERROR,
                    payment_method: PAYMENT_METHOD.STRIPE,
                    api_response: result?.error,
                    status: ERROR
                });
                resetMobileState();
            }
            if (result?.paymentIntent?.status === "succeeded") {
                dispatch(stripeAuthenticationPlaceOrderAction());
                navigate(STRIPE_PROGRESS)
            } else if (result?.paymentIntent?.status === "processing") {
                resetMobileState();
                showToast(ERROR, "Your payment is in processing . Please check order status after sometimes.")
            } else if (result?.paymentIntent?.status === "canceled") {
                resetMobileState();
                showToast(ERROR, "Payment failed. Please try again.")
            }

        } catch (error) {
            resetMobileState();
            console.log(error, "error on handle authentication")
        }
    }



    useEffect(() => {
        if (stripePaymentReducer.type === STRIPE_ORDER_SUCESS && checkoutResponse.type === ORDER_PLACED_SUCCESS && stripePaymentReducer?.data?.order?.order_number && stripePaymentReducer?.data?.order_number !== "") {
            navigate(ORDER_SUCCESS + "?fromCheckout=True");
        } else if (stripePaymentReducer.type === STRIPE_ORDER_AUTHENTICATION_REQUIRED && stripePaymentReducer?.data?.client_secret) {
            const clientSecret: string = stripePaymentReducer?.data?.client_secret;
            stripeHandleAuthentication(clientSecret);
        }
        else if (stripePaymentReducer.type === STRIPE_ORDER_FAILED) {
            resetMobileState();
            if (elements && elements != null) {
                const cardElement = elements.getElement(CardNumberElement);
                // cardElement?.clear();
            }
        }
    }, [stripePaymentReducer])

    const handleSubmit = async (e: any = null) => {  
        if (e) {
            e.preventDefault();
        }
        setSubmitLogin();     
        if (elements == null) {
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        if (cardElement == null) {
            return;
        }
        const cardNumber: any = elements.getElement('cardNumber');
        const isEmptyCardNum = cardNumber?._empty;
        const isInvalidCardNum = cardNumber?._invalid;
        const isvalidCardNum = !(isEmptyCardNum || isInvalidCardNum);
        if (!isvalidCardNum) {
            setCardNumberError(STRIPE_CARD_ERROR.CARD_NUMBER_ERROR);
        }

        const cardExpiry: any = elements.getElement('cardExpiry');
        const isEmptyCardExpiry = cardExpiry?._empty;
        const isInvalidCardExp = cardExpiry?._invalid;
        const isvalidExpiry = !(isEmptyCardExpiry || isInvalidCardExp);
        if (!isvalidExpiry) {
            setCardExpiryError(STRIPE_CARD_ERROR.CARD_EXPIRY_ERROR);
        }

        const cardCvv: any = elements.getElement('cardCvc');
        const isEmptyCardCvv = cardCvv?._empty;
        const isInvalidCardCvv = cardCvv?._invalid;
        const isvalidCardCvv = !(isEmptyCardCvv || isInvalidCardCvv);
        if (!isvalidCardCvv) {
            setCardCvvError(STRIPE_CARD_ERROR.CARD_CVV_ERROR);
        }

        if (!isvalidCardCvv || !isvalidExpiry || !isvalidCardNum) {
            resetMobileState();
            return;
        }

        try {
            setIsLoading(true);
            let email ;
            if(!isAuth()){
                email = getSessionItem(GUEST_EMAIL);
            }else{
                email = userEmail;
            }
            let paymentMethodRes = await stripe
                ?.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details:{
                        name:addressData?.addresses?.[0]?.firstname + addressData?.addresses?.[0]?.lastname,
                        email:email,
                        phone:addressData?.addresses?.[0]?.telephone,
                        address:{
                            city:addressData?.addresses?.[0]?.city,
                            country:"UK",
                            line1:addressData?.addresses?.[0]?.street?.[0],
                            line2:addressData?.addresses?.[0]?.street?.[1],
                            postal_code:addressData?.addresses?.[0]?.postcode,
                            state:addressData?.addresses?.[0]?.region?.region,
                        }
                    }
                });
            
            
            setIsLoading(false);
            const paymentMethod: any = paymentMethodRes?.paymentMethod;
            const error = paymentMethodRes?.error;
            if (error) {
                resetMobileState();
                showToast(ERROR, error.message);
                createLogs({
                    action: PAYMENT_ACTIONS.STRIPE_CREATE_PAYMENT_METHOD_ERROR,
                    payment_method: PAYMENT_METHOD.STRIPE,
                    api_response: error,
                    status: ERROR
                })
            }
            createLogs({
                action: PAYMENT_ACTIONS.STRIPE_CREATE_PAYMENT_METHOD_SUCESS,
                payment_method: PAYMENT_METHOD.STRIPE,
                api_response: paymentMethod,
                status: SUCCESS
            })

            if (paymentMethod?.id) {
                // navigate(`/checkout/stripe/${paymentMethod?.id}`)
                dispatch(stripePlaceOrderAction(paymentMethod?.id))
            }
        } catch (error) {
            setIsLoading(false);
            resetMobileState();
            dispatch({
                type: STRIPE_ORDER_FAILED,
                data: {
                    type: STRIPE_ORDER_FAILED
                }
            });
            createLogs({
                action: PAYMENT_ACTIONS.STRIPE_CREATE_PAYMENT_METHOD_ERROR,
                payment_method: PAYMENT_METHOD.STRIPE,
                api_response: error,
                status: ERROR
            })
        }
    }

    useEffect(() => {
        if (submitStripeForm && submitStripeForm === true) {
            handleSubmit();
            setSubmitStripeForm(false);
        }
    }, [submitStripeForm])

    return (
        <form onSubmit={handleSubmit} id="stripecheckout-form" className='stripecheckout-form'>
            {/* <PaymentElement /> */}
            <Row>
                <Col xs={12}>
                    <div className='input-groups'>
                        <label className="lm">Card Number</label>
                        <CardNumberElement
                            className='form-control'
                            options={{
                                showIcon: true,
                                iconStyle: "solid",
                                classes: {
                                    base: "form-control",
                                    complete: "form-control",
                                    invalid: "form-control",
                                },
                            }}
                            onChange={(e: any) => {
                                if (e.error) {
                                    setCardNumberError(e.error.message);
                                } else if (e.empty) {
                                    setCardNumberError(STRIPE_CARD_ERROR.CARD_NUMBER_ERROR);
                                } else {
                                    setCardNumberError("");
                                }
                            }}
                        />
                        {!!cardNumberError && cardNumberError !== "" && (
                            <span className="error">{cardNumberError}</span>
                        )}
                    </div>
                </Col>

                <Col xs={7}>
                    <div className='input-groups'>
                        <label className="lm">Expiry Date</label>
                        <CardExpiryElement
                            className='form-control'
                            options={{
                                classes: {
                                    base: "form-control",
                                    complete: "form-control",
                                    invalid: "form-control",
                                },
                            }}
                            onChange={(e: any) => {
                                if (e.error) {
                                    setCardExpiryError(e.error.message);
                                } else if (e.empty) {
                                    setCardExpiryError(STRIPE_CARD_ERROR.CARD_EXPIRY_ERROR);
                                } else {
                                    setCardExpiryError("");
                                }
                            }}
                        />

                        {!!cardExpiryError && cardExpiryError !== "" && (
                            <span className="error">{cardExpiryError}</span>
                        )}

                    </div>
                </Col>

                <Col xs={5}>
                    <div className='input-groups'>
                        <label className="lm">CVV</label>
                        <CardCvcElement
                            className='form-control'
                            options={{
                                classes: {
                                    base: "form-control",
                                    complete: "form-control",
                                    invalid: "form-control",
                                },
                            }}
                            onChange={(e: any) => {
                                if (e.error) {
                                    setCardCvvError(e.error.message);
                                } else if (e.empty) {
                                    setCardCvvError(STRIPE_CARD_ERROR.CARD_CVV_ERROR);
                                } else {
                                    setCardCvvError("");
                                }
                            }}
                        />
                        {!!cardCvvError && cardCvvError !== "" && (
                            <span className="error">{cardCvvError}</span>
                        )}

                    </div>
                </Col>
            </Row>

            {
                showContinueButton && (
                    <CustomButton
                        isLoading={isLoading || stripePaymentReducer.type === STRIPE_ORDER_REQUEST}
                        type='submit'
                        onClick={(e: any) => handleSubmit(e)}
                        disabled={!stripe || !elements}
                    >
                        Place Order
                    </CustomButton>
                )
            }
        </form>
    )
}
