import { pushDataLayer } from "../../../Utility/gtmhelper";
import { ADD_TO_WISHLIST_REQUEST } from "./../Products/ProductTypes";
import { ERROR, SUCCESS } from "../../../Constants/Labels";
import { CART_ID } from "../../../Constants/LocalStorage";
import {
  addCoupan,
  addProductToWishlist,
  addProductToWishlistItem,
  addSimpleProductToCartItem,
  addToCart,
  createEmptCart,
  deleteCart,
  getCartCountDetail,
  getCartDetail,
  getMiniCartDetail,
  getWishlistItems,
  mergerCart,
  removeCoupan,
  removeFromWishlist,
  updateCart,
} from "../../../Services/graphQL.service";
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
  showToast,
} from "../../../Utility/Utilities";
import {
  ADD_COUPAN_FAILED,
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_TO_WISHLIST_ERROR,
  ADD_TO_WISHLIST_SUCCESS,
  CREATE_EMPTY_CART_ERROR,
  CREATE_EMPTY_CART_SUCCESS,
  DELETE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_WISHLIST_ERROR,
  GET_CART_COUNT_FAILED,
  GET_CART_COUNT_SUCCESS,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_SUCCESS,
  GET_MINI_CART_FAILED,
  GET_MINI_CART_SUCCESS,
  GET_WISHLIST_ERROR,
  GET_WISHLIST_SUCCESS,
  REMOVE_COUPAN_FAILED,
  UPDATE_CART_ERROR,
  UPDATE_CART_REQUEST,
} from "./CartPageTypes";

export const getCartCountAction = () => {
  return async (dispatch: any) => {
    // dispatch({ type: GET_CART_PAGE_REQUEST });
    try {
      const cartId = getSessionItem(CART_ID);
      if (cartId) {
        const { data: response } = await getCartCountDetail(cartId);
        dispatch(getMiniCartAction())
        if (response && response.errors) {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            dispatch({
              type: GET_CART_COUNT_SUCCESS,
              payload: {
                type: GET_CART_COUNT_SUCCESS,
                data: response?.data?.cart?.total_quantity,
              },
            });
          } else {
            handleCartError(response, dispatch);
          }
        } else {
          dispatch({
            type: GET_CART_COUNT_SUCCESS,
            payload: {
              type: GET_CART_COUNT_SUCCESS,
              data: response?.data?.cart?.total_quantity,
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_CART_COUNT_FAILED,
        message: error,
      });
    }
  };
};

export const getMiniCartAction = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      if (cartId) {
        const { data: response } = await getMiniCartDetail(cartId);
        if (response && response.errors) {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: response?.data?.cart,
                error: true
              },
            });
          } else {
            dispatch({
              type: GET_MINI_CART_FAILED,
              message: response.errors[0].message,
            });
            handleCartError(response, dispatch);
          }
        } else {
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: response?.data.cart,
            },
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: GET_MINI_CART_FAILED,
        message: error?.message,
      });
    }
  };
};

export const getCartAction = () => {
  return async (dispatch: any) => {
    // dispatch({ type: GET_CART_PAGE_REQUEST });
    try {
      const cartIdStorage = getSessionItem(CART_ID);
      if (cartIdStorage) {
        const { data: response } = await getCartDetail(cartIdStorage);
        const { data: cartDetail } = response;
        if (cartDetail && cartDetail.cart) {
          let errorMsg = response.errors ? response.errors[0]?.message === 'Some item options or their combination are not currently available.' : false
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.cart,
              error: errorMsg
            },
          });
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: cartDetail.cart,
              error: errorMsg
            },
          });
        } else {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: cartDetail.cart,
                error: true
              },
            });
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: cartDetail.cart,
              },
            });
          } else {
            handleCartError(response, dispatch);
          }
        }
      }
    } catch (error: any) {
      dispatch({
        type: GET_CART_PAGE_ERROR,
        message: error.message,
      });
    }
  };
};

export const updateCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: UPDATE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.cartId) {
        const { data: response } = await updateCart(payload);
        const { data: cartDetail } = response;
        if (
          cartDetail &&
          cartDetail.updateCartItems &&
          cartDetail.updateCartItems.cart
        ) {
          dispatch(getCartCountAction());
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: cartDetail.updateCartItems.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.updateCartItems.cart,
            },
          });
        } else {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            handleCartError(response, dispatch);
            dispatch(getCartCountAction());
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: cartDetail.updateCartItems?.cart,
                error: true
              },
            });
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: cartDetail.updateCartItems?.cart,
                error: true
              },
            });
          } else {
            handleCartError(response, dispatch);
            dispatch({
              type: GET_CART_PAGE_ERROR,
              payload: {
                type: GET_CART_PAGE_ERROR,
                message: response.errors[0].message,
                data: payload,
              },
            });
          }
        }
      } else {
        showToast(ERROR, "Something went wrong");
        dispatch({
          type: GET_CART_PAGE_ERROR,
          payload: {
            type: GET_CART_PAGE_ERROR,
            data: payload,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: UPDATE_CART_ERROR,
        payload: { type: UPDATE_CART_REQUEST, message: error?.message },
      });
    }
  };
};

export const createEmptyCartAction = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      if (!cartId) {
        const { data: response } = await createEmptCart();
        const { customerCart: cartResponse } = response.data;
        if (cartResponse) {
          dispatch({
            type: CREATE_EMPTY_CART_SUCCESS,
            payload: { type: CREATE_EMPTY_CART_SUCCESS },
          });
          const token = getSessionItem(CART_ID);
          if (token) {
            try {
              await mergerCart({
                sourceCartId: token,
                destinationCartId: cartResponse.id,
              });
            } catch (error) {
              showToast(ERROR, "Unable to merge cart");
            }
          }
          if (cartResponse && cartResponse.id) {
            setSessionItem(CART_ID, cartResponse.id);
          }
        } else if (response.data.createEmptyCart) {
          dispatch({
            type: CREATE_EMPTY_CART_SUCCESS,
            payload: { type: CREATE_EMPTY_CART_SUCCESS },
          });
          if (response && response.data.createEmptyCart) {
            setSessionItem(CART_ID, response.data.createEmptyCart);
          }
        } else {
          handleCartError(response, dispatch);
          showToast(ERROR, response.errors[0].message);
        }
      } else {
        dispatch(getCartCountAction());
        dispatch(getCartAction());
      }
    } catch (error) {
      dispatch({
        type: CREATE_EMPTY_CART_ERROR,
        payload: { type: CREATE_EMPTY_CART_ERROR, message: error },
      });
      showToast(ERROR, error);
    }
  };
};

export const deleteCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: DELETE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await deleteCart(payload);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          dispatch({
            type: DELETE_CART_SUCCESS,
            payload: { type: DELETE_CART_SUCCESS, data: payload.cartItemId },
          });
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: response?.data?.removeItemFromCart?.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.removeItemFromCart?.cart,
            },
          });
          dispatch(getCartCountAction());
        } else {
          handleCartError(response, dispatch);
          dispatch({
            type: DELETE_CART_ERROR,
            payload: {
              type: DELETE_CART_ERROR,
              data: response.errors[0].message,
            },
          });
        }
      } else {
        dispatch({
          type: DELETE_CART_SUCCESS,
          payload: { type: DELETE_CART_SUCCESS, data: payload.cartItemId },
        });
        dispatch({
          type: GET_MINI_CART_SUCCESS,
          payload: {
            type: GET_MINI_CART_SUCCESS,
            data: response?.data?.removeItemFromCart?.cart,
          },
        });
        dispatch({
          type: GET_CART_PAGE_SUCCESS,
          payload: {
            type: GET_CART_PAGE_SUCCESS,
            data: response?.data?.removeItemFromCart?.cart,
          },
        });
        dispatch(getCartCountAction());
      }
    } catch (error: any) {
      dispatch({
        type: DELETE_CART_ERROR,
        payload: {
          type: DELETE_CART_ERROR,
          data: error.message,
        },
      });
      showToast(ERROR, error.message);
    }
  };
};

export const getWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getWishlistItems(payload);
      if (response) {
        if (response.errors) {
          dispatch({
            type: GET_WISHLIST_ERROR,
            payload: {
              type: GET_WISHLIST_ERROR,
              message: response.errors[0].message,
            },
          });
          showToast(ERROR, response.errors[0].message);
        } else {
          dispatch({
            type: GET_WISHLIST_SUCCESS,
            payload: {
              type: GET_WISHLIST_SUCCESS,
              data: response.data.customer?.wishlists[0],
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_WISHLIST_ERROR,
        payload: error,
      });
      showToast(ERROR, error);
    }
  };
};

export const removeFromWishlistItems = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_TO_WISHLIST_REQUEST,
        payload: {
          type: ADD_TO_WISHLIST_REQUEST,
          data: null,
        },
      });
      const { data: response } = await removeFromWishlist(payload);
      if (response.errors) {
        dispatch({
          type: DELETE_WISHLIST_ERROR,
          payload: {
            type: DELETE_WISHLIST_ERROR,
            message: response.errors[0].message,
          },
        });
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: GET_WISHLIST_SUCCESS,
          payload: {
            type: GET_WISHLIST_SUCCESS,
            data: {
              items_v2: {
                items: response.data.removeProductsFromWishlist.wishlist.items,
              },
              items_count:
                response.data.removeProductsFromWishlist.wishlist.items_count,
            },
          },
        });
        showToast(
          SUCCESS,
          "Product has been removed from wishlist successfully."
        );
      }
    } catch (error) {
      dispatch({
        type: DELETE_WISHLIST_ERROR,
        payload: {
          type: DELETE_WISHLIST_ERROR,
          message: error,
        },
      });
      showToast(ERROR, error);
    }
  };
};

export const MoveProductToWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      const response = await addProductToWishlist(payload);
      if (response && response.length > 0) {
        const { data: firstResponse } = response[0];
        const { data: secondResponse } = response[1];
        const { data: fR } = firstResponse;
        const { data: sR } = secondResponse;
        if (
          (fR &&
            fR.addProductsToWishlist &&
            (fR.addProductsToWishlist.errors ||
              fR.addProductsToWishlist.user_errors.length > 0)) ||
          (sR &&
            sR.removeCoupanFromCart &&
            (sR.removeItemFromCart.errors ||
              sR.removeItemFromCart.user_errors.length > 0))
        ) {
          let message = "";
          if (sR.removeItemFromCart.errors) {
            message = fR.addProductsToWishlist.errors
              ? fR.addProductsToWishlist.errors[0].message
              : sR.removeItemFromCart.errors
                ? sR.removeItemFromCart.errors[0].message
                : "Something went wrong";
            // REMOVE FROM WISHLIST
            const wishlistId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
            const wishlistItemId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                .items[0].id;
            await removeFromWishlist({ wishlistId, wishlistItemId });
          } else if (sR.removeItemFromCart.user_errors.length > 0) {
            message = fR.addProductsToWishlist.errors
              ? firstResponse.user_errors[0].message
              : sR.removeItemFromCart.user_errors
                ? sR.removeItemFromCart.user_errors[0].message
                : "Something went wrong";
            const wishlistId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
            const wishlistItemId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                .items[0].id;
            await removeFromWishlist({ wishlistId, wishlistItemId });
          }
          showToast(ERROR, message);
          dispatch({
            type: ADD_TO_WISHLIST_ERROR,
            payload: { type: ADD_TO_WISHLIST_ERROR, message: message },
          });
        } else {
          showToast(SUCCESS, "Moved to wishlist successfully");
          dispatch(getCartCountAction());
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: sR.removeItemFromCart.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: sR.removeItemFromCart.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: ADD_TO_WISHLIST_ERROR,
        payload: { type: ADD_TO_WISHLIST_ERROR, message: error },
      });
    }
  };
};

export const removeCoupanFromCart = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      const { data: response } = await removeCoupan(cartId);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          showToast(SUCCESS, "Removed coupon from basket successfully");
          if (response.data) {
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: response?.data?.removeCouponFromCart?.cart,
              },
            });
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: response?.data?.removeCouponFromCart?.cart,
              },
            });
          }
        } else {
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: REMOVE_COUPAN_FAILED,
            payload: {
              type: REMOVE_COUPAN_FAILED,
              message: response.errors[0].message,
            },
          });
        }
      } else {
        showToast(SUCCESS, "Removed coupon from basket successfully");
        if (response.data) {
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: response?.data?.removeCouponFromCart?.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.removeCouponFromCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: REMOVE_COUPAN_FAILED,
        payload: { type: REMOVE_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const applyCoupanToCart = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await addCoupan(payload);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          showToast(SUCCESS, "The coupon applied to basket successfully");
          if (response.data) {
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: response?.data?.applyCouponToCart?.cart,
              },
            });
          }
        } else {
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: ADD_COUPAN_FAILED,
            payload: {
              type: ADD_COUPAN_FAILED,
              message: response.errors[0].message,
            },
          });
        }
      } else {
        showToast(SUCCESS, "The coupon applied to basket successfully");
        if (response.data) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.applyCouponToCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: ADD_COUPAN_FAILED,
        payload: { type: ADD_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const addToWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_TO_WISHLIST_REQUEST,
        payload: {
          type: ADD_TO_WISHLIST_REQUEST,
          data: null,
        },
      });
      const { data: response } = await addProductToWishlistItem(payload);
      if (response.errors || response.user_errors) {
        const error =
          response.errors[0].message || response.user_errors[0].message;
        showToast(ERROR, error);
      } else {
        showToast(SUCCESS, "The product added to wishlist successfully");
        dispatch({
          type: ADD_TO_WISHLIST_SUCCESS,
          payload: {
            type: ADD_TO_WISHLIST_SUCCESS,
            data: response.data?.addProductsToWishlist?.wishlist,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const addProductToCartItem = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (!payload.cartId) {
        const { data: response } = await createEmptCart();
        const { customerCart: cartResponse } = response.data;
        if (cartResponse) {
          if (cartResponse && cartResponse.id) {
            payload.cartId = cartResponse.id;
            setSessionItem(CART_ID, cartResponse.id);
          }
        } else if (response.data.createEmptyCart) {
          if (response && response.data.createEmptyCart) {
            payload.cartId = response.data.createEmptyCart;
            setSessionItem(CART_ID, response.data.createEmptyCart);
          }
        } else {
          showToast(ERROR, response.errors[0].message);
        }
      }
      let data = null;
      let response = null;
      if (payload.parent_sku && payload.child_sku) {
        const { data: res } = await addToCart(payload);
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addConfigurableProductsToCart?.cart;
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED,
            },
          });
        }
      }
      if (payload.simple_sku) {
        const { data: res } = await addSimpleProductToCartItem({
          ...payload,
          sku: payload.simple_sku,
        });
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addSimpleProductsToCart?.cart;
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED,
            },
          });
        }
      }
      if (payload.sku && payload.sku.length > 0) {
        for (let index = 0; index < payload.sku.length; index++) {
          const sku = payload.sku[index];
          const { data: res } = await addSimpleProductToCartItem({ ...payload, sku });
          response = res;
          if (res && !res.errors) {
            data = response?.data?.addSimpleProductsToCart?.cart;
          } else if (res.errors) {
            dispatch({
              type: ADD_PRODUCT_TO_CART_FAILED,
              payload: {
                type: ADD_PRODUCT_TO_CART_FAILED,
              },
            });
          }
        }
      }
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: ADD_PRODUCT_TO_CART_FAILED,
          payload: {
            type: ADD_PRODUCT_TO_CART_FAILED,
          },
        });
      } else {
        showToast(SUCCESS, "Product added to basket successfully");
        // dispatch(getCartCountAction())
        dispatch({
          type: ADD_PRODUCT_TO_CART_SUCCESS,
          payload: {
            type: ADD_PRODUCT_TO_CART_SUCCESS,
          },
        });
        if (response.data) {
          pushDataLayer("addToCart", {
            click: {
              name: data.items[0].product.name,
              sku: data.items[0].product.sku,
              price: "£ " + data.items[0].prices.price.value,
              quantity: data.items[0].quantity,
            },
          });
          dispatch(getCartCountAction());
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data,
            },
          });
        }
      }
    } catch (error: any) {
      showToast(ERROR, error?.message);
      dispatch({
        type: ADD_PRODUCT_TO_CART_FAILED,
        payload: {
          type: ADD_PRODUCT_TO_CART_FAILED,
        },
      });
    }
  };
};

function handleCartError(response: any, dispatch: any) {
  if (response.errors[0].message === `The cart isn't active.`) {
    removeSessionItem(CART_ID);
    dispatch(createEmptCart());
  } else {
    if (
      response.errors[0].message.indexOf("Could not find a cart with ID") ===
      -1 &&
      response.errors[0].message.indexOf(
        `Can't assign cart to store in different website.`
      ) === -1
    ) {
      showToast(ERROR, response.errors[0].message);
      dispatch({
        type: GET_CART_PAGE_ERROR,
        payload: {
          type: GET_CART_PAGE_ERROR,
          message: response.errors[0].message,
        },
      });
    } else {
      removeSessionItem(CART_ID);
      dispatch(createEmptCart());
    }
  }
}
