import { IImage, IPriceRange } from "./Common.interface";

export interface IProductDetail {
    productbannermobiletop: string;
    availability_message: string;
    dividebuy_enable?:boolean;
    productbanner: string;
    stock_status: string;
    name: string;
    __typename: string;
    enable_free_fabric_sample: number;
    salable_quantity: number;
    description: { html: string };
    dimensions: { html: string };
    delivery: { html: string };
    whyus: { html: string };
    cms_block: string;
    configurable_options: IConfigurationOption[];
    image: IImage;
    media_gallery: IImage[];
    price_range: IPriceRange;
    related_products: IRelatedProducts[];
    short_description: { html: string };
    sku: string;
    uid: string;
    variants: IVariant[];
}

export class ProductDetails implements IProductDetail {
    productbannermobiletop!: string; 
    availability_message!: string;
    productbanner!: string;
    stock_status: string;
    name!: string;
    enable_free_fabric_sample!: number;
    salable_quantity!: number;
    description!: { html: string; };
    dimensions!: { html: string };
    delivery!: { html: string };
    whyus!: { html: string };
    cms_block!: string;
    configurable_options: IConfigurationOption[] = [];
    image!: IImage;
    media_gallery: IImage[] = [];
    price_range!: IPriceRange;
    related_products: IRelatedProducts[] = [];
    short_description!: { html: string; };
    sku!: string;
    uid!: string;
    variants: IVariant[] = [];
    __typename!: string;

    constructor(availability_message: string,
        name: string,
        productbannermobiletop: string,
        description: { html: string; },
        dimensions: { html: string },
        delivery: { html: string },
        whyus: { html: string },
        cms_block: string,
        configurable_options: IConfigurationOption[],
        image: IImage,
        media_gallery: IImage[],
        price_range: IPriceRange,
        related_products: IRelatedProducts[],
        short_description: { html: string; },
        sku: string,
        uid: string,
        upsell_products: IRelatedProducts[],
        variants: IVariant[],
        __typename: string,
        stock_status: string,
        productbanner: string
    ) {
        this.productbanner = productbanner;
        this.availability_message = availability_message;
        this.productbannermobiletop = productbannermobiletop;
        this.__typename = __typename
        this.name = name;
        this.description = description;
        this.dimensions = dimensions
        this.delivery = delivery
        this.whyus = whyus
        this.cms_block = cms_block
        this.configurable_options = configurable_options
        this.image = image
        this.media_gallery = media_gallery
        this.price_range = price_range
        this.related_products = related_products
        this.short_description = short_description
        this.sku = sku
        this.uid = uid
        this.variants = variants
        this.stock_status = stock_status
    }
}


export interface IVariant {
    attributes: IVariantAttributes[];
    product: IProductDetail;
}

export interface IVariantAttributes {
    code: string;
    label: string;
    uid: string;
    value_index: number;
}

export interface IVariantProduct {
    image: IImage;
    media_gallery: IImage[];
    name: string;
    price_range: IPriceRange;
    sku: string;
    uid: string;
    weight: number;
}

export interface IConfigurationOption {
    id: number;
    label: string;
    product_id: string;
    attribute_code: string;
    position: number;
    use_default: boolean;
    values: IConfigurationOptionValues[]
}

export interface IConfigurationOptionValues {
    length: number;
    uid: string;
    value_index: number;
    label: string;
    product_status: string;
    swatch_data: ISwatchData;
}

export interface IRelatedProducts {
    name: string;
    image: IImage;
    price_range: IPriceRange;
    url_key: string;
    url_suffix: string;
    sku: string;
    small_image: IImage;
    description: { html: string };
}

export interface ISwatchData {
    thumbnail: any; value: any; group: any; color_group: any;
}
export class SelectedProductOption {
    uid!: string;
    thumbnail!: string;
    label!: string;
    value!: string;
    constructor(uid: string, thumbnail: string, label: string, value: string) {
        this.uid = uid;
        this.thumbnail = thumbnail;
        this.label = label;
        this.value = value;
    }
}

export class GroupedColors {
    key!: string;
    value!: SelectedProductOption[]
    constructor(key: string,
        value: SelectedProductOption[]) {
        this.key = key;
        this.value = value;
    }
}