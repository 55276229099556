/* eslint-disable react-hooks/exhaustive-deps */
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import { useEffect, useState } from "react";
import WishlistSkl from "./WishlistSkl";
import { useDispatch, useSelector } from "react-redux";
import {
  getWishlist,
  removeFromWishlistItems,
} from "../../../Redux/Pages/CartPage/CartPageActions";
import { GET_WISHLIST_SUCCESS } from "../../../Redux/Pages/CartPage/CartPageTypes";
import RemovePopup from "../../Home/MiniCart/RemovePopup/RemovePopup";
import PaginatedItems from "../../Common/PaginatedItems/PaginatedItems";

const Wishlist = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const response = useSelector((state: any) => state.wishlistResponse);

  const [data, setData] = useState({
    items: [],
    items_count: 0,
  });

  const [wishlistId, setWishlistId] = useState(null);

  useEffect(() => {
    if (response && response.type === GET_WISHLIST_SUCCESS) {
      setLoading(false);
      setData({
        items: response.data.items_v2?.items,
        items_count: response.data.items_count,
      });
    }
  }, [response]);

  const onDeleteWishlist = () => {
    dispatch(removeFromWishlistItems({ wishlistItemId: wishlistId }));
    setWishlistId(null);
  };

  const [pagination, setPagination] = useState<{
    page: number;
    size: number;
  }>({
    page: 1,
    size: 12,
  });

  const setCurrentItems = (payload: { nextPage: number }) => {
    setPagination({
      ...pagination,
      page: payload.nextPage,
    });
  };

  useEffect(() => {
    dispatch(getWishlist(pagination));
  }, [pagination]);

  return (
    <>
      {!loading ? (
        <div className="account-container">
          <div className="ll hide-991">
            <Link to={MY_DETAIL} className="acc-backbtn ll">
              My Account
            </Link>
          </div>
          <div className="account-wrap">
            <Sidebar></Sidebar>
            <div className="acc-rightwrap">
              <div className="wishlist-wrap">
                <h1 className="caps-on">Wish list</h1>
                {data.items.length > 0 ? (
                  <ul className="list-items">
                    {data.items.map((item: any, index: number) => (
                      <li className="item" key={index + item.id}>
                        <div className="item-img">
                          <Link
                            to={
                              "/" +
                              item.product.url_key +
                              item.product.url_suffix
                            }
                            title={item.pdtName}
                          >
                            <img
                              src={item.product.image.url}
                              title={item.product.name}
                              alt={item.product.name}
                            />
                          </Link>
                        </div>
                        <div className="item-info">
                          <Link
                            to={
                              "/" +
                              item.product.url_key +
                              item.product.url_suffix
                            }
                            className="bm"
                            title={item.product.name}
                          >
                            {item.product.name}
                          </Link>
                          <div className="price-wrapper">
                            {item.product?.price_range?.maximum_price
                              ?.final_price?.value !==
                            item?.product?.price_range?.maximum_price
                              ?.regular_price?.value ? (
                              <>
                               <p className="product-price">
                                  £
                                  {Number(
                                    item?.product?.price_range?.maximum_price
                                      ?.regular_price?.value
                                  ).toFixed(2)}
                                </p>
                                
                                <p className="special-price">
                                  £
                                  {Number(
                                    item?.product?.price_range?.maximum_price
                                      ?.final_price?.value
                                  ).toFixed(2)}
                                </p>
                               
                              </>
                            ) : (
                              <p className="normal-price">
                                £
                                {Number(
                                  item?.product?.price_range?.maximum_price
                                    ?.regular_price?.value
                                ).toFixed(2)}
                              </p>
                            )}
                          </div>
                          <div className="wish-action">
                            <Link
                              to={""}
                              className="bm remove"
                              onClick={() => setWishlistId(item.id)}
                              title={"Remove"}
                            >
                              Remove
                            </Link>
                            <Link
                              to={
                                "/" +
                                item.product.url_key +
                                item.product.url_suffix
                              }
                              className="bm atc"
                              title={"Add To basket"}
                            >
                              Add To basket
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="ll">You don't have any items in wishlist.</p>
                )}
              </div>
              {data?.items_count > 12  ? (
                <PaginatedItems
                  itemsPerPage={pagination.size}
                  totalCount={response?.data?.items_count}
                  onItemChange={setCurrentItems}
                />
              ) : null}
            </div>
          </div>
          <RemovePopup
            text="Are you sure you would like to remove this item from the wishlist item?"
            onCancel={() => setWishlistId(null)}
            onSubmit={onDeleteWishlist}
            show={wishlistId}
          />
        </div>
      ) : (
        <WishlistSkl />
      )}
    </>
  );
};

export default Wishlist;
