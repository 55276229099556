import { getCMSPage } from "../../../Services/graphQL.service";
import { GET_CMS_PAGE_FAILED, GET_CMS_PAGE_REQUEST, GET_CMS_PAGE_SUCCESS } from "./CMSPageTypes";

export function getCMSPageAction(payload: string) {
    return (async (dispatch: any) => {
        try {
            dispatch({
                type: GET_CMS_PAGE_REQUEST,
                payload: { type: GET_CMS_PAGE_REQUEST },
            });
            const { data: response } = await getCMSPage(payload);
            const graphQLResponse = response.data?.cmsPage;
            if (graphQLResponse) {
                dispatch({
                    type: GET_CMS_PAGE_SUCCESS,
                    payload: {
                        type: GET_CMS_PAGE_SUCCESS,
                        data: graphQLResponse
                    },
                })
            } else {
                dispatch({
                    type: GET_CMS_PAGE_FAILED,
                    payload: {
                        type: GET_CMS_PAGE_FAILED,
                        data: response.errors[0].message
                    }
                })
            }
        } catch (error) {
            console.error("SOMETHING WENT WRONG WHILE FETCHING CMS PAGE DATA ", error);
            dispatch({
                type: GET_CMS_PAGE_FAILED,
                payload: { type: GET_CMS_PAGE_FAILED, message: error },
            });
        }
    });
}