declare global {
    interface Window {
        dataLayer: any;
        gtag: any;
    }
}

export const pushDataLayer = (eventName: string, obj: any) => {
    if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            'event': eventName,
            'ecommerce': obj
        })
    }
}

export function gtmlProductClick(productObj: any) {
    if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    // 'actionField': { 'list': 'Search Results' },      // Optional list property.
                    'products': [{
                        'name': productObj.content,                      // Name or ID is required.
                        'id': productObj.id,
                        'price': productObj.value
                    }]
                }
            }
        });
    }
}

export function gtmlPageView(title: string) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: window.location.pathname,
                title: title
            }
        });
    }
}

// export function gtmAddToCart(productObj: any) {
//     // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
//     // and a list of productFieldObjects.

//     if (window.dataLayer) {
//         console.log("gtmTrigger: addToCart", productObj);
//         window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//         window.dataLayer.push({
//             'event': 'addToCart',
//             'ecommerce': {
//                 'add': {                                // 'add' actionFieldObject measures.
//                     'products': [productObj]
//                 }
//             }
//         });
//     }
// }

// export function gtmRemoveFromCart(productObj) { NO
//     // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
//     // and a list of productFieldObjects.
//     if (window.dataLayer) {
//         console.log("gtmTrigger: removeFromCart");
//         window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//         window.dataLayer.push({
//             'event': 'removeFromCart',
//             'ecommerce': {
//                 'remove': {                                // 'add' actionFieldObject measures.
//                     'products': [{                        //  adding a product to a shopping cart.
//                         'id': productObj.id,
//                         'name': productObj.title,
//                         'price': productObj?.price,
//                         'brand': productObj?.brand ? productObj?.brand : "",
//                         'category': productObj?.category ? productObj?.category : "",
//                         'variant': productObj?.varient ? productObj?.varient : "",
//                         'quantity': productObj?.quantity ? productObj?.quantity : 1
//                     }]
//                 }
//             }
//         });
//     }
// }

// export function gtmCart(productArr, actionField) {
//     if (productArr?.length > 0) {
//         const gtmProducts = productArr?.map((p) => {
//             return {
//                 id: p.id,
//                 name: p.title,
//                 quantity: p?.selectedQuantity,
//                 price: p.price,
//                 brand: p?.brand ? p?.brand : "",
//             }
//         });
//         if (window.dataLayer) {

//             console.log("gtmTrigger: cart event");
//             window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//             window.dataLayer.push({
//                 'event': 'cart',
//                 'ecommerce': {
//                     'actionField': actionField,
//                     'cart': {
//                         'products': gtmProducts
//                     }
//                 }
//             });
//         }
//     }
// }

export function gtmInitiateCheckout(productArr: any, payableAmount: string) {
    if (productArr?.length > 0) {
        if (window.dataLayer) {
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                'event': 'initiateCheckout',
                'ecommerce': {
                    'actionField': { 'Payable Amount': payableAmount },
                    'initiateCheckout': {
                        'products': productArr
                    }
                }
            });
        }
    }
}


// export function gtmCheckout(productArr, actionField) {
//     if (productArr?.length > 0) {
//         const gtmProducts = productArr?.map((p) => {
//             return {
//                 id: p.id,
//                 name: p.title,
//                 quantity: p?.selectedQuantity,
//                 price: p.price,
//                 brand: p?.brand ? p?.brand : "",
//             }
//         });
//         if (window.dataLayer) {

//             console.log("gtmTrigger: checkout event");
//             window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//             window.dataLayer.push({
//                 'event': 'checkout',
//                 'ecommerce': {
//                     'actionField': actionField,
//                     'checkout': {
//                         'products': gtmProducts
//                     }
//                 }
//             });
//         }
//     }
// }

// export function gtmAddressCompleted(address) {
//     if (window.dataLayer) {
//         console.log("gtmTrigger: addressCompleted event");
//         window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//         window.dataLayer.push({
//             'event': 'addressCompleted',
//             'ecommerce': {
//                 'addressCompleted': {
//                     'address': address
//                 }
//             }
//         });
//     }
// }

// export function gtmShipmentCompleted(shipingMethod) {
//     if (window.dataLayer) {
//         console.log("gtmTrigger: shipmentCompleted event");
//         window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//         window.dataLayer.push({
//             'event': 'shipmentCompleted',
//             'ecommerce': {
//                 'shipmentCompleted': {
//                     'shipingMethod': shipingMethod?.rightText,
//                     'price': shipingMethod?.leftText
//                 }
//             }
//         });
//     }
// }

// export function gtmPaymentOption(productArr, selectedShippingData, paymentId, price) {
//     if (productArr?.length > 0) {
//         const gtmProducts = productArr?.map((p) => {
//             return {
//                 id: p.id,
//                 name: p.title,
//                 quantity: p?.selectedQuantity,
//                 price: p.price,
//                 brand: p?.brand ? p?.brand : "",
//             }
//         });
//         if (window.dataLayer) {
//             console.log("gtmTrigger: paymentOption event");
//             window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//             window.dataLayer.push({
//                 'event': 'paymentOption',
//                 'ecommerce': {
//                     'paymentOption': {
//                         'paymentId': paymentId,
//                         'price': price,
//                         'shipingMethod': selectedShippingData?.rightText,
//                         'products': gtmProducts
//                     }
//                 }
//             });
//         }
//     }
// }



export function gtmPurchase(order: any) {
    if (window.dataLayer) {
        // Send transaction data with a pageview if available
        // when the page loads. Otherwise, use an event when the transaction
        // data becomes available.
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'purchase',
            'ecommerce': {
                'currencyCode': 'GBP',
                'purchase': order
            }
        });
    }
    if (window.gtag) {
        const obj = {
            'send_to': 'AW-1068795480/wgnuCOD5nrMDENiM0v0D',
            'value': order.actionField?.revenue,
            'currency': 'GBP',
            'transaction_id': order.actionField?.id
        }
        window.gtag('event', 'conversion', obj)
    }
}

// export function gtmOrderFail() {

//     if (window.dataLayer) {
//         console.log("gtmTrigger: orderFail");
//         // Send transaction data with a pageview if available
//         // when the page loads. Otherwise, use an event when the transaction
//         // data becomes available.
//         window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//         window.dataLayer.push({
//             'event': 'orderFail',
//             'ecommerce': {
//                 'orderFail': {
//                     'eventMessage': 'Something went wrong with order either user cancelled payment.'
//                 }
//             }
//         });
//     }

// }

