import React from 'react'
import { Modal } from "react-bootstrap";
import DividebuyLogoBanner from "../../../Assets/Images/dividebuy-logobanner.png";

const DivideBuyInstallmentInfo = (props: any) => {
  return (
    <Modal
      {...props}
      centered
      className="install_popup"
    >
      <Modal.Header closeButton>
        <Modal.Title className="tm">Installment Information </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content_wrap" dangerouslySetInnerHTML={{ __html: props.content }} />
        <div className="dividebuy-interestfree-logo">
        <img src={DividebuyLogoBanner} alt="dividebuy-logo" />
          <div className="dividebuy-representative-text"><p>0% APR representative example.</p><p>Credit
            subject to status
            and minimum order value.</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DivideBuyInstallmentInfo
