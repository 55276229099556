import { useEffect, useState } from "react";

export const AboutUs = (props: {
  data: any;
  abouttrustpilot: any;
  abtusbanner: any;
}) => {

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  
  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(window.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(window.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <div className="atus-wrap">
      <div className="custom-container">
        <h3 className="caps-on hide-991">{props.data.title}</h3>
        <div className="abtus-sec">
          <div className="abt-left">
            {
              windowSize > 767 ? <img src={props.data.image}
              alt={props.data.title}
              title={props.data.title}
            ></img> : <img src={props.data.mimage} alt={props.data.title} title={props.data.title} ></img>
            }
            <div className="trust-review">
              <img src={props.abouttrustpilot} alt="Trust Pilot"></img>
            </div>
          </div>
          <div className="abt-right">
            <div className="right-top">
              <h3 className="caps-on show-992">{props.data.title}</h3>
              <p
                className="bm"
                dangerouslySetInnerHTML={{ __html: props.data.description }}
              ></p>
            </div>
            {/* {
                            props.data.action && props.data.action.title &&
                            <div className="right-action">
                                <CustomButton onClick={() => navigate("/" + props.data.urlKey)}>{props.data.action.title}</CustomButton>
                            </div>
                        } */}
          </div>
        </div>
      </div>
    </div>
  );
};
