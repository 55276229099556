import { ReactChild, ReactFragment, ReactPortal } from "react";
export interface ICustomButton {
    onClick?: any;// MouseEventHandler<HTMLButtonElement>;
    type?: string;
    id?: string;
    bg?: string;
    disabled?: boolean;
    children?: ReactChild | ReactFragment | ReactPortal | null;
    isLoading?: boolean;
}

const CustomButton = (props: ICustomButton) => {

    return (
        <>
            <button
                onClick={props.onClick}
                id={props.id}
                className={props.bg === "fill" ? "fill-btn" : "outline-btn"}
                disabled={props.disabled || props.isLoading}
            >
                {
                    props.isLoading ? (
                        props.bg === "fill" ?
                            <div className="loader-white-wrapper">
                                <span className="loader-white"></span>
                            </div>
                            :
                            <div className="loader-black-wrapper">
                                <span className="loader-black"></span>
                            </div>
                    )
                        :
                        props.children
                }
            </button>
        </>
    )
}

export default CustomButton;