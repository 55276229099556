import Skeleton from "react-loading-skeleton";

const CMSPageSkl = () => {
  return (
    <>
      <div className="custom-container skl cms">
      <div className="breadskl">
          <Skeleton />
        </div>
        <div className="lrg">
          <Skeleton />
        </div>
        <div className="med">
          <Skeleton />
        </div>
        <div className="sections">
          <div className="left">
            <Skeleton />
          </div>
          <div className="right">
            <Skeleton />
          </div>
        </div>
        <div className="med">
          <Skeleton />
        </div>
        <div className="med">
          <Skeleton />
        </div>
        <div className="lrg">
          <Skeleton />
        </div>
        <div className="sections">
          <div className="left">
            <Skeleton />
          </div>
          <div className="right">
            <Skeleton />
          </div>
        </div>
        <div className="med">
          <Skeleton />
        </div>
        <div className="lrg">
          <Skeleton />
        </div>
      </div>
    </>
  )
}

export default CMSPageSkl;