/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import {
  LABEL_CREATE_ONE,
  LABEL_DONT_HAVE_ACCOUNT,
  LABEL_ENTER_EMAIL_PASS,
  LABEL_FORGOT_PASSWORD,
  LABEL_LOGIN,
} from "../../Constants/Labels";
import { FORGOT_PWD, MY_DETAIL, SIGN_UP } from "../../Constants/Route";
import { loginAction } from "../../Redux/Auth/AuthAction";
import { FORGOT_PASSWORD_SUCCESS } from "../../Redux/Auth/AuthType";
import { createEmptyCartAction } from "../../Redux/Pages/CartPage/CartPageActions";
import validate from "../../Utility/Validation";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";

const Login = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state }: any = useLocation();

  const data = useSelector((state: any) => state.loginResponse);

  const forResponse = useSelector((state: any) => state.forgotPassword);

  const INITIAL_LOGIN_DATA: any = {
    email: {
      value: null,
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: null,
      validation: ["required"],
      errors: ["Password is required."],
    },
  };
  const [loginData, setLoginData] = useState(INITIAL_LOGIN_DATA);
  const [loginDataError, setLoginDataError] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormValidated, setIsFormValidated] = useState(false);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, loginData);
    loginDetails[field].value = value;
    setLoginData({ ...loginDetails });
  };

  useEffect(() => {
    return () => {
      setIsFormValidated(false);
      setLoginDataError({});
      // dispatch({ type: LOGIN_REQUEST, payload: null })
    };
  }, []);

  const onLogin = () => {
    setLoading(true);
    const validData = validate(loginData);
    setIsFormValidated(!validData?.isValidated);
    if (validData?.isValidated === true) {
      dispatch(
        loginAction({
          email: loginData.email.value,
          password: loginData.password.value,
        })
      );
    } else {
      setLoading(false);
      setLoginDataError({ ...validData?.errors });
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
    if (data && data.data) {
      dispatch(createEmptyCartAction());
      navigate(state && state.from ? state.from : MY_DETAIL, { replace: true });
    }
  }, [data]);

  return (
    <div className="authentication-wrap login">
      <div className="custom-container">
        <div className="inner-main">
          <div className="top-head">
            {forResponse && forResponse.type === FORGOT_PASSWORD_SUCCESS ? (
              <p className="text-success">{forResponse.data}</p>
            ) : null}
            <h1 className="caps-on">{LABEL_LOGIN}</h1>
            <p className="bm">{LABEL_ENTER_EMAIL_PASS}</p>
          </div>
          <InputGroups
            type="email"
            label="Email Address*"
            onChange={(e: any) => setData("email", e.target.value)}
            error={
              isFormValidated && loginDataError["email"]
                ? loginDataError["email"]
                : null
            }
          />
          <PasswordGroups
            onChange={(e: any) => setData("password", e)}
            error={
              isFormValidated && loginDataError["password"]
                ? loginDataError["password"]
                : null
            }
            label="Password*"
          />
          <div className="forgotpass">
            <Link to={FORGOT_PWD} className="bm">
              {LABEL_FORGOT_PASSWORD}
            </Link>
          </div>
          <CustomButton
            isLoading={loading}
            type="submit"
            onClick={() => onLogin()}
            bg={"fill"}
          >
            {LABEL_LOGIN}
          </CustomButton>
          <div className="form-bottom">
            <p className="bm">
              {LABEL_DONT_HAVE_ACCOUNT}{" "}
              <Link to={SIGN_UP} className="bm">
                {LABEL_CREATE_ONE}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
