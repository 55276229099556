import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MY_ADDRESS,
  MY_DETAIL,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
} from "../../../Constants/Route";
import LogoutPopup from "../../MyAccount/LogoutPopup/LogoutPopup";

const Sidebar = (props: any) => {
  const sideLinks: any = [
    {
      name: "My Details",
      links: MY_DETAIL,
    },
    {
      name: "Wish List",
      links: MY_WISHLIST,
    },
    {
      name: "My Orders",
      links: MY_ORDERS_PAGE,
    },
    {
      name: "Address Book",
      links: MY_ADDRESS,
    },
    {
      name: "Newsletter Subscription",
      links: NEWSLETTER,
    },
    {
      name: "Logout",
    },
  ];

  const [LogoutShow, setLogoutShow] = useState(false);

  const [currentLocation, setCurrentLocation] = useState(null);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  function clickedLeftItem(leftck: any) {
    if (typeof props.onLeftMenuClick === "function") {
      props.onLeftMenuClick(leftck);
    }
  }

  return (
    <>
      <div className="acc-leftwrap">
        <h6 className="caps-on hide-991">My Account</h6>
        <ul className="acc-menu">
          {sideLinks.map((items: any, index: number) =>
            items?.links ? (
              <li
                key={index}
                className={currentLocation === items?.links ? "active" : ""}
                onClick={() => {
                  clickedLeftItem(items.links);
                }}
              >
                <Link to={items?.links} className="sidebar-links ll">
                  {items.name}
                </Link>
              </li>
            ) : (
              <li key={12}>
                <span
                  className="sidebar-links logout ll"
                  onClick={() => setLogoutShow(true)}
                >
                  {items.name}
                </span>
              </li>
            )
          )}
          <LogoutPopup show={LogoutShow} onHide={() => setLogoutShow(false)} />
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
