import Skeleton from "react-loading-skeleton";
import BannerSectionSkl from "./BannerSection/BannerSectionSkl";
import CardBannerSkl from "./CardBanner/CardBannerSkl";
import CategoryBlockSkl from "./CategoryBlock/CategoryBlockSkl";
import SellingPointsSkl from "./SellingPoints/SellingPointsSkl";
import OwlCarousel from "react-owl-carousel";

const spotProductSlider = {
    autoplay: true,
    loop: true,
    dots: false,
    nav: true,
    responsiveClass: true,
    autoplayTimeout: 5000,
    responsive: {
        0: {
            items: 1.2,
            margin: 16,
        },
        768: {
            items: 3,
            margin: 24,
        },
    },
};

const testimoSlider = {
    autoplay: false,
    loop: true,
    dots: false,
    nav: true,
    responsiveClass: true,
    // mouseDrag: false,
    // touchDrag: true,
    // animateOut: 'fadeOut',
    // animateIn: 'fadeIn',
    responsive: {
        0: {
            items: 1.2,
            margin: 16,
        },
        768: {
            items: 1,
            margin: 24,
        },
    },
};

const HomeSkeleton = (props: {
    count: number
}) => {
    return (
        <div className="homepage-main">
            <div className="banner-wrap">
                <BannerSectionSkl />
            </div>
            <div className="sp-wrap">
                <SellingPointsSkl />
            </div>
            <div className="catb-wrap first">
                <CategoryBlockSkl key="1" count={props.count}></CategoryBlockSkl>
            </div>
            <div className="catb-wrap second">
                <CategoryBlockSkl key="2" count={props.count}></CategoryBlockSkl>
            </div>
            <div className="cb-wrap first">
                <CardBannerSkl key="3" count={props.count} />
            </div>
            <div className="product-spotlight-wrap">
                <div className="custom-container">
                    <>
                        <p className="skl-spot"><Skeleton /></p>
                        <div className="skl-spot-products">
                            <OwlCarousel className="owl-theme" {...spotProductSlider}>
                                <div className="list-item">
                                    <div className="img-skl"><Skeleton /></div>
                                    <div className="name-skl"><Skeleton /></div>
                                    <div className="price-skl"><Skeleton /></div>
                                    
                                </div>
                            </OwlCarousel>
                        </div>
                    </>
                </div>
            </div>
            <div className="tmo-wrap">
                <div className="custom-container">
                    <div className="testmo-sections">
                        <>
                            <div className="skl-tesmo">
                                <h6 className="skl-testotitle hide-767"><Skeleton /></h6>
                                <OwlCarousel className="owl-theme" {...testimoSlider}>
                                    <div className="testimo-slides">
                                        <div className="testmo-left">
                                            <div className="skl-title show-768"><Skeleton /></div>
                                            <div className="skl-desc"><Skeleton /></div>
                                            <div className="skl-cname"><Skeleton /></div>
                                            <div className="skl-btn"><Skeleton /></div>
                                        </div>
                                        <div className="testmo-right">
                                            <div className="skl-image"><Skeleton /></div>
                                            <div className="skl-pname"><Skeleton /></div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <div className="atus-wrap">
                <div className="custom-container">
                    <>
                        <div className="abtus-sec skl">
                            <div className="abt-left skl">
                                <Skeleton />
                            </div>
                            <div className="abt-right skl">
                                <div className="skl-title">
                                    <Skeleton />
                                </div>
                                <div className="skl-desc">
                                    <Skeleton />
                                </div>
                                <div className="skl-points">
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                                <div className="skl-btn">
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            <div className="cb-wrap second">
                <CardBannerSkl count={props.count} />
            </div>
        </div>
    )
}

export default HomeSkeleton;