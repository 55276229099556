import { Modal } from "react-bootstrap";

const RelatedProductPopup = ({ show, relatedProduct, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide} centered className="guardsam-popup">
      <Modal.Header closeButton>
        <Modal.Title className="tm">{relatedProduct?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <img
              src={relatedProduct?.image?.url}
              alt={relatedProduct?.image?.label || "Related Products"}
            />
          </p>
          <div
            className="bm"
            dangerouslySetInnerHTML={{
              __html: relatedProduct?.description?.html,
            }}
          />
          {/* <p className="bm">
            Protect your furniture with our Guardsman UK fabric Protection.
          </p>
          <br />
          <ul>
            <li className="bm">
              Shields fabric and upholstery against soils, spills and stains
            </li>
            <li className="bm">Won't change the fabric's look or feel</li>
            <li className="bm">Completely Odourless</li>
            <li className="bm">Maintains the fabrics breathable properties</li>
            <li className="bm">
              Formulated to meet the demands of furniture professionals
            </li>
          </ul> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RelatedProductPopup;
