import { Col, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link, useLocation } from "react-router-dom";

const spotProductSlider = {
  autoplay: true,
  loop: true,
  dots: false,
  nav: true,
  responsiveClass: true,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 1.2,
      margin: 16,
    },
    768: {
      items: 3,
      margin: 24,
    },
  },
};

export const ProductSpotlight = (props: { data: any[] }) => {
  const location = useLocation();
  return (
    <div
      className={
        props.data.length > 3
          ? "ps-light-wrap"
          : "ps-light-wrap no-slider"
      }
    >
      <div className="custom-container">
        <h2 className="section-title caps-on">Product Spotlight</h2>
        <div className="product-slider">
          {props.data.length > 3 ? (
            <OwlCarousel className="owl-theme" {...spotProductSlider}>
              {props.data.map((data, index) => (
                <div key={index} className="list-item">
                  <div className="image-top">
                    <Link to={"/" + data.urlKey} title={data.title} state={{path:location.pathname}}>
                      <img src={data.image} alt={data.title} />
                    </Link>
                  </div>
                  <div className="product-details">
                    <Link
                      to={"/" + data.urlKey}
                      state={{path:location.pathname}}
                      className="pdt-name bm"
                      title={data.title}
                    >
                      {data.title}
                    </Link>
                    <div className="price-wrapper">
                      {data.details.specialPrice ? (
                        <>
                          <p className="product-price">{data.details.price}</p>
                          <p className="special-price">
                            {data.details.specialPrice}
                          </p>
                        </>
                      ) : (
                        <p className="normal-price">{data.details.price}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          ) : (
            <Row>
              {props.data.map((data, index) => (
                <Col md={4} sm={12} xs={12} className="list-item-wrap">
                  <div key={index} className="list-item">
                    <div className="image-top">
                      <Link to={"/" + data.urlKey} title={data.title} state={{path:location.pathname}}>
                        <img src={data.image} alt={data.title} />
                      </Link>
                    </div>
                    <div className="product-details">
                      <Link
                        to={"/" + data.urlKey}
                        state={{path:location.pathname}}
                        className="pdt-name bm"
                        title={data.title}
                      >
                        {data.title}
                      </Link>
                      <div className="price-wrapper">
                        {data.details.specialPrice ? (
                          <>
                            <p className="special-price">
                              {data.details.specialPrice}
                            </p>
                            <p className="product-price">
                              {data.details.price}
                            </p>
                          </>
                        ) : (
                          <p className="normal-price">{data.details.price}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
