import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { applyCoupanToCart } from "../../../Redux/Pages/CartPage/CartPageActions";
import CustomButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";

const ApplyCouponPopup = (props: any) => {
  const dispatch = useDispatch();

  const [coupanCode, setCoupanCode] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(false);
  }, []);

  useEffect(() => {
    setShowError(false);
  }, [props]);

  const onSubmit = () => {
    if (!coupanCode || (coupanCode && coupanCode.trim() === "")) {
      setShowError(true);
    } else {
      dispatch(applyCoupanToCart({ coupan_code: coupanCode }));
      setCoupanCode("");
      props.onHide();
    }
  };

  return (
    <Modal {...props} centered className="coupon-popup">
      <Modal.Header closeButton>
        <Modal.Title className="tm">Apply Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {showError && <div className="coupon-wrap error">
          <InputGroups
            value={coupanCode}
            placeholder="Discount code"
            onChange={(e: any) => setCoupanCode(e.target.value)}
          />
          <CustomButton bg={"fill"} onClick={onSubmit}>
            Apply
          </CustomButton>
        </div>}
        {!showError && <div className="coupon-wrap">
          <InputGroups
            value={coupanCode}
            placeholder="Discount code"
            onChange={(e: any) => setCoupanCode(e.target.value)}
          />
          <CustomButton bg={"fill"} onClick={onSubmit}>
            Apply
          </CustomButton>
        </div>}
        {showError && <p className="text-danger">This field is required</p>}
      </Modal.Body>
    </Modal>
  );
};

export default ApplyCouponPopup;
