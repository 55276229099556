import { Modal } from "react-bootstrap";

const FabricInfoPopup = (props: any) => {
  return (
    <Modal {...props} centered className="fabricinfo-popup">
      <Modal.Header closeButton>
        <Modal.Title className="tm">Fabric Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
      </Modal.Body>
    </Modal>
  );
};

export default FabricInfoPopup;
