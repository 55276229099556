const SelectGroups = (props: {
  values: { title: string; value: string }[];
  label: string;
  selectedValue?: string;
  onSelect?: any;
}) => {
  return (
    <div className="select-group">
      <label className="lm">{props.label}</label>
      <select defaultValue={props.selectedValue} onChange={props.onSelect}>
        {props.values
          .filter((data: any) => data)
          .map((item: { title: string; value: string }, index: number) => (
            <option
              key={index}
              value={item.value}
              defaultValue={
                props.selectedValue ? props.selectedValue : item.value
              }
            >
              {item.title}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectGroups;
