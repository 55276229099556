import { ERROR } from "../../../Constants/Labels";
import { ERROR_PAGE } from "../../../Constants/Route";
import {
  getAuthUrlKey,
  getBlogCategoryItems,
  getBlogDetails,
  getBlogListItems,
  getBlogRecentItems,
  // getCategoryUrlKey,
  getCMSBlock,
  searchBlogPost,
} from "../../../Services/graphQL.service";
import { showToast } from "../../../Utility/Utilities";
import {
  BLOG_DETAIL_PAGE_FAILED,
  BLOG_DETAIL_PAGE_SUCCESS,
  BLOG_PAGE_CMS_FAILED,
  BLOG_PAGE_CMS_SUCCESS,
  BLOG_PAGE_FAILED,
  BLOG_PAGE_GET_CATEGORIES_FAILED,
  BLOG_PAGE_GET_CATEGORIES_SUCCESS,
  BLOG_PAGE_GET_RECENT_POST_FAILED,
  BLOG_PAGE_GET_RECENT_POST_SUCCESS,
  BLOG_PAGE_REQUEST,
  BLOG_PAGE_SUCCESS,
  SEARCH_BLOG_FAILED,
  SEARCH_BLOG_SUCCESS,
} from "./BlogPageTypes";

export function getCMSBlogAction() {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getCMSBlock(`"blog-top-banner-block"`);
      const graphQLResponse = response.data?.cmsBlocks;
      if (graphQLResponse && graphQLResponse.items) {
        dispatch({
          type: BLOG_PAGE_CMS_SUCCESS,
          payload: {
            type: BLOG_PAGE_CMS_SUCCESS,
            data: graphQLResponse?.items[0],
          },
        });
      } else {
        //add else if response.errors
        dispatch({
          type: BLOG_PAGE_CMS_FAILED,
          payload: {
            type: BLOG_PAGE_CMS_FAILED,
            data: response.errors[0].message,
          },
        });
      }
    } catch (error) {
      console.error(
        "SOMETHING WENT WRONG WHILE FETCHING CMS BLOCK DATA ",
        error
      );
      dispatch({
        type: BLOG_PAGE_CMS_FAILED,
        payload: { type: BLOG_PAGE_CMS_FAILED, message: error },
      });
    }
  };
}

export const getBlogListAction = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: BLOG_PAGE_REQUEST });
    try {
      const { data: response } = await getBlogListItems(payload);
      const data = response?.data;
      if (data) {
        if (payload.type === "ALL") {
          await fetchSideBlogData(data);
          await fetchSideBlogs(dispatch);
        }
        dispatch({
          type: BLOG_PAGE_SUCCESS,
          payload: {
            type: BLOG_PAGE_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: BLOG_PAGE_FAILED,
          payload: {
            type: BLOG_PAGE_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: BLOG_PAGE_FAILED,
        payload: { type: BLOG_PAGE_FAILED, message: error },
      });
    }
  };
};

async function fetchSideBlogData(data: any) {
  // const categoriesResponse = await setCategoryUrl(data?.amBlogPosts?.items);
  let authUrlKeys: any = [];
  if (data?.amBlogPosts?.items) {
    authUrlKeys = await Promise.all(
      data?.amBlogPosts?.items?.map((c: { author_id: string }) =>
        getAuthUrlKey(c.author_id)
      )
    );
  }
  let cateData: any[] | null = null;
  // if (categoriesResponse) {
  //   cateData = categoriesResponse
  //     .filter(
  //       (v, i, a) => a.findIndex((v2) => v2.categoryId === v.categoryId) !== i
  //     )
  //     .map((c: any) => c.data.data.amBlogCategory);
  // }
  data?.amBlogPosts?.items?.forEach((d: any) => {
    const url_key = authUrlKeys.find(
      (a: any) =>
        Number(a.data.data.amBlogAuthor.author_id) === Number(d.author_id)
    );
    if (cateData) {
      d.category_urls = cateData.filter((c) =>
        d.categories.includes(c.category_id)
      );
    }
    d.author_url_key = url_key
      ? "/blog/author/" + url_key.data.data.amBlogAuthor?.url_key + ".html"
      : null;
  });
}

async function fetchSideBlogs(dispatch: any) {
  const responses = await Promise.all([
    getBlogCategoryItems(),
    getBlogRecentItems(2),
  ]);
  if (responses[0].data.errors) {
    dispatch({
      type: BLOG_PAGE_GET_CATEGORIES_FAILED,
      payload: {
        type: BLOG_PAGE_GET_CATEGORIES_FAILED,
        message: responses[0].data.errors[0]?.message,
      },
    });
  } else if (responses[0].data) {
    dispatch({
      type: BLOG_PAGE_GET_CATEGORIES_SUCCESS,
      payload: {
        type: BLOG_PAGE_GET_CATEGORIES_SUCCESS,
        data: responses[0].data?.data,
      },
    });
  }
  if (responses[1].data.errors) {
    dispatch({
      type: BLOG_PAGE_GET_RECENT_POST_FAILED,
      payload: {
        type: BLOG_PAGE_GET_RECENT_POST_FAILED,
        message: responses[1].data.errors[0]?.message,
      },
    });
  } else if (responses[1].data) {
    dispatch({
      type: BLOG_PAGE_GET_RECENT_POST_SUCCESS,
      payload: {
        type: BLOG_PAGE_GET_RECENT_POST_SUCCESS,
        data: responses[1].data?.data,
      },
    });
  }
}

// async function setCategoryUrl(items: any[]) {
//   if (items && items.length > 0) {
//     let categories: any = Array.from(
//       new Set(items?.map((c: { categories: string }) => c.categories))
//     );
//     categories = categories
//       .map((c: string) => c.split(","))
//       .reduce((a: string[], b: string[]) => a.concat(b));
//     // const categoriesResponse = await Promise.all(
//     //   categories.map((c: string) => getCategoryUrlKey(c))
//     // );
//     return [];
//   } else return null;
// }

export function getBlogDetailsAction(nameKey: any) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getBlogDetails(nameKey);
      const graphQLResponse = response.data;
      if (
        graphQLResponse &&
        graphQLResponse.amBlogPost &&
        graphQLResponse.amBlogPost.author_id
      ) {
        dispatch({
          type: BLOG_DETAIL_PAGE_SUCCESS,
          payload: {
            type: BLOG_DETAIL_PAGE_SUCCESS,
            data: graphQLResponse,
          },
        });
        await fetchSideBlogs(dispatch);
      } else {
        window.location.href = ERROR_PAGE;
        dispatch({
          type: BLOG_DETAIL_PAGE_FAILED,
          payload: {
            type: BLOG_DETAIL_PAGE_FAILED,
            data: response.errors[0].message,
          },
        });
      }
    } catch (error) {
      console.error(
        "SOMETHING WENT WRONG WHILE FETCHING BLOG DETAILS DATA ",
        error
      );
      dispatch({
        type: BLOG_DETAIL_PAGE_FAILED,
        payload: { type: BLOG_DETAIL_PAGE_FAILED, message: error },
      });
    }
  };
}

export const searchBlogPostAction = (searchKey: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await searchBlogPost(searchKey);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        const categoriesResponse:any = []
        // await setCategoryUrl(
        //   response.data.amBlogPostsSearch.items
        // );
        if (categoriesResponse) {
          // const cateData = categoriesResponse.map(
          //   (c:any) => c.data.data.amBlogCategory
          // );
        //   response.data.amBlogPostsSearch?.items.forEach((d: any) => {
        //     if (cateData) {
        //       const categoryUrl = cateData.filter((c:any) => {
        //         return d.categories.includes(c.category_id);
        //       });
        //       d.category_urls = categoryUrl;
        //     }
        //   });
        }
        dispatch({
          type: SEARCH_BLOG_SUCCESS,
          payload: {
            type: SEARCH_BLOG_SUCCESS,
            data: response.data.amBlogPostsSearch,
          },
        });
        await fetchSideBlogs(dispatch);
      }
    } catch (error: any) {
      showToast(ERROR, error.message);
      dispatch({
        type: SEARCH_BLOG_FAILED,
        payload: {
          type: SEARCH_BLOG_FAILED,
          data: null,
        },
      });
    }
  };
};
