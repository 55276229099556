import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import Sidebar from "../Sidebar/Sidebar";

const MyOrdersSkl = () => {
  return (
    <div className="account-container">
      <div className="ll hide-991">
        <Link to={MY_DETAIL} className="acc-backbtn ll">
          My Account
        </Link>
      </div>
      <div className="account-wrap">
        <Sidebar></Sidebar>
        <div className="acc-rightwrap">
          <div className="orderlist-wrap skl">
            <h6 className="caps-on">
              <Skeleton />
            </h6>
            <ul className="order-items">
              <li className="item skl">
                <div className="order-top">
                  <div className="o-status">
                    <p className="ll os">
                      <Skeleton />
                    </p>
                    <p className="ls osline l-text show-768">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-total show-768">
                    <p className="ls ot l-text">
                      <Skeleton />
                    </p>
                    <p className="ll ototal">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-item show-768">
                    <p className="ls oi l-text">
                      <Skeleton />
                    </p>
                    <p className="ll oitem">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-id">
                    <p className="lm oid">
                      <Skeleton />
                    </p>
                    <p className="ls oid-date l-text">
                      <Skeleton />
                    </p>
                  </div>
                </div>
                <div className="inner-itemwrap">
                  <div className="side-img">
                    <Skeleton />
                  </div>
                  <div className="side-details">
                    <div className="product-name">
                      <Skeleton />
                    </div>
                    <div className="o-attr">
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inner-itemwrap">
                  <div className="side-img">
                    <Skeleton />
                  </div>
                  <div className="side-details">
                    <div className="product-name">
                      <Skeleton />
                    </div>
                    <div className="o-attr">
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="detail-action">
                  <div className="bm act-detail">
                    <Skeleton />
                  </div>
                </div>
              </li>
              <li className="item skl">
                <div className="order-top">
                  <div className="o-status">
                    <p className="ll os">
                      <Skeleton />
                    </p>
                    <p className="ls osline l-text show-768">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-total show-768">
                    <p className="ls ot l-text">
                      <Skeleton />
                    </p>
                    <p className="ll ototal">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-item show-768">
                    <p className="ls oi l-text">
                      <Skeleton />
                    </p>
                    <p className="ll oitem">
                      <Skeleton />
                    </p>
                  </div>
                  <div className="o-id">
                    <p className="lm oid">
                      <Skeleton />
                    </p>
                    <p className="ls oid-date l-text">
                      <Skeleton />
                    </p>
                  </div>
                </div>
                <div className="inner-itemwrap">
                  <div className="side-img">
                    <Skeleton />
                  </div>
                  <div className="side-details">
                    <div className="product-name">
                      <Skeleton />
                    </div>
                    <div className="o-attr">
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inner-itemwrap">
                  <div className="side-img">
                    <Skeleton />
                  </div>
                  <div className="side-details">
                    <div className="product-name">
                      <Skeleton />
                    </div>
                    <div className="o-attr">
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                      <span className="bs">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="detail-action">
                  <div className="bm act-detail">
                    <Skeleton />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrdersSkl;
