/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  changeEmailAction,
  changePasswordAction,
  getUserDetail,
} from "../../../Redux/Auth/AuthAction";
import {
  CHANGE_ACCOUNT_FAILED,
  CHANGE_ACCOUNT_REQUEST,
  CHANGE_ACCOUNT_SUCCESS,
} from "../../../Redux/Auth/AuthType";
import { isMobile } from "../../../Utility/Utilities";
import validate from "../../../Utility/Validation";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../Common/PasswordGroups/PasswordGroups";
import Sidebar from "../Sidebar/Sidebar";

const MyDetails = () => {
  const data = useSelector((state: any) => state?.userResponse);

  const changeAccount = useSelector((state: any) => state?.changeAccount);

  const [error, setError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const [userData, setUserData] = useState<any>({
    firstname: { value: null, validation: [], errors: [] },
    lastname: { value: null, validation: [], errors: [] },
    email: {
      value: null,
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: "",
      validation: ["min:8", "lower", "upper", "digit", "required", "special"],
      errors: [
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Password is required.",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
      ],
    },
    cpassword: {
      value: "",
      validation: ["min:8", "lower", "upper", "digit", "special", "required"],
      errors: [
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Password is required.",
      ],
    },
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [isEmailChecked, setIsEmailChecked] = useState(false);

  const handleOnEmailChange = () => {
    setIsEmailChecked(!isEmailChecked);
  };

  const [isPwdChecked, setIsPwdChecked] = useState(false);

  const handleOnPasswordChange = () => {
    setIsPwdChecked(!isPwdChecked);
  };

  const [centerText, setcenterText] = useState(false);
  const onLeftMenuClick = (leftck: any) => {
    setcenterText(true);
  };

  useEffect(() => {
    if (data && data.data) {
      const obj: any = Object.assign({}, userData);
      Object.keys(data.data).forEach((d) => {
        obj[d] = { ...obj[d], value: data.data[d] };
      });
      setUserData(obj);
     
    }
  }, [data]);

  useEffect(() => {
    dispatch(getUserDetail(false));
  }, []);

  useEffect(() => {
    if (changeAccount) {
      let detailOpensElement = document.getElementsByClassName("details-open");
      if(changeAccount.type === CHANGE_ACCOUNT_REQUEST){
        setLoading(true);
    }
     else if (changeAccount.type === CHANGE_ACCOUNT_SUCCESS) {
        setLoading(false);
        setData("password", "");
        setData("cpassword", "");
        setIsFormValidated(false);
        if (detailOpensElement && detailOpensElement[0]) {
          detailOpensElement[0].scrollTo(0, 0);
        }
        // document.getElementsByClassName("details-open")[0].scrollTo(0, 0);
      } else if (changeAccount.type === CHANGE_ACCOUNT_FAILED) {
        // error
        // showToast(ERROR, changeAccount.message);
        setData("password", "");
        setData("cpassword", "");
        setIsFormValidated(false);
        setLoading(false);
        // document.getElementsByClassName("details-open")[0].scrollTo(0, 0);
        if (detailOpensElement && detailOpensElement[0]) {
          detailOpensElement[0].scrollTo(0, 0);
        }
      }
    }
  }, [changeAccount]);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, userData);
    loginDetails[field].value = value;
    setUserData({ ...loginDetails });
  };

  const onSubmit = () => {
    const dat = Object.assign({}, JSON.parse(JSON.stringify(userData)));
    if (isEmailChecked && !isPwdChecked) {
      delete dat.cpassword;
    }
    if (!isEmailChecked && !isPwdChecked) {
      delete dat.email;
      delete dat.password;
      delete dat.cpassword;
    }
    const validationForm = validate(dat);
    if (validationForm.isValidated) {
      const obj: any = {};
      // dat.password =
      //   userData.cpassword.value !== ""
      //     ? userData.cpassword
      //     : userData.password;
      Object.keys(dat).forEach((d) => {
        obj[d] = dat[d].value;
      });
      if (!isPwdChecked) {
        dispatch(changeEmailAction(obj));
      } else {
        if (isEmailChecked) {
          dispatch(changeEmailAction(obj));
        }
        dispatch(changePasswordAction(obj));
      }
    } else {
      setError({ ...validationForm?.errors });
      setIsFormValidated(true);
    }
  };

  return (
    <>
      <div className="account-container">
        <div className="account-wrap sidebar-open">
          <Sidebar
            onLeftMenuClick={(leftck: any) => onLeftMenuClick(leftck)}
          ></Sidebar>
          <div
            className={
              centerText
                ? "acc-rightwrap details-open"
                : "acc-rightwrap details-close"
            }
          >
            {isMobile() && <ToastContainer />}
            <div className="mydetail-wrap">
              <div className="ll hide-991">
                <Link
                  to=""
                  onClick={() => setcenterText(false)}
                  className="acc-backbtn ll"
                >
                  My Account
                </Link>
              </div>
              <h1 className="caps-on">My Details</h1>
              <div className="detail-section">
                <div className="acc-info">
                  <label className="tm step-title">Account Information</label>
                  <div className="fields-set">
                    <InputGroups
                      label={"First Name*"}
                      value={userData?.firstname?.value}
                      error={
                        isFormValidated && error["firstname"]
                          ? error["firstname"]
                          : null
                      }
                      onChange={(e: any) =>
                        setData("firstname", e.target.value)
                      }
                    />
                    <InputGroups
                      label={"Last Name*"}
                      value={userData?.lastname?.value}
                      error={
                        isFormValidated && error["lastname"]
                          ? error["lastname"]
                          : null
                      }
                      onChange={(e: any) => setData("lastname", e.target.value)}
                    />
                    <CheckboxGroups
                      id={"tg-email"}
                      defaultChecked={isEmailChecked}
                      onChange={handleOnEmailChange}
                    >
                      Change Email
                    </CheckboxGroups>
                    <CheckboxGroups
                      id={"tg-pwd"}
                      defaultChecked={isPwdChecked}
                      onChange={handleOnPasswordChange}
                    >
                      Change Password
                    </CheckboxGroups>
                  </div>
                </div>
                <div className="toggle-info">
                  <label
                    className={
                      isEmailChecked || isPwdChecked
                        ? "tm step-title"
                        : "tm step-title hide"
                    }
                  >
                    {isEmailChecked && !isPwdChecked
                      ? "Change Email"
                      : isEmailChecked && isPwdChecked
                      ? "Change Email And Password"
                      : "Change Password"}
                  </label>
                  <div className="fields-set">
                    <div className={isEmailChecked ? "field" : "field hide"}>
                      <InputGroups
                        error={
                          isFormValidated && error["email"]
                            ? error["email"]
                            : null
                        }
                        label={"Email Address*"}
                        onChange={(e: any) => setData("email", e.target.value)}
                        value={userData?.email?.value}
                      />
                    </div>
                    <div
                      className={
                        isEmailChecked || isPwdChecked ? "field" : "field hide"
                      }
                    >
                      <PasswordGroups
                        value={userData?.password?.value}
                        label={"Current Password*"}
                        error={
                          isFormValidated && error["password"]
                            ? error["password"]
                            : null
                        }
                        onChange={(e: any) => setData("password", e)}
                      />
                    </div>
                    <div className={isPwdChecked ? "field" : "field hide"}>
                      <PasswordGroups
                        value={userData?.cpassword?.value}
                        error={
                          isFormValidated && error["cpassword"]
                            ? error["cpassword"]
                            : null
                        }
                        label={"New Password*"}
                        onChange={(e: any) => setData("cpassword", e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="action-bar">
                <CustomButton
                  isLoading={loading}
                  disabled={loading}
                  onClick={() => onSubmit()}
                  bg={"fill"}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDetails;
