/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { CART_ID, FIRST_NAME, GUEST_EMAIL, LAST_NAME } from "../../../Constants/LocalStorage";
import { CART_PAGE, HOME, ORDER_SUCCESS } from "../../../Constants/Route";
import { createEmptyCartAction } from "../../../Redux/Pages/CartPage/CartPageActions";
import {
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  ORDER_PLACED_SUCCESS,
  STRIPE_ORDER_CLEAR,
} from "../../../Redux/Pages/Checkout/CheckoutTypes";
import { getOrdersSuccessAction } from "../../../Redux/Pages/Orders/OrdersAction";
import { ORDERS_SUCCESS_SUCCESS } from "../../../Redux/Pages/Orders/OrdersTypes";
import { flowBoxCheckOutScript, getSessionItem, removeSessionItem } from "../../../Utility/Utilities";
import { gtmPurchase } from "../../../Utility/gtmhelper";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { GET_USER_DETAIL_SUCCESS } from "../../../Redux/Auth/AuthType";


const OrderSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orderSuccessResponse = useSelector(
    (state: any) => state?.ordersSuccessResponse
  );
  const userData = useSelector((state: any) => state?.userResponse);

  const [userInformation, setUserInformation] = useState<any>({});
  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const [orderData, setOrderData] = useState<any>({});
  const [staticData, setStaticData] = useState<any>({});

  useEffect(() => {
    setUserInformation({
      email:getSessionItem(GUEST_EMAIL) ?? "",
      firstname:getSessionItem(FIRST_NAME) ?? "",
      lastname:getSessionItem(LAST_NAME) ?? "",        
    })
  },[])

  useEffect(() => {
    if (checkoutResponse && checkoutResponse.type === ORDER_PLACED_SUCCESS) {
      removeSessionItem(GUEST_EMAIL);
      dispatch(getOrdersSuccessAction(checkoutResponse.data));
      setOrderData(checkoutResponse.cart);
      removeSessionItem(CART_ID);
      dispatch(createEmptyCartAction());
    }
  }, [checkoutResponse]);

  useEffect(() => {
    if (userData && userData.type === GET_USER_DETAIL_SUCCESS) {
      setUserInformation(userData?.data);
    }
  }, [userData]);

  useEffect(() => {
    if (
      orderSuccessResponse &&
      orderSuccessResponse.type === ORDERS_SUCCESS_SUCCESS
    ) {
      if (orderData) {
        const obj = {
          actionField: {
            id: orderData, // Transaction ID. Required for purchases and refunds.
            affiliation: "Online Store",
            revenue: Number(orderData?.prices?.grand_total?.value).toFixed(2), // Total transaction value (incl. tax and shipping)
            coupon:
              orderData?.applied_coupons &&
              orderData.applied_coupons?.length > 0
                ? orderData?.applied_coupons[0].code
                : "",
          },
          products: orderData?.items
            ?.filter((cart: any) => cart)
            ?.map((c: any) => {
              return {
                sku: c.product.sku,
                name: c.product.name,
                quantity: c.quantity,
                price: c.prices.row_total_including_tax.value,
              };
            }),
        };
        flowBoxCheckOutScript({
          orderId:orderSuccessResponse?.data?.OrderSuccess?.number,
          products:obj?.products?.map((p:any) => {
            return {
              id:p?.sku,
              quantity:parseInt(p?.quantity),
              price:parseFloat(p?.price),
              currency:"EUR"
            }
          }),
          customerData:{
            name: userInformation?.firstname,
            lastName: userInformation?.lastname,
            email: userInformation?.email,
          }
          
        })
        gtmPurchase(obj);
      }
      setStaticData(orderSuccessResponse?.data?.OrderSuccess);
      dispatch({
        type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
        payload: {
          type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
          data: null,
        },
      });
      dispatch({
        type: STRIPE_ORDER_CLEAR,
        payload: {
          type: STRIPE_ORDER_CLEAR,
          data: null,
        },
      });
    }
  }, [orderSuccessResponse]);

  useEffect(() => {
    if (location.search === "?fromCheckout=True") {
      window.history.pushState({}, "", ORDER_SUCCESS);
    } else {
      if (!orderSuccessResponse?.data?.OrderSuccess?.number) {
        navigate(CART_PAGE);
      }
    }
  }, [location]);

  return (
    <div className="ordrsucs-wrap">
      <div className="custom-container">
        <div className="ordrsucs-content">
          <img
            src={staticData.image}
            alt={"order success"}
            title={"order success"}
          />
          <h1 className="tm">Thank you for your purchase!</h1>
          <p className="lm">
            Order #{orderSuccessResponse?.data?.OrderSuccess?.number}
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: staticData.description }}
          ></div>
          <CustomButton
            onClick={() => {
              navigate(HOME);
            }}
            bg={"fill"}
          >
            {staticData.title}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
