import React, { forwardRef, useEffect } from 'react'
import {  loadFlowboxReview } from '../../../Utility/Utilities';

const locale = "en-GB";

const ProductStarWidget = forwardRef(({ flowKey, containerName , productId }: any , myRef :any) => {
  const container = `#${containerName}`;
  useEffect(() => {
  loadFlowboxReview().then(() => {
      window.flowboxReviews("init", {
        container,
        key: flowKey,
        locale,
        ...(productId && { productId }),
      });
    });
  }, [container, flowKey]);

  return <div ref={myRef} id={containerName}>  </div>;
}) 

export default ProductStarWidget