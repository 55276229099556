import React, { useEffect, useState } from 'react'
import DivideBuyInstallmentInfo from '../DivideBuyInstallmentInfoPopup/DivideBuyInstallmentInfo';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MIN_INSTALLMENT_AMOUNT_SUCCESS, GET_SOFT_SEARCH_KEY_SUCCESS, GET_PRODUCT_INSTALLMENT_HTML_SUCCESS, GET_SOFT_SEARCH_KEY_FAILED, GET_SOFT_SEARCH_KEY_REQUEST } from '../../../Redux/General/GeneralType';
import info from "../../../Assets/Images/info.svg";
import { getSoftSearchSplashKeyAction } from '../../../Redux/General/GeneralAction';
import CheckDivideBuyEligibilityPopup from '../CheckDivideBuyEligibilityPopup/CheckDivideBuyEligibilityPopup';
import DividebuyLogoBanner from "../../../Assets/Images/dividebuy-logobanner.png";
import DividebuyMiniLogo from "../../../Assets/Images/dividebuy-mini-logo.svg";

function DivideBuyDetails({ filteredProduct }: any) {
    const dispatch = useDispatch();

    const installmentResponse = useSelector(
        (state: any) => state.getProductInstallmentHtmlReducer
    );

    const minPriceResponse = useSelector(
        (state: any) => state.getMinInstallmentAmountReducer
    );

    const splashKeyResponse = useSelector(
        (state: any) => state.getSoftSearchKeyReducer
    );


    const [minInstallmentPrice, setMinInstallmentPrice] = useState<null | string>();
    const [installmentHtml, setInstallmentHtml] = useState<null | string>("");
    const [showInstallmentPopup, setShowInstallmentPopup] = useState(false);
    const [currentSplashKey, setCurrrentSplashKey] = useState<null | string>("");
    const [loading, setLoading] = useState(false);
    const [showCheckEligibilityPopup, setShowCheckEligibilityPopup] = useState(false);


    useEffect(() => {
        if (minPriceResponse) {
            if (minPriceResponse.type === GET_MIN_INSTALLMENT_AMOUNT_SUCCESS) {
                if (
                    minPriceResponse.data &&
                    minPriceResponse.data !== ""
                ) {
                    setMinInstallmentPrice(minPriceResponse.data?.tooltip_text)
                } else {
                    setMinInstallmentPrice(null)
                }
            }
        }
    }, [minPriceResponse]);

    useEffect(() => {

        if (splashKeyResponse) {
            if (splashKeyResponse.type === GET_SOFT_SEARCH_KEY_REQUEST) {
                setLoading(true)
            }
            else if (splashKeyResponse.type === GET_SOFT_SEARCH_KEY_SUCCESS) {
                setLoading(false);

                if (
                    splashKeyResponse.data &&
                    splashKeyResponse.data
                ) {
                    setCurrrentSplashKey(splashKeyResponse?.data?.splashKey);
                } else {
                    setCurrrentSplashKey(null);
                }
            } else if (splashKeyResponse.type === GET_SOFT_SEARCH_KEY_FAILED) {
                setLoading(false);
            }
        }
    }, [splashKeyResponse]);

    useEffect(() => {
        if (installmentResponse) {
            if (installmentResponse.type === GET_PRODUCT_INSTALLMENT_HTML_SUCCESS) {
                if (
                    installmentResponse.data
                ) {
                    setInstallmentHtml(installmentResponse.data);
                } else {
                    setInstallmentHtml(null);
                }
            }
        }
    }, [installmentResponse]);

    const checkEligibility = () => {
        const price = Number(
            filteredProduct.price_range.minimum_price
                .final_price.value
        ).toFixed(2);
        setShowCheckEligibilityPopup(true);
        dispatch(getSoftSearchSplashKeyAction(price))
    }

    return (
        <>  
            <div className="custom_divideby">
            {
                !!filteredProduct?.dividebuy_enable &&
                <div style={{ marginTop: "16px" }}>
                    {
                        (minInstallmentPrice && minInstallmentPrice !== null && minInstallmentPrice !== "")  ? (
                            <div id="dividebuy-text" className="active inner_divide">
                                <div className="dividebuy-left-text">
                                    <span className="bm">{minInstallmentPrice}</span>
                                    <img  style={{display:"inline-block"}} src={DividebuyLogoBanner}/>
                                    <img style={{ width: "16px", height: "16px"  , cursor:"pointer" , display:"inline-block"}} src={info} alt={""} onClick={() => {
                                    setShowInstallmentPopup(true);
                                }} />
                                </div>
                            </div>
                        ) : null
                    }
                    </div>
            }
            {
                !!filteredProduct?.dividebuy_enable &&

                <div className="lm dividebuy-softsearch dividebuy-text-left">
                    <a href="javascript:void(0)" onClick={checkEligibility} id="dividebuy-softsearch-text" className="link-dividebuy-softsearch-product"><span> <img style={{ width: "24px", height: "24px" }} src={DividebuyMiniLogo} alt="dividebuy-logo" /><span className="ll">Check your eligibility</span></span>
                    </a>
                    <p className="dividebuy-fastcheck-text">Fast check - won't affect your credit rating.</p>
                </div>

            }
            </div>
            <DivideBuyInstallmentInfo
                show={showInstallmentPopup}
                onHide={() => setShowInstallmentPopup(false)}
                content={installmentHtml}
            />
            <CheckDivideBuyEligibilityPopup
                show={showCheckEligibilityPopup}
                onHide={() => setShowCheckEligibilityPopup(false)}
                splashKey={currentSplashKey}
                loading={loading}
            />
        </>
    )
}

export default DivideBuyDetails
