import { Fragment } from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = (props: any) => {
  function isLast(index: any) {
    return index === props.value.length - 1;
  }

  return (
    <div className="cus-breadcrumb">
      {props.value
        .filter((data: any) => data)
        .map((data: any, index: any) => {
          const disabled = isLast(index) ? (
            <span className="disabled bs" key={index + data.label}>
              {data.label}
            </span>
          ) : (
            <Link
              to={"/" + data.path}
              className="item bs"
              key={index + data.label}
            >
              {data.label}
            </Link>
          );
          return <Fragment key={index}>{disabled}</Fragment>;
        })}
    </div>
  );
};

export default Breadcrumbs;
