/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { LOGIN } from "../../Constants/Route";
import { resetPasswordAction } from "../../Redux/Auth/AuthAction";
import { RESET_PASSWORD_SUCCESS } from "../../Redux/Auth/AuthType";
import validate from "../../Utility/Validation";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";

const ResetPassword = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<any>({});

  const INITIAL_DATA: any = {
    email: {
      value: null,
      validation: ["email", "required"],
      errors: [null, "Please enter valid email.", "Email is required."],
    },
    password: {
      value: "",
      validation: [
        "password",
        "required",
        "min:8",
        "lower",
        "upper",
        "digit",
        "special",
      ],
      errors: [
        null,
        "Password is required.",
        "Should have minimum 8 character",
        "Should have lowercase",
        "Should have uppercase",
        "Should have numbers",
        "Should have special characters",
      ],
    },
    cpassword: {
      value: "",
      validation: [
        "password",
        "required",
        "min:8",
        "lower",
        "upper",
        "digit",
        "special",
        "samepass:password",
      ],
      errors: [
        null,
        "Password is required.",
        "Should have minimum 8 character",
        "Should have lowercase",
        "Should have uppercase",
        "Should have numbers",
        "Should have special characters",
        "Password Mismatch",
      ],
    },
  };

  const [userData, setUserData] = useState<any>(INITIAL_DATA);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, userData);
    loginDetails[field].value = value;
    setUserData({ ...loginDetails });
  };

  const tokenUrl = location.search.split("?")[1];

  let token = tokenUrl ? tokenUrl.split("=")[1] : null;

  const [isFormValidated, setIsFormValidated] = useState(false);

  const response = useSelector((state: any) => state?.resetPassword);

  const navigate = useNavigate();

  useEffect(() => {
    if (response && response.type === RESET_PASSWORD_SUCCESS) {
      setLoading(false);
      setUserData(INITIAL_DATA);
      navigate(LOGIN);
    }
  }, [response]);

  const onSubmit = () => {
    setLoading(true);
    const dat = Object.assign({}, userData);
    const v = validate(dat);
    console.log("userData ", userData);
    setIsFormValidated(!v.isValidated);
    if (v.isValidated) {
      const obj: any = {};
      Object.keys(dat).forEach((d) => {
        obj[d] = dat[d].value;
      });
      obj["token"] = token;
      dispatch(resetPasswordAction(obj));
    } else {
      setTimeout(() => setLoading(false), 3000);
      setError({ ...v?.errors });
      setIsFormValidated(true);
    }
  };

  return (
    <div className="authentication-wrap resetpwd">
      <div className="custom-container">
        <div className="inner-main">
          <div className="top-head resetpwdtx">
            <h1 className="caps-on">set a new password</h1>
          </div>
          <InputGroups
            id="email"
            type="email"
            label="Email Address*"
            onChange={(e: any) => setData("email", e.target.value)}
            error={isFormValidated && error["email"] ? error["email"] : null}
          />
          <PasswordGroups
            value={userData?.password?.value}
            label={"New Password*"}
            error={
              isFormValidated && error["password"] ? error["password"] : null
            }
            onChange={(e: any) => setData("password", e)}
          />
          <PasswordGroups
            value={userData?.cpassword?.value}
            label="Confirm New Password*"
            error={
              isFormValidated && error["cpassword"] ? error["cpassword"] : null
            }
            onChange={(e: any) => setData("cpassword", e)}
          />
          <CustomButton
            isLoading={loading}
            onClick={() => onSubmit()}
            bg={"fill"}
          >
            set a new Password
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
