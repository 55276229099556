const FETCHIFY_ACCESS_TOKEN = process.env.FETCHIFY_ACCESS_TOKEN || "1e438-61258-698a2-0690b"

const API_URL = (postcode: string): string => `https://pcls1.craftyclicks.co.uk/json/rapidaddress?key=
${FETCHIFY_ACCESS_TOKEN}&postcode=${postcode}&sort=asc&response=data_formatted&lines=3`



export const postcodeLookup = (pincode: string): Promise<Response> => {
    return fetch(API_URL(pincode))
}

export const addressLookup = (searchQuery: string): Promise<Response> => {
    const data = {
        "key": FETCHIFY_ACCESS_TOKEN, "query": searchQuery,
        "id": "", "country": "gbr",
        "fingerprint": "11e3a74679e4a0",
        "integration": "Magento 2",
        "js_version": "1.2.10", "sequence": 7,
        "type": 0, "coords": {}, "extra": { "exclude_areas": [""] }
    }
    return fetch(`https://api.craftyclicks.co.uk/address/1.1/find`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
}