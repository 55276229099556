import React from 'react'
import { FLOWBOX_CONTAINER } from '../../Constants/Labels'
import Flowbox from '../Flowbox/Flowbox'
import { FLOWBOX_COMMUNITY_PAGE_FLOW_KEY } from '../../Constants/Config'


export default function CustomerShowCase() {
    return (
        <div className="custom-container">
            <Flowbox
                containerName={FLOWBOX_CONTAINER.COMMUNITY_FLOW}
                flowKey={FLOWBOX_COMMUNITY_PAGE_FLOW_KEY}
            />
        </div>
    )
}
