import { useLocation } from "react-router";
import { Navigate } from "react-router";
import { LOGIN } from "./Constants/Route";
import { isAuth } from "./Utility/Utilities";

function ProtectedRoute({ children }: any) {
    const location = useLocation();
    if (!isAuth()) {
        return <Navigate to={LOGIN} replace  state={{ from: location }} />
    }
    return children
}

export default ProtectedRoute;
