import { Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import { useSelector } from "react-redux";

const WebProductCMSContent = (props: {
  setFreesampleModalShow: any;
  dimensions: any;
  description: any;
  whyus: any;
  delivery: any;
}) => {
  const [freesampleModalShow, setfreesampleModalShow] = useState(false);
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("fspopup")?.addEventListener("click", (e) => {
        setfreesampleModalShow(true);
      });
    }, 100);
  }, []);

  return (
    <>
      <div className="product-detail-tab">
        <div className="product-detail-tab-innerwrap">
          <div className="custom-container">
            <Tabs defaultActiveKey="Description" className="product-tabs">
              {props.description && props.description !== "" ? (
                <Tab eventKey="Description" title="Description">
                  <div dangerouslySetInnerHTML={{ __html: props.description }} />
                </Tab>
              ) : null}
              {props.dimensions && props.dimensions !== "" ? (
                <Tab eventKey="dimensions" title="dimensions">
                  <div
                    className="dimensions-box"
                    dangerouslySetInnerHTML={{ __html: props.dimensions }}
                  ></div>
                </Tab>
              ) : null}
              {props.delivery && props.delivery !== "" ? (
                <Tab eventKey="delivery" title="delivery">
                  <div dangerouslySetInnerHTML={{ __html: props.delivery }} />
                </Tab>
              ) : null}
              {props.whyus && props.whyus !== "" ? (
                <Tab eventKey="why us" title="why us">
                  <div dangerouslySetInnerHTML={{ __html: props.whyus }} />
                </Tab>
              ) : null}
            </Tabs>
          </div>
          <div className="shadow-box"></div>
        </div>
      </div>
      <FreeSamplesPopup
        storeConfigInfo={storeConResponse}
        show={freesampleModalShow}
        onHide={() => setfreesampleModalShow(false)}
      />
    </>
  );
};
export default WebProductCMSContent;
