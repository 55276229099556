import Skeleton from "react-loading-skeleton";

const ProductDetailSkl = () => {
  return (
    <div className="product-detail-wrap skl">
      <div className="custom-container top">
        <div className="breadskl">
          <Skeleton />
        </div>
        <div className="product-detail-main skl ">
          <div className="left-media">
            <div className="product-left-txt show-992">
              <ul>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </ul>
            </div>
            <div className="sample-productdetails skl">
              <div className="preview-slider">
                <div className="slider-img">
                  <Skeleton />
                </div>
              </div>
              <div className="thumbnail-slider skl">
                <div className="point">
                  <Skeleton />
                </div>
                <div className="point">
                  <Skeleton />
                </div>
                <div className="point">
                  <Skeleton />
                </div>
                <div className="point">
                  <Skeleton />
                </div>
                <div className="point">
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
          <div className="product-right-txt skl">
            <h6>
              <Skeleton />
            </h6>
            <div className="product-social skl">
              <p className="bs">
                <Skeleton />
              </p>
              <div className="extra-action">
                <div className="action">
                  <Skeleton />
                </div>
                <div className="action">
                  <Skeleton />
                </div>
              </div>
            </div>
            <div className="mob-filter">
              <div className="filter">
              <Skeleton />
              </div>
              <div className="filter">
              <Skeleton />
              </div>
              </div>
            <div className="product-txt-box skl">
              <div className="product-swatches">
                <div className="sample-wrap">
                  <p className="ll check-txt-991">
                    <Skeleton />
                  </p>
                  <div className="sample-main">
                    <Skeleton />
                  </div>
                </div>
                <div className="sample-wrap check-hide-991">
                  <p className="ll">
                    <Skeleton />
                  </p>
                  <div className="sample-main">
                    <Skeleton />
                  </div>
                </div>
                <div className="sample-wrap check-hide-991">
                  <p className="ll">
                    <Skeleton />
                  </p>
                  <div className="sample-main">
                    <Skeleton />
                  </div>
                </div>
              </div>
              <div className="select-box">
                <div className="select-btn">
                  <Skeleton />
                  <Skeleton />
                </div>

                <div className="sidepanel-tabs">
                  <div className="on-radio">
                    {" "}
                    <Skeleton />
                  </div>
                  <div className="on-radio">
                    {" "}
                    <Skeleton />
                  </div>
                </div>
                <div className="availibility">
                  <Skeleton />
                </div>
              </div>
              <div className="product-price">
                <div className="price">
                  <Skeleton />
                </div>
              </div>
              <div className="protection-box">
                <Skeleton />
              </div>
              <div className="action-btn">
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-detail-tab">
        <div className="custom-container">
          <ul>
            <li>
              <Skeleton />
            </li>
            <li>
              <Skeleton />
            </li>
            <li>
              <Skeleton />
            </li>
            <li>
              <Skeleton />
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-cont-first">
            <Skeleton />
            </div>
            <div className="tab-cont-second">
            <Skeleton />
            </div>
            <div className="tab-cont-third">
            <Skeleton />
            </div>
            <div className="tab-cont-fourth">
            <Skeleton />
            </div>
          </div>
        </div>
      </div>
      <div className="related-product">
        <div className="custom-container">
          <h6>
            <Skeleton />
          </h6>
          <div className="product-box">
            <div className="list-item">
              <div className="img-skl">
                <Skeleton />
              </div>
              <div className="name-skl">
                <Skeleton />
              </div>
              <div className="price-skl">
                <Skeleton />
              </div>
            </div>
            <div className="list-item">
              <div className="img-skl">
                <Skeleton />
              </div>
              <div className="name-skl">
                <Skeleton />
              </div>
              <div className="price-skl">
                <Skeleton />
              </div>
            </div>
            <div className="list-item">
              <div className="img-skl">
                <Skeleton />
              </div>
              <div className="name-skl">
                <Skeleton />
              </div>
              <div className="price-skl">
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailSkl;
