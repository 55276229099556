import CustomButton from "../Common/CustomButton/CustomButton";
import NotFoundImage from "../../Assets/Images/404.jpg";
import { useNavigate } from "react-router";
import { HOME } from "../../Constants/Route";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page fzp">
      <div className="custom-container">
        <div className="error-page-inner">
          <div className="error-box left">
            <h6>
              404: <br />
              PAGE NOT FOUND
            </h6>
            <p className="bm">
              The link you clicked may be broken or the page may have been
              removed.
            </p>
            <CustomButton bg="fill" onClick={() => navigate(HOME)}>
              Go To Homepage
            </CustomButton>
          </div>
          <div className="error-box right">
            <div className="error-img">
              <img src={NotFoundImage} alt={"404-error"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
