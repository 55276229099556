import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ABOUT_US,
  BEST_SELLERS,
  BLOG_LISTING,
  CART_PAGE,
  CONTACT_US,
  CUSTOMER_SHOWCASE,
  FAQS,
  INTEREST_FREE_CREDIT,
  MATERIALS,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  STAY_IN_BED,
  TESTIMONIALS,
} from "../../Constants/Route";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";

export const FooterLinks = () => {
  const [freeSampleModalShow, setFreeSampleModalShow] = useState(false);
  return (
    <>
      <div className="footer-secondary">
        <div className="custom-container">
          <div className="footer-menu">
            <div id="accordionExample" className="accordion">
              <div className="accordion-item">
                <h2 id="headingOne" className="accordion-header">
                  <button
                    className="accordion-button tm"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {" "}
                    My Account{" "}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bm">
                    <ul>
                      <li>
                        <Link to={MY_ORDERS_PAGE}>My Orders</Link>
                      </li>
                      <li>
                        <Link to={CART_PAGE}>Shopping Basket</Link>
                      </li>
                      <li>
                        <Link to={MY_WISHLIST}>Wish List</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 id="headingTwo" className="accordion-header">
                  <button
                    className="accordion-button tm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {" "}
                    About{" "}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bm">
                    <ul>
                      <li>
                        <Link to={ABOUT_US}>About Us</Link>
                      </li>
                      <li>
                        <Link to={TESTIMONIALS}>Testimonials</Link>
                      </li>
                      <li>
                        <Link to={BLOG_LISTING}>Blogs</Link>
                      </li>
                      <li>
                        <Link to={MATERIALS}>Materials</Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 id="headingThree" className="accordion-header">
                  <button
                    className="accordion-button tm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {" "}
                    Support{" "}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bm">
                    <ul>
                      <li>
                        <Link to={CONTACT_US}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={FAQS}>FAQS</Link>
                      </li>
                      <li>
                        <Link to={CUSTOMER_SHOWCASE}>Customer Showcase</Link>
                      </li>
                      {/* <li>
                        <Link to={FINANCE}>Finance</Link>
                      </li> */}
                      <li>
                        <span onClick={() => setFreeSampleModalShow(true)}>
                          Free Samples
                        </span>
                      </li>
                      <li>
                        <Link to={BEST_SELLERS}>Best Sellers</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 id="headingFour" className="accordion-header">
                  <button
                    className="accordion-button tm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {" "}
                    Legal
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bm">
                    <ul>
                      <li>
                        <a href="/terms">Delivery &amp; Returns</a>
                      </li>
                      <li>
                        <a href="/privacy-policy-cookie-restriction-mode">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/terms">Terms &amp; Conditions</a>
                      </li>
                      <li>
                        <Link to={INTEREST_FREE_CREDIT}>Interest Free Credit</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 id="headingFive" className="accordion-header">
                  <button
                    className="accordion-button tm collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    {" "}
                    Connect{" "}
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body bm">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/livingitupuk"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/livingitupuk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.pinterest.co.uk/livingitupuk/_created/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Pinterest
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.livingitupsalon.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Livingitupsalon.co.uk
                        </a>
                      </li>
                    
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
      <FreeSamplesPopup
        show={freeSampleModalShow}
        onHide={() => setFreeSampleModalShow(false)}
      />
    </>
  );
};
