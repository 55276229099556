import Skeleton from "react-loading-skeleton"

const OrderDetailSkl = () => {
  return (
    <div className="orderdetail-wrap skl">
        <div className="account-container">
          <div className="breadskl">
            <Skeleton />
          </div>
          <div className="ord-sections">
            <div className="ord-topinfo skl">
              <div className="top-info">
                <h6 className="caps-on"><Skeleton /></h6>
                <p className="ls oid-date"><Skeleton /></p>
                <p className="ls oid"><Skeleton /></p>
              </div>
            </div>
            <div className="ord-details">
              <div className="inner-itemwrap">
                <div className="side-img">
                  <Skeleton />
                </div>
                <div className="side-details">
                  <div className="pdt-nameskl"><Skeleton /></div>
                  <div className="o-attr">
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                  </div>
                </div>
              </div>
              <div className="inner-itemwrap">
                <div className="side-img">
                  <Skeleton />
                </div>
                <div className="side-details">
                  <div className="pdt-nameskl"><Skeleton /></div>
                  <div className="o-attr">
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                    <span className="bs"><Skeleton /></span>
                  </div>
                </div>
              </div>
              <div className="ord-additional skl">
                <div className="add-address">
                  <p className="tm intitle"><Skeleton /></p>
                  <p className="ll name"><Skeleton /></p>
                  <address className="bm"><Skeleton /></address>
                  <p className="tm mobile"><Skeleton /></p>
                </div>
                <div className="add-payment">
                  <p className="tm intitle"><Skeleton /></p>
                  <span className="bm"><Skeleton /></span>
                </div>
              </div>
            </div>
            <div className="ord-summary skl">
              <p className="tm"><Skeleton /></p>
              <div className="total-table">
                <div className="sub-col total">
                  <p className="stitle bm"><Skeleton /></p>
                  <p className="svalue bm"><Skeleton /></p>
                </div>
                <div className="sub-col delivery">
                  <p className="stitle bm"><Skeleton /></p>
                  <p className="svalue bm"><Skeleton /></p>
                </div>
                <div className="sub-col maintotal">
                  <p className="stitle tm"><Skeleton /></p>
                  <p className="svalue tm"><Skeleton /></p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default OrderDetailSkl;