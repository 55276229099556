/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import MainNavigation from "../NavigationMenu/MainNavigation";
import heart from "../../Assets/Images/heart.svg";
import cart from "../../Assets/Images/cart.svg";
import search from "../../Assets/Images/search.svg";
import MiniCart from "../Home/MiniCart/MiniCart";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECKOUT,
  HOME,
  MY_WISHLIST,
  ORDER_SUCCESS,
  SEARCH_CATEGORY,
} from "../../Constants/Route";
import {
  DELETE_CART_SUCCESS,
  GET_CART_COUNT_SUCCESS,
  GET_CART_PAGE_SUCCESS,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import { LOGOUT_SUCCESS } from "../../Redux/Auth/AuthType";
import {
  GET_META_TITLE_SUCCESS,
  SEARCH_FUNCTIONALITY_SUCCESS,
} from "../../Redux/General/GeneralType";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router";
import { getMetaTitleDetail } from "../../Redux/General/GeneralAction";
import { gtmlPageView } from "../../Utility/gtmhelper";

const WebHeader = () => {
  const urls = [CHECKOUT, ORDER_SUCCESS];
  const [isActive, setActive] = useState(false);
  const htmlElRef: any = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCartActive, setCartActive] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [cartCount, setCartCount] = useState(0);

  const loginResponse = useSelector((state: any) => state.loginResponse);
  const cartResponse = useSelector((state: any) => state.getCartCountResponse);
  const deleteCartResponse = useSelector(
    (state: any) => state.deleteCartItemResponse
  );
  const searchResponse = useSelector(
    (state: any) => state.searchCategoryResponse
  );
  const urlResolverData = useSelector((state: any) => state.urlResolver);
  const metaTitleResponse = useSelector(
    (state: any) => state.getMetaTitleResponse
  );

  useEffect(() => {
    if (location.pathname) {
      const isStatic =
        location.pathname.includes("/") ||
        location.pathname.includes("/customer/") ||
        location.pathname.includes("/checkout") ||
        location.pathname.includes("/cart") ||
        location.pathname.includes("/blog");
      let type = "static";
      if (isStatic) {
        type = "static";
      } else {
        type =
          urlResolverData && urlResolverData.data && urlResolverData.data.type
            ? ""
            : "static";
      }
      dispatch(
        getMetaTitleDetail(
          location.pathname === "/" ? "//" : location.pathname,
          type
        )
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (metaTitleResponse.type === GET_META_TITLE_SUCCESS) {
      document.title = metaTitleResponse.data.meta_title;
      gtmlPageView(
        metaTitleResponse.data.meta_title
          ? metaTitleResponse.data.meta_title
          : "Livingitup"
      );
    }
  }, [metaTitleResponse]);

  useEffect(() => {
    if (loginResponse && loginResponse.type === LOGOUT_SUCCESS) {
      setCartActive(false);
      dispatch({ type: GET_CART_PAGE_SUCCESS, payload: null });
    }
  }, [loginResponse]);

  useEffect(() => {
    if (deleteCartResponse) {
      if (deleteCartResponse.type === DELETE_CART_SUCCESS) {
        setCartActive(false);
      }
    }
  }, [deleteCartResponse]);

  useEffect(() => {
    htmlElRef.current && htmlElRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    if (
      searchResponse &&
      searchResponse.type === SEARCH_FUNCTIONALITY_SUCCESS
    ) {
      setSearchCategory("");
      setActive(false);
    }
  }, [searchResponse]);

  const searchCat = (e: any) => {
    if (e.key === "Enter") {
      navigate({
        pathname: SEARCH_CATEGORY,
        search: searchCategory,
      });
    }
  };
  const [backStatus2, setBackStatus2] = useState(false);
  const toggleBack2 = () => {
    setBackStatus2(!backStatus2);
  };
  const [currentLocation, setCurrentLocation] = useState(String);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (cartResponse.type === GET_CART_COUNT_SUCCESS) {
      setCartCount(cartResponse.data);
    }
  }, [cartResponse]);

  return (
    <>
      <div
        className={
          urls.includes(currentLocation)
            ? "header-content show-768 in-checkout"
            : "header-content show-768"
        }
      >
        <div className="custom-container">
          <div className="header-web">
            <header className="header-content">
              <div className="left-info">
                <span className="ll">9am - 5pm</span>
                <a className="ll" href="tel:01162695960" title="Call Us">
                  0116 269 5960
                </a>
              </div>
              <div className="logo">
                {location.pathname === HOME ? (
                  <h1 title="Living It Up">
                    <img
                      src={`${process.env.REACT_APP_BASE_MEDIA_URL}logo/${process.env.REACT_APP_HEADER_LOGO_SRC}`}
                      alt="Web Logo"
                    />
                  </h1>
                ) : (
                  <Link to={""} title="Living It Up">
                    <img
                      src={`${process.env.REACT_APP_BASE_MEDIA_URL}logo/${process.env.REACT_APP_HEADER_LOGO_SRC}`}
                      alt="Web Logo"
                    />
                  </Link>
                )}
              </div>
              <div className="right-info">
                <span
                  title="Search"
                  className="right-link search"
                  onClick={() => setActive(!isActive)}
                >
                  <img src={search} alt="Search Bar"></img>
                </span>
                <Link
                  to={MY_WISHLIST}
                  title="Wishlist"
                  className="right-link heart"
                >
                  <img src={heart} alt="Wishlist"></img>
                </Link>
                <div className="minicart lm">
                  <Dropdown onToggle={() => toggleBack2()} show={backStatus2}>
                    <Dropdown.Toggle onClick={() => toggleBack2()}>
                      <img src={cart} alt="Cart" className="cart" />
                      <span className="lm">
                        {cartCount > 0 ? cartCount : null}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        <div
                          className={
                            isCartActive
                              ? "minicart-wrapper open"
                              : "minicart-wrapper open"
                          }
                        >
                          <MiniCart toggleBack2={toggleBack2} />
                        </div>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
      <MainNavigation key={"Main navigation"} />
      <div className={isActive ? "search-box open" : "search-box close"}>
        <div className="custom-container">
          <div className="search-grp">
            <input
              type="search"
              ref={htmlElRef}
              value={searchCategory}
              onChange={(e: any) => setSearchCategory(e.target.value)}
              onKeyDown={searchCat}
              autoFocus
            />
            <button
              className="close-search"
              onClick={() => setActive(!isActive)}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebHeader;
