import { Dropdown } from "react-bootstrap";
import Share from "../../Assets/Images/Share.svg";
import socialShareFB from "../../Assets/Images/FB.svg";
import socialSharePin from "../../Assets/Images/Pin.svg";
import socialShareWP from "../../Assets/Images/WP.svg";
import socialShareTW from "../../Assets/Images/TW.svg";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GET_PRODUCT_DETAIL_SUCCESS } from "../../Redux/Pages/Products/ProductTypes";

const ShareDropdown = () => {
  const response: any = useSelector(
    (state: any) => state.productDetailResponse
  );

  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (response.type === GET_PRODUCT_DETAIL_SUCCESS) {
      setData(response.data);
    }
  }, [response]);

  return (
    <Dropdown className="social-share">
      <Dropdown.Toggle>
        <img src={Share} alt="" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>
          <PinterestShareButton
            url={window.location.href}
            title={data?.name}
            media={data?.image?.url}
          >
            <img src={socialSharePin} alt="" />
          </PinterestShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <FacebookShareButton url={window.location.href} title={data?.name}>
            <img src={socialShareFB} alt="" />
          </FacebookShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <TwitterShareButton url={window.location.href} title={data?.name}>
            <img src={socialShareTW} alt="" />
          </TwitterShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <WhatsappShareButton url={window.location.href}>
            <img src={socialShareWP} alt="" />
          </WhatsappShareButton>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareDropdown;
