/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomForm,
  submitSampleForm,
} from "../../Redux/General/GeneralAction";
import {
  GET_CUSTOM_FORM_SUCCESS,
  SUBMIT_SAMPLE_FORM_ERROR,
  SUBMIT_SAMPLE_FORM_SUCCESS,
} from "../../Redux/General/GeneralType";
import FreeSampleColorForm from "./FreeSampleColorForm";
import FreeSampleUserForm from "./FreeSampleUserForm";
import { showToast } from "../../Utility/Utilities";
import { WARNING } from "../../Constants/Labels";
import { Buffer } from "buffer";
import SampleImagePlaceOrder from "../../Assets/Images/samplepopup_placeholder.jpg";

const FreeSamplesPopup = (props: any) => {
  const dispatch = useDispatch();
  const formResponse = useSelector((state: any) => state.getCustomFormResponse);
  const submitFormResponse = useSelector(
    (state: any) => state.submitSampleFormResponse
  );
  const [colorPallets, setColorPallets] = useState([]);
  const [data, setData] = useState([]);
  const [currentState, setCurrentState] = useState(1);
  const [selectedColors, setSelectedColors] = useState<any[]>([]);

  useEffect(() => {
    if (props.show) {
      dispatch(getCustomForm("free_sample_request"));
    }
  }, [props]);

  useEffect(() => {
    if (formResponse && formResponse.type === GET_CUSTOM_FORM_SUCCESS) {
      const dataR = unescape(formResponse.data.form_json);
      setData(JSON.parse(dataR));
      const labels = JSON.parse(dataR)[0][0]
        ?.values.filter((data: any) => data)
        .map((v: any) => v.value.toLowerCase());
      const dataD = labels
        .filter((data: any) => data)
        .map((l: string) => {
          const ll = JSON.parse(dataR)[0][0]?.values.find(
            (v: any) => v.value.toLowerCase() === l
          );
          const d = JSON.parse(dataR)[0]?.find(
            (dd: any) => dd.name === l.replace(/\s+/g, "")
          );
          return {
            label: ll.label,
            values: d?.values,
            description: d?.label,
            newValue: ll.value,
          };
        });
      setColorPallets(dataD);
    }
  }, [formResponse]);

  useEffect(() => {
    if (selectedColors.length > 8) {
      showToast(WARNING, "You have reached the maximum (8) of free samples");
    }
  }, [selectedColors]);

  useEffect(() => {
    if (submitFormResponse) {
      if (submitFormResponse.type === SUBMIT_SAMPLE_FORM_SUCCESS) {
        setSelectedColors([]);
        setCurrentState(1);
        setData([]);
        setColorPallets([]);
        // props.show = false;
        props.onHide();
      }
    }
  }, [submitFormResponse]);

  const getColorCode = (c: any) => {
    if (c.image === "") {
      return <img src={SampleImagePlaceOrder} alt={c.label} />;
    }
    return c.image?.includes("#") ? (
      <span style={{ background: `${c.image}` }} />
    ) : c?.image ? (
      <img
        src={`${process.env.REACT_APP_BASE_MEDIA_URL}attribute/swatch${c?.image}`}
        alt={c.label}
      />
    ) : (
      c.label
    );
  };

  const onSelectColors = (key: string, c: any, cc: any) => {
    const sColor = selectedColors
      .filter((data: any) => data)
      .map((s) => s.value)
      .find((s) => s === c.value);
    if (selectedColors && sColor) {
      setSelectedColors(selectedColors.filter((s) => s.value !== c.value));
    } else {
      if (selectedColors.length < 8) {
        c["key"] = key;
        c["newValue"] = cc.newValue;
        c["newLabelDescription"] = cc.description;
        setSelectedColors([...selectedColors, c]);
      }
    }
  };

  const submitForm = (
    contactForm: any,
    firstname: string,
    lastname: string,
    email: string,
    newCustom: any
  ) => {
    const options = Array.from(
      new Set(selectedColors.filter((data: any) => data).map((s) => s.key))
    );
    const newOptions = Array.from(
      new Set(
        selectedColors
          .filter((data: any) => data)
          .map((s) => {
            return { newValue: s.newValue, description: s.newLabelDescription };
          })
      )
    );
    const formjson: any = { ...contactForm, tabsradio: options };
    const customFormjson = { ...newCustom, tabsradio: options };
    options.forEach((k) => {
      formjson[k] = selectedColors
        .filter((c) => c["key"] === k)
        .map((c) => c.value);
    });
    newOptions.forEach((k) => {
      const keyy = selectedColors
        .filter((c) => c["newValue"] === k.newValue)
        .map((c) => c.label);
      customFormjson[k.newValue] = {
        value: keyy.join(","),
        label: k.description,
      };
    });
    const encodedString = Buffer.from(JSON.stringify(formjson)).toString(
      "base64"
    );
    const encodedString2 = Buffer.from(JSON.stringify(customFormjson)).toString(
      "base64"
    );
    dispatch(
      submitSampleForm(
        encodedString,
        encodedString2,
        firstname,
        lastname,
        email
      )
    );
  };
  return (
    <Modal
      {...props}
      centered
      className={`freesample-popup ${currentState === 2 ? "step2" : ""} `}
    >
      <Modal.Header closeButton>
        <Modal.Title className="tm">Order Free Fabric Sample</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submitFormResponse &&
          submitFormResponse.type === SUBMIT_SAMPLE_FORM_ERROR && (
            <p className="text-danger">{submitFormResponse.payload}</p>
          )}
        {currentState === 1 && (
          <FreeSampleColorForm
            setCurrentState={setCurrentState}
            colorPallets={colorPallets}
            onSelectColors={onSelectColors}
            getColorCode={getColorCode}
            selectedColors={selectedColors}
          />
        )}
        {currentState === 2 && (
          <FreeSampleUserForm
            data={data}
            setCurrentState={setCurrentState}
            submitContactForm={submitForm}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FreeSamplesPopup;
