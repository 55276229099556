/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import BlogListingSkl from "./BlogListingSkl";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogListAction,
  getCMSBlogAction,
  searchBlogPostAction,
} from "../../Redux/Pages/Blog/BlogPageAction";
import {
  BLOG_PAGE_CMS_SUCCESS,
  BLOG_PAGE_GET_CATEGORIES_SUCCESS,
  BLOG_PAGE_GET_RECENT_POST_SUCCESS,
  BLOG_PAGE_SUCCESS,
  SEARCH_BLOG_SUCCESS,
} from "../../Redux/Pages/Blog/BlogPageTypes";
import BlogBanner from "./BlogBanner";
import BlogList from "./BlogList";
import SideBlog from "./SideBlog";
import { useLocation } from "react-router";
import PaginatedItems from "../Common/PaginatedItems/PaginatedItems";

const BlogPage = () => {
  const breadSc = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "blog.html",
      label: "Blog",
    },
  ];

  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [cmsData, setCMSData] = useState("");
  const [sideBlog, setSideBlog] = useState<any>({
    categoryList: [],
    recentPost: [],
  });
  const [data, setData] = useState<any>([]);
  const [isCategorized, setCategorized] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const blogResponse = useSelector((state: any) => state?.blogListResponse);
  const [isSearch, setIseSearch] = useState(false);
  useEffect(() => {
    dispatch(getCMSBlogAction());
    if (location && !location.search) {
      dispatch(
        getBlogListAction({
          page: 1,
          type: "ALL",
        })
      );
    }
    return () => {
      dispatch({
        type: SEARCH_BLOG_SUCCESS,
        payload: {
          type: SEARCH_BLOG_SUCCESS,
          data: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (blogResponse.type === BLOG_PAGE_GET_CATEGORIES_SUCCESS) {
      setSideBlog({
        ...sideBlog,
        categoryList: blogResponse?.data?.amBlogCategories?.items,
      });
    }
    if (blogResponse.type === BLOG_PAGE_GET_RECENT_POST_SUCCESS) {
      setSideBlog({
        ...sideBlog,
        recentPost: blogResponse?.data?.amBlogRecentPostsWidget?.items,
      });
    }
    if (blogResponse.type === BLOG_PAGE_CMS_SUCCESS) {
      setCMSData(blogResponse?.data?.content);
    }
    if (blogResponse.type === BLOG_PAGE_SUCCESS) {
      setLoading(false);
      setData(blogResponse?.data?.amBlogPosts);
    }
    if (blogResponse.type === SEARCH_BLOG_SUCCESS) {
      setLoading(false);
      setData(blogResponse.data);
    }
  }, [blogResponse]);

  useEffect(() => {
    if (sideBlog.categoryList && sideBlog.categoryList.length > 0) {
      checkCategoryUrlKeyExist();
    }
  }, [sideBlog.categoryList]);

  useEffect(() => {
    if (location && location.search) {
      setIseSearch(true);
      dispatch(searchBlogPostAction(decodeURI(location.search.slice(1))));
    } else {
      setIseSearch(false);
      checkCategoryUrlKeyExist();
    }
  }, [location]);

  useEffect(() => {
    if (!isCategorized && !location.pathname?.split("/")[3] && !isSearch) {
      setLoading(true);
      setCategorized(false);
      dispatch(
        getBlogListAction({
          page: 1,
          type: "ALL",
        })
      );
    }
  }, [isCategorized, isSearch]);

  const checkCategoryUrlKeyExist = () => {
    if (location.pathname?.split("/")[3] && sideBlog.categoryList.length > 0) {
      const categoryId = sideBlog.categoryList.find(
        (c: any) =>
          c.url_key === location.pathname.split("/")[3].split(".html")[0]
      )?.category_id;
      setCategoryId(categoryId);
      if (categoryId) {
        setCategorized(true);
        dispatch(
          getBlogListAction({
            page: 1,
            type: "CATEGORY",
            categoryId,
          })
        );
      } else {
        setCategorized(false);
      }
    } else {
      setCategorized(false);
    }
  };

  const setCurrentItems = ({ start, end, nextPage }: any) => {
    if (start) {
      const obj = {
        page: nextPage,
        type: isCategorized ? "CATEGORY" : "ALL",
        categoryId,
      };
      dispatch(getBlogListAction(obj));
    }
  };

  return (
    <>
      {!loading && data ? (
        <div className="blog-container">
          <Breadcrumbs value={breadSc} />
          <BlogBanner cmsData={cmsData} />
          {location.search && (
            <h6 className="section-title caps-on mb-2">
              Search results for: '{decodeURI(location.search.slice(1))}'
            </h6>
          )}
          <div className="bl-contentwrap">
            <BlogList data={data.items} />
            <SideBlog
              recentPost={sideBlog.recentPost}
              categoryList={sideBlog.categoryList}
            />
          </div>
          {data && data.items && data?.all_post_size > data.items.length && (
            <PaginatedItems
              itemsPerPage={data.items.length}
              totalCount={data?.all_post_size}
              onItemChange={(e: any) => setCurrentItems(e)}
            />
          )}
        </div>
      ) : (
        <BlogListingSkl />
      )}
    </>
  );
};

export default BlogPage;
