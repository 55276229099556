/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  IAddressResponse,
  // IAddressSearch,
} from "../../../Interfaces/FetchifyAddress.interface";
import {
  // addressSearchLookupAction,
  createAddress,
  pincodeLookupAction,
  updateAddress,
} from "../../../Redux/Auth/AuthAction";
import {
  ADDRESS_SEARCH_LOOKUP_FAILED,
  ADDRESS_SEARCH_LOOKUP_SUCCESS,
  POSTCODE_LOOKUP_FAILED,
  POSTCODE_LOOKUP_SUCCESS,
} from "../../../Redux/Auth/AuthType";
import {
  setBillingAddress,
  setShippingAddress,
} from "../../../Redux/Pages/Checkout/CheckoutAction";
import { isAuth } from "../../../Utility/Utilities";
import validate from "../../../Utility/Validation";
import CustomButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGroups from "../../Common/SelectGroups/SelectGroups";


declare global {
  interface Window {
    cp_obj: string;
  }
}

const AddEditAddressPopup = (props: {
  show: boolean;
  isEdit: boolean;
  address: any;
  onHide: any;
  isBillingAddress?: boolean;
  setBillingAddress?: any;
}) => {
  const countries = [{ title: "United Kingdom", value: "United Kingdom" }];

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const INITIAL_FORM: any = {
    id: { value: null },
    firstname: {
      value: props.address?.firstname,
      validation: ["required"],
      errors: ["First name is required."],
    },
    lastname: {
      value: props.address?.lastname,
      validation: ["required"],
      errors: ["Last name is required."],
    },
    country: {
      value: countries[0],
      validation: ["required"],
      errors: ["Country is required."],
    },
    city: {
      value: props.address?.city,
      validation: ["required"],
      errors: ["City is required"],
    },
    state: {
      value: props.address?.region?.region,
      validation: [],
      errors: [null],
    },
    postcode: {
      value: props.address?.postcode,
      validation: ["postcode", "required"],
      errors: [
        "Provided Zip/Postal Code seems to be invalid. Example: AB12 3CD; A1B 2CD; AB1 2CD; AB1C 2DF; A12 3BC; A1 2BC. If you believe it is the right one you can ignore this notice.",
        "Postcode is required",
      ],
    },
    street: {
      value: props.address?.street.join(),
      validation: ["required"],
      errors: ["Street is required"],
    },
    company: {
      value: props.address?.company,
      validation: [],
      errors: [],
    },
    telephone: {
      value: props.address?.telephone,
      validation: ["number", "required"],
      errors: [
        "Please enter valid Telephone Number.",
        "Telephone Number is required.",
      ],
    },
  };
  const [form, setForm] = useState<any>(INITIAL_FORM);
  const [error, setError] = useState({
    firstname: null,
    lastname: null,
    city: null,
    street: null,
    postcode: null,
    telephone: null,
    company: null,
    state: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);
  // const [openSuggestion, setOpenedSuggestion] = useState(false);
  const [addressData, setAddressData] = useState<any>();
  // const [addressListData, setAddressListData] = useState<IAddressSearch[]>([]);
  const [addressLookupLoading, setAddressLookupLoading] = useState(false);

  const pincodeLookupResponse = useSelector(
    (state: any) => state.pincodeLookupResponse
  );

  const addressListResponse = useSelector(
    (state: any) => state.addressSearchResponse
  );

  useEffect(() => {
    if (addressListResponse.type === ADDRESS_SEARCH_LOOKUP_SUCCESS) {
      setAddressLookupLoading(false);
      // setAddressListData(addressListResponse.data);
    } else if (addressListResponse.type === ADDRESS_SEARCH_LOOKUP_FAILED) {
      setAddressLookupLoading(false);
      // setAddressListData([]);
    }
  }, [addressListResponse]);

  useEffect(() => {
    return () => {
      dispatch({
        type: POSTCODE_LOOKUP_SUCCESS,
        payload: {
          type: POSTCODE_LOOKUP_SUCCESS,
          data: null,
        },
      });
      dispatch({
        type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
        payload: {
          type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
          data: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (
      pincodeLookupResponse &&
      pincodeLookupResponse.type === POSTCODE_LOOKUP_SUCCESS
    ) {
      setAddressLookupLoading(false);
      setAddressData(pincodeLookupResponse.data);
    } else if (
      pincodeLookupResponse &&
      pincodeLookupResponse.type === POSTCODE_LOOKUP_FAILED
    ) {
      setAddressLookupLoading(false);
      setAddressData(null);
      setIsFormValidated(true);
      setError({
        ...error,
        postcode: pincodeLookupResponse.data,
      });
    }
  }, [pincodeLookupResponse]);

  const onSubmit = () => {
    setLoading(true);
    const vForm = validate(form);
    if (vForm.isValidated === true) {
      const payload: any = {
        isBillingAddress: props.isBillingAddress,
      };
      Object.keys(form).forEach((k) => {
        payload[k] = form[k].value;
      });
      setIsFormValidated(false);
      if (props.isEdit) {
        payload.id = props.address?.id;
        if (isAuth()) {
          dispatch(updateAddress(payload));
        } else {
          delete payload.country;
          payload.region = { region: payload.state };
          if (!props.isBillingAddress) {
            dispatch(setShippingAddress(payload));
          } else {
            props.setBillingAddress(false);
            dispatch(setBillingAddress(payload));
          }
        }
      } else {
        if (isAuth()) {
          dispatch(createAddress(payload));
        } else {
          if (props.isBillingAddress) {
            props.setBillingAddress(false);
            dispatch(setBillingAddress({ address: payload }));
          } else {
            dispatch(setShippingAddress(payload));
          }
        }
      }
    } else {
      const mesage = { ...vForm?.errors };
      setTimeout(() => setLoading(false), 3000);
      setError(mesage);
      setLoading(false);
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    setIsFormValidated(false);
    setLoading(false);
    dispatch({
      type: POSTCODE_LOOKUP_SUCCESS,
      payload: {
        type: POSTCODE_LOOKUP_SUCCESS,
        data: null,
      },
    });
    dispatch({
      type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
      payload: {
        type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
        data: null,
      },
    });
    if (props && props.address) {
      const frm = Object.assign({}, INITIAL_FORM);
      if (Object.keys(props.address)) {
        Object.keys(props.address).forEach((k) => {
          if (frm[k]) {
            frm[k].value = props.address[k];
          }
        });
      }
      setForm(frm);
    } else if (props && !props.address) {
      setForm(INITIAL_FORM);
    }
  }, [props]);

  const setData = (field: string, value: string) => {
    let details = Object.assign({}, form);
    details[field].value = value;
    setForm({ ...details });
  };


  const onPincodeLookup = () => {
    const currentForm = {
      postcode: form.postcode,
    };
    let vForm = validate(currentForm);
    if (form.postcode.value && form.postcode.value.trim() !== "") {
      if (vForm.errors && Object.keys(vForm.errors).length > 0) {
        const message = { ...vForm?.errors };
        setTimeout(() => setLoading(false), 3000);
        setError(message);
        setLoading(false);
        setIsFormValidated(true);
      } else {
        setAddressLookupLoading(true);
        setIsFormValidated(false);
        setError({
          firstname: null,
          lastname: null,
          city: null,
          street: null,
          postcode: null,
          telephone: null,
          company: null,
          state: null,
        });
        dispatch(pincodeLookupAction(form.postcode.value.trim()));
      }
    } else {
      if (vForm.errors && Object.keys(vForm.errors).length > 0) {
        const message = { ...vForm?.errors };
        setTimeout(() => setLoading(false), 3000);
        setError(message);
        setLoading(false);
        setIsFormValidated(true);
      }
    }
  };

  const onSelectAddress = (e: any) => {
    if (e.target.value && e.target.value !== "") {
      let address: IAddressResponse = JSON.parse(e.target.value);
      const currentForm = Object.assign({}, form);
      currentForm.company.value = address.organisation_name;
      currentForm.street.value = `${
        address.line_1 && address.line_1 !== "" ? address.line_1 + ", " : ""
      }${address.line_2 && address.line_2 !== "" ? address.line_2 + ", " : ""}${
        address.line_3 && address.line_3 !== "" ? address.line_3 : ""
      }`;
      currentForm.state.value = addressData.traditional_county;
      currentForm.city.value = addressData.town;
      setForm(currentForm);
    }
  };

  // const onSelectAddressList = (add: IAddressSearch) => {
  //   if (add) {
  //     setOpenedSuggestion(false);
  //     const currentForm = Object.assign({}, form);
  //     if (add.labels && add.labels.length > 0) {
  //       currentForm.postcode.value = add.labels[0];
  //       if (add.labels.length > 1) {
  //         currentForm.street.value = add.labels[1];
  //       }
  //     }
  //     setForm(currentForm);
  //   }
  // };

  return (
    <Modal show={props.show} centered className="address-popup">
      <Modal.Header closeButton onClick={() => props.onHide()}>
        <Modal.Title className="tm">
          {props.isEdit ? "Edit" : "Add New"} Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroups
          className="fname"
          type="text"
          value={form?.firstname?.value || ""}
          onChange={(e: any) => setData("firstname", e.target.value)}
          id="firstname"
          error={
            isFormValidated && error["firstname"] ? error["firstname"] : null
          }
          label="First Name*"
         
        />
        <InputGroups
          className="lname"
          type="text"
          value={form?.lastname?.value || ""}
          onChange={(e: any) => setData("lastname", e.target.value)}
          id="lastname"
          error={
            isFormValidated && error["lastname"] ? error["lastname"] : null
          }
          label="Last Name*"
        />
        <SelectGroups
          values={countries}
          label="Country*"
          selectedValue={countries[0].value}
        />
        <div
          className={
            addressData && addressData.delivery_points
              ? "postcode-wrap with-address"
              : "postcode-wrap"
          }
        >
          <InputGroups
            label="Postcode*"
            type="text"
            value={form?.postcode?.value || ""}
            onChange={(e: any) => setData("postcode", e.target.value)}
            id="postcode"
            error={
              isFormValidated && error["postcode"] ? error["postcode"] : null
            }
          />
          <CustomButton
            disabled={addressLookupLoading}
            onClick={onPincodeLookup}
            bg={"fill"}
          >
            Find Address
          </CustomButton>

          {addressData && addressData.delivery_points ? (
            <SelectGroups
              values={[
                {
                  title: "Select Your Address",
                  value: "",
                },
                ...addressData.delivery_points?.map((d: IAddressResponse) => {
                  return {
                    value: JSON.stringify(d),
                    title: `${
                      d.organisation_name && d.organisation_name !== ""
                        ? d.organisation_name + ","
                        : ""
                    }${d.line_1 && d.line_1 !== "" ? d.line_1 + "," : ""}${
                      d.line_2 && d.line_2 !== "" ? d.line_2 + "," : ""
                    }${d.line_3 && d.line_3 !== "" ? d.line_3 + "," : ""}`,
                  };
                }),
              ]}
              label=""
              onSelect={onSelectAddress}
              selectedValue={addressData.delivery_points[0].dps}
            />
          ) : null}
        </div>
        <InputGroups
          className="company"
          label="Company"
          type="text"
          value={form?.company?.value || ""}
          onChange={(e: any) => setData("company", e.target.value)}
          id="company"
          error={isFormValidated && error["company"] ? error["company"] : null}
        />
        {/* <div className="input-groups address-search">
          <InputGroups
            className="addr-search"
            placeholder="Start with post code or street"
            label="Address Search"
            onKeyDown={(e: any) => {
              if (e.target.value && e.target.value.trim() !== "") {
                setOpenedSuggestion(true);
                dispatch(addressSearchLookupAction(e.target.value.trim()));
              }
            }}
          />
          {openSuggestion && addressListData && addressListData.length > 0 ? (
            <div className="sub-dropdown">
              {addressListData.map((add: IAddressSearch) => {
                return (
                  <li
                    key={add?.labels?.join(" ")}
                    onClick={() => onSelectAddressList(add)}
                  >
                    {add?.labels?.join(" ")}
                  </li>
                );
              })}
            </div>
          ) : null}
        </div> */}
        <InputGroups
          className="full-street"
          label="Street Address*"
          type="text"
          value={form?.street?.value || ""}
          onChange={(e: any) => setData("street", e.target.value)}
          id="street"
          error={isFormValidated && error["street"] ? error["street"] : null}
        />
        <InputGroups
          className="state"
          label="State/Province"
          type="text"
          value={form?.state?.value || ""}
          onChange={(e: any) => setData("state", e.target.value)}
          id="state"
          error={isFormValidated && error["state"] ? error["state"] : null}
        />
        <InputGroups
          className="city"
          label="City*"
          type="text"
          value={form?.city?.value || ""}
          onChange={(e: any) => setData("city", e.target.value)}
          id="city"
          error={isFormValidated && error["city"] ? error["city"] : null}
        />
        <InputGroups
          className="phone"
          type="tel"
          label="Phone Number*"
          value={form?.telephone?.value || ""}
          onChange={(e: any) => setData("telephone", e.target.value)}
          id="telephone"
          error={
            isFormValidated && error["telephone"] ? error["telephone"] : null
          }
        />
        <div className="action-bar">
          <CustomButton isLoading={loading} onClick={onSubmit} bg="fill">
            save
          </CustomButton>
          <CustomButton onClick={() => props.onHide()}>cancel</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditAddressPopup;
