export const LABEL_CREATE_ACCOUNT = "Create Account";
export const LABEL_FILL_INFO_BELOW = "Please fill in the information below.";
export const LABEL_SIGNUP_NEWSLETTER = "Sign up for newsletter";
export const LABEL_BY_CREATE_YOUR = "By creating an account, you agree to our";
export const LABEL_TERMS_AND_CONDITION = "Terms & Conditions";
export const LABEL_PRIVACY_POLICY = " Privacy Policy.";
export const LABEL_ALREADY_HAVE_AN_ACCOUNT = "Already have an account?";
export const LABEL_LOGIN = "Login";
export const LABEL_ENTER_EMAIL_PASS = "Please enter your email and password.";
export const LABEL_FORGOT_PASSWORD = "Forgot password?";
export const LABEL_CREATE_ONE = "Create one";
export const LABEL_DONT_HAVE_ACCOUNT = "Don't have an account?";
export const LABEL_MY_ACCOUNT = "My Account";
export const LABEL_LOGOUT = "Logout";
export const LABEL_CANCEL = "Cancel";
export const LABEL_LOGOUT_TEXT = "Are you sure you want to log out now..?";
export const LABEL_CONTACT_US = "Contact Us";
export const LABEL_FINANCE = "Finance";

export const KEY_USER_TOKEN = "token";
export const KEY_USER = "user";


export const UN_AUTHORIZED_TEXT = "graphql-authorization";
export const CURRENT_USER_UNAUTHORIZED =
  "The current customer isn't authorized.";
export const USER_REGISTER_SUCCESS = "User registered successfully";
export const USER_REGISTER_FAIL = "User registration fails";

export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const DEFAULT = "default";

export const LABEL_CONFIGURABLEPRODUCT = "ConfigurableProduct";
export const LBAEL_FROM_PRICE = "from"

export const PAYMENT_ACTIONS = {
  CREATE_PAYPAL_EXPRESS_TOKEN :"CREATE_PAYPAL_EXPRESS_TOKEN",
  CREATE_RAPYD_CHECKOUT_ID:"CREATE_RAPYD_CHECKOUT_ID",
  SET_GUEST_EMAIL_ADDRESS:"SET_GUEST_EMAIL_ADDRESS",
  INTIATED:"PAYMENT_INTIATED",
  PAYPAL_EXPRESS_PLACE_ORDER:"PAYPAL_EXPRESS_PLACE_ORDER",
  RAPYD_CHECKOUT_ID_GENERATED:"RAPYD_CHECKOUT_ID_GENERATED",
  RAPYD_ON_CHECKOUT_PAYMENT_SUCCESS:"RAPYD_ON_CHECKOUT_PAYMENT_SUCCESS",
  RAPYD_PAYMENT_RESPONSE:"RAPYD_PAYMENT_RESPONSE",
  RAPYD_CHECKOUT_FAILURE:"RAPYD_CHECKOUT_FAILURE",
  RAPYD_CHECKOUT_PAYMENT_FAILURE:"RAPYD_CHECKOUT_PAYMENT_FAILURE",
  RAPYD_PLACE_ORDER_RESPONSE:"RAPYD_PLACE_ORDER_RESPONSE",
  RAPYD_PLACE_ORDER_ERROR:"RAPYD_PLACE_ORDER_ERROR",
  ORDER_SUCCESS:"ORDER_SUCCESS",
  ORDER_FAILED:"ORDER_FAILED",
  SET_PAYMENT_METHOD_ON_CART:"SET_PAYMENT_METHOD_ON_CART",
  PAYPAL_EXPRESS_REDIRECT_BACK_URL:"PAYPAL_EXPRESS_REDIRECT_BACK_URL",
  STRIPE_CREATE_PAYMENT_METHOD_ERROR:"STRIPE_CREATE_PAYMENT_METHOD_ERROR",
  STRIPE_CREATE_PAYMENT_METHOD_SUCESS:"STRIPE_CREATE_PAYMENT_METHOD_SUCESS",
  STRIPE_PLACE_ORDER_ERROR:"STRIPE_PLACE_ORDER_ERROR",
  STRIPE_PLACE_ORDER_SUCESS:"STRIPE_PLACE_ORDER_SUCESS",
  STRIPE_AUTHENTICATION_ERROR:"STRIPE_AUTHENTICATION_ERROR",
  STRIPE_PLACE_ORDER_AFTER_AUTHENTICATION:"STRIPE_PLACE_ORDER_AFTER_AUTHENTICATION",
  VERIFY_SPLASH_KEY_FAILED : "VERIFY_SPLASH_KEY_FAILED",
  VERIFY_SPLASH_KEY_CALLBACK_FAILED:"VERIFY_SPLASH_KEY_CALLBACK_FAILED",
  DIVIDE_BUY_ORDER_SUCCESS:"DIVIDE_BUY_ORDER_SUCCESS",
  DIVIDE_BUY_SPLASH_KEY_FAILED:"DIVIDE_BUY_SPLASH_KEY_FAILED",
  DIVIDE_BUY_SPLASH_KEY_SUCCESS:"DIVIDE_BUY_SPLASH_KEY_SUCCESS",
  DIVIDE_BUY_SPLASH_KEY_REQUEST:"DIVIDE_BUY_SPLASH_KEY_REQUEST",
  VERIFY_SPLASH_KEY_REQUEST:"VERIFY_SPLASH_KEY_REQUEST"
}

export const PAYMENT_METHOD = {
  PAYPAL:"Paypal Express",
  RAPYD:"Rapyd Payment",
  OTHERS:"Others",
  STRIPE:"STRIPE",
  DIVIDE_BUY:"DIVIDE_BUY"
}

export const ERROR_LOGS = {
  TOAST_ERROR: "Toast Error",
}

export const FLOWBOX_CONTAINER = {
  HOME_PAGE: "home-page-flow",
  CATEGORY_PAGE: "category-flow",
  PRODUCT_DETAILS: "product-details-flow",
  COMMUNITY_FLOW:"community-flow",
  REVIEW_WIDGET : "flowbox-review-widget",
  MINIMAL_START_WIDGET : "flowbox-minimal-list-widget"
}
export const STRIPE_CARD_ERROR = {
  CARD_NUMBER_ERROR: "Your card number is incomplete.",
  CARD_EXPIRY_ERROR: "Your card's expiry date is incomplete.",
  CARD_CVV_ERROR: "Your card's security code is incomplete."
}

export const AUTHENTICATION_REQUIRED = "Authentication Required";

export const STRIPE_AUTHENTICATION_STATUS = {
    SUCCEEDED : "SUCCEEDED"
}

export const DIVIDE_BUY_ORDER_STATUS = {
   SUCCESS:"success",
   CANCEL:"cancel" 
}