import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import Sidebar from "../Sidebar/Sidebar";

const WishlistSkl = () => {
  return (
    <div className="account-container">
      <div className="ll hide-991">
        <Link to={MY_DETAIL} className="acc-backbtn ll">
          My Account
        </Link>
      </div>
      <div className="account-wrap">
        <Sidebar></Sidebar>
        <div className="acc-rightwrap">
          <div className="wishlist-wrap skl">
            <h6 className="caps-on">
              <Skeleton />
            </h6>
            <ul className="list-items">
              <li className="item">
                <div className="item-img">
                  <Skeleton />
                </div>
                <div className="item-info">
                  <div className="item-name skl">
                    <Skeleton />
                  </div>
                  <div className="price-wrapper skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                  <div className="wish-action skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="item-img">
                  <Skeleton />
                </div>
                <div className="item-info">
                  <div className="item-name skl">
                    <Skeleton />
                  </div>
                  <div className="price-wrapper skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                  <div className="wish-action skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="item-img">
                  <Skeleton />
                </div>
                <div className="item-info">
                  <div className="item-name skl">
                    <Skeleton />
                  </div>
                  <div className="price-wrapper skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                  <div className="wish-action skl">
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistSkl;
