import { getOrderSuccessPayload } from "../../../Constants/Api";
import { ERROR, PAYMENT_ACTIONS } from "../../../Constants/Labels";
import { createLogs } from "../../../Services/axios.service";
import {  getOrdersuccess } from "../../../Services/graphQL.service";
import { ORDERS_SUCCESS_FAILED, ORDERS_SUCCESS_REQUEST, ORDERS_SUCCESS_SUCCESS } from "./OrdersTypes"

export const getOrdersSuccessAction = (oid: string) => {
  return (async (dispatch: any) => {
    dispatch({ type: ORDERS_SUCCESS_REQUEST })
    try {
      const { data: response } = await getOrdersuccess(oid);
      createLogs({
      action: PAYMENT_ACTIONS.ORDER_SUCCESS,
      payment_method: null,
      api_response: JSON.stringify(response),
      api_request: getOrderSuccessPayload(oid),
      amount: null,
      status:response.errors ? ERROR : null
    });
      
      const data = response?.data;
      if (data) {
        dispatch({
          type: ORDERS_SUCCESS_SUCCESS,
          payload: {
            type: ORDERS_SUCCESS_SUCCESS,
            data
          },
        })
      }
      else {
        dispatch({
          type: ORDERS_SUCCESS_FAILED,
          payload: {
            type: ORDERS_SUCCESS_FAILED,
            message: response.errors[0]?.message
          },
        })
      }
    } catch (error) {
       createLogs({
        action: PAYMENT_ACTIONS.ORDER_FAILED,
        payment_method: null,
        api_response: JSON.stringify(error),
        api_request: getOrderSuccessPayload(oid),
        amount: null,
        status:ERROR
      });
  
      dispatch({
        type: ORDERS_SUCCESS_FAILED,
        payload: { type: ORDERS_SUCCESS_FAILED, message: error },
      })
    }
  })
}