/* eslint-disable react-hooks/exhaustive-deps */
import { HOME, MY_ORDERS_PAGE } from "../../../Constants/Route";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import OrderDetailSkl from "./OrderDetailSkl";
import { useParams } from "react-router";
import { getOrderDetail } from "../../../Redux/General/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { MY_ORDERS_DETAILS_SUCCESS } from "../../../Redux/General/GeneralType";
import moment from "moment";

const OrderDetail = () => {
  const [breadcrumb, setBreadcrumb] = useState([
    {
      path: HOME,
      label: "Home",
    },
    {
      path: MY_ORDERS_PAGE.slice(1),
      label: "My Orders",
    },
  ]);

  const [loading, setloading] = useState(true);

  const [orderData, setorderData] = useState<any>();

  const { orderId } = useParams();

  const response = useSelector((state: any) => state.myOrdersResponse);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetail(orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (response && response.type === MY_ORDERS_DETAILS_SUCCESS) {
      setorderData(response?.data);
      const currentscrum = Object.assign([], breadcrumb);
      if (orderId) {
        if (currentscrum.length <= 2) {
          currentscrum.push({
            label: orderId,
            path: "",
          });
          setBreadcrumb(currentscrum);
        }
      }
      setloading(false);
    }
  }, [response]);

  return (
    <>
      {loading ? (
        <OrderDetailSkl />
      ) : (
        <>
          <div className="orderdetail-wrap">
            <div className="account-container">
              <Breadcrumbs value={breadcrumb}></Breadcrumbs>
              {orderData?.items.map((data: any, index: any) => (
                <div className="ord-sections" key={index}>
                  <div className="ord-topinfo">
                    <div className="top-info">
                      <h1 className="caps-on">Order Details</h1>
                      <p className="ls oid-date">
                        Placed On{" "}
                        {moment(data.order_date).format("D MMMM, YYYY")}
                      </p>
                      <p className="ls oid">Order #{data.increment_id}</p>
                    </div>
                  </div>
                  <div className="ord-details">
                    {data?.items.map((data1: any, index1: any) => (
                      <div className="inner-itemwrap" key={index1}>
                        <div className="side-img">
                          <img
                            src={data1?.thumbnail?.thumbnail?.url}
                            alt={data1?.thumbnail?.thumbnail?.label}
                          />
                        </div>
                        <div className="side-details">
                          <Link
                            className="ll"
                            to={"/" + data1.product_url_key + ".html"}
                            title={data1.product_name}
                          >
                            {data1.product_name}
                          </Link>
                          <div className="o-attr">
                            <span className="bs">
                              £
                              {Number(data1.product_sale_price.value).toFixed(
                                2
                              )}
                            </span>
                            {data1.selected_options.map(
                              (data2: any, index2: any) => (
                                <span className="bs" key={index2 + index1}>
                                  {data2.value}
                                </span>
                              )
                            )}
                            <span className="bs">
                              Qty {data1.quantity_ordered}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="ord-additional">
                      <div className="add-address">
                        <p className="tm intitle">Delivery Address</p>
                        <span className="ll">
                          {data?.shipping_address?.firstname +
                            " " +
                            data?.shipping_address?.lastname}
                        </span>
                        {data?.shipping_address?.street?.map(
                          (data4: any, index4: any) => (
                            <address className="bm">
                              {data4}, {data?.shipping_address?.region},{" "}
                              {data?.shipping_address?.city}
                            </address>
                          )
                        )}
                        <a
                          className="ll"
                          title="Mobile No."
                          href={"tel:" + data?.shipping_address?.telephone}
                        >
                          {data?.shipping_address?.telephone}
                        </a>
                      </div>
                      <div className="add-payment">
                        <p className="tm intitle">Payment Method</p>
                        {data?.payment_methods.map(
                          (data3: any, index3: any) => (
                            <span className="bm" key={index3}>
                              {data3.name}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ord-summary">
                    <p className="tm">Order Summary</p>
                    <div className="total-table">
                      <div className="sub-col total">
                        <p className="stitle bm">Subtotal</p>
                        <p className="svalue bm">
                          £{Number(data?.total?.subtotal?.value).toFixed(2)}
                        </p>
                      </div>
                      <div className="sub-col delivery">
                        <p className="stitle bm">
                          Delivery ({data?.shipping_method})
                        </p>
                        <p className="svalue bm">
                          £
                          {Number(
                            data?.total?.shipping_handling?.total_amount?.value
                          ).toFixed(2)}
                        </p>
                      </div>
                      {data?.total?.discounts?.length > 0 ? (
                        <>
                          {data?.total?.discounts?.map(
                            (data4: any, index4: any) => (
                              <div className="sub-col delivery">
                                <p className="stitle bm">Discounts</p>
                                <p className="svalue bm" key={index4}>
                                  £{Number(data4?.amount?.value).toFixed(2)}
                                </p>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="sub-col maintotal">
                        <p className="stitle tm">Order Total</p>
                        <p className="svalue tm">
                          £{Number(data?.total?.grand_total?.value).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetail;
