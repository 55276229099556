/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCheckoutDeliveryMethod } from "../../../Redux/Pages/Checkout/CheckoutAction";

const MobCkDeliveryMethod = ({
  cartResponse,
  shipping_addresses,
  mobileState,
  setMobileState,
}: any) => {
  const dispatch = useDispatch();

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });

  const [selectedDeliveryMethod, setDeliveryMethod] = useState(0);

  useEffect(() => {
    if (
      mobileState &&
      mobileState.step === 2 &&
      cartResponse.data?.items?.length > 0
    ) {
      if (mobileState.isClicked) {
        setMobileState({
          ...mobileState,
          loading: true,
          isClicked: false,
          disabled:
            cartResponse?.data?.shipping_addresses[0] &&
            cartResponse?.data?.shipping_addresses[0]
              .available_shipping_methods &&
            cartResponse?.data?.shipping_addresses[0].available_shipping_methods
              .length === 0,
        });
        dispatch(
          setCheckoutDeliveryMethod(
            deliveryMethodData?.available_shipping_methods[
              selectedDeliveryMethod
            ]
          )
        );
      }
    }
  }, [mobileState]);

  useEffect(() => {
    setDeliveryMethodData(shipping_addresses);
    const index = shipping_addresses?.available_shipping_methods?.findIndex(
      (a: any) =>
        a.carrier_code ===
        shipping_addresses?.selected_shipping_method?.carrier_code
    );
    setDeliveryMethod(index > -1 ? index : 0);
  }, [shipping_addresses]);

  // useEffect(() => {
  //   if (
  //     checkoutResponse &&
  //     checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
  //   ) {
  //     setDeliveryMethodData(
  //       checkoutResponse?.data?.cart?.shipping_addresses[0]
  //     );
  //     const index =
  //       checkoutResponse?.data?.cart?.shipping_addresses[0]?.available_shipping_methods?.findIndex(
  //         (a: any) =>
  //           a.carrier_code ===
  //           checkoutResponse?.data?.cart?.shipping_addresses[0]
  //             ?.selected_shipping_method?.carrier_code
  //       );
  //     setDeliveryMethod(index > -1 ? index : 0);
  //   } else if (
  //     checkoutResponse &&
  //     (checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS ||
  //       checkoutResponse.type === CHECKOUT_ADDRESS_SUCCESS)
  //   ) {
  //     setDeliveryMethodData(checkoutResponse?.data?.shipping_addresses[0]);
  //   }
  // }, [checkoutResponse]);

  const [appliedMethod, setAppliedMethod] = useState(false);

  useEffect(() => {
    setAppliedMethod(
      deliveryMethodData?.selected_shipping_method !== null &&
        deliveryMethodData?.selected_shipping_method !== undefined
    );
  }, [deliveryMethodData?.selected_shipping_method]);

  return (
    <div className="ckout-inner">
      <div
        className={
          appliedMethod ? "ckout-top-box s-delivmethod" : "ckout-top-box"
        }
      >
        <p className="tm">Delivery Method</p>
      </div>
      <div className="delivery-box">
        {deliveryMethodData?.available_shipping_methods?.length > 0 ? (
          deliveryMethodData?.available_shipping_methods
            ?.filter((data: any) => data)
            .map((data: any, index1: any) => (
              <>
                <div
                  key={data.carrier_code + index1}
                  className="delivery-method"
                >
                  <div className="rd" key={index1}>
                    <input
                      type="radio"
                      name="delivery-selected"
                      id={data.carrier_code}
                      onChange={() => setDeliveryMethod(index1)}
                      defaultChecked={index1 === selectedDeliveryMethod}
                    />
                    <label className="bm" htmlFor={data.carrier_code}>
                      {data?.amount?.value >= 0
                        ? "£" + Number(data?.amount?.value).toFixed(2)
                        : null}
                    </label>
                  </div>
                  <span className="bm">{data.method_title}</span>
                </div>
              </>
            ))
        ) : (
          <p>Sorry, no quotes are available for this order at this time </p>
        )}
      </div>
    </div>
  );
};

export default MobCkDeliveryMethod;
