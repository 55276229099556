import {
  createPaypalExpressToken,
  generateOrderSplashKey,
  getCartDetail,
  getPaymentInstallment,
  getPaymentMinAmount,
  getRapydCheckoutId,
  onPaypalExpressPlaceOrder,
  onPlaceOrderService,
  orderSplashKeyCallback,
  rapydPlaceOrder,
  stripePlaceOrder,
} from "./../../../Services/graphQL.service";

import {
  AUTHENTICATION_REQUIRED,
  DIVIDE_BUY_ORDER_STATUS,
  INFO,
  PAYMENT_ACTIONS,
  PAYMENT_METHOD,
  SUCCESS,
} from "./../../../Constants/Labels";
import {
  getSessionItem,
  isAuth,
  setSessionItem,
  showToast,
} from "../../../Utility/Utilities";
import { CART_ID, FIRST_NAME, GUEST_EMAIL, LAST_NAME } from "../../../Constants/LocalStorage";
import {
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_DELIVERY_METHOD_REQUEST,
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_FAILED,
  CHECKOUT_GET_DELIVERY_METHOD_REQUEST,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  CHECK_EMAIL_AVAILABLE_SUCCESS,
  CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
  CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST,
  CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS,
  CREATE_RAPYD_CHECKOUT_ID_FAILED,
  CREATE_RAPYD_CHECKOUT_ID_REQUEST,
  CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
  DIVIDEBUY_ORDER_SUCCESS,
  DIVIDEBUY_SPLASH_KEY_FAILED,
  DIVIDEBUY_SPLASH_KEY_REQUEST,
  DIVIDEBUY_SPLASH_KEY_SUCCESS,
  GET_BRAINTREETOKEN_FAILED,
  GET_BRAINTREETOKEN_SUCCESS,
  GET_PAYMENT_MIN_AMOUNT_FAILED,
  GET_PAYMENT_MIN_AMOUNT_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
  PAYMENT_INSTALLMENT_FAILED,
  PAYMENT_INSTALLMENT_REQUEST,
  PAYMENT_INSTALLMENT_SUCCESS,
  RAPYD_ORDER_FAILED,
  RAPYD_ORDER_SUCCESS,
  STRIPE_ORDER_AUTHENTICATION_REQUIRED,
  STRIPE_ORDER_FAILED,
  STRIPE_ORDER_REQUEST,
  STRIPE_ORDER_SUCESS,
  VERIFY_SPLASH_KEY_FAILED,
  VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
  VERIFY_SPLASH_KEY_REQUEST,
  VERIFY_SPLASH_KEY_SUCCESS,
} from "./CheckoutTypes";
import {
  checkCustomerEmail,
  getBrainTreeToken,
  getDeliveryMethods,
  placeOrder,
  setBillingAddressOnCart,
  setCheckoutAddress as setShippingAddresss,
  setCheckoutDelMethod,
  setGuestEmailAddress,
} from "../../../Services/graphQL.service";
import { ERROR } from "../../../Constants/Labels";
import { GET_CART_PAGE_SUCCESS } from "../CartPage/CartPageTypes";
import { createLogs, verifySplashKey } from "../../../Services/axios.service";
import {
  createPaypalExpressTokenPayload,
  generateOrderSplashKeyPayload,
  getCartDetailPayload,
  getRapydCheckoutIdPayload,
  onPaypalExpressPlaceOrderPayload,
  onPlaceOrderPayload,
  placeOrderPayload,
  rapydPlaceOrderPayload,
  setGuestEmailPayload,
  stripePlaceOrderPayload,
} from "../../../Constants/Api";
import OrderDetail from "../../../Components/MyAccount/OrderDetail/OrderDetail";
import { getOrdersSuccessAction } from "../Orders/OrdersAction";
// import { onPlaceOrderPayload } from "../../../Constants/Api";

export const setShippingAddress = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.cartId) {
        if (payload.country) {
          payload.region = { region: payload.country.value };
        }
        payload.customerAddressId = payload.id;
        const { data: response } = await setShippingAddresss(payload);
        if (!isAuth()) {
          setSessionItem(FIRST_NAME ,payload.firstname);
          setSessionItem(LAST_NAME ,payload.lastname);
          payload.address = payload;
        }
        if (response.errors) {
          showToast(ERROR, response.errors[0].message);
        } else {
          dispatch(setBillingAddress(payload));
          showToast(SUCCESS, "The shipping address has set successfully");
          dispatch({
            type: CHECKOUT_ADDRESS_SUCCESS,
            payload: {
              type: CHECKOUT_ADDRESS_SUCCESS,
              data: response.data?.setShippingAddressesOnCart?.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response.data?.setShippingAddressesOnCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const getCheckoutDelvMethod = () => {
  return async (dispatch: any) => {
    dispatch({ type: CHECKOUT_GET_DELIVERY_METHOD_REQUEST });
    try {
      const cartId = getSessionItem(CART_ID);
      const { data: response } = await getDeliveryMethods(cartId);
      const data = response?.data;
      if (data) {
        dispatch({
          type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
          payload: {
            type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
          payload: {
            type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
        payload: { type: CHECKOUT_GET_DELIVERY_METHOD_FAILED, message: error },
      });
    }
  };
};

export const setCheckoutDeliveryMethod = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: CHECKOUT_DELIVERY_METHOD_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.cartId) {
        const { data: response } = await setCheckoutDelMethod(payload);
        if (response.errors) {
          showToast(ERROR, response.errors[0].message);
        } else {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.setShippingMethodsOnCart?.cart,
            },
          });
          dispatch({
            type: CHECKOUT_DELIVERY_METHOD_SUCCESS,
            payload: {
              type: CHECKOUT_DELIVERY_METHOD_SUCCESS,
              data: response?.data?.setShippingMethodsOnCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const getBtreeToken = () => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getBrainTreeToken();
      const data = response?.data;
      if (data) {
        dispatch({
          type: GET_BRAINTREETOKEN_SUCCESS,
          payload: {
            type: GET_BRAINTREETOKEN_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: GET_BRAINTREETOKEN_FAILED,
          payload: {
            type: GET_BRAINTREETOKEN_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_BRAINTREETOKEN_FAILED,
        payload: { type: GET_BRAINTREETOKEN_FAILED, message: error },
      });
    }
  };
};


export const onPlaceOrder = (payload: any) => {
  return async (dispatch: any) => {
    const cart_id = getSessionItem(CART_ID);
    try {
      payload.cartId = cart_id;
      payload.cart_id = cart_id;
      if (!isAuth()) {
        const { data: guestResponse } = await setGuestEmailAddress(
          getSessionItem(GUEST_EMAIL),
          payload.cartId
        );
        if (guestResponse.errors) {
          showToast(ERROR, guestResponse?.errors[0]?.message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
            },
          });
        }
        createLogs({
          action: PAYMENT_ACTIONS.SET_GUEST_EMAIL_ADDRESS,
          payment_method: payload?.method_code,
          api_response: JSON.stringify(guestResponse),
          api_request: setGuestEmailPayload(
            getSessionItem(GUEST_EMAIL),
            cart_id
          ),
          status: guestResponse.errors ? ERROR : null,
        });
      }
      const { data: responseData } = await placeOrder(payload);
      createLogs({
        action: PAYMENT_ACTIONS.SET_PAYMENT_METHOD_ON_CART,
        payment_method: payload?.method_code,
        api_response: JSON.stringify(responseData),
        api_request: onPlaceOrderPayload(payload),
        status: responseData.errors ? ERROR : null,
      });
      if (
        responseData &&
        responseData.errors &&
        responseData.errors.length > 0
      ) {
        showToast(ERROR, responseData.errors[0]?.message);
        dispatch({
          type: ORDER_PLACED_FAILED,
          payload: {
            type: ORDER_PLACED_FAILED,
            data: responseData.errors[0]?.message,
          },
        });
      } else {
        const { data: response } = await onPlaceOrderService(payload);
        let placeOrderAction = "";
        if (response.errors) {
          placeOrderAction = PAYMENT_ACTIONS.ORDER_FAILED;
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
            },
          });
        } else {
          // check if payment method id divide buy then generate splash key and redirect user to divide buy portal
          placeOrderAction = PAYMENT_ACTIONS.DIVIDE_BUY_ORDER_SUCCESS;
          if(payload.method_code === "dbpayment") {
            dispatch({
              type: DIVIDEBUY_ORDER_SUCCESS,
              payload: {
                type: DIVIDEBUY_ORDER_SUCCESS,
                data: response.data?.placeOrder?.order?.order_number,
              },
            });
            dispatch(generateOrderSplashKeyAction(response.data?.placeOrder?.order?.order_id))
          }else{
            placeOrderAction = PAYMENT_ACTIONS.ORDER_SUCCESS;
            showToast(SUCCESS, "Your order has placed successfully");
            dispatch({
              type: ORDER_PLACED_SUCCESS,
              payload: {
                type: ORDER_PLACED_SUCCESS,
                data: response.data?.placeOrder?.order?.order_number,
                cart: responseData?.data?.setPaymentMethodOnCart?.cart,
              },
            });
          }
          
          // dispatch({
          //   type: GET_CART_PAGE_SUCCESS,
          //   payload: {
          //     type: GET_CART_PAGE_SUCCESS,
          //     data: response?.data ?
          //   }
          // })
        }
        createLogs({
          action: placeOrderAction,
          payment_method: payload?.method_code,
          api_response: JSON.stringify(response),
          api_request: onPlaceOrderPayload(payload),
          status: response.errors ? ERROR : null,
        });
      }
    } catch (error) {
      createLogs({
        action: PAYMENT_ACTIONS.ORDER_FAILED,
        payment_method: payload?.method_code,
        api_response: JSON.stringify(error),
        api_request: null,
        status: ERROR,
      });
      showToast(ERROR, error);
      dispatch({
        type: ORDER_PLACED_FAILED,
        payload: {
          type: ORDER_PLACED_FAILED,
        },
      });
    }
  };
};

export const setBillingAddress = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.country) {
        payload.region = { region: payload.country.value };
      }
      const { data: response } = await setBillingAddressOnCart(payload);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: CHECKOUT_ADDRESS_SUCCESS,
          payload: {
            type: CHECKOUT_ADDRESS_SUCCESS,
            data: response.data?.setBillingAddressOnCart?.cart,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const checkCustomerEmailAvailable = (email: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await checkCustomerEmail(email);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        if (response.data?.isEmailAvailable?.is_email_available) {
          const cartId = getSessionItem(CART_ID);
          await setGuestEmailAddress(email, cartId);
        }
        setSessionItem(GUEST_EMAIL, email);
        dispatch({
          type: CHECK_EMAIL_AVAILABLE_SUCCESS,
          payload: {
            type: CHECK_EMAIL_AVAILABLE_SUCCESS,
            data: response.data?.isEmailAvailable?.is_email_available,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const createPaypalExpressTokenAction = () => {
  return async (dispatch: any) => {
    const cartId = getSessionItem(CART_ID);
    try {
      dispatch({
        type: CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST,
        payload: {
          type: CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST,
          data: null,
        },
      });
      const { data: response } = await createPaypalExpressToken(cartId);
      createLogs({
        action: PAYMENT_ACTIONS.CREATE_PAYPAL_EXPRESS_TOKEN,
        payment_method: PAYMENT_METHOD.PAYPAL,
        api_response: JSON.stringify(response),
        api_request: createPaypalExpressTokenPayload(cartId),
        status: response.errors ? ERROR : null,
      });
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
          payload: {
            type: CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
            data: response.errors[0].message,
          },
        });
      } else {
        dispatch({
          type: CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS,
          payload: {
            type: CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS,
            data: response.data?.createPaypalExpressToken,
          },
        });
      }
    } catch (error: any) {
      createLogs({
        action: PAYMENT_ACTIONS.CREATE_PAYPAL_EXPRESS_TOKEN,
        payment_method: PAYMENT_METHOD.PAYPAL,
        api_response: JSON.stringify(error),
        api_request: createPaypalExpressTokenPayload(cartId),
        status: ERROR,
      });
      showToast(ERROR, error.message);
      dispatch({
        type: CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
        payload: {
          type: CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
          data: error.message,
        },
      });
    }
  };
};

export const onPaypalExpressPlaceOrderAction = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const cart_id = getSessionItem(CART_ID);
      payload.cart_id = cart_id;
      payload.cartId = cart_id;
      if (!isAuth()) {
        const email = getSessionItem(GUEST_EMAIL);
        const { data: guestResponse } = await setGuestEmailAddress(
          email,
          payload.cart_id
        );
        createLogs({
          action: PAYMENT_ACTIONS.SET_GUEST_EMAIL_ADDRESS,
          payment_method: PAYMENT_METHOD.PAYPAL,
          api_response: JSON.stringify(guestResponse),
          api_request: setGuestEmailPayload(email, cart_id),
          status: guestResponse.errors ? ERROR : null,
        });
        if (guestResponse.errors) {
          showToast(ERROR, guestResponse?.errors[0]?.message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
            },
          });
        }
      }
      const { data: responseData } = await onPaypalExpressPlaceOrder(payload);
      createLogs({
        action: PAYMENT_ACTIONS.PAYPAL_EXPRESS_PLACE_ORDER,
        payment_method: PAYMENT_METHOD.PAYPAL,
        api_response: JSON.stringify(responseData),
        api_request: onPaypalExpressPlaceOrderPayload(payload),
        status: responseData.errors ? ERROR : null,
      });
      if (
        responseData &&
        responseData.errors &&
        responseData.errors.length > 0
      ) {
        showToast(ERROR, responseData.errors[0].message);
        dispatch({
          type: ORDER_PLACED_FAILED,
          payload: {
            type: ORDER_PLACED_FAILED,
            data: responseData.errors[0].message,
          },
        });
      } else {
        const { data: response } = await onPlaceOrderService(payload);
        let orderAction = "";
        if (response.errors) {
          orderAction = PAYMENT_ACTIONS.ORDER_FAILED;
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
            },
          });
        } else {
          orderAction = PAYMENT_ACTIONS.ORDER_SUCCESS;
          dispatch({
            type: ORDER_PLACED_SUCCESS,
            payload: {
              type: ORDER_PLACED_SUCCESS,
              data: response.data?.placeOrder?.order?.order_number,
              cart: responseData?.data?.setPaymentMethodOnCart?.cart,
            },
          });
        }
        createLogs({
          action: orderAction,
          payment_method: PAYMENT_METHOD.PAYPAL,
          api_response: JSON.stringify(response),
          api_request: null,
          status: responseData.errors ? ERROR : null,
        });
      }
    } catch (error: any) {
      createLogs({
        action: PAYMENT_ACTIONS.ORDER_FAILED,
        payment_method: PAYMENT_METHOD.PAYPAL,
        api_response: JSON.stringify(error),
        api_request: null,
        status: ERROR,
      });
      showToast(ERROR, error.message);
      dispatch({
        type: ORDER_PLACED_FAILED,
        payload: {
          type: ORDER_PLACED_FAILED,
        },
      });
    }
  };
};

export const getRapydCheckoutIdAction = () => {
  return async (dispatch: any) => {
    dispatch({
      type: CREATE_RAPYD_CHECKOUT_ID_REQUEST,
      payload: {
        type: CREATE_RAPYD_CHECKOUT_ID_REQUEST,
      },
    });
    const cartId = getSessionItem(CART_ID);
    try {
      const { data: response } = await getRapydCheckoutId(cartId);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: CREATE_RAPYD_CHECKOUT_ID_FAILED,
          payload: {
            type: CREATE_RAPYD_CHECKOUT_ID_FAILED,
          },
        });
      }
      createLogs({
        action: PAYMENT_ACTIONS.CREATE_RAPYD_CHECKOUT_ID,
        payment_method: PAYMENT_METHOD.RAPYD,
        api_response: JSON.stringify(response),
        api_request: getRapydCheckoutIdPayload(cartId),
        status: response.errors ? ERROR : null,
      });
      dispatch({
        type: CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
        payload: {
          type: CREATE_RAPYD_CHECKOUT_ID_SUCCESS,
          data: response?.data?.rapydToken?.token,
        },
      });
    } catch (error: any) {
      createLogs({
        action: PAYMENT_ACTIONS.CREATE_RAPYD_CHECKOUT_ID,
        payment_method: PAYMENT_METHOD.RAPYD,
        api_response: JSON.stringify(error),
        api_request: getRapydCheckoutIdPayload(cartId),
        status: ERROR,
      });
      dispatch({
        type: CREATE_RAPYD_CHECKOUT_ID_FAILED,
        payload: {
          type: CREATE_RAPYD_CHECKOUT_ID_FAILED,
          data: error.message,
        },
      });
    }
  };
};

export const rapydPlaceOrderAction = () => {
  return async (dispatch: any) => {
    const id: any = localStorage.getItem(CART_ID);
    let cart_id = JSON.parse(id);
    try {
      // onPlaceOrderPayload
      if (!isAuth() && cart_id) {
        let email = getSessionItem(GUEST_EMAIL);
        const { data: guestResponse } = await setGuestEmailAddress(
          email,
          cart_id
        );
        createLogs({
          action: PAYMENT_ACTIONS.SET_GUEST_EMAIL_ADDRESS,
          payment_method: PAYMENT_METHOD.RAPYD,
          api_response: JSON.stringify(guestResponse),
          api_request: setGuestEmailPayload(email, cart_id),
          status: guestResponse.errors ? ERROR : null,
        });
        if (guestResponse.errors) {
          showToast(ERROR, guestResponse.errors[0].message);
          dispatch({
            type: RAPYD_ORDER_FAILED,
            payload: {
              type: RAPYD_ORDER_FAILED,
            },
          });
        }
      }
      if (cart_id) {
        const { data: response } = await rapydPlaceOrder(cart_id);
        createLogs({
          action: PAYMENT_ACTIONS.SET_PAYMENT_METHOD_ON_CART,
          payment_method: PAYMENT_METHOD.RAPYD,
          api_response: JSON.stringify(response),
          api_request: rapydPlaceOrderPayload(cart_id),
          amount: null,
          status: response.errors ? ERROR : null,
        });
        dispatch({
          type: RAPYD_ORDER_SUCCESS,
          payload: {
            type: RAPYD_ORDER_SUCCESS,
            data: response.data?.placeOrder?.order?.order_number,
          },
        });
        dispatch({
          type: ORDER_PLACED_SUCCESS,
          payload: {
            type: ORDER_PLACED_SUCCESS,
            data: response.data?.placeOrder?.order?.order_number,
            cart: response?.data?.setPaymentMethodOnCart?.cart,
          },
        });
      } else {
        showToast(ERROR, "Basket not found");
        createLogs({
          action: PAYMENT_ACTIONS.RAPYD_PLACE_ORDER_ERROR,
          payment_method: PAYMENT_METHOD.RAPYD,
          api_response: JSON.stringify(
            `cart_id :${cart_id} Basket Id Not Found`
          ),
          api_request: rapydPlaceOrderPayload(cart_id),
          amount: null,
          status: ERROR,
        });
        dispatch({
          type: RAPYD_ORDER_FAILED,
          payload: {
            type: RAPYD_ORDER_FAILED,
            data: null,
          },
        });
      }
    } catch (error: any) {
      createLogs({
        action: PAYMENT_ACTIONS.RAPYD_PLACE_ORDER_ERROR,
        payment_method: PAYMENT_METHOD.RAPYD,
        api_response: JSON.stringify(error),
        api_request: rapydPlaceOrderPayload(cart_id),
        amount: null,
        status: ERROR,
      });
      showToast(ERROR, error.message);
      dispatch({
        type: RAPYD_ORDER_FAILED,
        payload: {
          type: RAPYD_ORDER_FAILED,
          data: null,
        },
      });
    }
  };
};

export const stripePlaceOrderAction = (paymentMethodId: string) => {
  return async (dispatch: any) => {
    let cartId = getSessionItem(CART_ID);
    try {
      dispatch({
        type: STRIPE_ORDER_REQUEST,
        payload: {
          type: STRIPE_ORDER_REQUEST,
        },
      });
      // onPlaceOrderPayload
      if (!isAuth() && cartId) {
        let email = getSessionItem(GUEST_EMAIL);
        const { data: guestResponse } = await setGuestEmailAddress(
          email,
          cartId
        );
        createLogs({
          action: PAYMENT_ACTIONS.SET_GUEST_EMAIL_ADDRESS,
          payment_method: PAYMENT_METHOD.STRIPE,
          api_response: guestResponse,
          api_request: setGuestEmailPayload(email, cartId),
          status: guestResponse.errors ? ERROR : null,
        });
        if (guestResponse.errors) {
          showToast(ERROR, guestResponse.errors[0].message);
          dispatch({
            type: STRIPE_ORDER_FAILED,
            payload: {
              type: STRIPE_ORDER_FAILED,
            },
          });
        }
      }
      if (cartId) {
        const { data: responseData } = await stripePlaceOrder(
          cartId,
          paymentMethodId
        );
        createLogs({
          action: PAYMENT_ACTIONS.SET_PAYMENT_METHOD_ON_CART,
          payment_method: PAYMENT_METHOD.RAPYD,
          api_response: responseData,
          api_request: stripePlaceOrderPayload(cartId, paymentMethodId),
          amount: null,
          status: responseData.errors ? ERROR : null,
        });
        let payload = {
          cart_id: cartId,
        };
        if (
          responseData &&
          responseData.errors &&
          responseData.errors.length > 0
        ) {
          showToast(ERROR, responseData.errors[0].message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
              data: responseData.errors[0].message,
            },
          });
        }
        const { data: response } = await onPlaceOrderService(payload);
        if (response.errors) {
          const errorMessage: string = response.errors[0].message;
          if (errorMessage && errorMessage.includes(AUTHENTICATION_REQUIRED)) {
            let clientSecret = errorMessage.split(":")?.[1]?.trim();
            dispatch({
              type: STRIPE_ORDER_AUTHENTICATION_REQUIRED,
              payload: {
                type: STRIPE_ORDER_AUTHENTICATION_REQUIRED,
                data: {
                  client_secret: clientSecret,
                },
              },
            });
          } else {
            showToast(ERROR, response.errors[0].message);
            dispatch({
              type: STRIPE_ORDER_FAILED,
              payload: {
                type: STRIPE_ORDER_FAILED,
                data: null,
               
              },
            });
          }
        } else {
          dispatch({
            type: STRIPE_ORDER_SUCESS,
            payload: {
              type: STRIPE_ORDER_SUCESS,
              data: response.data?.placeOrder,
            },
          });
          dispatch({
            type: ORDER_PLACED_SUCCESS,
            payload: {
              type: ORDER_PLACED_SUCCESS,
              data: response.data?.placeOrder?.order?.order_number,
              cart: responseData?.data?.setPaymentMethodOnCart?.cart,
              // cart: response?.data?.setPaymentMethodOnCart?.cart,
            },
          });
        }
      } else {
        showToast(ERROR, "Basket not found");
        createLogs({
          action: PAYMENT_ACTIONS.STRIPE_PLACE_ORDER_SUCESS,
          payment_method: PAYMENT_METHOD.STRIPE,
          api_response: { message: `cartId :${cartId} Basket Id Not Found` },
          api_request: stripePlaceOrderPayload(cartId, paymentMethodId),
          amount: null,
          status: ERROR,
        });
        dispatch({
          type: STRIPE_ORDER_FAILED,
          payload: {
            type: STRIPE_ORDER_FAILED,
            data: null,
          },
        });
      }
    } catch (error: any) {
      createLogs({
        action: PAYMENT_ACTIONS.STRIPE_PLACE_ORDER_ERROR,
        payment_method: PAYMENT_METHOD.STRIPE,
        api_response: error,
        api_request: stripePlaceOrderPayload(cartId, paymentMethodId),
        amount: null,
        status: ERROR,
      });
      showToast(ERROR, error.message);
      dispatch({
        type: STRIPE_ORDER_FAILED,
        payload: {
          type: STRIPE_ORDER_FAILED,
          data: null,
        },
      });
    }
  };
};

export const stripeAuthenticationPlaceOrderAction = () => {
  return async (dispatch: any) => {
    const cartId = getSessionItem(CART_ID);
    try {
      let payload: any = {
        cart_id: cartId,
      };
      if (cartId) {
        const {data : cartResponse} = await getCartDetail(cartId);
        if (
          cartResponse &&
          cartResponse.errors &&
          cartResponse.errors.length > 0
        ) {
          showToast(ERROR, cartResponse.errors[0].message);
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
              data: cartResponse.errors[0].message,
            },
          });
        }
        const { data: response } = await onPlaceOrderService(payload);
        createLogs({
          action: PAYMENT_ACTIONS.STRIPE_PLACE_ORDER_AFTER_AUTHENTICATION,
          payment_method: PAYMENT_METHOD.STRIPE,
          api_response: response,
          api_request: onPlaceOrderPayload(payload),
          amount: null,
          status: INFO,
        });
        if (response.errors) {
          showToast(ERROR, response.errors?.[0].message);
          dispatch({
            type: STRIPE_ORDER_FAILED,
            payload: {
              type: STRIPE_ORDER_FAILED,
              data:null
            },
          });
        } else {
          dispatch({
            type: STRIPE_ORDER_SUCESS,
            payload: {
              type: STRIPE_ORDER_SUCESS,
              data: response.data?.placeOrder,
            },
          });
          dispatch({
            type: ORDER_PLACED_SUCCESS,
            payload: {
              type: ORDER_PLACED_SUCCESS,
              data: response.data?.placeOrder?.order?.order_number,
              cart: cartResponse?.data?.cart,
            },
          });
        }
      } else {
        showToast(ERROR, "Basket not found");
        createLogs({
          action: PAYMENT_ACTIONS.STRIPE_PLACE_ORDER_AFTER_AUTHENTICATION,
          payment_method: PAYMENT_METHOD.STRIPE,
          api_response: { message: `cartId :${cartId} Basket Id Not Found` },
          api_request: onPlaceOrderPayload(payload),
          amount: null,
          status: ERROR,
        });
        dispatch({
          type: STRIPE_ORDER_FAILED,
          payload: {
            type: STRIPE_ORDER_FAILED,
            data: null,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, error.message);
      createLogs({
        action: PAYMENT_ACTIONS.STRIPE_PLACE_ORDER_AFTER_AUTHENTICATION,
        payment_method: PAYMENT_METHOD.STRIPE,
        api_response: error,
        api_request: onPlaceOrderPayload({
          cart_id: cartId,
        }),
        amount: null,
        status: ERROR,
      });
      dispatch({
        type: STRIPE_ORDER_FAILED,
        payload: {
          type: STRIPE_ORDER_FAILED,
          data: null,
        },
      });
    }
  };
};


export const generateOrderSplashKeyAction = (orderId : string) => {
  return async (dispatch: any) => {
    dispatch({
      type: DIVIDEBUY_SPLASH_KEY_REQUEST,
      payload: {
        type: DIVIDEBUY_SPLASH_KEY_REQUEST,
      },
    });

    try {
      const { data: responseData } = await generateOrderSplashKey(orderId);
      if (
        responseData &&
        responseData.errors &&
        responseData.errors.length > 0 
      ) {
        showToast(ERROR, responseData.errors[0]?.message);
        dispatch({
          type: ORDER_PLACED_FAILED,
          payload: {
            type: ORDER_PLACED_FAILED,
            data: responseData.errors[0]?.message,
          },
        });
        dispatch({
          type: DIVIDEBUY_SPLASH_KEY_FAILED,
          payload: {
            type: DIVIDEBUY_SPLASH_KEY_FAILED,
            data: null,
          },
        });
      } else {
        dispatch({
          type: DIVIDEBUY_SPLASH_KEY_SUCCESS,
          payload: {
            type: DIVIDEBUY_SPLASH_KEY_SUCCESS,
            data:responseData.data?.ordersplashkey?.splashkey
          },
        });
      }
      createLogs({
        action: PAYMENT_ACTIONS.DIVIDE_BUY_SPLASH_KEY_REQUEST,
        payment_method: PAYMENT_METHOD.DIVIDE_BUY,
        api_response: JSON.stringify(responseData),
        api_request: generateOrderSplashKeyPayload(orderId),
        status:  responseData.errors ? ERROR : SUCCESS,
      });
    } catch (error) {
      showToast(ERROR, error);
      createLogs({
        action: PAYMENT_ACTIONS.DIVIDE_BUY_SPLASH_KEY_FAILED,
        payment_method: PAYMENT_METHOD.DIVIDE_BUY,
        api_response: JSON.stringify(error),
        api_request: null,
        status: ERROR,
      });
      dispatch({
        type: DIVIDEBUY_SPLASH_KEY_FAILED,
        payload: {
          type: DIVIDEBUY_SPLASH_KEY_FAILED,
          data: error,
        },
      });
      dispatch({
        type: ORDER_PLACED_FAILED,
        payload: {
          type: ORDER_PLACED_FAILED,
        },
      });
    }
  }
}

export const verifySplashKeyAction = (splashKey : string) => {
  return async (dispatch: any) => {
    dispatch({
      type: VERIFY_SPLASH_KEY_REQUEST,
      payload: {
        type: VERIFY_SPLASH_KEY_REQUEST,
      },
    });

    try {
      const { data: responseData } = await verifySplashKey(splashKey);

      if(responseData?.error){
        showToast(ERROR, responseData?.message);
        dispatch({
          type: VERIFY_SPLASH_KEY_FAILED,
          payload: {
            type: VERIFY_SPLASH_KEY_FAILED,
            data: responseData?.message,
          },
        });
      } else{
        // SAVE NEW QUOTE ID AND REDIRECT CUSTOMER TO CHECKOUT PAGE 
        if(responseData?.status === DIVIDE_BUY_ORDER_STATUS.CANCEL && responseData?.quoteid){
          if(responseData?.message){
            showToast(ERROR, responseData?.message);  
          }          
          setSessionItem(CART_ID , responseData?.quoteid)
          setTimeout(() => {
            dispatch({
              type: VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
              payload: {
                type: VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
                data: responseData?.quoteid,
              },
            });            
          }, 500); 
        } else if (responseData?.status === DIVIDE_BUY_ORDER_STATUS.CANCEL && (responseData?.quoteid === "" || responseData?.quoteid === false ) && responseData?.quote_entity_id ){
          const { data: callbackRes } = await orderSplashKeyCallback(responseData?.quote_entity_id);

          if (
            callbackRes &&
            callbackRes.errors &&
            callbackRes.errors.length > 0 
          ) {
            showToast(ERROR, callbackRes.errors[0]?.message);
            dispatch({
              type: VERIFY_SPLASH_KEY_FAILED,
              payload: {
                type: VERIFY_SPLASH_KEY_FAILED,
                data: callbackRes.errors,
              },
            });
            createLogs({
              action: PAYMENT_ACTIONS.VERIFY_SPLASH_KEY_CALLBACK_FAILED,
              payment_method: PAYMENT_METHOD.DIVIDE_BUY,
              api_response: JSON.stringify(callbackRes.errors),
              api_request: null,
              status: ERROR,
            });
          }else if (callbackRes?.data?.ordersplashkeycallback?.status === DIVIDE_BUY_ORDER_STATUS.SUCCESS && callbackRes?.data?.ordersplashkeycallback?.cartid ){
            showToast(ERROR, callbackRes?.data?.ordersplashkeycallback?.message); 
            setSessionItem(CART_ID , callbackRes?.data?.ordersplashkeycallback?.cartid)
            setTimeout(() => {
              dispatch({
                type: VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
                payload: {
                  type: VERIFY_SPLASH_KEY_GENERATED_QUOTE_ID,
                  data: callbackRes?.data?.ordersplashkeycallback?.cartid,
                },
              });
            }, 500);
           }else{
            dispatch({
              type: ORDER_PLACED_FAILED,
              payload: {
                type: ORDER_PLACED_FAILED,
              },
            });
            dispatch({
              type: VERIFY_SPLASH_KEY_FAILED,
              payload: {
                type: VERIFY_SPLASH_KEY_FAILED,
                data: "",
              },
            });
           }
        } else if (responseData?.status === DIVIDE_BUY_ORDER_STATUS.SUCCESS){
          dispatch({
            type: ORDER_PLACED_SUCCESS,
            payload: {
              type: ORDER_PLACED_SUCCESS,
              data: responseData.order_id,
            },
          });

          dispatch({
            type: VERIFY_SPLASH_KEY_SUCCESS,
            payload: {
              type: VERIFY_SPLASH_KEY_SUCCESS,
              data:responseData.order_id
            },
          });
        }else{
          dispatch({
            type: ORDER_PLACED_FAILED,
            payload: {
              type: ORDER_PLACED_FAILED,
            },
          });
          dispatch({
            type: VERIFY_SPLASH_KEY_FAILED,
            payload: {
              type: VERIFY_SPLASH_KEY_FAILED,
              data: "",
            },
          });
        }
      }
      createLogs({
        action: PAYMENT_ACTIONS.VERIFY_SPLASH_KEY_REQUEST,
        payment_method: PAYMENT_METHOD.DIVIDE_BUY,
        api_response: JSON.stringify(responseData),
        api_request: splashKey,
        status: responseData?.error ? ERROR : SUCCESS,
      });
    } catch (error) {
      createLogs({
        action: PAYMENT_ACTIONS.VERIFY_SPLASH_KEY_FAILED,
        payment_method: PAYMENT_METHOD.DIVIDE_BUY,
        api_response: JSON.stringify(error),
        api_request: null,
        status: ERROR,
      });
      dispatch({
        type: VERIFY_SPLASH_KEY_FAILED,
        payload: {
          type: VERIFY_SPLASH_KEY_FAILED,
          data: error,
        },
      });
      showToast(ERROR, error);
      dispatch({
        type: ORDER_PLACED_FAILED,
        payload: {
          type: ORDER_PLACED_FAILED,
        },
      });
    }
  }
}

export const getPaymentInstallmentAction = (orderTotal : string) => {
  return async (dispatch: any) => {
    dispatch({
      type: PAYMENT_INSTALLMENT_REQUEST,
      payload: {
        type: PAYMENT_INSTALLMENT_REQUEST,
      },
    });

    try {
      const { data: responseData } = await getPaymentInstallment(orderTotal);
      if (
        responseData &&
        responseData.errors &&
        responseData.errors.length > 0 
      ) {
        showToast(ERROR, responseData.errors[0]?.message);
        dispatch({
          type: PAYMENT_INSTALLMENT_FAILED,
          payload: {
            type: PAYMENT_INSTALLMENT_FAILED,
            data: responseData.errors[0]?.message,
          },
        });
      } else {
        dispatch({
          type: PAYMENT_INSTALLMENT_SUCCESS,
          payload: {
            type: PAYMENT_INSTALLMENT_SUCCESS,
            data:responseData.data?.getpaymentInstallments?.installmentcontent
          },
        });
      }

    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: PAYMENT_INSTALLMENT_FAILED,
        payload: {
          type: PAYMENT_INSTALLMENT_FAILED,
          data: error,
        },
      });        
    }
  }
}

export const getPaymentMinAmountAction = () => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getPaymentMinAmount();
           
      if (response && response.data && response.data.paymentminimumamount && response.data.paymentminimumamount) {
        dispatch({
          type: GET_PAYMENT_MIN_AMOUNT_SUCCESS,
          payload: {
            type: GET_PAYMENT_MIN_AMOUNT_SUCCESS,
            data: response.data?.paymentminimumamount?.paymentminimumamount
          }
        })
      }

      if (response && response.errors) {
        // showToast(ERROR, response.errors[0].message);
        dispatch({
          type: GET_PAYMENT_MIN_AMOUNT_FAILED,
          payload: {
            type: GET_PAYMENT_MIN_AMOUNT_FAILED,
            message: response.errors[0].message
          },
        });
      } 

    } catch (error: any) {
      // dispatch({
      //   type: GET_PAYMENT_MIN_AMOUNT_FAILED,
      //   payload: {
      //     type: GET_PAYMENT_MIN_AMOUNT_FAILED,
      //     message: error.message
      //   }
      // })
    }
  }
}