import { Modal } from "react-bootstrap";
import CustomButton from "../../../Common/CustomButton/CustomButton";

const RemovePopup = (props: any) => {
  return (
    <Modal {...props} centered className="remove-popup">
      <Modal.Header>
        <Modal.Title className="bm">{props.text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="remove-wrap">
          <CustomButton bg={"outline-btn"} onClick={props.onCancel}>
            cancel
          </CustomButton>
          <CustomButton
            isLoading={props.loading}
            bg={"fill"}
            onClick={props.onSubmit}
          >
            ok
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemovePopup;
