import { useNavigate } from "react-router";
import SomethingWrongImage from "../../Assets/Images/something-wrong.jpg";
import { HOME } from "../../Constants/Route";
import CustomButton from "../Common/CustomButton/CustomButton";

const SomethingWrongPage = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page nodata">
      <div className="custom-container">
        <div className="error-page-inner">
          <div className="error-box left">
            <h6>SOMETHING WENT WRONG!</h6>
            <p className="bm">
              Looks like we are not able to connect to our server.
            </p>
            <CustomButton onClick={() => navigate(HOME)} bg="fill">
              Try Again
            </CustomButton>
          </div>
          <div className="error-box right">
            <div className="error-img">
              <img src={SomethingWrongImage} alt={"Something went wrong"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SomethingWrongPage;
