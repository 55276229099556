/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rapydPlaceOrderAction } from "../../Redux/Pages/Checkout/CheckoutAction";
import LOADER_IMAGE from "../../Assets/Images/LIU-Loader.gif";
import { RAPYD_ORDER_SUCCESS } from "../../Redux/Pages/Checkout/CheckoutTypes";
import { useNavigate } from "react-router";
import { ORDER_SUCCESS } from "../../Constants/Route";

const PaymentProgress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RapydPaymentReducer = useSelector(
    (state: any) => state.RapydPaymentReducer
  );

  useEffect(() => {
    
    if (
      RapydPaymentReducer &&
      RapydPaymentReducer.type === RAPYD_ORDER_SUCCESS
    ) {
      navigate(ORDER_SUCCESS + "?fromCheckout=True");
    }
  }, [RapydPaymentReducer]);

  useEffect(() => {
    dispatch(rapydPlaceOrderAction());
  }, []);

  return (
    <p className="order-processing">
      <img src={LOADER_IMAGE} alt="Loader" />
      Please wait while we process your request...
    </p>
  );
};

export default PaymentProgress;
