/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { CHECKOUT, ERROR_PAGE, ORDER_SUCCESS } from "../Constants/Route";
import { onPaypalExpressPlaceOrderAction } from "../Redux/Pages/Checkout/CheckoutAction";
import { ORDER_PLACED_FAILED, ORDER_PLACED_SUCCESS } from "../Redux/Pages/Checkout/CheckoutTypes";
import LOADER_IMAGE from "../Assets/Images/LIU-Loader.gif";
import { createLogs } from "../Services/axios.service";
import { ERROR, PAYMENT_ACTIONS, PAYMENT_METHOD } from "../Constants/Labels";

const PaypalSuccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);

  useEffect(() => {
    if (location.search !== "") {
      const params = location.search.substring(
        location.search.indexOf("?") + 1
      );
      const token = params.split("&")[0].split("token=")[1];
      const payer_id = params.split("&")[1].split("PayerID=")[1];
       createLogs({
        action: PAYMENT_ACTIONS.PAYPAL_EXPRESS_REDIRECT_BACK_URL,
        payment_method: PAYMENT_METHOD.PAYPAL,
        api_response: JSON.stringify({locationSearch:location.search , token,payer_id}),
        api_request: null,
        amount: checkoutResponse?.prices?.grand_total?.value,
        status: (token && payer_id) ? null : ERROR
      });
      if (token && payer_id) {
        dispatch(onPaypalExpressPlaceOrderAction({ token, payer_id }));
      }
    } else {
      window.location.href = ERROR_PAGE;
    } 
  }, [location]);

  useEffect(() => {
    if (checkoutResponse && checkoutResponse.type === ORDER_PLACED_SUCCESS) {
      navigate(ORDER_SUCCESS + "?fromCheckout=True");
    } else if (checkoutResponse.type === ORDER_PLACED_FAILED){
      navigate(CHECKOUT);
    }
  }, [checkoutResponse]);

  return (
    <p className="order-processing">
      <img src={LOADER_IMAGE} alt="Loader"/>
      Please wait while we process your request...
    </p>
  );
};

export default PaypalSuccess;
