import React from 'react'

function DividebyFooter() {
    return (
        <div className='footer-divideby-wrap'>
            <div className="custom-container">
            <p>
                Zopa Embedded Finance Limited t/a DivideBuy credit agreements are not regulated by the Financial Conduct Authority and do not fall under the jurisdiction of the Financial Ombudsman Service. Credit is available to permanent UK residents aged 18+, subject to status, any missed payments may affect your ability to obtain credit from DivideBuy and other lenders. Please spend responsibly. Copyright © 2023 Zopa Embedded Finance. All Rights Reserved.
            </p>
            </div>
          
        </div>
    )
}

export default DividebyFooter