
import { FILTER_PRODUCT_RESPONSE_FAILED, FILTER_PRODUCT_RESPONSE_REQUEST, FILTER_PRODUCT_RESPONSE_SUCCESS, GET_CONFIGURABLE_PRODUCTS_SUCCESS, GET_PRODUCT_CMS_BLOCK_SUCCESS, GET_PRODUCT_DETAIL_FAILED, GET_PRODUCT_DETAIL_REQUEST, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_FABRIC_INFORMATION_SUCCESS, GET_PRODUCT_LIST_FAILED, GET_PRODUCT_LIST_REQUEST, GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_VARIANTS_FAILED, GET_PRODUCT_VARIANTS_SUCCESS, GET_RELATED_PRODUCTS_FAILED, GET_RELATED_PRODUCTS_SUCCESS, GET_UPSELL_PRODUCT_DETAIL_SUCCESS } from "./ProductTypes";

export function productList(state = {}, action: any) {
    switch (action.type) {
        case GET_PRODUCT_LIST_REQUEST:
            return { ...action.payload };
        case GET_PRODUCT_LIST_SUCCESS:
            return { ...action.payload };
        case GET_PRODUCT_LIST_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}

export function productDetailResponse(state = {}, action: any) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_REQUEST:
            return { ...action.payload };
        case GET_PRODUCT_DETAIL_SUCCESS:
            return { ...action.payload };
        case GET_PRODUCT_DETAIL_FAILED:
            return { ...action.payload };
        case GET_PRODUCT_CMS_BLOCK_SUCCESS:
            return { ...action.payload }
        case GET_PRODUCT_FABRIC_INFORMATION_SUCCESS:
            return { ...action.payload }
        case GET_UPSELL_PRODUCT_DETAIL_SUCCESS:
            return { ...action.payload }
        case GET_CONFIGURABLE_PRODUCTS_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
}

export const filterProductReducer = (state = {}, action: any) => {
    switch (action.type) {
        case FILTER_PRODUCT_RESPONSE_SUCCESS:
            return { ...action.payload }
        case FILTER_PRODUCT_RESPONSE_FAILED:
            return { ...action.payload }
        case FILTER_PRODUCT_RESPONSE_REQUEST:
            return { ...action.payload }
        default:
            return state
    }
}

export const getRelatedProductHTMLContentReducer = (state = {}, action: any) => {
    if (action.type === GET_RELATED_PRODUCTS_SUCCESS || action.type === GET_RELATED_PRODUCTS_FAILED) {
        return { ...action.payload }
    } else {
        return state
    }
}

export const getProductVariantsReducer = (state = null, action: any) => {
    return action.type === GET_PRODUCT_VARIANTS_SUCCESS ||
        action.type === GET_PRODUCT_VARIANTS_FAILED ?
        { ...action.payload } : state;
}