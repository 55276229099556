/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAddressResponse } from "../../Interfaces/FetchifyAddress.interface";
import { UserInputForm } from "../../Interfaces/UserInput.interface";
import { pincodeLookupAction } from "../../Redux/Auth/AuthAction";
import {
  POSTCODE_LOOKUP_FAILED,
  POSTCODE_LOOKUP_SUCCESS,
} from "../../Redux/Auth/AuthType";
import {
  SUBMIT_SAMPLE_FORM_ERROR,
  SUBMIT_SAMPLE_FORM_SUCCESS,
} from "../../Redux/General/GeneralType";
import validate from "../../Utility/Validation";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import SelectGroups from "../Common/SelectGroups/SelectGroups";

const FreeSampleUserForm = ({
  setCurrentState,
  data,
  submitContactForm,
}: any) => {
  const submitFormResponse = useSelector(
    (state: any) => state.submitSampleFormResponse
  );
  const [loading, setLoading] = useState(false);
  const [lookupLoading, setLookupLoading] = useState(false);
  const [userInputForm, setUserInputsForm] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const INITIAL_ERROR = {
    firstname: null,
    lastname: null,
    email: null,
    postcode: null,
    telephone: null,
    address1: null,
    town: null,
  };
  const [error, setError] = useState(INITIAL_ERROR);
  const [addressData, setAddressData] = useState<any>();

  const pincodeLookupResponse = useSelector(
    (state: any) => state.pincodeLookupResponse
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({
        type: POSTCODE_LOOKUP_SUCCESS,
        payload: {
          type: POSTCODE_LOOKUP_SUCCESS,
          data: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (
      pincodeLookupResponse &&
      pincodeLookupResponse.type === POSTCODE_LOOKUP_SUCCESS
    ) {
      setAddressData(pincodeLookupResponse.data);
      setLookupLoading(false);
    } else if (
      pincodeLookupResponse &&
      pincodeLookupResponse.type === POSTCODE_LOOKUP_FAILED
    ) {
      setAddressData(null);
      setLookupLoading(false);
      setIsFormValidated(true);
      setError({
        ...error,
        postcode: pincodeLookupResponse.data,
      });
    }
  }, [pincodeLookupResponse]);

  useEffect(() => {
    setForm(data);
  }, [data]);

  useEffect(() => {
    if (submitFormResponse) {
      if (submitFormResponse.type === SUBMIT_SAMPLE_FORM_SUCCESS) {
        setLoading(false);
        setUserInputsForm({});
        setForm(data);
      } else if (submitFormResponse.type === SUBMIT_SAMPLE_FORM_ERROR) {
        setLoading(false);
      }
    }
  }, [submitFormResponse]);

  const onSubmit = () => {
    const validateData = validate(userInputForm);
    if (validateData.isValidated === true) {
      setLoading(true);
      setError(INITIAL_ERROR);
      const formData: any = {};

      Object.keys(userInputForm).forEach((c: string) => {
        formData[userInputForm[c].name] = userInputForm[c].value;
      });

      // console.log('encodedString 2',c);
      // console.log('encodedString 3',userInputForm[c].value);

      const newCustom: any = {};
      Object.keys(userInputForm).forEach((a) => {
        newCustom[userInputForm[a].name] = {
          label: userInputForm[a].label,
          value: userInputForm[a].value,
        };
      });

      submitContactForm(
        formData,
        userInputForm.firstname.value,
        userInputForm.lastname.value,
        userInputForm.email.value,
        newCustom
      );
    } else {
      const message = validateData?.errors;
      setError(message);
      setLoading(false);
      setIsFormValidated(true);
    }
  };

  const setData = (field: string, value: string) => {
    let details = Object.assign({}, userInputForm);
    details[field].value = value;
    setUserInputsForm(details);
  };

  const onPincodeLookup = () => {
    if (userInputForm.postcode.value.trim() !== "") {
      setLookupLoading(true);
      const currentForm = {
        postcode: {
          ...userInputForm.postcode,
          value: userInputForm.postcode.value.trim(),
        },
      };
      let vForm = validate(currentForm);
      if (vForm.errors && Object.keys(vForm.errors).length > 0) {
        const message = { ...vForm?.errors };
        setLookupLoading(false);
        setError(message);
        setIsFormValidated(true);
      } else {
        setIsFormValidated(false);
        setLoading(false);
        setError({
          ...error,
          postcode: null,
        });
        dispatch(pincodeLookupAction(userInputForm.postcode.value.trim()));
      }
    }
  };

  const onSelectAddress = (e: any) => {
    if (e.target.value && e.target.value !== "") {
      let address: IAddressResponse = JSON.parse(e.target.value);
      const currentForm = Object.assign({}, userInputForm);
      currentForm.address1.value = `${
        address.line_1 && address.line_1 !== "" ? address.line_1 + ", " : ""
      }${address.line_2 && address.line_2 !== "" ? address.line_2 + ", " : ""}${
        address.line_3 && address.line_3 !== "" ? address.line_3 : ""
      }`;
      currentForm.town.value = addressData.town;
      setUserInputsForm(currentForm);
    }
  };

  function setForm(data: any) {
    const form: any = {};
    data
      .map((c: any[]) =>
        c.filter((cc: { type: string }) => cc.type !== "checkboxtwo")
      )
      .filter((c: any[]) => c.length > 0)
      .forEach((cc: any) => {
        cc.forEach((c: any) => {
          if (c.label === "First Name") {
            form["firstname"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["firstName", "required"],
              c.value,
              [null, "First name is required."]
            );
          } else if (c.label === "Last Name") {
            form["lastname"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["lastName", "required"],
              c.value,
              [null, "Last name is required."]
            );
          } else if (c.label === "Email") {
            form["email"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["email", "required"],
              c.value,
              ["Please enter valid email.", "Email is required."]
            );
          }

          // IT"S FOR PHONE NUMBER VALIDATION. PLEASE DON'T REMOVE THIS.
          else if (c.label === "Phone Number") {
            form["telephone"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              [],
              c.value,
              []
            );
          } else if (c.label === "Address Line 1") {
            form["address1"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["required"],
              c.value,
              [
                "Please enter valid Street Address.",
                "Street Address is required.",
              ]
            );
          } else if (c.label === "Town") {
            form["town"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["required"],
              c.value,
              ["Town is required."]
            );
          } else if (c.label === "Postcode") {
            form["postcode"] = new UserInputForm(
              c.name,
              c.label,
              c.required === "1",
              ["postcode", "required"],
              c.value,
              [
                "Provided Zip/Postal Code seems to be invalid. Example: AB12 3CD; A1B 2CD; AB1 2CD; AB1C 2DF; A12 3BC; A1 2BC. If you believe it is the right one you can ignore this notice.",
                "Postcode is required.",
              ]
            );
          }
        });
      });
    setUserInputsForm(form);
  }

  return (
    <>
      <InputGroups
        className="fname"
        type="text"
        onChange={(e: any) => setData("firstname", e.target.value)}
        id="firstName"
        label="First Name*"
        required={userInputForm?.firstname?.required}
        error={
          isFormValidated && error["firstname"] ? error["firstname"] : null
        }
      />
      <InputGroups
        className="lname"
        label="Last Name*"
        type="text"
        required={userInputForm?.lastname?.required}
        onChange={(e: any) => setData("lastname", e.target.value)}
        id="lastName"
        error={isFormValidated && error["lastname"] ? error["lastname"] : null}
      />
      <InputGroups
        className="email"
        label="Email Address*"
        type="email"
        required={userInputForm?.email?.required}
        onChange={(e: any) => setData("email", e.target.value)}
        id="email"
        error={isFormValidated && error["email"] ? error["email"] : null}
      />
      <InputGroups
        className="phoneno"
        label="Phone Number"
        type="tel"
        onChange={(e: any) => setData("telephone", e.target.value)}
        id="telephone"
        error={
          isFormValidated && error["telephone"] ? error["telephone"] : null
        }
      />
      <div className="full-street">
        <InputGroups
          className="street"
          label="Street Address*"
          type="text"
          value={userInputForm?.address1?.value || ""}
          required={userInputForm?.address1?.required}
          onChange={(e: any) => setData("address1", e.target.value)}
          id="address1"
          error={
            isFormValidated && error["address1"] ? error["address1"] : null
          }
        />
      </div>
      <InputGroups
        className="city"
        label="City/Town*"
        type="text"
        value={userInputForm?.town?.value || ""}
        required={userInputForm?.town?.required}
        onChange={(e: any) => setData("town", e.target.value)}
        id="town"
        error={isFormValidated && error["town"] ? error["town"] : null}
      />
      <div
        className={
          addressData && addressData.delivery_points
            ? "postcode-wrap with-address"
            : "postcode-wrap"
        }
      >
        <InputGroups
          label="Postcode*"
          type="text"
          required={userInputForm?.postcode?.required}
          onChange={(e: any) => setData("postcode", e.target.value)}
          id="postcode"
          error={
            isFormValidated && error["postcode"] ? error["postcode"] : null
          }
        />
        <CustomButton
          disabled={!userInputForm?.postcode?.value}
          isLoading={lookupLoading}
          onClick={onPincodeLookup}
          bg={"fill"}
        >
          Find Address
        </CustomButton>
        {addressData && addressData.delivery_points ? (
          <SelectGroups
            values={[
              {
                title: "Select Your Address",
                value: "",
              },
              ...addressData.delivery_points?.map((d: IAddressResponse) => {
                return {
                  value: JSON.stringify(d),
                  title: `${
                    d.organisation_name && d.organisation_name !== ""
                      ? d.organisation_name + ","
                      : ""
                  }${d.line_1 && d.line_1 !== "" ? d.line_1 + "," : ""}${
                    d.line_2 && d.line_2 !== "" ? d.line_2 + "," : ""
                  }${d.line_3 && d.line_3 !== "" ? d.line_3 + "," : ""}`,
                };
              }),
            ]}
            label=""
            onSelect={onSelectAddress}
            selectedValue={addressData.delivery_points[0].dps}
          />
        ) : null}
      </div>
      <div className="action-bar">
        <CustomButton onClick={() => setCurrentState(1)}>Previous</CustomButton>
        <CustomButton bg="fill" onClick={onSubmit} isLoading={loading}>
          Submit
        </CustomButton>
      </div>
    </>
  );
};

export default FreeSampleUserForm;
