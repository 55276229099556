import { useEffect, useState } from "react";
import minus from "../../../Assets/Images/minus.svg";
import plus from "../../../Assets/Images/plus.svg";

const IncDecBox = (props: {
  quantityNo: number;
  onIncrement: any;
  onDecrement: any;
  disabled: boolean;
}) => {
  let [num, setNum] = useState(props.quantityNo);

  useEffect(() => {
    setNum(props.quantityNo);
  }, [props]);

  let incNum = () => {
    setNum(Number(num) + 1);
    props.onIncrement(num + 1);
  };

  let decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    } else {
      setNum(0);
    }
    props.onDecrement(num - 1);
  };

  let handleChange = (e: any) => {
    setNum(e.target.value);
  };

  return (
    <div className="inc-dec-wrapper">
      <button onClick={decNum} disabled={props.disabled}>
        <img src={minus} alt="Minus QTY"></img>
      </button>
      <input type="text" readOnly value={num} onChange={handleChange} />
      <button onClick={incNum} disabled={props.disabled}>
        <img src={plus} alt="Plus QTY"></img>
      </button>
    </div>
  );
};

export default IncDecBox;
