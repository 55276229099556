/* eslint-disable react-hooks/exhaustive-deps */
import DropIn from "braintree-web-drop-in-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CHECKOUT, ORDER_SUCCESS } from "../../../Constants/Route";
import { GET_CART_PAGE_SUCCESS } from "../../../Redux/Pages/CartPage/CartPageTypes";
import {
  createPaypalExpressTokenAction,
  getPaymentMinAmountAction,
  onPlaceOrder,
  setBillingAddress,
} from "../../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  CREATE_PAYPAL_EXPRESS_TOKEN_FAILED,
  CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST,
  CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS,
  DIVIDEBUY_SPLASH_KEY_FAILED,
  DIVIDEBUY_SPLASH_KEY_REQUEST,
  DIVIDEBUY_SPLASH_KEY_SUCCESS,
  GET_PAYMENT_MIN_AMOUNT_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
  PAYMENT_INSTALLMENT_FAILED,
  PAYMENT_INSTALLMENT_REQUEST,
  PAYMENT_INSTALLMENT_SUCCESS,
} from "../../../Redux/Pages/Checkout/CheckoutTypes";
import { isAuth } from "../../../Utility/Utilities";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";
import PaypalExpress from "../PaypalExpress";
import RapydPayment from "../RapydPayment";
import StripeCheckout from "../StripeCheckout/StripeCheckout";
import DivideBuy from "../DivideBuy/DivideBuy";
const MobCkPaymentOption = (props: {
  mobileState: any;
  setMobileState: any;
  setShowAddressPopup: any;
  braintreeToken: any;
  addresses: any;
  addressPopup: any;
  setAddress: any;
  isBillingAddress: boolean;
  setBillingAddress: any;
  isEdit: boolean;
  setEdit: any;
  billingAddresses: any;
  radioStateClass: any;
  setSubmitLogin: any;
  submitStripeForm: any
  setSubmitStripeForm: any
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const addressResponse = useSelector((state: any) => state.addressResponse);
  const cartResponse = useSelector((state: any) => state.getCartResponse);
  const paypalExpressResponse = useSelector(
    (state: any) => state.paypalExpressReducer
  );

  const divideBuyResponse = useSelector((state: any) => state.divideBuyReducer);
  const paymentInstallmentResponse = useSelector((state: any) => state.paymentInstallmentReducer);

  const minPaymentAmountResponse = useSelector((state: any) => state.getPaymentMinAmountReducer);
  const [paymentMinAmount, setPaymentMinAmount] = useState<any>(null);

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });
  const [paypalData, setPaypalData] = useState<any>({});
  const [showBillingAddressDropdown, setBillingAddressDropdown] =
    useState(false);

  const [paymentOptionCode, setPaymentOptionCode] = useState<any>("braintree");
  const [paymentOptionIndex, setPaymentOptionIndex] = useState(0);
  const [showSelectedAddr, setShowSelectedAddr] = useState<any>(null);
  const [cardInstance, setCardInstance] = useState<any>(null);
  const [isDivideBuyEnable, setIsDividebuyEnable] = useState(false);

  useEffect(() => {
    dispatch(getPaymentMinAmountAction());
  },[])

  useEffect(() => {
    if (minPaymentAmountResponse.type === GET_PAYMENT_MIN_AMOUNT_SUCCESS) {
      setPaymentMinAmount(minPaymentAmountResponse?.data);
    }
  }, [minPaymentAmountResponse])

  useEffect(() => {
    if(divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_SUCCESS){
      window.location.href = divideBuyResponse?.data;
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    }else if (divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_REQUEST){
      props.setMobileState({
        ...props.mobileState,
        loading: true,
      });
    }
    else if (divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_FAILED){
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    }
   
  }, [divideBuyResponse])


  useEffect(() => {
    if (
      paypalExpressResponse &&
      paypalExpressResponse.type === CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS
    ) {
      setPaypalData(paypalExpressResponse.data);
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    } else if (
      paypalExpressResponse &&
      paypalExpressResponse.type === CREATE_PAYPAL_EXPRESS_TOKEN_FAILED
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    }
    else if (
      paypalExpressResponse &&
      paypalExpressResponse.type === CREATE_PAYPAL_EXPRESS_TOKEN_REQUEST
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: true,
      });
    }
  }, [paypalExpressResponse]);


  useEffect(() => {
    if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_REQUEST) {
      props.setMobileState({
        ...props.mobileState,
        loading: true,
      });
    } else if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_SUCCESS) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    }
    else if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_FAILED) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
      });
    }

  }, [paymentInstallmentResponse])


  useEffect(() => {
    if (props.mobileState && props.mobileState.step === 3) {
      if (props.mobileState.isClicked) {
        props.setMobileState({
          ...props.mobileState,
          isClicked: false,
          loading: true,
        });
        if (paymentOptionCode === "braintree") {
          (async () => {
            try {
              const { nonce } = await cardInstance.requestPaymentMethod();
              dispatch(
                onPlaceOrder({
                  nonce,
                  method_code: "braintree",
                })
              );
            } catch (error) {
              props.setMobileState({
                ...props.mobileState,
                loading: false,
                isClicked: false,
              });
            }
          })();
        } else if (
          paymentOptionCode !== "braintree" &&
          paymentOptionCode !== "paypal_express" && paymentOptionCode !== "stripe_payments"
        ) {
          dispatch(
            onPlaceOrder({
              nonce: null,
              method_code: paymentOptionCode,
            })
          );
        } else if (
          paymentOptionCode === "paypal_express" &&
          paypalData?.paypal_urls?.start
        ) {
          window.location.href = paypalData?.paypal_urls?.start || CHECKOUT;
        }else if (paymentOptionCode === "stripe_payments" ){
          props.setSubmitStripeForm(true);
        }
      }
    }
  }, [props.mobileState]);



  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      const isFree =
        checkoutResponse?.data?.cart.available_payment_methods?.find(
          (c: any) => c.code === "free"
        );
      const available_payment_methods = isFree
        ? checkoutResponse?.data?.cart.available_payment_methods?.filter(
          (c: { code: string }) => c.code === "free"
        )
        : checkoutResponse?.data?.cart.available_payment_methods?.filter(
          (c: any) => c.code !== "free"
        );
      const cart = checkoutResponse?.data?.cart;
      const divideBuyEnable = cart?.items?.filter((item: any) => item)?.every(checkIsDivideBuyEnable);
      setIsDividebuyEnable(divideBuyEnable);
      cart.available_payment_methods = available_payment_methods;
      setDeliveryMethodData(cart);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_SUCCESS
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Place Order",
        step: 1,
      });
      navigate(ORDER_SUCCESS + "?fromCheckout=True");
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_FAILED
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Place Order",
      });
      navigate(CHECKOUT);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS
    ) {
      const isFree = checkoutResponse?.data?.available_payment_methods?.find(
        (c: any) => c.code === "free"
      );
      const available_payment_methods = isFree
        ? checkoutResponse?.data?.available_payment_methods.filter(
          (c: { code: string }) => c.code === "free"
        )
        : checkoutResponse?.data?.available_payment_methods.filter(
          (c: any) => c.code !== "free"
        );
      const cart = checkoutResponse?.data;
      cart.available_payment_methods = available_payment_methods;
      const divideBuyEnable = cart?.items?.filter((item: any) => item)?.every(checkIsDivideBuyEnable);
      setIsDividebuyEnable(divideBuyEnable);
      setPaymentOptionCode(available_payment_methods[0]?.code);
      setDeliveryMethodData(cart);
    }
  }, [checkoutResponse]);

  function checkIsDivideBuyEnable(item:any) {
    if (item.__typename === "SimpleCartItem") {
        // Check dividebuy_enable for SimpleCartItem
        return item.product && item.product.dividebuy_enable === true;
    } else if (item.__typename === "ConfigurableCartItem") {
        // Check configured_variant and dividebuy_enable for ConfigurableCartItem
        return (
            item.configured_variant &&
            item.configured_variant.dividebuy_enable === true
        );
    } else {
        // Handle other cases if needed
        return false;
    }
}

  function handleSelectChange(event: any) {
    if (!isAuth() && Number(event.target.value) === 0) {
      props.setAddress(null);
      props.setEdit(false);
      props.setBillingAddress(Number(event.target.value) === 0);
    }
    props.setShowAddressPopup(Number(event.target.value) === 0);
    const address = props.addresses.addresses?.find(
      (add: { id: number }) => add.id === Number(event.target.value)
    );
    if (Number(event.target.value)) {
      dispatch(
        setBillingAddress({ customerAddressId: Number(event.target.value) })
      );
    }
    setShowSelectedAddr(address);
  }

  const setBillingDrop = (e: boolean) => {
    if (e) {
      if (cartResponse.type === GET_CART_PAGE_SUCCESS) {
        const shippingAddress = cartResponse.data.shipping_addresses[0];
        if (shippingAddress) {
          const addressId = addressResponse?.data?.addresses?.find(
            (d: any) =>
              d.telephone === shippingAddress.telephone &&
              d.street.join() === shippingAddress.street.join() &&
              d.firstname === shippingAddress.firstname &&
              d.lastname === shippingAddress.lastname &&
              d.region.region === shippingAddress.region.region
          )?.id;
          if (addressId) {
            dispatch(
              setBillingAddress({
                customerAddressId: Number(addressId),
              })
            );
          }
        }
      }
    }
    setBillingAddressDropdown(!e);
  };

  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <p className="tm">Payment Options</p>
      </div>
      {cartResponse.data &&
        cartResponse.data.items &&
        cartResponse.data.items.length > 0 &&
        deliveryMethodData?.shipping_addresses &&
        deliveryMethodData?.shipping_addresses[0] &&
        deliveryMethodData?.shipping_addresses[0].selected_shipping_method ? (
        <div className="payment-box">
          {deliveryMethodData?.available_payment_methods
            ?.filter((data: any) => data)
            .map((data1: any, index: any) => {
              if(data1.code === "dbpayment" && !isDivideBuyEnable ){
                return null;
            }
            else if (data1.code === "dbpayment" && paymentMinAmount &&  cartResponse?.data?.prices?.grand_total?.value  < Number(paymentMinAmount) ){
              return null;
            }
              return    <div key={data1.title + index} className="delivery-method">
              <div className="rd" key={index}>
                <input
                  type="radio"
                  name="payment-selected"
                  id={data1.code}
                  defaultChecked={props.radioStateClass === index}
                  checked={index === paymentOptionIndex}
                  onChange={() => {
                    setPaymentOptionCode(data1.code);
                    setPaymentOptionIndex(index);
                    if (data1.code === "paypal_express" || data1.code === "stripe_payments" || data1.code === "dbpayment") {
                      props.setMobileState({
                        ...props.mobileState,
                        notShow: false,
                      });
                    }
                  }}
                />
                <label className="bm" htmlFor={data1.code}>
                  {data1?.title}
                </label>
              </div>
              {data1.code === paymentOptionCode ? (
                <>
                  {paymentOptionCode !== "paypal_express" &&
                    paymentOptionCode !== "rapyd_card" && paymentOptionCode !== "stripe_payments" && data1.code !== "dbpayment" && (
                      <div className="cod payment-action-content">
                        <CheckboxGroups
                          onChange={(e: any) => {
                            setBillingDrop(e.target.checked);
                          }}
                          defaultChecked={!showBillingAddressDropdown}
                          id="unselect-addresses"
                        >
                          Bill to delivery address
                        </CheckboxGroups>
                      </div>
                    )}
                  {paymentOptionCode !== "paypal_express" &&
                    showBillingAddressDropdown ? (
                    <div className="list-addresses">
                      <div className="select-group">
                        <select
                          value={showSelectedAddr?.id}
                          onChange={handleSelectChange}
                        >
                          {isAuth()
                            ? props.addresses.addresses?.map(
                              (item: any, index2: number) => (
                                <option
                                  key={index2 + item.firstname}
                                  value={item.id}
                                >
                                  {item.firstname + " " + item.lastname}
                                  {item.street?.join(", ") +
                                    ", " +
                                    (item.region?.region
                                      ? item.region?.region + ","
                                      : "") +
                                    props.addresses.country_name +
                                    ", " +
                                    item.postcode}
                                </option>
                              )
                            )
                            : props.billingAddresses
                              ?.filter((data: any) => data)
                              .map((item: any, index2: number) => (
                                <option
                                  key={index2 + item.firstname}
                                  value={item.id}
                                >
                                  {item.firstname + " " + item.lastname}
                                  {item.street?.join(", ") +
                                    ", " +
                                    (item.region?.region
                                      ? item.region?.region + ","
                                      : "") +
                                    props.addresses.country_name +
                                    ", " +
                                    item.postcode}
                                </option>
                              ))}
                          {isAuth() ? (
                            <option value="0">New Address</option>
                          ) : null}
                        </select>
                      </div>
                      {isAuth()
                        ? props.addresses.addresses
                          ?.filter((data: any) => data)
                          .map((item: any) =>
                            item?.id === showSelectedAddr?.id ? (
                              <p key={item?.id} className="bm">
                                <div className="ckout-txt">
                                  <p className="bm">
                                    {item.firstname + " " + item.lastname}
                                  </p>
                                  <p className="bm">
                                    {item.street?.join(", ") +
                                      ", " +
                                      (item.region?.region
                                        ? item.region?.region + ","
                                        : "") +
                                      props.addresses.country_name +
                                      ", " +
                                      item.postcode}
                                  </p>
                                  <Link to={""} className="bm">
                                    {item.telephone}
                                  </Link>
                                </div>
                              </p>
                            ) : null
                          )
                        : props.billingAddresses
                          ?.filter((data: any) => data)
                          .map((item: any) =>
                            item?.id === showSelectedAddr?.id ? (
                              <p key={item?.id} className="bm">
                                <div className="ckout-txt">
                                  <p className="bm">
                                    {item.firstname + " " + item.lastname}
                                  </p>
                                  <p className="bm">
                                    {item.street?.join(", ") +
                                      ", " +
                                      (item.region?.region
                                        ? item.region?.region + ","
                                        : "") +
                                      props.addresses.country_name +
                                      ", " +
                                      item.postcode}
                                  </p>
                                  <Link to={""} className="bm">
                                    {item.telephone}
                                  </Link>
                                </div>
                              </p>
                            ) : null
                          )}
                    </div>
                  ) : null}
                  <div className={`${data1.code != "dbpayment" ? "credit-card-content" : ""}`}>
                    {data1.code === "braintree" ? (
                      <div>
                        <DropIn
                          options={{
                            cvv: {
                              maskInput: true,
                            },
                            authorization: props.braintreeToken,
                          }}
                          onError={(error) => {
                            console.error("DROPIN ERROR ", error?.message);
                          }}
                          onInstance={(cardInstance: any) =>
                            setCardInstance(cardInstance)
                          }
                        />
                      </div>
                    ) : null}
                    {data1.code === "paypal_express" ? (
                      <PaypalExpress
                        setSubmitLogin={props.setSubmitLogin}
                        showContinueBtn={false}
                      />
                    ) : null}
                     {data1.code === "dbpayment" && isDivideBuyEnable ? (
                      <DivideBuy
                      code = "dbpayment"
                      showPlaceOrder={false}
                      />
                    ) : null}
                    {data1.code === "rapyd_card" && (
                      <RapydPayment
                        // checkoutPayment={checkoutPayment}
                        // setCheckoutPayment={setCheckoutPayment}
                        setMobileState={props.setMobileState}
                        mobileState={props.mobileState}
                        checkoutResponse={checkoutResponse}
                        cartResponse={cartResponse}
                        setSubmitLogin={props.setSubmitLogin}
                        showContinueBtn={true}
                      />
                    )}
                    {
                      data1.code === "stripe_payments" && (
                        <StripeCheckout
                          submitStripeForm={props.submitStripeForm}
                          setSubmitStripeForm={props.setSubmitStripeForm}
                          mobileState={props.mobileState}
                          setMobileState={props.setMobileState}
                          addressData={props.addresses}
                          setSubmitLogin={() => {
                            
                          }}
                        />
                      )
                    }
                  </div>
                </>
              ) : null}
            </div>
            })}
        </div>
      ) : null}
    </div>
  );
};

export default MobCkPaymentOption;
