import Skeleton from "react-loading-skeleton";

const BlogDetailsSkl = () => {
  return (
    <div className="blogdetail-wrap skl">
      <div className="blog-container">
        <div className="bread-skl">
          <Skeleton />
        </div>
        <div className="blog-section skl">
          <div className="left-content skl">
            <h4><Skeleton /></h4>
            <div className="extra-infoskl">
              <Skeleton />
            </div>
            <div className="blog-img">
              <Skeleton />
            </div>
            <div className="blog-content skl">
              <div className="subtitle">
                <Skeleton />
              </div>
              <div className="para-skl one">
                <Skeleton />
              </div>
              <div className="para-skl two">
                <Skeleton />
              </div>
              <div className="para-skl three">
                <Skeleton />
              </div>
              <div className="para-skl four">
                <Skeleton />
              </div>
              <div className="blog-img in">
                <Skeleton />
                <div className="inside-img"><Skeleton /></div>
              </div>
              <div className="para-skl five">
                <Skeleton />
              </div>
              <div className="para-skl six">
                <Skeleton />
              </div>
              <div className="para-skl sev">
                <Skeleton />
              </div>
              <div className="blog-img in">
                <Skeleton />
                <div className="inside-img"><Skeleton /></div>
              </div>
              <div className="para-skl eight">
                <Skeleton />
              </div>
              <div className="actionbar">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          </div>
          <div className="right-content skl">
            <div className="sear-blog box">
              <div className="box-title"><Skeleton /></div>
              <div className="sear-input">
                <Skeleton />
              </div>
              <div className="sear-action">
                <Skeleton />
              </div>
            </div>
            <div className="recent-posts box">
              <div className="box-title"><Skeleton /></div>
              <ul className="recent-list">
                <Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton />
              </ul>
            </div>
            <div className="categories box">
              <div className="box-title"><Skeleton /></div>
              <ul className="recent-list">
                <Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetailsSkl;