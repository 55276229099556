import React, { useEffect } from 'react'
import { Modal } from "react-bootstrap";

function CheckDivideBuyEligibilityPopup(props: any) {

  useEffect(() => {
    function receiver(e:any) {
      if (e.data == 'soft-search-popup-close') {
        props.onHide();
      }
    }
      window.addEventListener('message', receiver, false);       
      return () => {
        window.removeEventListener("message" , receiver)
      }
  },[])


  return (
    <Modal
      {...props}
      centered
      className="eligibility_popup"
    >
      <Modal.Header closeButton onClick={() => props.onHide()}>
      </Modal.Header>
      <Modal.Body >
        <div style={{marginTop: "-10px"}}>
          {props?.loading ? <div className="dividebuy-loader"></div> : null}
          <iframe style={{ border: "0px", minHeight: "750px", overflow: "auto" }} id="frame-dividebuy-softcredit-check" src={`${process.env.REACT_APP_DIVIDEBUY_SOFTKEY_URL}?splashKey=${props?.splashKey}`} width="100%" className="frame" />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CheckDivideBuyEligibilityPopup
