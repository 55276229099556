import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menu_close from "../../Assets/Images/close.svg";
import {
  LABEL_CONTACT_US,
  LABEL_CREATE_ACCOUNT,
  LABEL_FINANCE,
  LABEL_LOGIN,
  LABEL_MY_ACCOUNT,
} from "../../Constants/Labels";
import {
  CONTACT_US,
  INTEREST_FREE_CREDIT,
  LOGIN,
  MY_DETAIL,
  MY_WISHLIST,
  SIGN_UP,
} from "../../Constants/Route";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";

const MobileMainNavigation = (props: any) => {
  const [freeSampleModalShow, setFreeSampleModalShow] = useState(false);

  const [data, setData] = useState<any>({});

  const dataLogin = useSelector((state: any) => state.loginResponse?.data);

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  const headerResponse = useSelector(
    (state: any) => state?.categoryListResponse?.data?.categories
  );

  useEffect(() => {
    if (headerResponse) {
      setData(headerResponse);
    }
  }, [headerResponse]);

  return (
    <>
      <div
        className={`hide-767 app-nav ${props.toggleSide ? "nav--visible" : ""}`}
      >
        <div className="sidebar-container">
          <div className="top-section">
            <div className="naviLevel title">
              <img
                src={`${process.env.REACT_APP_BASE_MEDIA_URL}logo/${process.env.REACT_APP_HEADER_LOGO_SRC}`}
                alt="Livingitup"
              />
            </div>
            <button className="dismiss-mob-nav" onClick={props.toggleMenu}>
              <img src={menu_close} alt="Close Menu"></img>
            </button>
          </div>
          <div className="mob-links">
            <ul className="top-part">
              <Accordion>
                {data?.items?.filter((item: any) => item.include_in_menu === 1)
                  .map((info: any, indexno: any) => (
                    <Accordion.Item key={info.url_key} eventKey={indexno}>
                      <Accordion.Header className="ll">
                        <Link
                          onClick={props.toggleMenu}
                          to={info.url_key + info.url_suffix}
                        >
                          {info.name}
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="inner-content">
                          {info?.children?.filter((item: any) => item.include_in_menu === 1).map((info1: any, indexno1: any) => (
                            <li
                              key={info1.url_path}
                              className="submenu-items ll"
                            >
                              <Link
                                className="ll"
                                to={"/" + info1.url_path + info.url_suffix}
                                onClick={props.toggleMenu}
                              >
                                {info1.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
              <li className="sub-lower-item">
                <Link
                  className="ll"
                  to={INTEREST_FREE_CREDIT}
                  onClick={props.toggleMenu}
                  title="Finance"
                >
                  {LABEL_FINANCE}
                </Link>
              </li>
              <li className="sub-lower-item">
                <Link
                  className="ll"
                  to={CONTACT_US}
                  onClick={props.toggleMenu}
                  title="Contact Us"
                >
                  {LABEL_CONTACT_US}
                </Link>
              </li>
              <li
                className="sub-lower-item ll"
                onClick={() => setFreeSampleModalShow(true)}
              >
                Free Fabric Samples
              </li>
            </ul>
            <ul className="user-specific-link">
              {!dataLogin ? (
                <>
                  <li>
                    <Link
                      onClick={props.toggleMenu}
                      className="ll login"
                      to={LOGIN}
                      title={LABEL_LOGIN}
                    >
                      {LABEL_LOGIN}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={props.toggleMenu}
                      className="ll c-acc"
                      to={SIGN_UP}
                      title={LABEL_CREATE_ACCOUNT}
                    >
                      {LABEL_CREATE_ACCOUNT}
                    </Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link
                    onClick={props.toggleMenu}
                    className="ll c-acc"
                    to={MY_DETAIL}
                    title={LABEL_MY_ACCOUNT}
                  >
                    {LABEL_MY_ACCOUNT}
                  </Link>
                </li>
              )}
              <li>
                <Link
                  onClick={props.toggleMenu}
                  className="ll wish"
                  to={MY_WISHLIST}
                  title={"Wishlist"}
                >
                  {"Wishlist"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FreeSamplesPopup
        storeConfigInfo={storeConResponse}
        show={freeSampleModalShow}
        onHide={() => setFreeSampleModalShow(false)}
      />
    </>
  );
};

export default MobileMainNavigation;
