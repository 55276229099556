/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { IImage } from "../../Interfaces/Common.interface";
import ReactPlayer from "react-player/lazy";
import { scrollTop } from "../../Utility/Utilities";

import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Settings from "./zoom-image/Settings";
import Video from "yet-another-react-lightbox/plugins/video";
import SliderFilter from "./zoom-image/SliderFilter";

const ProductImageSlider = (props: { media_gallery: IImage[] }) => {
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const preImageSilderLength: any = useRef();
  const [mainImgSlider, setMainImgSlider] = useState({
    swipeToSlide: false,
    asNavFor: nav2,
    arrows: false,
    slidesToScroll: 1,
    ref: (slider1: any) => setNav1(slider1),
  });

  // function usePrevious<T>(value: T): T | undefined {
  //   const ref = useRef<T>();
  //   useEffect(() => {
  //     ref.current = value;
  //   }, [value]);
  //   return ref.current;
  // }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (nav2 && !preImageSilderLength.current) {
      preImageSilderLength.current = nav2?.props?.children?.length;
    }
  }, [nav2]);

  const thumbImgSlider = {
    asNavFor: nav1,
    ref: (slider2: any) => setNav2(slider2),
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3.3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4.3,
        },
      },
    ],
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    scrollTop();
  }, []);

  useEffect(() => {
    if (props.media_gallery) {
      const mainImageSlider = Object.assign({}, mainImgSlider);
      mainImageSlider.ref((slider1: any) => {
        slider1.slickGoTo(0);
        setNav1(slider1);
        thumbImgSlider.ref((slider2: any) => {
          if (slider2) {
            // goto 0 if slider is not at 0 or change in product image count [preventing scrolling]
            if (
              nav2?.innerSlider?.state?.targetSlide !== 0 ||
              preImageSilderLength?.current !== props.media_gallery.length
            ) {
              slider2.slickGoTo(0);
              setTimeout(() => {
                window.scrollTo(0, scrollPosition);
              }, 0);
            }
            preImageSilderLength.current = props.media_gallery.length;
            setNav2(slider2);
          }
        });
      });
      setMainImgSlider(mainImageSlider);
    }
  }, [props.media_gallery]);

  useEffect(() => {
    if (nav1) {
      thumbImgSlider.ref((slider2: any) => {
        slider2.slickGoTo(0);
        setNav2(slider2);
      });
    }
    // window.scrollTo(0, 0);
    scrollTop();
  }, [nav1]);

  // const newMainImgSlider = {
  //   slidesToShow: 5,
  //   swipeToSlide: true,
  //   focusOnSelect: true,
  //   slidesToScroll: 1,
  //   infinite: false,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 3.3,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 4.3,
  //       },
  //     },
  //   ],
  // };

  //const [bigImg, setBigImg] = useState<any>(props?.media_gallery?.length ? props?.media_gallery[0]?.url : null);
  // const [hideStz, setHideStz] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setHideStz(true);
  //   }, 4000);
  //   return () => clearTimeout(timer);
  // }, []);

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(4);

  return (
    <>
      <Settings>
        <SliderFilter
          label="maxZoomPixelRatio"
          value={maxZoomPixelRatio}
          onChange={(value: any) => setMaxZoomPixelRatio(value)}
        />
      </Settings>

      <Slider className="preview-slider" {...mainImgSlider}>
        {props.media_gallery
          ? props.media_gallery
              .filter((m) => !m.disabled)
              .sort((a, b) => (a.position > b.position ? 1 : -1))
              .map((media_g: IImage, indx: number) => {
                return media_g.video_content ? (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      width="100%"
                      height="100%"
                      url={media_g.video_content.video_url}
                      playing={false}
                      controls={true}
                    />
                  </div>
                ) : (
                  <img
                    key={media_g.label}
                    src={media_g.url}
                    alt={media_g.label}
                    onClick={() => {
                      setOpen(true);
                      setIndex(indx);
                    }}
                  />
                );
              })
          : null}
      </Slider>
      <Slider className="thumbnail-slider" {...thumbImgSlider}>
        {props.media_gallery
          ? props.media_gallery
              .filter((m) => !m.disabled)
              .sort((a, b) => (a.position > b.position ? 1 : -1))
              .map((media_g: IImage) => {
                return (
                  <div key={media_g.label} className="point">
                    <img
                      key={media_g.label}
                      src={media_g.url}
                      alt={media_g.label}
                    />
                  </div>
                );
              })
          : null}
      </Slider>
      {/* <br></br><hr></hr><br></br> */}
      {/* <div className="right-big-img">
        <div className={`STZ ${(hideStz ? 'hide' : '')}`}><p>Tap and Hold to Zoom</p></div>
        <ReactImageMagnify {...{
          smallImage: {
            isFluidWidth: true,
            src: bigImg
          },
          largeImage: {
            src: bigImg,
            width: 1000,
            height: 750,
          },
          enlargedImageContainerClassName: "enlarge-img",
          isActivatedOnTouch: true,
          enlargedImagePosition: "over",
        }} />
      </div> */}
      {/* <Slider className="thumbnail-slider" {...newMainImgSlider}>
        {props.media_gallery
          ? props.media_gallery
            .filter((m) => !m.disabled)
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((media_g: IImage) => {
              return (
                <img key={media_g.label} src={media_g.url} onClick={() => setBigImg(media_g.url)} alt={media_g.label} />
              );
            })
          : null}
      </Slider> */}
      {/* <br></br><hr></hr><br></br> */}
      <Lightbox
        index={index}
        plugins={[Thumbnails, Zoom, Video]}
        open={open}
        close={() => setOpen(false)}
        slides={[
          ...props.media_gallery
            .filter((c) => !c.video_content)
            .map((c) => {
              return {
                src: c.url,
              };
            }),
        ]}
        zoom={{
          maxZoomPixelRatio,
        }}
      />
    </>
  );
};

export default ProductImageSlider;
