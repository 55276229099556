/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import BlogDetailsSkl from "./BlogDetailsSkl";
import { useLocation } from "react-router";
import { getBlogDetailsAction } from "../../Redux/Pages/Blog/BlogPageAction";
import { useDispatch, useSelector } from "react-redux";
import {
  BLOG_DETAIL_PAGE_SUCCESS,
  BLOG_PAGE_GET_CATEGORIES_SUCCESS,
  BLOG_PAGE_GET_RECENT_POST_SUCCESS,
} from "../../Redux/Pages/Blog/BlogPageTypes";
import moment from "moment";
import SideBlog from "./SideBlog";
import { setSessionItem } from "../../Utility/Utilities";
import { PRODUCT_DETAILS_PREVIOUS_URL } from "../../Constants/LocalStorage";

const BlogDetails = () => {
  const INITIAL_SCRUM = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "blog.html",
      label: "Blog",
    },
  ];

  const [breadSc, setBreadScrumbs] = useState(INITIAL_SCRUM);

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const dispatch = useDispatch();

  const blogResponse = useSelector((state: any) => state?.blogListResponse);

  const [sideBlog, setSideBlog] = useState({
    categoryList: [],
    recentPost: [],
  });

  useEffect(() => {
    if (location && location.pathname) {
      setBreadScrumbs(INITIAL_SCRUM);
      const blogName =
        location.pathname.split("/")[2] === "category"
          ? location.pathname.split("/")[3]
          : location.pathname.split("/")[2];
      dispatch(getBlogDetailsAction(blogName.split(".html")[0]));
    }
  }, [location]);

  const [detailsData, setDetailsData] = useState<any>({});

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  useEffect(() => {
    if (blogResponse && blogResponse.type === BLOG_DETAIL_PAGE_SUCCESS) {
      setDetailsData(blogResponse?.data?.amBlogPost);
      const currentScrumbs = Object.assign(INITIAL_SCRUM, breadSc);
      currentScrumbs.push({
        path: "",
        label: blogResponse?.data?.amBlogPost.title,
      });
      setBreadScrumbs(currentScrumbs);
      setLoading(false);
    }
    if (blogResponse.type === BLOG_PAGE_GET_CATEGORIES_SUCCESS) {
      setSideBlog({
        ...sideBlog,
        categoryList: blogResponse?.data?.amBlogCategories?.items,
      });
    }
    if (blogResponse.type === BLOG_PAGE_GET_RECENT_POST_SUCCESS) {
      setSideBlog({
        ...sideBlog,
        recentPost: blogResponse?.data?.amBlogRecentPostsWidget?.items,
      });
    }
  }, [blogResponse, storeConResponse]);

  return (
    <>
      {!loading ? (
        <div className="blogdetail-wrap">
          <div className="blog-container">
            <Breadcrumbs value={breadSc}></Breadcrumbs>
            <div className="blog-section">
              <div className="left-content">
                <h4>{detailsData.title}</h4>
                <div className="extra-info">
                  <div className="bm author">
                    By{" "}
                    <Link to={""} title={detailsData.author_name}>
                      {detailsData.author_name}
                    </Link>
                  </div>
                  <div className="bm pub-date">
                    <p className="bm">
                      {moment(detailsData?.updated_at).format("D MMM YYYY")}
                    </p>
                  </div>
                </div>
                {detailsData.post_thumbnail && (
                  <div className="blog-img">
                    <img
                      src={detailsData.post_thumbnail}
                      title={detailsData.title}
                      alt={detailsData.title}
                    />
                  </div>
                )}
                {detailsData.full_content && (
                  <div
                    className="blog-content"
                    onClick={() => {
                      setSessionItem(PRODUCT_DETAILS_PREVIOUS_URL, location.pathname)
                    }}
                    dangerouslySetInnerHTML={{
                      __html: detailsData.full_content,
                    }}
                  ></div>
                )}
              </div>
              <div className="right-content">
                <SideBlog
                  recentPost={sideBlog.recentPost}
                  categoryList={sideBlog.categoryList}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BlogDetailsSkl />
      )}
    </>
  );
};

export default BlogDetails;
