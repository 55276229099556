const ProductColorbox = (props: {
  uid: string;
  thumbnail: string;
  label: string;
  isChecked: boolean;
  onClicked: any;
}) => {
  const onSelectBox = (e: any) => {
    if (e.target.checked) {
      props.onClicked({
        isChecked: e.target.checked,
        label: props.label,
        uid: props.uid,
        type: "color",
      });
    }
  };

  const getColorCode = (c: any) => {
    return c?.image?.includes("#") ? (
      <span style={{ background: `${c.image}` }} />
    ) : c?.thumbnail ? (
      <img src={c?.thumbnail} alt={c.label} title={c.label} />
    ) : (
      <span style={{ background: `${c.value}` }} />
    );
  };

  return (
    <div className={`samp-tick  ${props.isChecked ? "selected" : ""}`}>
      <input
        onClick={onSelectBox}
        type="checkbox"
        name={props.label}
        id={props.label}
        checked={props.isChecked}
        value={props.label}
      />
      <label htmlFor={props.label}>{getColorCode(props)}</label>
    </div>
  );
};

export default ProductColorbox;
