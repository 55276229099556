import {
  ERROR,
  KEY_USER_TOKEN,
  SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
} from "../../Constants/Labels";
import { CART_ID } from "../../Constants/LocalStorage";
import {
  createCustomerAddress,
  deleteCustomerAddress,
  getCountryName,
  getUserAddresses,
  mergerCart,
  updateCustomerAddress,
} from "../../Services/graphQL.service";
import {
  login,
  register,
  getUserDetail as getUser,
  changePassword,
  changeEmail,
  logout,
  updateGuestUserNewsLetter,
  updateLoggedInNewsLet,
  forgotPassrd,
  resetPasswor,
} from "../../Services/User.service";
import { addressLookup, postcodeLookup } from "../../Services/vendor.service";
import {
  clearSession,
  getSessionItem,
  setSessionItem,
  showToast,
} from "../../Utility/Utilities";
import { createEmptyCartAction } from "../Pages/CartPage/CartPageActions";
import {
  ADDRESS_SEARCH_LOOKUP_FAILED,
  ADDRESS_SEARCH_LOOKUP_SUCCESS,
  CHANGE_ACCOUNT_FAILED,
  CHANGE_ACCOUNT_REQUEST,
  CHANGE_ACCOUNT_SUCCESS,
  CREATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_ADDRESS_SUCCESS,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  POSTCODE_LOOKUP_FAILED,
  POSTCODE_LOOKUP_SUCCESS,
  REGISTER_FAILED,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_NEWS_LETTER_FAILED,
  UPDATE_NEWS_LETTER_REQUEST,
  UPDATE_NEWS_LETTER_SUCCESS,
} from "./AuthType";

export function onRegistration(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
        payload: { type: REGISTER_REQUEST },
      });
      const { data: response } = await register(payload);
      const { createCustomerV2: graphQLResponse } = response.data;
      if (graphQLResponse) {
        showToast(SUCCESS, USER_REGISTER_SUCCESS);
        dispatch(loginAction(payload));
        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            type: REGISTER_SUCCESS,
            data: graphQLResponse?.customer,
          },
        });
      }
      if (response.errors && response.errors[0]) {
        dispatch({
          type: REGISTER_FAILED,
          payload: {
            type: REGISTER_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE REGISTERING ", error);
      showToast(ERROR, USER_REGISTER_FAIL);
      dispatch({
        type: REGISTER_FAILED,
        payload: { type: REGISTER_FAILED, message: error?.message },
      });
    }
  };
}

export function loginAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
        payload: { type: LOGIN_REQUEST },
      });
      const { data: response } = await login(payload);
      const data = response?.data?.generateCustomerToken?.token;
      if (data) {
        setSessionItem(KEY_USER_TOKEN, data);
        const sourceCartId = getSessionItem(CART_ID);
        if (sourceCartId) {
          const { data: cartResponse } = await mergerCart({ sourceCartId });
          setSessionItem(CART_ID, cartResponse?.data?.mergeCarts?.id);
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            type: LOGIN_SUCCESS,
            data,
          },
        });
      } else if (response && response.errors[0] && response.errors[0].message) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: LOGIN_FAILED,
          payload: {
            type: LOGIN_FAILED,
            message: response.errors[0].message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, "Something went wrong");
      dispatch({
        type: LOGIN_FAILED,
        payload: { type: LOGIN_FAILED, message: error?.message },
      });
    }
  };
}

export function changePasswordAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CHANGE_ACCOUNT_REQUEST,
        payload: { type: CHANGE_ACCOUNT_REQUEST },
      });
      const { data: response } = await changePassword(payload);
      const data = response?.data?.changeCustomerPassword?.email;
      if (data) {
        showToast(SUCCESS, "Password updated successfully");
        dispatch({
          type: CHANGE_ACCOUNT_SUCCESS,
          payload: {
            type: CHANGE_ACCOUNT_SUCCESS,
            data,
          },
        });
      } else {
        showToast(ERROR, response.errors[0]?.message);
        dispatch({
          type: CHANGE_ACCOUNT_FAILED,
          payload: {
            type: CHANGE_ACCOUNT_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      console.error("SOMETHING WENT WRONG WHILE LOGIN ", error);
      dispatch({
        type: CHANGE_ACCOUNT_FAILED,
        payload: { type: CHANGE_ACCOUNT_FAILED, message: error?.message },
      });
    }
  };
}

export function changeEmailAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CHANGE_ACCOUNT_REQUEST,
        payload: { type: CHANGE_ACCOUNT_REQUEST },
      });
      const { data: response } = await changeEmail(payload);
      const data = response?.data?.updateCustomer?.customer;
      if (data) {
        showToast(SUCCESS, "Email updated successfully.");
        dispatch({
          type: CHANGE_ACCOUNT_SUCCESS,
          payload: {
            type: CHANGE_ACCOUNT_SUCCESS,
            data,
          },
        });
      } else if (response && response.errors) {
        showToast(ERROR, response.errors[0]?.message);
        dispatch({
          type: CHANGE_ACCOUNT_FAILED,
          payload: {
            type: CHANGE_ACCOUNT_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      console.error("SOMETHING WENT WRONG WHILE LOGIN ", error);
      dispatch({
        type: CHANGE_ACCOUNT_FAILED,
        payload: { type: CHANGE_ACCOUNT_FAILED, message: error?.message },
      });
    }
  };
}

export function getUserDetail(isFromHomePage: boolean) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getUser(isFromHomePage);
      const data = response?.data?.customer;
      dispatch({
        type: GET_USER_DETAIL_SUCCESS,
        payload: {
          type: GET_USER_DETAIL_SUCCESS,
          data,
        },
      });
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE FETCHING USER DETAIL ", error);
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: GET_USER_DETAIL_FAILED,
        payload: { type: GET_USER_DETAIL_FAILED, message: error?.message },
      });
    }
  };
}

export function logoutAction() {
  return async (dispatch: any) => {
    try {
      await logout();
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: {
          type: LOGOUT_SUCCESS,
        },
      });
      clearSession();
      dispatch(createEmptyCartAction());
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: null,
      });
      clearSession();
    }
  };
}

export function updateNewsLetterData(
  payload: any,
  isGuestUser: boolean,
  firstname: string = ""
) {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_NEWS_LETTER_REQUEST,
      payload: { type: UPDATE_NEWS_LETTER_REQUEST },
    });
    try {
      if (isGuestUser) {
        const { data: response } = await updateGuestUserNewsLetter(payload);
        const data = response?.data?.subscribeEmailToNewsletter?.status;
        if (data) {
          showToast(SUCCESS, "Newsletter updated successfully");
          dispatch({
            type: UPDATE_NEWS_LETTER_SUCCESS,
            payload: {
              type: UPDATE_NEWS_LETTER_SUCCESS,
              data,
            },
          });
        } else if (response.errors) {
          showToast(ERROR, response.data?.errors[0]?.message);
          dispatch({
            type: UPDATE_NEWS_LETTER_FAILED,
            payload: {
              type: UPDATE_NEWS_LETTER_FAILED,
              data: response.data?.errors[0]?.message,
            },
          });
        }
      } else {
        const { data: response } = await updateLoggedInNewsLet(
          payload,
          firstname
        );
        const data =
          response?.data?.updateCustomerV2?.customer?.is_subscribed !==
          undefined;
        if (response && response.errors && response.errors.length > 0) {
          showToast(ERROR, response.errors[0]?.message);
          dispatch({
            type: UPDATE_NEWS_LETTER_FAILED,
            payload: {
              type: UPDATE_NEWS_LETTER_FAILED,
              data: response.errors[0]?.message,
            },
          });
          return;
        }
        if (data) {
          showToast(SUCCESS, "Newsletter updated successfully");
          dispatch({
            type: UPDATE_NEWS_LETTER_SUCCESS,
            payload: {
              type: UPDATE_NEWS_LETTER_SUCCESS,
              data,
            },
          });
        } else {
          showToast(ERROR, response.errors[0]?.message);
          dispatch({
            type: UPDATE_NEWS_LETTER_FAILED,
            payload: {
              type: UPDATE_NEWS_LETTER_FAILED,
              data: response.errors[0]?.message,
            },
          });
        }
      }
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE FETCHING USER DETAIL ", error);
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: UPDATE_NEWS_LETTER_FAILED,
        payload: { type: UPDATE_NEWS_LETTER_FAILED, message: error?.message },
      });
    }
  };
}

export function forgotPasswordAction(payload: any) {
  return async (dispatch: any) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: { type: FORGOT_PASSWORD_REQUEST },
    });
    try {
      const { data: response } = await forgotPassrd(payload);
      const data = response?.data?.requestPasswordResetEmail !== null;
      if (data) {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: {
            type: FORGOT_PASSWORD_SUCCESS,
            data,
          },
        });
      } else {
        showToast(ERROR, response?.errors[0]?.message);
        dispatch({
          type: FORGOT_PASSWORD_FAILED,
          payload: {
            type: FORGOT_PASSWORD_FAILED,
            message: response?.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE FETCHING USER DETAIL ", error);
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: FORGOT_PASSWORD_FAILED,
        payload: { type: FORGOT_PASSWORD_FAILED, message: error?.message },
      });
    }
  };
}

export function resetPasswordAction(payload: any) {
  return async (dispatch: any) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      payload: { type: RESET_PASSWORD_REQUEST },
    });
    try {
      const { data: response } = await resetPasswor(payload);
      const data = response?.data?.resetPassword;
      if (data) {
        showToast(SUCCESS, "Password changed successfully");
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: {
            type: RESET_PASSWORD_SUCCESS,
            data,
          },
        });
      } else if (response.errors) {
        showToast(ERROR, response?.errors[0]?.message);
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: {
            type: RESET_PASSWORD_FAILED,
            message: response?.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE FETCHING USER DETAIL ", error);
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: { type: RESET_PASSWORD_FAILED, message: error?.message },
      });
    }
  };
}

export function getAddresses() {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getUserAddresses();
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        if (
          response.data?.customer &&
          response.data?.customer?.addresses &&
          response.data?.customer?.addresses.length > 0
        ) {
          const { data: countryResponse } = await getCountryName(
            response.data?.customer?.addresses[0]?.country_code
          );
          if (countryResponse && countryResponse.errors) {
            showToast(ERROR, countryResponse.errors[0].message);
          } else {
            response.data.customer["country_name"] =
              countryResponse.data?.country?.full_name_locale;
            dispatch({
              type: GET_ADDRESS_SUCCESS,
              payload: {
                type: GET_ADDRESS_SUCCESS,
                data: response.data?.customer,
              },
            });
          }
        } else {
          dispatch({
            type: GET_ADDRESS_SUCCESS,
            payload: {
              type: GET_ADDRESS_SUCCESS,
              data: response.data?.customer,
            },
          });
        }
      }
    } catch (error: any) {
      showToast(ERROR, error);
    }
  };
}

export function deleteAddress(addressId: number) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await deleteCustomerAddress(addressId);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: DELETE_ADDRESS_FAILED,
          payload: {
            type: DELETE_ADDRESS_FAILED,
          },
        });
      } else {
        showToast(SUCCESS, "Address deleted successfully");
        dispatch({
          type: DELETE_ADDRESS_SUCCESS,
          payload: {
            type: DELETE_ADDRESS_SUCCESS,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, error);
    }
  };
}

export function createAddress(address: any) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await createCustomerAddress(address);
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        showToast(SUCCESS, "Address Created Successfully");
        dispatch({
          type: CREATE_ADDRESS_SUCCESS,
          payload: {
            type: CREATE_ADDRESS_SUCCESS,
            data: response.data.createCustomerAddress,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, error);
    }
  };
}

export function updateAddress(address: any) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await updateCustomerAddress(address);
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        showToast(SUCCESS, "Address Updated Successfully");
        dispatch({
          type: UPDATE_ADDRESS_SUCCESS,
          payload: {
            type: UPDATE_ADDRESS_SUCCESS,
            data: response.data?.updateCustomerAddress,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, error);
    }
  };
}

export const pincodeLookupAction = (pincode: string) => {
  return async (dispatch: any) => {
    try {
      const response = await postcodeLookup(pincode);
      const responseData = await response.json();
      if (responseData.error_msg) {
        dispatch({
          type: POSTCODE_LOOKUP_FAILED,
          payload: {
            type: POSTCODE_LOOKUP_FAILED,
            data: responseData.error_msg,
          },
        });
      } else {
        dispatch({
          type: POSTCODE_LOOKUP_SUCCESS,
          payload: {
            type: POSTCODE_LOOKUP_SUCCESS,
            data: responseData,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: POSTCODE_LOOKUP_FAILED,
        payload: {
          type: POSTCODE_LOOKUP_FAILED,
          data: error.message,
        },
      });
    }
  };
};

export const addressSearchLookupAction = (searchQuery: string) => {
  return async (dispatch: any) => {
    try {
      const response = await addressLookup(searchQuery);
      let responseData = await response.json();
      if (responseData.error_msg) {
        showToast(ERROR, responseData.error_msg);
        dispatch({
          type: ADDRESS_SEARCH_LOOKUP_FAILED,
          payload: {
            type: ADDRESS_SEARCH_LOOKUP_FAILED,
          },
        });
      } else {
        dispatch({
          type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
          payload: {
            type: ADDRESS_SEARCH_LOOKUP_SUCCESS,
            data: responseData?.results,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, error.message);
      dispatch({
        type: ADDRESS_SEARCH_LOOKUP_FAILED,
        payload: {
          type: ADDRESS_SEARCH_LOOKUP_FAILED,
        },
      });
    }
  };
};
