/* eslint-disable react-hooks/exhaustive-deps */
import DropIn from "braintree-web-drop-in-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import { CHECKOUT, ORDER_SUCCESS } from "../../Constants/Route";
import {
  CREATE_ADDRESS_SUCCESS,
  GET_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
} from "../../Redux/Auth/AuthType";
import { GET_CART_PAGE_SUCCESS } from "../../Redux/Pages/CartPage/CartPageTypes";
import {
  getPaymentMinAmountAction,
  onPlaceOrder,
  setBillingAddress,
} from "../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  DIVIDEBUY_ORDER_SUCCESS,
  DIVIDEBUY_SPLASH_KEY_FAILED,
  DIVIDEBUY_SPLASH_KEY_REQUEST,
  DIVIDEBUY_SPLASH_KEY_SUCCESS,
  GET_BRAINTREETOKEN_SUCCESS,
  GET_PAYMENT_MIN_AMOUNT_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
  PAYMENT_INSTALLMENT_FAILED,
  PAYMENT_INSTALLMENT_REQUEST,
  PAYMENT_INSTALLMENT_SUCCESS,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import { getSessionItem, isAuth, isGuestEmail, scrollTop } from "../../Utility/Utilities";
import CheckboxGroups from "../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../Common/CustomButton/CustomButton";
import PaypalExpress from "./PaypalExpress";
import RapydPayment from "./RapydPayment";
import StripeCheckout from "./StripeCheckout/StripeCheckout";
import DivideBuy from "./DivideBuy/DivideBuy";
import { getMinInstallmentAmountAction } from "../../Redux/General/GeneralAction";

const CheckoutPaymentOption = (props: {
  setAddressPopup: any;
  billingAddresses: any;
  addresses: any;
  setSubmitLogin: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const cartResponse = useSelector((state: any) => state.getCartResponse);
  const addressResponse = useSelector((state: any) => state.addressResponse);
  const divideBuyResponse = useSelector((state: any) => state.divideBuyReducer);
  const paymentInstallmentResponse = useSelector((state: any) => state.paymentInstallmentReducer);
  const minPaymentAmountResponse = useSelector((state: any) => state.getPaymentMinAmountReducer);

  const [paymentMinAmount, setPaymentMinAmount] = useState<any>(null);
  const [isDivideBuyEnable, setIsDividebuyEnable] = useState(false);

  const [cartData, setCartData] = useState<any>({});
  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });
  const [addressData, setAddressData] = useState<any>({
    addresses: [],
    country_name: "United Kingdom",
  });
  const [showBillingAddressDropdown, setBillingAddressDropdown] =
    useState(false);

  const [paymentOptionIndex, setPaymentOptionIndex] = useState(0);
  const [showSelectedAddr, setShowSelectedAddr] = useState<any>(null);
  const [cardInstance, setCardInstance] = useState<any>(null);
  const [braintreeToken, setBraintreeToken] = useState<any>(null);
  const [onPlaceOrderLoading, setPlaceOrderLoading] = useState(false);

  useEffect(() => {
    dispatch(getPaymentMinAmountAction());
  },[])

  useEffect(() => {
    if (divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_SUCCESS) {
      if (divideBuyResponse?.data && divideBuyResponse?.data != "") {
        window.location.href = divideBuyResponse?.data;
      } else {
        setPlaceOrderLoading(false);
      }
    } else if (divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_REQUEST) {
      setPlaceOrderLoading(true);
    }
    else if (divideBuyResponse.type === DIVIDEBUY_SPLASH_KEY_FAILED) {
      setPlaceOrderLoading(false);
    }

  }, [divideBuyResponse])

  useEffect(() => {
    if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_REQUEST) {
      setPlaceOrderLoading(true);
    } else if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_SUCCESS) {
      setPlaceOrderLoading(false);
    }
    else if (paymentInstallmentResponse.type === PAYMENT_INSTALLMENT_FAILED) {
      setPlaceOrderLoading(false);
    }

  }, [paymentInstallmentResponse])

  useEffect(() => {
    if (minPaymentAmountResponse.type === GET_PAYMENT_MIN_AMOUNT_SUCCESS) {
      setPaymentMinAmount(minPaymentAmountResponse?.data);
    }
  }, [minPaymentAmountResponse])

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      const isFree =
        checkoutResponse?.data?.cart?.available_payment_methods?.find(
          (c: any) => c.code === "free"
        );
      const available_payment_methods = isFree
        ? checkoutResponse?.data?.cart?.available_payment_methods?.filter(
          (c: { code: string }) => c.code === "free"
        )
        : checkoutResponse?.data?.cart?.available_payment_methods?.filter(
          (c: any) => c.code !== "free"
        );
      const cart = checkoutResponse?.data?.cart;
      if (cart) {
        cart.available_payment_methods = available_payment_methods;
        const divideBuyEnable = cart?.items?.filter((item: any) => item)?.every(checkIsDivideBuyEnable);
        setIsDividebuyEnable(divideBuyEnable);
        setDeliveryMethodData(cart);
      }
    } else if (
      checkoutResponse &&
      checkoutResponse.type === GET_BRAINTREETOKEN_SUCCESS
    ) {
      // setCardInstance(null);
      setBraintreeToken(checkoutResponse?.data.createBraintreeClientToken);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_SUCCESS
    ) {
      setPlaceOrderLoading(false);
      navigate(ORDER_SUCCESS + "?fromCheckout=True");
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_FAILED
    ) {
      setPlaceOrderLoading(false);
      navigate(CHECKOUT);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS
    ) {
      const isFree = checkoutResponse?.data?.available_payment_methods?.find(
        (c: any) => c.code === "free"
      );
      const available_payment_methods = isFree
        ? checkoutResponse?.data?.available_payment_methods.filter(
          (c: { code: string }) => c.code === "free"
        )
        : checkoutResponse?.data?.available_payment_methods.filter(
          (c: any) => c.code !== "free"
        );
      const cart = checkoutResponse?.data;
      cart.available_payment_methods = available_payment_methods;
      const divideBuyEnable = cart?.items?.filter((item: any) => item)?.every(checkIsDivideBuyEnable);
      setIsDividebuyEnable(divideBuyEnable);
      setDeliveryMethodData(cart);
    }
  }, [checkoutResponse]);

  useEffect(() => {
    if (cartResponse.type === GET_CART_PAGE_SUCCESS) {
      const isFree = cartResponse.data.available_payment_methods?.find(
        (c: any) => c.code === "free"
      );
      const available_payment_methods = isFree
        ? cartResponse.data.available_payment_methods?.filter(
          (c: { code: string }) => c.code === "free"
        )
        : cartResponse.data.available_payment_methods?.filter(
          (c: any) => c.code !== "free"
        );
      const cart = cartResponse.data;
      cart.available_payment_methods = available_payment_methods;
      const divideBuyEnable = cart?.items?.filter((item: any) => item)?.every(checkIsDivideBuyEnable);
      setIsDividebuyEnable(divideBuyEnable);
      setDeliveryMethodData(cart);
      setCartData(cartResponse?.data);
    }
  }, [cartResponse]);



  useEffect(() => {
    if (addressResponse && addressResponse.type === GET_ADDRESS_SUCCESS) {
      addressResponse.data.addresses.forEach((res: any) => {
        res.state = res.region.region;
      });
      setAddressData(addressResponse.data);
    } else if (
      addressResponse &&
      addressResponse.type === UPDATE_ADDRESS_SUCCESS
    ) {
      const currentState = Object.assign({}, addressData);
      const index: number = currentState.addresses.findIndex(
        (s: { id: number }) => s.id === addressResponse.data.id
      );
      currentState.addresses[index] = addressResponse.data;
      setAddressData(currentState);
      props.setAddressPopup(false);
    } else if (
      addressResponse &&
      addressResponse.type === CREATE_ADDRESS_SUCCESS
    ) {
      // const currentState = Object.assign({}, addressData);
      // currentState.addresses.push(addressResponse.data);
      // setAddressData(currentState);
      // props.setAddressPopup(false);
    }
  }, [addressResponse]);

  useEffect(() => {
    setAddressData(props.addresses);
  }, [props.addresses]);

  function getAddressId(addressResponse: any, shippingAddress: any) {
    return addressResponse?.data?.addresses?.find(
      (d: any) =>
        d.telephone === shippingAddress.telephone &&
        d.street.join() === shippingAddress.street.join() &&
        d.firstname === shippingAddress.firstname &&
        d.lastname === shippingAddress.lastname &&
        d.region.region === shippingAddress.region.region
    )?.id;
  }

  const setBillingDrop = (e: boolean) => {
    if (e) {
      if (cartResponse.type === GET_CART_PAGE_SUCCESS) {
        const shippingAddress = cartResponse.data.shipping_addresses[0];
        if (shippingAddress) {
          const addressId = getAddressId(addressResponse, shippingAddress);
          if (addressId) {
            dispatch(
              setBillingAddress({
                customerAddressId: Number(addressId),
              })
            );
          }
        }
      }
    }
    setBillingAddressDropdown(!e);
  };

  function checkIsDivideBuyEnable(item:any) {
    if (item.__typename === "SimpleCartItem") {
        // Check dividebuy_enable for SimpleCartItem
        return item.product && item.product.dividebuy_enable === true;
    } else if (item.__typename === "ConfigurableCartItem") {
        // Check configured_variant and dividebuy_enable for ConfigurableCartItem
        return (
            item.configured_variant &&
            item.configured_variant.dividebuy_enable === true
        );
    } else {
        // Handle other cases if needed
        return false;
    }
}

  function handleSelectChange(event: any) {
    props.setAddressPopup(Number(event.target.value) === 0);
    const address = addressData.addresses?.find(
      (add: { id: number }) => add.id === Number(event.target.value)
    );
    if (Number(event.target.value)) {
      dispatch(
        setBillingAddress({ customerAddressId: Number(event.target.value) })
      );
    }
    setShowSelectedAddr(address);
  }

  async function buy(method_code: string) {
    if (!isAuth() && !isGuestEmail()) {
      window.scrollTo(0, 0);
      props.setSubmitLogin(true);
      return;
    }
    if (isAuth() || (!isAuth() && isGuestEmail())) {
      setPlaceOrderLoading(true);
      let nonce = null;
      try {
        if (method_code === "braintree") {
          nonce = await cardInstance.requestPaymentMethod();
        }
        dispatch(
          onPlaceOrder({
            nonce,
            method_code,
          })
        );
      } catch (error) {
        console.error("ERROR");
        setPlaceOrderLoading(false);
      }
    } else {
      // window.scrollTo(0, 0);
      scrollTop();
      props.setSubmitLogin(true);
    }
  }
  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <div className="ckout-head">
          <span>
            <p className="bs">4</p>
          </span>
          <p className="ts caps-on">Payment Options</p>
        </div>
      </div>
      {cartData &&
        cartData.items &&
        cartData.items.length > 0 &&
        deliveryMethodData?.shipping_addresses &&
        deliveryMethodData?.shipping_addresses[0] &&
        deliveryMethodData?.shipping_addresses[0].selected_shipping_method ? (
        <div className="payment-box">
          {deliveryMethodData?.available_payment_methods
            ?.filter((data: any) => data)
            .map((data1: any, index: any) => {
              if ( data1.code === "dbpayment" && !isDivideBuyEnable ) {
                return null;
              }
              else if (data1.code === "dbpayment" && paymentMinAmount &&  cartResponse?.data?.prices?.grand_total?.value  < Number(paymentMinAmount) ){
                return null;
              }
              return <div key={data1.title} className="delivery-method">
                <div className="rd">
                  <input
                    type="radio"
                    name="payment-selected"
                    id={data1.code}
                    onChange={() => {
                      if (getSessionItem('guest_email') || isAuth()) {
                        setPaymentOptionIndex(index)
                      } else {
                        setPaymentOptionIndex(-1)
                        window.scroll(0, 0)
                        props.setSubmitLogin(true)
                      }
                    }}
                    checked={index === paymentOptionIndex}
                    defaultChecked={index === paymentOptionIndex}
                  />
                  <label className="bm" htmlFor={data1.code}>
                    {data1?.title}
                  </label>
                </div>
                {index === paymentOptionIndex ? (
                  <>
                    {data1.code !== "paypal_express" &&
                      data1.code !== "rapyd_card" && data1.code !== "stripe_payments" && data1.code !== "dbpayment" && (
                        <div className="cod payment-action-content">
                          <CheckboxGroups
                            onChange={(e: any) => {
                              setBillingDrop(e.target.checked);
                            }}
                            defaultChecked={!showBillingAddressDropdown}
                            id="unselect-addresses"
                          >
                            Bill to delivery address
                          </CheckboxGroups>
                        </div>
                      )}
                    {showBillingAddressDropdown ? (
                      <div className="list-addresses">
                        <div className="select-group">
                          <select
                            value={showSelectedAddr?.id}
                            onChange={handleSelectChange}
                          >
                            {isAuth()
                              ? addressData.addresses
                                ?.filter((data: any) => data)
                                .map((item: any, index2: number) => (
                                  <option
                                    key={index2 + item.firstname}
                                    value={item.id}
                                  >
                                    {item.firstname + " " + item.lastname}
                                    {item.street?.join(", ") +
                                      ", " +
                                      (item.region?.region
                                        ? item.region?.region + ","
                                        : "") +
                                      addressData.country_name +
                                      ", " +
                                      item.postcode}
                                  </option>
                                ))
                              : props.billingAddresses
                                ?.filter((data: any) => data)
                                .map((item: any, index2: number) => (
                                  <option
                                    key={index2 + item.firstname}
                                    value={item.id}
                                  >
                                    {item.firstname + " " + item.lastname}
                                    {item.street?.join(", ") +
                                      ", " +
                                      (item.region?.region
                                        ? item.region?.region + ","
                                        : "") +
                                      props.billingAddresses.country_name +
                                      ", " +
                                      item.postcode}
                                  </option>
                                ))}
                            {isAuth() ? (
                              <option value="0">New Address</option>
                            ) : null}
                          </select>
                        </div>
                        {isAuth()
                          ? addressData.addresses
                            ?.filter((data: any) => data)
                            .map((item: any, index: number) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm" key={index + "bm"}>
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        addressData.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )
                          : props.billingAddresses
                            ?.filter((data: any) => data)
                            .map((item: any, index: number) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm" key={index + "payment"}>
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        addressData.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )}
                      </div>
                    ) : null}
                    <div className="credit-card-content">
                      {data1.code === "braintree" && braintreeToken ? (
                        <div>
                          <DropIn
                            options={{
                              cvv: {
                                maskInput: true,
                              },
                              authorization: braintreeToken,
                            }}
                            onError={(error) => {
                              console.error("DROPIN ERROR ", error?.message);
                            }}
                            onInstance={(cardInstance: any) =>
                              setCardInstance(cardInstance)
                            }
                          />
                          <CustomButton
                            isLoading={onPlaceOrderLoading}
                            onClick={() => buy("braintree")}
                          >
                            {" "}
                            Place Order
                          </CustomButton>
                        </div>
                      ) : null}
                      {data1.code !== "braintree" &&
                        data1.code !== "paypal_express" &&
                        data1.code !== "rapyd_card" && data1.code !== "stripe_payments" && data1.code !== "dbpayment" ? (
                        <CustomButton
                          isLoading={onPlaceOrderLoading}
                          onClick={() => buy(data1.code)}
                        >
                          {" "}
                          Place Order
                        </CustomButton>
                      ) : null}
                      {data1.code === "paypal_express" ? (
                        <PaypalExpress
                          setSubmitLogin={props.setSubmitLogin}
                          showContinueBtn={true}
                        />
                      ) : null}
                      {data1.code === "dbpayment" && isDivideBuyEnable ? (
                        <DivideBuy
                      code = "dbpayment"
                          onPlaceOrderLoading={onPlaceOrderLoading}
                          buy={buy}
                        />
                      ) : null}
                      {data1.code === "rapyd_card" && (
                        <RapydPayment
                          // checkoutPayment={checkoutPayment}
                          // setCheckoutPayment={setCheckoutPayment}
                          mobileState={null}
                          setMobileState={null}
                          checkoutResponse={checkoutResponse}
                          cartResponse={cartResponse}
                          setSubmitLogin={() => {
                            window.scroll(0, 0)
                            props.setSubmitLogin(true)
                            setPaymentOptionIndex(-1)
                          }}
                          showContinueBtn={true}
                        />
                      )}
                      {
                        data1.code === "stripe_payments" && (
                          <StripeCheckout
                            showContinueButton={true}
                            addressData={addressData}
                            setSubmitLogin={() => {
                              if (!getSessionItem('guest_email') && !isAuth()) {
                                setPaymentOptionIndex(-1)
                                window.scroll(0, 0)
                                props.setSubmitLogin(true)
                              }
                            }}
                          />
                        )
                      }
                    </div>

                  </>
                ) : null}
              </div>
            })}
        </div>
      ) : null}
    </div>
  );
};

export default CheckoutPaymentOption;
