/* eslint-disable react-hooks/exhaustive-deps */
import CustomButton from "../Common/CustomButton/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CartPageSkl from "./CartPageSkl";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItem,
  getCartAction,
  MoveProductToWishlist,
  updateCartItem,
} from "../../Redux/Pages/CartPage/CartPageActions";
import {
  DELETE_CART_SUCCESS,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_SUCCESS,
  DELETE_CART_ERROR,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import IncDecBox from "../Common/IncDecBox/IncDecBox";
import RemovePopup from "../Home/MiniCart/RemovePopup/RemovePopup";
import OrderSummary from "./OrderSummary/OrderSummary";
import { CHECKOUT, HOME } from "../../Constants/Route";
import { gtmInitiateCheckout } from "../../Utility/gtmhelper";
import { CHECKOUT_GET_DELIVERY_METHOD_SUCCESS } from "../../Redux/Pages/Checkout/CheckoutTypes";

const CartPage = () => {
  const [loading, setLoading] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [data, setData] = useState<any>({});
  const [isDisabled, setDisabled] = useState(false);

  const cartResponse = useSelector((state: any) => state.getCartResponse);

  const deleteCartResponse = useSelector(
    (state: any) => state.deleteCartItemResponse
  );

  const [removeCartId, setRemoveCartId] = useState(null);

  useEffect(() => {
    if (cartResponse && cartResponse.type === GET_CART_PAGE_SUCCESS) {
      setDisabled(false);
      setProcessing(false);
      setData(cartResponse.data);
      setLoading(false);
    } else if (cartResponse && cartResponse.type === GET_CART_PAGE_ERROR) {
      setDisabled(false);
      setLoading(false);
      setProcessing(false);
    }
  }, [cartResponse]);

  useEffect(() => {
    dispatch({
      type:CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
      data:null
  })
    setLoading(true);
    dispatch(getCartAction());
  }, []);

  useEffect(() => {
    if (deleteCartResponse) {
      if (
        deleteCartResponse.type === DELETE_CART_SUCCESS &&
        data &&
        data.items
      ) {
        const currentState = Object.assign({}, data);
        currentState.items = data.items?.filter(
          (d: { uid: any }) => d.uid !== deleteCartResponse.data
        );
        setData(currentState);
        setProcessing(false);
        setRemoveCartId(null);
      } else if (
        deleteCartResponse &&
        deleteCartResponse.type === DELETE_CART_ERROR
      ) {
        setProcessing(false);
        setRemoveCartId(null);
      }
    }
  }, [deleteCartResponse]);

  const updateCart = (quantity: number, uid: string) => {
    if (quantity >= 0) {
      setProcessing(true);
      setDisabled(true);
      dispatch(
        updateCartItem({
          uid,
          quantity,
        })
      );
    }
  };

  const moveToWishlist = (
    quantity: number,
    sku: string,
    cartItemId: string
  ) => {
    dispatch(MoveProductToWishlist({ quantity, sku, cartItemId }));
  };

  const onDeleteCart = () => {
    setProcessing(true);
    dispatch(
      deleteCartItem({
        cartItemId: removeCartId,
      })
    );
  };

  return !loading ? (
    <div className="cart-page-wrap">
      <div className="custom-container">
        {cartResponse && cartResponse.error && (
          <div className="alert alert-danger" role="alert">
            Some item options or their combination are not currently available.
          </div>
        )}
        <div className="cart-section">
          <div className="cart-info">
            <h1 className="caps-on">Shopping Basket</h1>
            <ul className="cart-items">
              {data && data.items && data.items.length > 0 ? (
                data.items
                  ?.filter((c: any) => c)
                  .map((item: any, index: number) => (
                    <>
                      <li className="item" key={index + 1 + item.uid}>
                        <div className="ct-img">
                          <img
                            src={item.product.image.url}
                            alt={item.product.image.label}
                            title={item.product.image.label}
                          />
                        </div>
                        <div className="ct-details">
                          <div className="ct-top">
                            <div className="product-name">
                              <Link
                                className="tm"
                                to={
                                  "/" +
                                  item.product.url_key +
                                  item.product.url_suffix
                                }
                                title={item.product.image.label}
                              >
                                {item.product.name}
                              </Link>
                            </div>
                            <div className="attributes">
                              {item.configurable_options
                                ?.filter((data: any) => data)
                                .map((option: { value_label: string }) => (
                                  <p
                                    key={option.value_label}
                                    className="bs type attr"
                                  >
                                    <span>{option.value_label}</span>{" "}
                                  </p>
                                ))}
                              {item.product?.availability_message ? (
                                <p className="bs dispatch attr">
                                  <span>
                                    {item.product.availability_message}
                                  </span>
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="price-wrapper">
                            <p className="normal-price">
                              £
                              {item.prices?.row_total?.value >= 0
                                ? Number(item.prices?.row_total?.value).toFixed(
                                    2
                                  )
                                : null}
                            </p>
                          </div>
                          <div className="inc-dec-box">
                            <p className="bs">QTY</p>
                            <IncDecBox
                              quantityNo={item.quantity}
                              disabled={isDisabled}
                              onIncrement={(e: number) =>
                                updateCart(e, item.uid)
                              }
                              onDecrement={(e: number) =>
                                updateCart(e, item.uid)
                              }
                            />
                          </div>
                          <div className="ct-actions show-992">
                            <Link
                              to={""}
                              onClick={() => setRemoveCartId(item.uid)}
                              className="bm remove"
                            >
                              Remove
                            </Link>
                            <Link
                              to={""}
                              className="bm wishlist"
                              onClick={() =>
                                moveToWishlist(
                                  item.quantity,
                                  item.product.sku,
                                  item.uid
                                )
                              }
                            >
                              Move to Wishlist
                            </Link>
                          </div>
                        </div>
                      </li>
                      <div
                        className="hide-991 mobile-ct-actions"
                        key={index + 2 + "i"}
                      >
                        <div className="sub-actions">
                          <Link
                            to={""}
                            onClick={() => setRemoveCartId(item.uid)}
                            className="bm remove"
                          >
                            <span>Remove</span>
                          </Link>
                          <Link
                            to={""}
                            className="bm wishlist"
                            onClick={() =>
                              moveToWishlist(
                                item.quantity,
                                item.product.sku,
                                item.uid
                              )
                            }
                          >
                            <span>Move to Wishlist</span>
                          </Link>
                        </div>
                      </div>
                      <RemovePopup
                        loading={isProcessing}
                        text="Are you sure you would like to remove this item from the shopping basket?"
                        onCancel={() => setRemoveCartId(null)}
                        onSubmit={onDeleteCart}
                        show={removeCartId}
                      />
                    </>
                  ))
              ) : (
                <div className="not-cart">
                  <p className="tm">
                    You have no items in your shopping basket.
                  </p>
                  <div className="bm">
                    Click{" "}
                    <Link className="bm home-link" to={HOME}>
                      here
                    </Link>{" "}
                    to continue shopping.
                  </div>
                </div>
              )}
            </ul>
          </div>
          {data && data.items && data.items?.length > 0 ? (
            <div className="cart-summary">
              <p className="tm">Price Details</p>
              <OrderSummary />
              {cartResponse && !cartResponse.error && (
                <div className="action-bar">
                  <CustomButton
                    onClick={() => {
                      const d = data?.items
                        .filter((data: any) => data)
                        .map((c: any) => {
                          return {
                            name: c.product.name,
                            price: c.prices?.row_total.value,
                            sku: c.product.sku,
                          };
                        });

                      gtmInitiateCheckout(
                        d,
                        data?.prices?.grand_total?.value >= 0
                          ? "£ " +
                              Number(data?.prices?.grand_total?.value).toFixed(
                                2
                              )
                          : ""
                      );
                      navigate(CHECKOUT);
                    }}
                    bg={"fill"}
                  >
                    Checkout
                  </CustomButton>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="cart-sticky-bar hide-991">
        <div className="act-bar">
          <div className="od-total">
            <span className="ls">Order Total</span>
            <p className="tm">
              {data?.prices?.grand_total?.value >= 0
                ? "£" + Number(data?.prices?.grand_total?.value).toFixed(2)
                : null}
            </p>
          </div>
          {cartResponse &&
            cartResponse.type === GET_CART_PAGE_SUCCESS &&
            data &&
            data.items &&
            data.items.length > 0 &&
            !cartResponse.error && (
              <div className="od-action">
                <CustomButton onClick={() => navigate(CHECKOUT)} bg={"fill"}>
                  Checkout
                </CustomButton>
              </div>
            )}
        </div>
      </div>
    </div>
  ) : (
    <CartPageSkl />
  );
};

export default CartPage;
