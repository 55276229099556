import {
  CONTACT_US_FORM_FAILED,
  CONTACT_US_FORM_REQUEST,
  CONTACT_US_FORM_SUCCESS,
  GET_CUSTOM_FORM_FAILED,
  GET_CUSTOM_FORM_SUCCESS,
  GET_META_TITLE_SUCCESS,
  GET_MIN_INSTALLMENT_AMOUNT_FAILED,
  GET_MIN_INSTALLMENT_AMOUNT_REQUEST,
  GET_MIN_INSTALLMENT_AMOUNT_SUCCESS,
  GET_PRODUCT_INSTALLMENT_HTML_FAILED,
  GET_PRODUCT_INSTALLMENT_HTML_REQUEST,
  GET_PRODUCT_INSTALLMENT_HTML_SUCCESS,
  GET_SOFT_SEARCH_KEY_FAILED,
  GET_SOFT_SEARCH_KEY_REQUEST,
  GET_SOFT_SEARCH_KEY_SUCCESS,
  GUEST_NEWS_LETTER_SUCCESS,
  MY_ORDERS_DETAILS_FAILED,
  MY_ORDERS_DETAILS_REQUEST,
  MY_ORDERS_DETAILS_SUCCESS,
  MY_ORDERS_FAILED,
  MY_ORDERS_REQUEST,
  MY_ORDERS_SUCCESS,
  SEARCH_FUNCTIONALITY_ERROR,
  SEARCH_FUNCTIONALITY_SUCCESS,
  STORE_CONFIG_FAILED,
  STORE_CONFIG_REQUEST,
  STORE_CONFIG_SUCCESS,
  SUBMIT_SAMPLE_FORM_ERROR,
  SUBMIT_SAMPLE_FORM_SUCCESS,
  URL_RESOLVER_FAILED,
  URL_RESOLVER_REQUEST,
  URL_RESOLVER_SUCCESS,
} from "./GeneralType";

export function urlResolver(state = {}, action: any) {
  switch (action.type) {
    case URL_RESOLVER_SUCCESS:
      return { ...action.payload };
    case URL_RESOLVER_FAILED:
      return { ...action.payload };
    case URL_RESOLVER_REQUEST:
      return { ...action.payload };
    default:
      return state;
  }
}

export function contactUsSubmit(state = {}, action: any) {
  switch (action.type) {
    case CONTACT_US_FORM_SUCCESS:
      return { ...action.payload };
    case CONTACT_US_FORM_FAILED:
      return { ...action.payload };
    case CONTACT_US_FORM_REQUEST:
      return { ...action.payload };
    default:
      return state;
  }
}

export function storeConfigInfo(state = {}, action: any) {
  switch (action.type) {
    case STORE_CONFIG_SUCCESS:
      return { ...action.payload };
    case STORE_CONFIG_FAILED:
      return { ...action.payload };
    case STORE_CONFIG_REQUEST:
      return { ...action.payload };
    default:
      return state;
  }
}

export const guestNewsLetterResponse = (state = {}, action: any) => {
  switch (action.type) {
    case GUEST_NEWS_LETTER_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
};

export function searchCategoryResponse(state = {}, action: any) {
  switch (action.type) {
    case SEARCH_FUNCTIONALITY_SUCCESS:
      return { ...action.payload };
    case SEARCH_FUNCTIONALITY_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function myOrdersResponse(state = {}, action: any) {
  switch (action.type) {
    case MY_ORDERS_SUCCESS:
      return { ...action.payload };
    case MY_ORDERS_FAILED:
      return { ...action.payload };
    case MY_ORDERS_REQUEST:
      return { ...action.payload };
    case MY_ORDERS_DETAILS_SUCCESS:
      return { ...action.payload };
    case MY_ORDERS_DETAILS_FAILED:
      return { ...action.payload };
    case MY_ORDERS_DETAILS_REQUEST:
      return { ...action.payload };
    default:
      return state;
  }
}

export function getCustomFormResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_CUSTOM_FORM_SUCCESS:
      return { ...action.payload };
    case GET_CUSTOM_FORM_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function submitSampleFormResponse(state = {}, action: any) {
  switch (action.type) {
    case SUBMIT_SAMPLE_FORM_SUCCESS:
      return { ...action.payload };
    case SUBMIT_SAMPLE_FORM_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function getMetaTitleResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_META_TITLE_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}


export function getSoftSearchKeyReducer(state = {}, action: any) {
  switch (action.type) {
    case GET_SOFT_SEARCH_KEY_REQUEST:
      return { ...action.payload };
    case GET_SOFT_SEARCH_KEY_SUCCESS:
      return { ...action.payload };
    case GET_SOFT_SEARCH_KEY_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function getMinInstallmentAmountReducer(state = {}, action: any) {
  switch (action.type) {
    case GET_MIN_INSTALLMENT_AMOUNT_REQUEST:
      return { ...action.payload };
    case GET_MIN_INSTALLMENT_AMOUNT_SUCCESS:
      return { ...action.payload };
    case GET_MIN_INSTALLMENT_AMOUNT_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function getProductInstallmentHtmlReducer(state = {}, action: any) {
  switch (action.type) {
    case GET_PRODUCT_INSTALLMENT_HTML_REQUEST:
      return { ...action.payload };
    case GET_PRODUCT_INSTALLMENT_HTML_SUCCESS:
      return { ...action.payload };
    case GET_PRODUCT_INSTALLMENT_HTML_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}
