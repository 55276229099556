export const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="custom-container">
        <div className="footer-bottom-wrap">
          <div className="copyright">
            <p className="bs">© 2023 Living It Up</p>
          </div>
          <div className="image-points">
            <img
              src="https://cdn.livingitup.co.uk/media/.renditions/wysiwyg/blog/Cards.png"
              alt="Payment Icons"
              title="Payment Icons"
            />
          </div>
          <div className="madeby">
            <p className="bs">
              Made by
              <a
                className="bs company-link"
                href="https://magnetoitsolutions.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Magneto IT Solutions
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
