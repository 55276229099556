import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LABEL_CANCEL, LABEL_LOGOUT, LABEL_LOGOUT_TEXT } from "../../../Constants/Labels";
import { HOME } from "../../../Constants/Route";
import { logoutAction } from "../../../Redux/Auth/AuthAction";
import CustomButton from "../../Common/CustomButton/CustomButton";

const LogoutPopup = (props: any) => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logout = () => {
    dispatch(logoutAction())
    props.onHide()
    navigate(HOME)
  }

  return (
    <Modal {...props} centered className="logout-popup">
      <Modal.Header closeButton>
        <div className="tm modal-title">{LABEL_LOGOUT}</div>
      </Modal.Header>
      <Modal.Body>
        <p className="bm">{LABEL_LOGOUT_TEXT}</p>
        <div className="logout-action">
          <CustomButton bg="fill" onClick={logout}>{LABEL_LOGOUT}</CustomButton>
          <CustomButton onClick={() => props.onHide()}>{LABEL_CANCEL}</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LogoutPopup;