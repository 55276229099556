
export class UserInputForm {
    name?: string;
    label?: string;
    required?: boolean;
    validation?: any[] = [];
    value?: string;
    errors?: any[] = []
    constructor(
        name?: string,
        label?: string,
        required?: boolean,
        validation?: any[],
        value?: string,
        errors?: any[]) {
        this.name = name
        this.label = label;
        this.required = required
        this.value = value;
        this.validation = validation
        this.errors = errors
    }
}