export const HOME = "/";
export const CART_PAGE = "/cart";
export const SIGN_UP = "/customer/account/create";
export const LOGIN = "/customer/account/login";
export const FORGOT_PWD = "/customer/account/forgotpassword";
export const RESET_PWD = "/customer/account/createPassword";
export const TERMS = "/terms";
export const PRIVACY_POLICY = "/privacy-policy-cookie-restriction-mode";

export const MY_ORDERS_PAGE = "/sales/order/history";
export const MY_WISHLIST = "/wishlist";
export const MY_DETAIL = "/customer/account/edit";
export const MY_ADDRESS = "/customer/address/new/";
export const NEWSLETTER = "/newsletter/manage/";
export const ORDER_DETAILS = "/sales/order/view/order_id/";

export const CONTACT_US = "/contact";
export const ABOUT_US = "/about-us";
export const CONSTRUCTION = "/construction";
export const MATERIALS = "/upholstered-materials";
export const STAY_IN_BED = "https://stayinbed.co.uk/"
export const TESTIMONIALS = "/testimonials";
export const MEASURING_GUIDE = "/measuring-guide";
export const BLOG_LISTING = "/blog.html";
export const BLOG_SEARCH_CATEGORY = "/blog/search.html";
export const BLOG_DETAIL = "/blog/:name";
export const BLOG_CATEGORY_PAGE = "/blog/category/:name";
export const BLOG_AUTHOR_DETAIL = "/blog/author/:name";

export const ERROR_PAGE = "/404";
export const NODATA = "/nodata";
export const SOMETHING_WRONG = "/something-wrong";
export const FINANCE = "/finance";

export const DYNAMIC_ROUTE = "/:route1";
export const DYNAMIC_ROUTE2 = "/:route1/:route2";
export const FAQS = "/faqs";
export const INTEREST_FREE_CREDIT = "/credit-page"
export const BEST_SELLERS  = "/bestsellers"

export const ORDER_SUCCESS = "/checkout/onepage/success/";
export const CHECKOUT = "/checkout";
export const CUSTOMER_SHOWCASE = "/customer-showcase";

export const SEARCH_CATEGORY = "/catalogsearch/result/";
export const CHECKOUTSKl = "/CheckoutSkl";

export const PAYPAL_RETURN_URL = "/checkout/onepage/success.html";
export const PAYMENT_PROGRESS = "/checkout/onepage";
export const RAPYD_PROGRESS = "/rapyd/success";
export const DIVIDEBUY_PROGRESS = "/dividebuy/payment/response"
export const STRIPE_PROGRESS = "/checkout/onepage/stripe";

