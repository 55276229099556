import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentInstallmentAction } from '../../../Redux/Pages/Checkout/CheckoutAction';
import { PAYMENT_INSTALLMENT_SUCCESS } from '../../../Redux/Pages/Checkout/CheckoutTypes';
import CustomButton from '../../Common/CustomButton/CustomButton';

const DivideBuy = ({ onPlaceOrderLoading, buy, code, showPlaceOrder = true }: any) => {

    const dispatch = useDispatch();
    const [installmentHtml, setInstallmentHtml] = useState("");

    const cartResponse = useSelector((state: any) => state.getCartResponse);
    const paymentInstallmentRes = useSelector((state: any) => state.paymentInstallmentReducer);

    useEffect(() => {
        const orderTotal = cartResponse?.data?.prices?.grand_total?.value;
        dispatch(getPaymentInstallmentAction(orderTotal));
    }, [cartResponse]);

    useEffect(() => {
        if (paymentInstallmentRes.type === PAYMENT_INSTALLMENT_SUCCESS) {
            setInstallmentHtml(paymentInstallmentRes?.data);
        }
    }, [paymentInstallmentRes])

    function handleRadioChange(event: any) {
        // Remove 'dividebuy-active-row' from all elements with class 'dividebuy-active-row'
        document.querySelectorAll('.dividebuy-checkout-row').forEach(row => {
            row.classList.remove('dividebuy-active-row');
        });

        // Add 'dividebuy-active-row' to the closest parent with class 'dividebuy-checkout-row' of the checked radio button
        const closestRow = event.target.closest('.dividebuy-checkout-row');
        if (closestRow) {
            closestRow.classList.add('dividebuy-active-row');
        }
    }

    useEffect(() => {
        let elements = document.getElementsByName("dividebuy_available_instalments");
        elements.forEach(element => {
            element.addEventListener('change', handleRadioChange);
        });

        return () => {
            elements.forEach(element => {
                element.removeEventListener('change', handleRadioChange);
            });
        }

    }, [installmentHtml])

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: installmentHtml }} />
            {
                showPlaceOrder && <CustomButton
                    isLoading={onPlaceOrderLoading}
                    onClick={() => buy(code)}
                >
                    {" "}
                    Place Order
                </CustomButton>
            }

        </>

    )
}

export default DivideBuy
