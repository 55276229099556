import Skeleton from "react-loading-skeleton";

const CartPageSkl = ({itemCount =2}) => {

  return (
    <div className="cart-page-wrap skl">
      <div className="custom-container">
        <div className="cart-section">
          <div className="cart-info skl">
            <h6 className="titleskl"><Skeleton /></h6>
            <ul className="cart-items">
              {
                Array.from(itemCount).forEach((d) => {
                  <>
                  <li className="item" key={d + 1}>
                    <div className="ct-img">
                      <Skeleton />
                    </div>
                    <div className="ct-details">
                      <div className="ct-top">
                        <div className="product-name"><Skeleton /></div>
                        <div className="attributes">
                          <Skeleton />
                        </div>
                      </div>
                      <div className="price-wrapper"><p><Skeleton /></p><p><Skeleton /></p></div>
                      <div className="inc-dec-box">
                          <Skeleton />
                      </div>
                      <div className="ct-actions skl show-992">
                        <p><Skeleton /></p>
                        <p><Skeleton /></p>
                      </div>
                    </div>
                  </li>
                  <div className="hide-991 mobile-ct-actions">
                    <div className="sub-actions skl">
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                </>
                })
              }
            </ul>
          </div>
          <div className="cart-summary skl">
            <p className="tm"><Skeleton /></p>
            <div className="total-table">
              <div className="sub-col total">
                <p className="stitle bm"><Skeleton /></p>
                <p className="svalue bm"><Skeleton /></p>
              </div>
              <div className="sub-col delivery">
                <p className="stitle bm"><Skeleton /></p>
                <p className="svalue bm"><Skeleton /></p>
              </div>
            </div>
            <div className="discount-box">
              <div className="extra-ip-groups">
                <Skeleton />
              </div>
            </div>
            <div className="order-total">
              <div className="sub-col maintotal">
                <p className="stitle bm"><Skeleton /></p>
                <p className="svalue bm"><Skeleton /></p>
              </div>
            </div>
            <div className="action-bar">
              <div className="btn"><Skeleton /></div>
              <div className="btn"><Skeleton /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartPageSkl;