/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoryListAction } from "../../Redux/Pages/CategoryList/CategoryListAction";
import { useLocation } from "react-router";
import { CHECKOUT, INTEREST_FREE_CREDIT, ORDER_SUCCESS } from "../../Constants/Route";
import { PRODUCT_DETAILS_PREVIOUS_URL } from "../../Constants/LocalStorage";
import { setSessionItem } from "../../Utility/Utilities";

const MainNavigation = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});

  const headerResponse = useSelector(
    (state: any) => state?.categoryListResponse?.data?.categories
  );

  useEffect(() => {
    dispatch(getCategoryListAction("2"));
  }, []);

  useEffect(() => {
    if (headerResponse) {
      setData(headerResponse);
    }
  }, [headerResponse]);

  const [activeMenu, setActiveMenu] = useState<any>({});

  const [showHideMenu, setShowHideMenu] = useState(false);

  function menuActiveCall(menuNo: any) {
    setActiveMenu(menuNo);
    setShowHideMenu(true);
  }

  function menuRemoveActiveCall(menuNo: any) {
    setActiveMenu(false);
  }

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  const urls = [CHECKOUT, ORDER_SUCCESS];

  return (
    <div
      key={"MainNavigation"}
      className={
        urls.includes(currentLocation)
          ? "show-768 web-menu in-checkout"
          : "show-768 web-menu"
      }
    >
      <div className="custom-container">
        <ul className="menu-items">
          {data?.items?.filter((item: any) => item.include_in_menu === 1)
            .map((info: any, index: number) => (
              <div
                className={`item ${(showHideMenu && (activeMenu === index)) && "hovered"}`
                }
                key={info.url_key}
                onMouseOver={() => menuActiveCall(index)}
                onMouseLeave={() => setShowHideMenu(false)}
              >
                <li className="dnav-item">
                  <Link
                    to={info.url_key + info.url_suffix}
                    className="ll"
                    onClick={() => setShowHideMenu(false)}
                  >
                    {info.name}
                  </Link>
                </li>
                {showHideMenu ? (
                  <div
                    key="hidemenu"
                    className={
                      activeMenu === index
                        ? "content-section active"
                        : "content-section"
                    }
                    onMouseLeave={() => menuRemoveActiveCall(index)}
                  >
                    <div className="custom-container" key={""}>
                      <Row>
                        <Col
                          xl={{ span: 3, offset: 1 }}
                          lg={{ span: 3, offset: 1 }}
                          md={{ span: 4, offset: 1 }}
                        >
                          <div
                            className="left-image"
                            onClick={() => {
                              setSessionItem(PRODUCT_DETAILS_PREVIOUS_URL, navigate.pathname)
                            }}
                            dangerouslySetInnerHTML={{
                              __html: info.cms_block.content,
                            }}
                          ></div>
                        </Col>
                        <Col xl={8} lg={8} md={7} className="links-details">
                          <ul className="right-content">
                            {info?.children
                              .filter((item: any) => item.include_in_menu === 1)
                              .slice(0, 7)
                              .map((info1: any, indexno1: any) => (
                                <li
                                  className="submenu-items ll"
                                  key={info1.url_path}
                                >
                                  <Link
                                    className="ll"
                                    onClick={() => setShowHideMenu(false)}
                                    to={
                                      "/" + info1.url_path + info.url_suffix
                                    }
                                  >
                                    {info1.name}
                                  </Link>
                                </li>
                              ))}

                          </ul>
                          {info?.children.length > 7 ? (
                            <ul className="right-content">
                              {info?.children
                                .filter((item: any) => item.include_in_menu === 1)
                                .slice(7, 14)
                                .map((info1: any, index1: any) => (
                                  <li
                                    className="submenu-items ll"
                                    key={info1.url_path}
                                  >
                                    <Link
                                      className="ll"
                                      onClick={() => setShowHideMenu(false)}
                                      to={
                                        "/" + info1.url_path + info.url_suffix
                                      }
                                    >
                                      {info1.name}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          <div className={`item ${(showHideMenu && (activeMenu === -2)) && "hovered"}`
                }
           onMouseOver={() => menuActiveCall(-2)}
           onMouseLeave={() => setShowHideMenu(false)}
          >
          <li
            className="dnav-item"
          >
            <Link
              className="ll"
              to={INTEREST_FREE_CREDIT}
            >
              Finance
            </Link>
          </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MainNavigation;
