import Skeleton from "react-loading-skeleton";

export const ProductConfigurationSkeleton = () => {
  return (
    <div className="sub-section">
      <div className="product-right-txt skl">
        <div className="mob-filter">
          <div className="filter">
            <Skeleton />
          </div>
          <div className="filter">
            <Skeleton />
          </div>
        </div>
        <div className="product-txt-box skl">
          <div className="product-swatches">
            <div className="sample-wrap">
              <p className="ll check-txt-991">
                <Skeleton />
              </p>
              <div className="sample-main">
                <Skeleton />
              </div>
            </div>
            <div className="sample-wrap check-hide-991">
              <p className="ll">
                <Skeleton />
              </p>
              <div className="sample-main">
                <Skeleton />
              </div>
            </div>
            <div className="sample-wrap check-hide-991">
              <p className="ll">
                <Skeleton />
              </p>
              <div className="sample-main">
                <Skeleton />
              </div>
            </div>
          </div>
          <div className="select-box">
            <div className="select-btn">
              <Skeleton />
              <Skeleton />
            </div>

            <div className="sidepanel-tabs">
              <div className="on-radio">
                {" "}
                <Skeleton />
              </div>
              <div className="on-radio">
                {" "}
                <Skeleton />
              </div>
            </div>
            <div className="availibility">
              <Skeleton />
            </div>
          </div>
          <div className="product-price">
            <div className="price">
              <Skeleton />
            </div>
          </div>
          <div className="protection-box">
            <Skeleton />
          </div>
          <div className="action-btn">
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};
