/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton/CustomButton";
import Carttrash from "../../../Assets/Images/trash.svg";
import IncDecBox from "../../Common/IncDecBox/IncDecBox";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_CART_SUCCESS,
  GET_MINI_CART_FAILED,
  GET_MINI_CART_SUCCESS,
} from "../../../Redux/Pages/CartPage/CartPageTypes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CART_PAGE, CHECKOUT } from "../../../Constants/Route";
import {
  deleteCartItem,
  getMiniCartAction,
  updateCartItem,
} from "../../../Redux/Pages/CartPage/CartPageActions";
import RemovePopup from "./RemovePopup/RemovePopup";
import { gtmInitiateCheckout } from "../../../Utility/gtmhelper";

const MiniCart = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cartResponse = useSelector((state: any) => state.getMiniCartResponse);
  const [originalData, setOriginalData] = useState<any>({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const deleteCartResponse = useSelector(
    (state: any) => state.deleteCartItemResponse
  );
  const [removeCartId, setRemoveCartId] = useState(null);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    dispatch(getMiniCartAction());
  }, []);

  useEffect(() => {
    if (
      cartResponse &&
      cartResponse.type === GET_MINI_CART_SUCCESS &&
      cartResponse.data
    ) {
      cartResponse.data.items = cartResponse.data?.items?.filter((c: any) => c);
      setData(cartResponse.data);
      setDisabled(false);
      setLoading(false);
      setOriginalData(cartResponse.data);
    } else if (cartResponse && cartResponse.type === GET_MINI_CART_SUCCESS) {
      setDisabled(false);
      setError(cartResponse.error);
    } else if (cartResponse && cartResponse.type === GET_MINI_CART_FAILED) {
      const currentState = Object.assign({}, data);
      setLoading(false);
      setDisabled(false);
      const index = currentState?.items?.findIndex(
        (item: { uid: any }) => item.uid === cartResponse?.data?.uid
      );
      if (index >= 0) {
        currentState.items[index].quantity = originalData.items[index].quantity;
        setData(currentState);
      }
    }
  }, [cartResponse]);

  const updateCart = (quantity: number, uid: string) => {
    if (quantity >= 0) {
      setDisabled(true);
      dispatch(
        updateCartItem({
          uid,
          quantity,
        })
      );
    }
  };

  useEffect(() => {
    if (deleteCartResponse) {
      if (deleteCartResponse.type === DELETE_CART_SUCCESS && data.items) {
        const currentState = Object.assign({}, data);
        currentState.items = data.items.filter(
          (d: { uid: any }) => d.uid !== deleteCartResponse.data
        );
        setData(currentState);
        setRemoveCartId(null);
      }
    }
  }, [deleteCartResponse]);

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      deleteCartItem({
        cartItemId: removeCartId,
      })
    );
  };

  const redirectFunction = (e: string) => {
    if (e === CHECKOUT) {
      const d = data?.items.map((c: any) => {
        return {
          name: c.product.name,
          price: c.prices?.row_total.value,
          sku: c.product.sku,
        };
      });

      gtmInitiateCheckout(
        d,
        "£ " + Number(data?.prices?.grand_total?.value).toFixed(2)
      );
    }
    props.toggleBack2();
    navigate(e);
  };

  return (
    <div className="inner-minicart">
      {data?.items?.length > 0 ? (
        <>
          <div className="minicart-top">
            <span className="tm">
              {data?.items?.filter((c: any) => c).length || 0} Items in Basket
            </span>
            <span className="tm">
              £{Number(data?.prices?.grand_total?.value).toFixed(2)}
            </span>
            {!error && (
              <div className="action-bar">
                <CustomButton
                  onClick={() => redirectFunction(CHECKOUT)}
                  bg={"fill"}
                >
                  Proceed to Checkout
                </CustomButton>{" "}
              </div>
            )}
          </div>
          <div className="minicart-center">
            <ul className="product-list">
              {data.items
                ?.filter((c: any) => c)
                .map((item: any, index: number) => (
                  <li className="item" key={item.uid + index}>
                    <div className="left-side">
                      <img
                        src={item?.product?.image?.url}
                        alt={item?.product?.image?.label}
                      />
                    </div>
                    <div className="right-side">
                      <Link
                        to={
                          "/" + item.product.url_key + item.product.url_suffix
                        }
                        state={{path:location.pathname}}
                        onClick={() => props.toggleBack2()}
                        className="product-name ll"
                      >
                        {item.product.name}
                      </Link>
                      <div className="attributes">
                        {item.configurable_options?.map(
                          (option: { value_label: string }) => (
                            <p
                              key={option.value_label}
                              className="bs type attr"
                            >
                              <span>{option.value_label}</span>
                            </p>
                          )
                        )}
                        {item.product.availability_message ? (
                          <p className="bs dispatch attr">
                            <span>{item.product?.availability_message}</span>
                          </p>
                        ) : null}
                      </div>
                      <div className="price-wrapper">
                        <p className="normal-price">
                          £{Number(item.prices?.row_total.value).toFixed(2)}
                        </p>
                      </div>
                      <div className="inc-dec-box">
                        <div className="inleft-side">
                          <p className="bs">QTY</p>
                          <IncDecBox
                            disabled={disabled}
                            quantityNo={item.quantity}
                            onIncrement={(e: number) => updateCart(e, item.uid)}
                            onDecrement={(e: number) => updateCart(e, item.uid)}
                          />
                        </div>
                        <div className="inright-side">
                          <img
                            src={Carttrash}
                            title="Delete Product"
                            alt="Delete Product"
                            onClick={() => setRemoveCartId(item.uid)}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="minicart-bottom">
            <div className="action-bar">
              <CustomButton onClick={() => redirectFunction(CART_PAGE)}>
                View basket
              </CustomButton>
            </div>
            <RemovePopup
              text="Are you sure you would like to remove this item from the shopping basket?"
              onCancel={() => setRemoveCartId(null)}
              onSubmit={onSubmit}
              loading={loading}
              show={removeCartId}
            />
          </div>
        </>
      ) : (
        <div className="empty-cart">
          <p className="bm">You have no items in your shopping basket.</p>
        </div>
      )}
    </div>
  );
};

export default MiniCart;
