/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPaypalExpressTokenAction } from "../../Redux/Pages/Checkout/CheckoutAction";
import { CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS } from "../../Redux/Pages/Checkout/CheckoutTypes";
import { isAuth, isGuestEmail } from "../../Utility/Utilities";
import CustomButton from "../Common/CustomButton/CustomButton";


const PaypalExpress = (props: {
  showContinueBtn: any;
  setSubmitLogin: any;
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    token: "",
    paypal_urls: {
      start: "",
      edit: "",
    },
  });

  const paypalExpressResponse = useSelector(
    (state: any) => state.paypalExpressReducer
  );

  useEffect(() => {
    dispatch(createPaypalExpressTokenAction());
  }, []);

  useEffect(() => {
    if (
      paypalExpressResponse &&
      paypalExpressResponse.type === CREATE_PAYPAL_EXPRESS_TOKEN_SUCCESS
    ) {
      setData(paypalExpressResponse.data);
    }
  }, [paypalExpressResponse]);



  return (
    <>
      <div className="image-points mb-2">
        <img
          src="https://cdn.livingitup.co.uk/media/.renditions/wysiwyg/blog/Cards.png"
          alt="Payment Icons"
          title="Payment Icons"
        />
      </div>
      {data && data.paypal_urls?.start && (
        <>
          <span className="mb-3 mt-3 paypal_text">
            {" "}
            You will be directed to a secure paypal checkout where you can pay
            via paypal or with a credit/debit card by selecting the option 'pay
            by debit or credit card'. If you are unable to select this option,
            please call or contact us and we will be able to resolve the order.
          </span>
          {props.showContinueBtn && (
            <CustomButton
              onClick={() => {
                if (!isAuth() && !isGuestEmail()) {
                  window.scrollTo(0, 0);
                  props.setSubmitLogin(true);
                  return;
                } else {
                  window.location.href = data.paypal_urls.start;
                }
              }}
            >
              Continue to Paypal{" "}
            </CustomButton>
          )}
        </>
      )}
    </>
  );
};

export default PaypalExpress;
