import {
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_TO_WISHLIST_ERROR,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  CREATE_EMPTY_CART_ERROR,
  CREATE_EMPTY_CART_REQUEST,
  CREATE_EMPTY_CART_SUCCESS,
  DELETE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  GET_CART_COUNT_FAILED,
  GET_CART_COUNT_SUCCESS,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_REQUEST,
  GET_CART_PAGE_SUCCESS,
  GET_MINI_CART_FAILED,
  GET_MINI_CART_REQUEST,
  GET_MINI_CART_SUCCESS,
  GET_WISHLIST_ERROR,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
} from "./CartPageTypes";

export function getCartResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_CART_PAGE_REQUEST:
      return { ...action.payload };
    case GET_CART_PAGE_SUCCESS:
      return { ...action.payload };
    case GET_CART_PAGE_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function getCartCountResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_CART_COUNT_SUCCESS:
      return { ...action.payload }
    case GET_CART_COUNT_FAILED:
      return { ...action.payload }
    default:
      return state
  }
}

export function deleteCartItemResponse(state = {}, action: any) {
  switch (action.type) {
    case DELETE_CART_REQUEST:
      return { ...action.payload };
    case DELETE_CART_SUCCESS:
      return { ...action.payload };
    case DELETE_CART_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function createCartResponse(state = {}, action: any) {
  switch (action.type) {
    case CREATE_EMPTY_CART_REQUEST:
      return { ...action.payload };
    case CREATE_EMPTY_CART_SUCCESS:
      return { ...action.payload };
    case CREATE_EMPTY_CART_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function addProductToCart(state = {}, action: any) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_SUCCESS:
      return { ...action.payload };
    case ADD_PRODUCT_TO_CART_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function wishlistResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_WISHLIST_REQUEST:
      return { ...action.payload };
    case GET_WISHLIST_SUCCESS:
      return { ...action.payload };
    case GET_WISHLIST_ERROR:
      return { ...action.payload };
    case ADD_TO_WISHLIST_REQUEST:
      return { ...action.payload };
    case ADD_TO_WISHLIST_SUCCESS:
      return { ...action.payload };
    case ADD_TO_WISHLIST_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export const getMiniCartResponse = (state = {}, action: any) => {
  switch (action.type) {
    case GET_MINI_CART_REQUEST:
      return { ...action.payload }
    case GET_MINI_CART_SUCCESS:
      return { ...action.payload }
    case GET_MINI_CART_FAILED:
      return { ...action.payload }
    default:
      return state
  }
}