import { useState } from "react";
import { useDispatch } from "react-redux";
import arrowRight from "../../Assets/Images/arrow-right.svg";
import { submitGuestNewsletter } from "../../Redux/General/GeneralAction";
import validate from "../../Utility/Validation";
import InputGroups from "../Common/InputGroups/InputGroups";

const BlogBanner = ({ cmsData }: any) => {
  const dispatch = useDispatch();
  const setFormData = (field: string, value: string) => {
    let details = Object.assign({}, form);
    details[field].value = value;
    setForm({ ...details });
  };
  const INITIAL_DATA: any = {
    email: {
      value: "",
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
  };

  const [form, setForm] = useState<any>(INITIAL_DATA);
  const [error, setError] = useState({
    email: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);
  const submit = () => {
    if (validate(form).isValidated) {
      dispatch(submitGuestNewsletter(form.email.value));
      setFormData("email", "");
      setIsFormValidated(false);
    } else {
      const mesage = { ...validate(form)?.errors };
      setError(mesage);
      setIsFormValidated(true);
    }
  };
  return cmsData && cmsData !== "" ? (
    <div className="bl-topinfo">
      <div className="blbanner-info">
        <div className="section-desc">
          <h1 className="caps-on">Blogs</h1>
          <p className="bm">
            Subscribe to receive communications from us about our products,
            services and tips to transform your home.
          </p>
          <div className="input-action">
            <div className="input-groups footer-newsletter">
              <label className="lm"></label>
              <InputGroups
                placeholder="Email Address"
                id="email"
                value={form.email.value}
                type="email"
                onChange={(e: any) => setFormData("email", e.target.value)}
                error={
                  isFormValidated && error["email"] ? error["email"] : null
                }
              />
            </div>
            <button className="submit-action" onClick={submit}>
              <img
                title="Newsletter Email"
                src={arrowRight}
                alt="Newsletter Email"
              />
            </button>
          </div>
          <p className="bm">Unsubscribe anytime.</p>
        </div>
      </div>
      <div
        className="blbanner-img"
        dangerouslySetInnerHTML={{ __html: cmsData }}
      ></div>
    </div>
  ) : null;
};
export default BlogBanner;
