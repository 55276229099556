import { ERROR } from "../../../Constants/Labels";
import { SUCCESS_RESPONSE_CODE } from "../../../Constants/Status";
import {
  getCMSBlock,
  getGraphQLDataByPage,
} from "../../../Services/graphQL.service";
import { showToast } from "../../../Utility/Utilities";
import {
  GRAPHQL_HOMEPAGE_API_FAILED,
  GRAPHQL_HOMEPAGE_API_REQUEST,
  GRAPHQL_HOMEPAGE_API_SUCCESS,
  HEADER_BANNER_CMS_BLOCK_FAILED,
  HEADER_BANNER_CMS_BLOCK_SUCCESS,
} from "./HomepageTypes";

export function getHomepageData() {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GRAPHQL_HOMEPAGE_API_REQUEST,
        payload: { type: GRAPHQL_HOMEPAGE_API_REQUEST },
      });
      const { data: response } = await getGraphQLDataByPage("home");
      const { component: graphQLResponse } = response.data;
      if (graphQLResponse.statusCode === SUCCESS_RESPONSE_CODE) {
        dispatch({
          type: GRAPHQL_HOMEPAGE_API_SUCCESS,
          payload: {
            type: GRAPHQL_HOMEPAGE_API_SUCCESS,
            data: graphQLResponse,
          },
        });
      } else {
        dispatch({
          type: GRAPHQL_HOMEPAGE_API_FAILED,
          payload: {
            type: GRAPHQL_HOMEPAGE_API_FAILED,
            message: "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(
        "SOMETHING WENT WRONG WHILE FETCHING HOMEPAGE DATA ",
        error
      );
      dispatch({
        type: GRAPHQL_HOMEPAGE_API_FAILED,
        payload: { type: GRAPHQL_HOMEPAGE_API_FAILED, message: error },
      });
    }
  };
}

export const getHeaderBannerCMSBlockAction = () => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getCMSBlock(`"header-top-center-panel"`);
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
      }
      dispatch({
        type: HEADER_BANNER_CMS_BLOCK_SUCCESS,
        payload: {
          type: HEADER_BANNER_CMS_BLOCK_SUCCESS,
          data: response?.data?.cmsBlocks?.items[0],
        },
      });
    } catch (error: any) {
      showToast(ERROR, error.message);
      dispatch({
        type: HEADER_BANNER_CMS_BLOCK_FAILED,
        payload: {
          type: HEADER_BANNER_CMS_BLOCK_FAILED,
          data: error.message,
        },
      });
    }
  };
};
