import { useState } from "react";

const PasswordGroups = (props: any) => {

  const [isActive, setIsActive] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  function handleTextChange(text: any) {
    text !== '' ? setIsActive(true) : setIsActive(false);
  }

  const togglePassword = (e: any) => {
    e.preventDefault()
    setPasswordShown(!passwordShown);
  };

  const topLabel = (e: any) => {
    handleTextChange(e.target.value);
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.value);
    }
  }


  return (
    <div className="input-groups password">
      <label className={isActive ? "active lm" : "lm"}>
        {props.label}
      </label>
      <div className="pwd-wrap">
        <input
          type={passwordShown ? "text" : "password"}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          value={props.value}
          name={props.name}
          id={props.id}
          className={props.error ? 'error' : ''}
          onChange={(e) => topLabel(e)}
        />
        <button className={passwordShown ? "visibilitybtn show" : "visibilitybtn hide"} onClick={(e) => togglePassword(e)}></button>
      </div>
      {
        props.error ?
          <span className="error">{props.error}</span> : null
      }
    </div>
  )
}

export default PasswordGroups;