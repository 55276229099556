/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitGuestNewsletter } from "../../Redux/General/GeneralAction";
import validate from "../../Utility/Validation";
import InputGroups from "../Common/InputGroups/InputGroups";
import { Link, useLocation } from "react-router-dom";
import DivideByDesktopImage from "../../Assets/Images/dividebuy_footer_desktop.jpg";
import DivideByMobileImage from "../../Assets/Images/dividebuy_footer_mobile.jpg";
import { INTEREST_FREE_CREDIT } from "../../Constants/Route";

export const FooterTop = () => {
  const INITIAL_DATA: any = {
    email: {
      value: "",
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
  };
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>(INITIAL_DATA);
  const [error, setError] = useState({ email: null });
  const [isFormValidated, setIsFormValidated] = useState(false);
  const response = useSelector((state: any) => state.guestNewsLetterResponse);
  const location = useLocation();

  useEffect(() => {
    if (response) {
      setForm(INITIAL_DATA);
      setIsFormValidated(false);
      setError({ email: null });
    }
  }, [response]);

  useEffect(() => {
    setForm(INITIAL_DATA);
    setIsFormValidated(false);
    setError({ email: null });
  }, [location?.pathname])

  const submit = () => {
    if (validate(form).isValidated) {
      dispatch(submitGuestNewsletter(form.email.value));
      setFormData("email", "");
      setIsFormValidated(false);
    } else {
      const message = { ...validate(form)?.errors };
      setError(message);
      setIsFormValidated(true);
    }
  };

  const setFormData = (field: string, value: string) => {
    let details = Object.assign({}, form);
    details[field].value = value;
    setForm({ ...details });
  };

  return (
    <>
      <div className="custom-container">
      <Link to={INTEREST_FREE_CREDIT} className="divideby_img">
        <img className="show-768" src={DivideByDesktopImage} alt="Divideby Payment" />
        <img className="hide-767" src={DivideByMobileImage} alt="Divideby Payment" />
      </Link>
      </div>      
      <div className="footer-top">
        <div className="custom-container">
          <div className="footer-top-wrap">
            <div className="help-section">
              <p className="ts help-title caps-on">Need Help?</p>
              <p className="bm desc-text">
                Have questions regarding your purchase, need clarifications or
                advice while choosing a product?
              </p>
              <div className="additional-links">
                <a className="ll point-tel" href="tel:01162695960">
                  0116 269 5960
                </a>{" "}
                <span className="ll point-schedule">Mon-Fri | 9am-5pm</span>{" "}
                <a className="ll point-email" href="mailto:info@livingitup.co.uk">
                  info@livingitup.co.uk
                </a>{" "}
                <span className="ll point-addr">
                  Unit 3, 5 Knights Close, Thurmaston, Leicester, LE4 8EW
                </span>
              </div>
              <div className="additional-links">&nbsp;</div>
            </div>
            <div className="newsletter-section">
              <p className="ts help-title caps-on">{"Subscribe"}</p>
              <p className="bm desc-text">
                {
                  "Subscribe to receive offers and updates on our products and services."
                }
              </p>
              <div className="input-action">
                <InputGroups
                  placeholder="Email Address"
                  className="footer-newsletter"
                  id="email"
                  value={form.email.value}
                  type="email"
                  onChange={(e: any) => setFormData("email", e.target.value)}
                  error={
                    isFormValidated && error["email"] ? error["email"] : null
                  }
                />
                <button className="submit-action" onClick={submit}>
                  {/* <img src={ArrowRight} alt="Newsletter Email" /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
