/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import {
  CHECKOUT,
  CONTACT_US,
  LOGIN,
  MY_DETAIL,
  ORDER_SUCCESS,
  SIGN_UP,
} from "../../Constants/Route";
import { useDispatch, useSelector } from "react-redux";
import {
  LABEL_CONTACT_US,
  LABEL_CREATE_ACCOUNT,
  LABEL_LOGIN,
} from "../../Constants/Labels";
import { useLocation } from "react-router";
import { GET_USER_DETAIL_SUCCESS } from "../../Redux/Auth/AuthType";
import { getHeaderBannerCMSBlockAction } from "../../Redux/Pages/homepage/HomepageAction";

const BadgeHeader = () => {
  const urls = [CHECKOUT, ORDER_SUCCESS];
  const [freeSampleModalShow, setFreeSampleModalShow] = useState(false);
  const data = useSelector((state: any) => state.loginResponse?.data);
  const userData = useSelector((state: any) => state?.userResponse);
  const [currentLocation, setCurrentLocation] = useState(String);
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);
  const navigate: any = useLocation();
  const dispatch = useDispatch();
  const [userInformation, setUserInformation] = useState<any>({});

  const headerBannerResponse = useSelector(
    (state: any) => state.headerBannerReducer
  );

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  useEffect(() => {
    dispatch(getHeaderBannerCMSBlockAction());
  }, []);

  useEffect(() => {
    if (userData && userData.type === GET_USER_DETAIL_SUCCESS) {
      setUserInformation(userData?.data);
    }
  }, [userData]);

  return (
    <>
      <div
        className={
          urls.includes(currentLocation)
            ? "header-panel in-checkout"
            : "header-panel"
        }
      >
        <div className="custom-container">
          <div className="panel-info">
            <span
              className="lm free-sample"
              onClick={() => setFreeSampleModalShow(true)}
            >
              <p>
                <span className="lm free-sample">
                  Order Free Fabric Samples
                </span>
              </p>
            </span>
            <span className="ll center-line">
              {headerBannerResponse?.data?.content &&
                headerBannerResponse?.data?.content !== "" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: headerBannerResponse?.data?.content,
                    }}
                  />
                )}
            </span>
            <div className="authentication-wrap">
              {!data ? (
                <>
                  <Link className="lm login" to={LOGIN} title={LABEL_LOGIN}>
                    {LABEL_LOGIN}
                  </Link>
                  <Link
                    className="lm c-acc"
                    to={SIGN_UP}
                    title={LABEL_CREATE_ACCOUNT}
                  >
                    {LABEL_CREATE_ACCOUNT}
                  </Link>
                </>
              ) : (
                userInformation &&
                userInformation.firstname && (
                  <Link
                    className="lm c-acc"
                    to={MY_DETAIL}
                    title={userInformation?.firstname}
                  >
                    Hello,{" "}
                    {userInformation?.firstname +
                      " " +
                      userInformation?.lastname}
                  </Link>
                )
              )}
              <Link className="lm contact" to={CONTACT_US} title="Contact Us">
                {LABEL_CONTACT_US}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FreeSamplesPopup
        show={freeSampleModalShow}
        storeConfigInfo={storeConResponse}
        onHide={() => setFreeSampleModalShow(false)}
      />
    </>
  );
};

export default BadgeHeader;
