/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LABEL_FORGOT_PASSWORD, LABEL_LOGIN } from "../../Constants/Labels";
import { FORGOT_PWD } from "../../Constants/Route";
import {
  getAddresses,
  getUserDetail,
  loginAction,
} from "../../Redux/Auth/AuthAction";
import { LOGIN_FAILED, LOGIN_SUCCESS } from "../../Redux/Auth/AuthType";
import { getCartAction } from "../../Redux/Pages/CartPage/CartPageActions";
import {
  checkCustomerEmailAvailable,
  getCheckoutDelvMethod,
} from "../../Redux/Pages/Checkout/CheckoutAction";
import { CHECK_EMAIL_AVAILABLE_SUCCESS } from "../../Redux/Pages/Checkout/CheckoutTypes";
import { isAuth, isGuestEmail, removeSessionItem } from "../../Utility/Utilities";
import validate from "../../Utility/Validation";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";
import { GUEST_EMAIL } from '../../Constants/LocalStorage';

const CheckoutGuestEmail = (props: {
  setGuestUser: any;
  isGuestUser: string;
  setMobileState: any;
  mobileState: any;
  submitLogin: any;
}) => {
  const dispatch = useDispatch();
  const INITIAL_LOGIN_DATA: any = {
    email: {
      value: "",
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: "",
      validation: ["min:8", "lower", "upper", "digit", "required", "special"],
      errors: [
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
        "Password is required.",
        "Must contain atleast 8 character, 1 lowercase, 1 uppercase, 1 number, 1 special character",
      ],
    },
  };

  const [loginData, setLoginData] = useState(INITIAL_LOGIN_DATA);
  const [loginDataError, setLoginDataError] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormValidated, setIsFormValidated] = useState(false);

  const response = useSelector((state: any) => state.checkoutResponse);
  const loginResponse = useSelector((state: any) => state.loginResponse);

  useEffect(() => {
    const isGuest = isGuestEmail();
    if (isGuest) {
      setLoginData({
        ...loginData,
        email: {
          ...loginData.email,
          value: isGuest,
        },
      });
    }
    return () => {
      setIsFormValidated(false);
      setLoginDataError({});
    };
  }, []);

  useEffect(() => {
    if (
      props.mobileState &&
      props.mobileState.step === 1 &&
      props.mobileState.isClicked
    ) {
      if (!isAuth() && !isGuestEmail()) {
        window.scroll({ top: 1 });
        onLogin();
      }
    }
  }, [props.mobileState]);

  useEffect(() => {
    if (loginResponse && loginResponse.type === LOGIN_SUCCESS) {
      setLoading(false);
      props.setGuestUser("");
      dispatch(getUserDetail(false));
      dispatch(getCartAction());
      dispatch(getAddresses());
      dispatch(getCheckoutDelvMethod());
    } else if (loginResponse.type === LOGIN_FAILED) {
      setLoading(false);
    }
  }, [loginResponse]);

  useEffect(() => {
    if (response && response.type === CHECK_EMAIL_AVAILABLE_SUCCESS) {
      props.setGuestUser(response.data ? loginData.email.value : "no");
    }
  }, [response]);

  useEffect(() => {
    if (props.submitLogin) {
      onLogin();
    }
  }, [props.submitLogin]);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, loginData);
    loginDetails[field].value = value;
    setLoginData({ ...loginDetails });
    if (field === "email") {
      localStorage.setItem("guest_email", value);
    }
  };

  const onLogin = () => {
    setLoading(true);
    if (validate(loginData).isValidated === true) {
      dispatch(
        loginAction({
          email: loginData.email.value,
          password: loginData.password.value,
        })
      );
    } else {
      setTimeout(() => setLoading(false), 3000);
      setLoginDataError({ ...validate(loginData)?.errors });
      setIsFormValidated(true);
    }
  };

  const checkUser = (e: any) => {
    let emailData = Object.assign({}, loginData);
    delete emailData["password"];
    const validateData = validate(emailData);
    setIsFormValidated(!validateData.isValidated);
    if (validateData.isValidated === true) {
      dispatch(checkCustomerEmailAvailable(e.target.value));
    } else {
      removeSessionItem(GUEST_EMAIL);
      setLoginDataError({ ...validateData?.errors });
    }
  };

  // onKeyDown = { checkUser };
  return (
    <div className="email-input">
      <InputGroups
        type="email"
        value={loginData.email.value}
        label="Email Address*"
        onBlur={checkUser}
        onChange={(e: any) => setData("email", e.target.value)}
        error={
          isFormValidated && loginDataError["email"]
            ? loginDataError["email"]
            : null
        }
      />
      {props.isGuestUser === "no" && loginData?.email?.value ? (
        <>
          <PasswordGroups
            onChange={(e: any) => setData("password", e)}
            error={
              isFormValidated && loginDataError["password"]
                ? loginDataError["password"]
                : null
            }
            label="Password*"
          />
          <div className="forgotpass">
            <Link to={FORGOT_PWD} className="bm">
              {LABEL_FORGOT_PASSWORD}
            </Link>
          </div>
          <CustomButton
            isLoading={loading}
            type="submit"
            onClick={() => onLogin()}
            bg={"fill"}
          >
            {LABEL_LOGIN}
          </CustomButton>
        </>
      ) : null}
    </div>
  );
};

export default CheckoutGuestEmail;
