/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import { deleteAddress, getAddresses } from "../../../Redux/Auth/AuthAction";
import {
  CREATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_SUCCESS,
  GET_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
} from "../../../Redux/Auth/AuthType";
import RemovePopup from "../../Home/MiniCart/RemovePopup/RemovePopup";
import Sidebar from "../Sidebar/Sidebar";
import AddEditAddressPopup from "./AddNewAddressPopup";
import MyAddressesSkl from "./MyAddressesSkl";

const MyAddress = () => {
  const dispatch = useDispatch();

  const [addressPopup, setAddress] = useState<any>(null);

  const [showAddressPopup, setShowAddressPopup] = useState(false);

  const response = useSelector((state: any) => state.addressResponse);

  const [opLoading, setOpLoading] = useState(false);

  const [addressId, setAddressId] = useState<any>(null);

  const [isEdit, setEdit] = useState(false);

  const [data, setData] = useState<any>({
    addresses: [],
    country_name: "United Kingdom",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAddresses());
  }, []);

  useEffect(() => {
    if (response && response.type === GET_ADDRESS_SUCCESS) {
      setLoading(false);
      response.data.addresses.forEach((res: any) => {
        res.state = res.region.region;
      });
      setData(response.data);
    } else if (response && response.type === DELETE_ADDRESS_SUCCESS) {
      setOpLoading(false);
      const currentState = Object.assign({}, data);
      currentState.addresses = currentState.addresses.filter(
        (address: { id: number }) => address.id !== addressId
      );
      setData(currentState);
      setAddressId(null);
    } else if (response && response.type === DELETE_ADDRESS_FAILED) {
      setOpLoading(false);
      setAddressId(null);
    } else if (response && response.type === UPDATE_ADDRESS_SUCCESS) {
      setShowAddressPopup(false);
      const currentState = Object.assign({}, data);
      const index: number = currentState.addresses.findIndex(
        (s: { id: number }) => s.id === response.data.id
      );
      currentState.addresses[index] = response.data;
      setData(currentState);
    } else if (response && response.type === CREATE_ADDRESS_SUCCESS) {
      const currentState = Object.assign({}, data);
      currentState.addresses.push(response.data);
      setData(currentState);
      setShowAddressPopup(false);
    }
  }, [response]);

  const onDeleteAddress = () => {
    setOpLoading(true);
    dispatch(deleteAddress(addressId));
  };

  return (
    <>
      {loading ? (
        <MyAddressesSkl />
      ) : (
        <div className="account-container">
          <div className="ll hide-991">
            <Link to={MY_DETAIL} className="acc-backbtn ll">
              My Account
            </Link>
          </div>
          <div className="account-wrap">
            <Sidebar />
            <div className="acc-rightwrap">
              <div className="myaddress-wrap">
                <h1 className="caps-on">Address Book</h1>
                <div className="addr-top">
                  <span className="tm">My Addresses</span>
                  <span
                    title="Add New Address"
                    className="bm adr"
                    onClick={() => {
                      setEdit(false);
                      setShowAddressPopup(true);
                      setAddress(null);
                    }}
                  >
                    Add New Address
                  </span>
                </div>
                <ul className="addr-list">
                  {data.addresses && data.addresses.length > 0 ? (
                    data.addresses?.map((item: any, index: number) => (
                      <li className="add-item" key={item.id + index}>
                        <div className="addr-details">
                          <p className="ll">
                            {item.firstname + " " + item.lastname}
                          </p>
                          <address className="bm">
                            {item.street?.join(", ") +
                              ", " +
                              (item.region?.region
                                ? item.region?.region + ","
                                : "") +
                              data.country_name +
                              ", " +
                              item.postcode}
                          </address>
                          <Link to={""} title="Mobile No" className="ll">
                            {item.telephone}
                          </Link>
                        </div>
                        <div className="addr-action">
                          <Link
                            to={""}
                            title="Edit"
                            className="bm edit"
                            onClick={() => {
                              setAddress(item);
                              setEdit(true);
                              setShowAddressPopup(true);
                            }}
                          >
                            Edit
                          </Link>
                          <Link
                            to={""}
                            title="Remove"
                            onClick={() => setAddressId(item.id)}
                            className="bm remove"
                          >
                            Remove
                          </Link>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="ll">You don't have any saved address.</p>
                  )}
                </ul>
              </div>
            </div>
            <AddEditAddressPopup
              address={addressPopup}
              isEdit={isEdit}
              show={showAddressPopup}
              onHide={() => setShowAddressPopup(false)}
            />
            <RemovePopup
              loading={opLoading}
              text="Are you sure you would like to remove this address?"
              onCancel={() => setAddressId(null)}
              onSubmit={onDeleteAddress}
              show={addressId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MyAddress;
