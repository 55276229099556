/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setShippingAddress } from "../../../Redux/Pages/Checkout/CheckoutAction";
import { isAuth, isGuestEmail } from "../../../Utility/Utilities";

const MobCkDeliveryAddress = (props: {
  cartResponse: any;
  addresses: any;
  showAddressPopup: boolean;
  setShowAddressPopup: any;
  selectedShippingAddress: any;
  setSelectedShippingAddress: any;
  mobileState: any;
  setMobileState: any;
  isGuestUser: string;
  radioStateClass: number;
  setRadioClass: any;
  setAddress: any;
  setAddressId: any;
  addressPopup: any;
  opLoading: boolean;
  onDeleteAddress: any;
  addressId: number;
  setEdit: any;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.mobileState &&
      props.mobileState.step === 1 &&
      props.mobileState.isClicked &&
      props.cartResponse.data &&
      props.cartResponse &&
      props.cartResponse.data &&
      props.cartResponse.data.items &&
      props.cartResponse.data.items.length > 0
    ) {
      if (isAuth()) {
        props.setMobileState({
          ...props.mobileState,
          isClicked: false,
          loading: true,
        });
        dispatch(
          setShippingAddress(props.addresses.addresses[props.radioStateClass])
        );
      } else {
        if (isGuestEmail() && props.selectedShippingAddress) {
          props.setMobileState({
            ...props.mobileState,
            loading: false,
            isClicked: false,
            buttonTitle: "Continue",
            headerTitle: "Select Delivery Address",
            step: 2,
          });
        }
      }
    }
  }, [props.mobileState]);

  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <p className="tm">Select Delivery Address</p>
      </div>
      {
        <div className="ckout-content">
          {props.addresses.addresses && props.addresses.addresses.length > 0 ? (
            props.addresses.addresses
              ?.filter((data: any) => data)
              .map((item: any, index: number) => (
                <div
                  key={item.firstname + index}
                  className={
                    props.radioStateClass === index
                      ? "ckout-mid-box bg-surface"
                      : "ckout-mid-box"
                  }
                >
                  <div className="ckout-address">
                    <div className="rd">
                      <input
                        type="radio"
                        checked={props.radioStateClass === index}
                        name="addr-selected"
                        onChange={(e) => props.setRadioClass(index)}
                        defaultChecked={index === 0 ? true : false}
                      />
                    </div>
                    <div className="ckout-txt">
                      <p className="bm">
                        {item.firstname + " " + item.lastname}
                      </p>
                      <p className="bm">
                        {item.street?.join(", ") +
                          ", " +
                          (item.region?.region
                            ? item.region?.region + ","
                            : "") +
                          props.addresses.country_name +
                          ", " +
                          item.postcode}
                      </p>
                      <Link to={""} className="bm">
                        {item.telephone}
                      </Link>
                    </div>
                  </div>
                  <div className="ckout-edit">
                    <Link
                      to={""}
                      title="Edit"
                      className="bm with-border"
                      onClick={() => {
                        if (!isAuth()) {
                          props.setEdit(true);
                          props.setAddress(props.selectedShippingAddress);
                          props.setShowAddressPopup(true);
                        } else {
                          props.setEdit(true);
                          props.setAddress(item);
                          props.setSelectedShippingAddress(null);
                          props.setShowAddressPopup(true);
                        }
                      }}
                    >
                      Edit
                    </Link>
                    {isAuth() && (
                      <Link
                        to={""}
                        title="Remove"
                        onClick={() => props.setAddressId(item.id)}
                        className="bm with-border"
                      >
                        Remove
                      </Link>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <p className="ll alignboth-center">
              You don't have any saved address.
            </p>
          )}
        </div>
      }
    </div>
  );
};

export default MobCkDeliveryAddress;
