import { Link } from "react-router-dom";

const SellingPoints = (props: { data: any[], setFreeSampleModalShow: any }) => {

  const onFuncCall = (e: any, data: any) => {
    e.preventDefault()
    if(e.currentTarget.id === 'samplepop0') {
      props.setFreeSampleModalShow(true)
    }
  };

  return (
    <div className="sp-wrap">
      <div className="custom-container">
        <div className="point-list">
          {
            props.data.map((data, index) => {
              return <div key={data.elementId} className="sub-point fabric">
                <Link to={""} className="point-links" title={data.title} id={"samplepop" + index} onClick={(e) => onFuncCall(e, data)}>
                  <img src={data.image} height={48} width={48} alt={data.title}></img>
                  <span className="tm">{data.title}</span>
                </Link>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default SellingPoints;