import { CATEGORY_LIST_FAILED, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from "./CategoryListTypes";
import { getCategoryList } from "../../../Services/graphQL.service";
import { getSessionItem } from "../../../Utility/Utilities";
import { MEGA_MENU } from "../../../Constants/LocalStorage";

export const getCategoryListAction = (uid: string) => {
  return (async (dispatch: any) => {
    dispatch({ type: CATEGORY_LIST_REQUEST })
    try {
      let response = getSessionItem(MEGA_MENU)
      response = !response ? await getCategoryList(uid) : { data: { data: JSON.parse(response) } };
      const data = response?.data?.data;
      if (data) {
        // setSessionItem(MEGA_MENU, JSON.stringify(data))
        dispatch({
          type: CATEGORY_LIST_SUCCESS,
          payload: {
            type: CATEGORY_LIST_SUCCESS,
            data
          }
        })
      }
      else {
        dispatch({
          type: CATEGORY_LIST_FAILED,
          payload: {
            type: CATEGORY_LIST_FAILED,
            message: response.errors[0]?.message
          }
        })
      }
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST_FAILED,
        payload: { type: CATEGORY_LIST_FAILED, message: error },
      })
    }
  })
}