import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from '@stripe/react-stripe-js';
import StripeCheckoutForm from "./StripeCheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "");


export default function StripeCheckout({ submitStripeForm, setSubmitStripeForm, showContinueButton , mobileState, setMobileState , addressData , setSubmitLogin}: any) {

  const options: any = {
    mode: 'payment',
    amount: 1200,
    currency: 'usd',
    fonts: [
      {
        family: 'Inter',
        cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
      }
    ],
    appearance: {
      variables: {
        fontFamily: "Inter",
        colorDanger: "#f94c43",
        borderRadius: "4px"
      },
      rules: {
        '.Label': {
          color: "#212B36",
          fontSize: "12px",
          fontWeight: "500",
          marginBottom: "8px",
          lineHeight: "16px",
          display: "block",
          letterSpacing: "0.5px"
        },
        '.Input': {
          borderColor: '#cccccc',
          boxShadow: "none"
        },
        '.Input:focus': {
          borderColor: '#cccccc',
          boxShadow: "none"
        },
      }
    },

  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm addressData={addressData} showContinueButton={showContinueButton} submitStripeForm={submitStripeForm} setSubmitStripeForm={setSubmitStripeForm} mobileState={mobileState} setMobileState={setMobileState} setSubmitLogin={setSubmitLogin}/>
      {/* <StripeCheckoutFormNew /> */}
    </Elements>
  )
}
