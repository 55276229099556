import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Login from "./Components/Authentication/Login";
import Signup from "./Components/Authentication/Signup";
import Footer from "./Components/Footer/Footer";
import Headers from "./Components/Headers/Headers";
import Home from "./Components/Home/Home";
import {
  BLOG_CATEGORY_PAGE,
  BLOG_DETAIL,
  BLOG_LISTING,
  BLOG_SEARCH_CATEGORY,
  CART_PAGE,
  CHECKOUT,
  CONTACT_US,
  DIVIDEBUY_PROGRESS,
  CUSTOMER_SHOWCASE,
  DYNAMIC_ROUTE,
  DYNAMIC_ROUTE2,
  FORGOT_PWD,
  HOME,
  LOGIN,
  MY_ADDRESS,
  MY_DETAIL,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
  ORDER_DETAILS,
  ORDER_SUCCESS,
  PAYMENT_PROGRESS,
  PAYPAL_RETURN_URL,
  RAPYD_PROGRESS,
  RESET_PWD,
  SEARCH_CATEGORY,
  SIGN_UP,
  SOMETHING_WRONG,
  STRIPE_PROGRESS,
} from "./Constants/Route";
import Main from "./Main";
import CartPage from "./Components/CartPage/CartPage";
import MyAddress from "./Components/MyAccount/MyAddress/MyAddress";
import MyDetails from "./Components/MyAccount/MyDetails/MyDetails";
import MyOrders from "./Components/MyAccount/MyOrders/MyOrders";
import Newsletter from "./Components/MyAccount/Newsletter/Newsletter";
import OrderDetail from "./Components/MyAccount/OrderDetail/OrderDetail";
import Wishlist from "./Components/MyAccount/Wishlist/Wishlist";
import BlogPage from "./Components/Blogs/BlogPage";
import ContactUs from "./Components/CMSPages/ContactUs";
import ProtectedRoute from "./ProtectedRoutes";
import OrderSuccess from "./Components/Checkout/OrderSuccess/OrderSuccess";
import Checkout from "./Components/Checkout/Checkout";
import { ToastContainer } from "react-toastify";
import SearchCategory from "./Components/SearchCategory/SearchCategory";
import BlogDetails from "./Components/Blogs/BlogDetails";
import PaypalSuccess from "./Components/PaypalSuccess";
import SomethingWrongPage from "./Components/ErrorPages/SomethingWentWrongPage";
import ScrollToTop from "./Utility/ScrollTop";
import PaymentProgress from "./Components/Checkout/PaymentProgress";
import AllowCookiesPopup from "./Components/AllowCookiesPopup/AllowCookiesPopup";
import StripePaymentProgress from "./Components/Checkout/StripeCheckout/StripePaymentProgress";
import PageNotFound from './Components/ErrorPages/PageNotFound';
import DivideBuyProgress from "./Components/Checkout/DivideBuy/DivideBuyProgress/DivideBuyProgress";
import CustomerShowCase from "./Components/CustomerShowCase/CustomerShowCase";

const RouterConfig = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Headers />
      <ToastContainer />
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={CUSTOMER_SHOWCASE} element={<CustomerShowCase />} />
        <Route path={SOMETHING_WRONG} element={<SomethingWrongPage />} />
        <Route path={DYNAMIC_ROUTE} element={<Main />} />
        <Route path={DYNAMIC_ROUTE2} element={<Main />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<Signup />} />
        <Route path={FORGOT_PWD} element={<ForgotPassword />} />
        <Route path={RESET_PWD} element={<ResetPassword />} />
        <Route path={CART_PAGE} element={<CartPage />} />
        {/* Protected routes */}
        <Route
          path={MY_ORDERS_PAGE}
          element={
            <ProtectedRoute>
              <MyOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_WISHLIST}
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_DETAIL}
          element={
            <ProtectedRoute>
              <MyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_ADDRESS}
          element={
            <ProtectedRoute>
              <MyAddress />
            </ProtectedRoute>
          }
        />
        <Route
          path={NEWSLETTER}
          element={
            <ProtectedRoute>
              <Newsletter />
            </ProtectedRoute>
          }
        />
        <Route
          path={ORDER_DETAILS + ":orderId"}
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route path={BLOG_LISTING} element={<BlogPage />} />
        <Route path={BLOG_CATEGORY_PAGE} element={<BlogPage />} />
        <Route path={BLOG_SEARCH_CATEGORY} element={<BlogPage />} />
        <Route path={BLOG_DETAIL} element={<BlogDetails />} />
        <Route path={CONTACT_US} element={<ContactUs />} />
        <Route path={ORDER_SUCCESS} element={<OrderSuccess />} />
        <Route path={CHECKOUT} element={<Checkout />} />
        <Route path={SEARCH_CATEGORY} element={<SearchCategory />} />
        <Route path={PAYPAL_RETURN_URL} element={<PaypalSuccess />} />
        <Route path={PAYMENT_PROGRESS} element={<PaymentProgress />} />
        <Route path={RAPYD_PROGRESS} element={<PaymentProgress />} />
        <Route path={DIVIDEBUY_PROGRESS} element={<DivideBuyProgress />} />
        
        <Route path={STRIPE_PROGRESS} element={<StripePaymentProgress />} />
        <Route path="*"  element={<PageNotFound />} />
      </Routes>
      <Footer />
      {/* <AllowCookiesPopup /> */}
    </BrowserRouter>
  );
};

export default RouterConfig;
