/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCMSPageAction } from "../../Redux/Pages/CMSPage/CMSPageAction";
import {
  GET_CMS_PAGE_FAILED,
  GET_CMS_PAGE_SUCCESS,
} from "../../Redux/Pages/CMSPage/CMSPageTypes";
import CMSPageSkl from "./CMSPageSkl";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import { useLocation } from "react-router";
import { setSessionItem } from "../../Utility/Utilities";
import { PRODUCT_DETAILS_PREVIOUS_URL } from "../../Constants/LocalStorage";

export const CMSPages = (props: { page: string }) => {
  const dispatch = useDispatch();
  const location = useLocation()

  const dataResponse = useSelector((state: any) => state.cmsResponse);

  const [loading, setloading] = useState(true);

  const [data, setData] = useState<any>({});

  const [error, setError] = useState("");
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  useEffect(() => {
    dispatch(getCMSPageAction(props.page));
  }, []);

  const [freesampleModalShow, setfreesampleModalShow] = useState(false);

  useEffect(() => {
    if (dataResponse) {
      if (dataResponse.type === GET_CMS_PAGE_SUCCESS) {
        setData(dataResponse.data);
        setloading(false);
      } else if (dataResponse.type === GET_CMS_PAGE_FAILED) {
        setError(dataResponse.data);
      }
    }
  }, [dataResponse]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("fspopup")?.addEventListener("click", (e) => {
        setfreesampleModalShow(true);
      });
    }, 2000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("fspopup2")?.addEventListener("click", (e) => {
        setfreesampleModalShow(true);
      });
    }, 2000);
  }, []);

  return (
    <>
      {error ? <p className="text-danger">{error}</p> : null}
      {loading ? (
        <CMSPageSkl />
      ) : (
        <div onClick={() => {
          setSessionItem(PRODUCT_DETAILS_PREVIOUS_URL, location.pathname)
        }} dangerouslySetInnerHTML={{ __html: data.content }} />
      )}
      <FreeSamplesPopup
        storeConfigInfo={storeConResponse}
        show={freesampleModalShow}
        onHide={() => setfreesampleModalShow(false)}
      />
    </>
  );
};
