/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import prevImg from "../../../Assets/Images/chevron-left16.svg"
import nextImg from "../../../Assets/Images/chevron-right16.svg"

function PaginatedItems({ itemsPerPage, totalCount, onItemChange }: any) {
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const [nextPage , setNextPage] = useState(1)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    onItemChange({ start: itemOffset, end: endOffset, nextPage })
    setPageCount(Math.ceil(totalCount / itemsPerPage));
  }, [itemOffset, itemsPerPage, totalCount]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    setItemOffset(newOffset);
    setNextPage(event.selected + 1)
  };

  return (

    <ReactPaginate
      breakLabel="..."
      className="custom-pagination"
      nextLabel={<img src={nextImg} alt="Next"/>}
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel={<img src={prevImg} alt="Prev"/>}
    />
  )
}
export default PaginatedItems;