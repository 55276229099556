/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BLOG_SEARCH_CATEGORY } from "../../Constants/Route";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";

const SideBlog = ({ recentPost, categoryList }: any) => {
  const [searchCate, setSearchCate] = useState("");
  const navigate = useNavigate();

  const submit = () => {
    if (searchCate.trim() !== "") {
      setSearchCate("");
      navigate({
        pathname: BLOG_SEARCH_CATEGORY,
        search: searchCate,
      });
    }
  };

  return (
    <div className="list-sidebar">
      <div className="sear-blog box">
        <p className="tm box-title">Search the blog</p>
        <div className="sear-input">
          <InputGroups
            class="search"
            placeholder="Search..."
            value={searchCate}
            onChange={(e: any) => setSearchCate(e.target.value)}
          />
        </div>
        <div className="sear-action">
          <CustomButton onClick={submit} bg="fill">
            Search
          </CustomButton>
        </div>
      </div>
      {recentPost?.length > 0 ? (
        <div className="recent-posts box">
          <p className="tm box-title">Recent posts</p>
          <ul className="recent-list">
            {recentPost
              ?.filter((data: any) => data)
              .map((data: any, index: any) => (
                <li key={data.title} className="ritem">
                  <Link
                    to={"/blog/" + data.url_key + ".html"}
                    title={data.title}
                    className="ll"
                  >
                    {data.title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      ) : null}
      {categoryList?.length > 0 ? (
        <div className="categories box">
          <p className="tm box-title">Categories</p>
          <ul className="recent-list">
            {categoryList
              ?.filter((data: any) => data)
              .map((data: any, index: any) => (
                <li key={data.title} className="citem">
                  <Link
                    to={"/blog/category/" + data.url_key + ".html"}
                    title={data.name}
                    className="ll"
                  >
                    {data.name} ({data.post_count})
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default SideBlog;
