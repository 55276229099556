import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton/CustomButton";

const CardBanner = (props: { data: any[]; setFreeSampleModalShow: any }) => {
  const navigate = useNavigate();

  const onFuncCall = (e: any, data: any) => {
    e.preventDefault();
    if (e.currentTarget.id === "samplepop00" && (data?.redirectTypeId === "" || data?.redirectTypeId === null)) {
      props.setFreeSampleModalShow(true);
    } else {
      navigate("/" + data.redirectTypeId);
    }
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(window.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(window.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <>
      <div className="custom-container">
        <div className="cardrel-content">
          {props.data.map((data: any, index: number) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              id={"samplepop" + index + "0"}
              title={data.title}
              key={data.elementId + index}
              onClick={(e) => onFuncCall(e, data)}
            >
              <div className="inner-card">
                <div className="cr-image">
                  {windowSize > 767 ? (
                    <img src={data.image} alt={data.title}></img>
                  ) : (
                    <img src={data.mimage} alt={data.title}></img>
                  )}
                </div>
                <div className="cr-desc">
                  <h2 className="caps-on">{data.title}</h2>
                  <p
                    className="bm"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></p>
                  {data.action && data.action.title && (
                    <CustomButton
                      onClick={() => navigate("/" + data.redirectTypeId)}
                      bg={"fill"}
                    >
                      {data.action.title}
                    </CustomButton>
                  )}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default CardBanner;
