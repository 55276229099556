import { IComponent } from "./Components.interface";

export enum TYPES {
    CATEGORY = "CATEGORY",
    CMS_PAGE = "CMS_PAGE",
    PRODUCT_DETAIL = "PRODUCT_DETAIL",
    PRODUCT = "PRODUCT"
}

export interface IComponentResponse {
    message: string;
    status: string;
    statusCode: string;
    component: IComponent[];
}

export interface IURLResponse {
    relative_url: string;
    type: TYPES
}

export interface IGraphQLResponse {
    data: IResponse
}

export interface IURLResolverResponse {
    data: IURLResponse
}
export interface IResponse {
    component: IComponentResponse
}