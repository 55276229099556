import { Tab, Tabs } from "react-bootstrap";
import CustomButton from "../Common/CustomButton/CustomButton";

const FreeSampleColorForm = (props: {
  colorPallets: any[];
  setCurrentState: any;
  selectedColors: any[];
  getColorCode: any;
  onSelectColors: any;
}) => {
  return (
    <>
      {" "}
      <div className="sample-tab-main">
        <Tabs defaultActiveKey="Velvet" className="sample-tabs">
          {props.colorPallets && props.colorPallets.length > 0
            ? props.colorPallets
                .filter((data: any) => data)
                .map((c: any, index: number) => {
                  return (
                    <Tab
                      key={c.label}
                      eventKey={c.label + "Index " + index}
                      title={c.label}
                      className="ll"
                    >
                      <div className="sample-left">
                        <div className="sample-wrap">
                          <p className="ll box-title">{c.label}</p>
                          <p className="bm">{c.description}</p>
                          <div className="sample-main">
                            <div className="sample-checkbox">
                              {c.values && c.values.length > 0
                                ? c.values
                                    .filter((data: any) => data)
                                    .map((cc: any, index: number) => {
                                      return (
                                        <div
                                          key={cc.value}
                                          onClick={() =>
                                            props.onSelectColors(c.label, cc, c)
                                          }
                                          className={`samp-tick ${
                                            props.selectedColors.find(
                                              (s: any) => s.value === cc.value
                                            ) &&
                                            props.selectedColors.length <= 8
                                              ? "selected"
                                              : "disabled"
                                          }`}
                                        >
                                          <input
                                            disabled={
                                              props.selectedColors.length >= 8
                                            }
                                            key={cc.label + index}
                                            type="checkbox"
                                            name={cc.label + index}
                                            id={cc.label + index}
                                            value={cc.value}
                                          />
                                          <label
                                            key={
                                              cc.labenewLabelDescriptionl +
                                              " 1" +
                                              index
                                            }
                                          >
                                            {props.getColorCode(cc)}
                                          </label>
                                        </div>
                                      );
                                    })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  );
                })
            : null}
        </Tabs>
        {props.selectedColors.length > 0 ? (
          <div className="sample-right">
            {props.getColorCode(
              props.selectedColors[props.selectedColors.length - 1]
            )}
            <p className="bm box-title">
              {props.selectedColors[props.selectedColors.length - 1].key}{" "}
              -&nbsp;
              {props.selectedColors[props.selectedColors.length - 1].value}
            </p>
          </div>
        ) : null}
      </div>
      <div className="sample-left left-bottom">
        <div className="sample-wrap">
          <p className="tm">Your Samples</p>
          <div className="sample-main last-samp">
            <div className="sample-checkbox">
              {props.selectedColors
                .filter((data: any) => data)
                .map((cc, index) => (
                  <div key={cc.label} className="samp-tick">
                    <input
                      disabled={props.selectedColors.length >= 8}
                      key={cc.label + index}
                      type="checkbox"
                      name={cc.label + index}
                      id={cc.label + index}
                      value={cc.value}
                    />
                    <label key={cc.label + " 2" + index}>
                      {props.getColorCode(cc)}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="action-bar">
          <CustomButton
            bg={"fill"}
            disabled={props.selectedColors.length < 1}
            onClick={() => props.setCurrentState(2)}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </>
  );
};
export default FreeSampleColorForm;
